import React from 'react';
import { useUser } from 'store/User/Context';

import { HeaderStyle, LegendList, SectionBox } from 'components/analysis/AnalysisSaleList';
import BarWrapper from 'components/common/BarWrapper';

export const AreaRangeTotalPriceBars = ({ totalPriceByArea }) => {
    const { user } = useUser();

    return (
        <SectionBox>
            <HeaderStyle>
                <h2>Cena całkowita według przedziałów powierzchni</h2>
                <LegendList darkMode={user.isDarkMode}>
                    <li>Średnia cena całkowita brutto [PLN]</li>
                    <li>Mediana ceny całkowitej brutto [PLN]</li>
                </LegendList>
                <BarWrapper className="total-price-area" barData={totalPriceByArea}/>
            </HeaderStyle>
        </SectionBox>
    );
};
