import React, { createContext, useCallback, useEffect, useState } from 'react';
import { UserActions } from 'store/User/Actions';
import { useUser } from 'store/User/Context';
import { analysisApiRequests } from 'utils/api-requests/analysis';
import { analysisSaleApiRequests } from 'utils/api-requests/analysis/analysisSale';
import { filtersApiRequests } from 'utils/api-requests/filters';
import { generateUniqueId } from 'utils/formatters/generateUniqueId';
import {
    getAnalysisSaleValueFromState,
    initialState,
    setAnalysisSaleStateValues
} from 'utils/state-managment/analysis/analysisSaleFilter';
import { analysisChartData } from 'utils/state-managment/analysis/analysisSaleState';
import { SaleModule } from 'utils/types/AnalysisModels';
import {
    AnalysisSaleChartData,
    AnalysisSaleFilterReturnData,
    AnalysisSaleFiltersStateType
} from 'utils/types/AnalysisSale';
import { MultipleFilterType, ObjectAndSingleValueChangeType } from 'utils/types/InputTypes';
import { AdvertValidatorType, isFieldValid } from 'utils/validators/advertAddingValidator';

import { activeNotification } from 'components/functions/activeNotification';

export interface AnalysisSaleContextType {
    handleChangePropertyType: (value: SaleModule) => void,
    propertyType: SaleModule,
    analysisSaleFiltersState: AnalysisSaleFiltersStateType,
    activeFiltersState: AnalysisSaleFiltersStateType,
    analysisSaleState: AnalysisSaleChartData,
    handleDeleteFilter: (id: number) => void,
    handleSetFiltersValues: (id: number) => void
    handleChange: (name: string, value: ObjectAndSingleValueChangeType) => void
    validateField: (name: AdvertValidatorType, value: ObjectAndSingleValueChangeType) => boolean
    clearFilters: () => void
    validateAllFields: () => boolean
    getSavedFilters: (id?: number) => void
    handleChangeDateList: (fieldName: string, value: number) => void
    handleActiveFilterChange: (fieldName: string, value: string | number | boolean | MultipleFilterType) => void
    clearAnalysis: () => void,
    loadAnalysis: () => void,
    validateFilters: () => boolean,
    handleSaveOrUpdateFilter: () => void,
}

const values: AnalysisSaleContextType = {
    handleChangePropertyType: () => {},
    handleDeleteFilter: () => {},
    handleSetFiltersValues: () => {},
    handleChange: () => {},
    handleChangeDateList: () => {},
    clearFilters: () => {},
    validateField: () => false,
    validateAllFields: () => false,
    propertyType: SaleModule.SALE_FLAT,
    analysisSaleFiltersState: {} as AnalysisSaleFiltersStateType,
    activeFiltersState: {} as AnalysisSaleFiltersStateType,
    analysisSaleState: {} as AnalysisSaleChartData,
    getSavedFilters: () => {},
    handleActiveFilterChange: () => {},
    clearAnalysis: () => {},
    loadAnalysis: () => {},
    validateFilters: () => false,
    handleSaveOrUpdateFilter: () => {}
};

export const AnalysisSaleContext = createContext(values);

type MappedIdType = Record<string, { id: number, module: SaleModule}>;

export const AnalysisSaleProvider = ({ children }: { children: React.ReactNode }) => {
    const { dispatch: dispatchUser } = useUser();
    const [offerType, setOfferType] = useState<SaleModule>(SaleModule.SALE_FLAT);
    const [analysisSaleFiltersState, setAnalysisSaleFiltersState] = useState(initialState.analysisSaleFilterInitialState());
    const [activeFiltersState, setActiveFiltersState] = useState(initialState.analysisSaleFilterInitialState());
    const [analysisSaleState, setAnalysisSaleState] = useState(analysisChartData);
    const [idMap, setIdMap] = useState<MappedIdType>({});

    const setLoadingPage = () => {
        setAnalysisSaleState((prev) => ({
            ...prev,
            isLoading: true
        }));
    };

    const loadAnalysis = async () => {
        setLoadingPage();

        const response = await analysisApiRequests.getAnalysis(offerType, getAnalysisSaleValueFromState(analysisSaleFiltersState));

        if (response) {
            setAnalysisSaleState((prev) => ({
                ...prev,
                isLoading: false,
                chartData: [response],
                afterSearch: true
            }));
        }
    };

    const validateFilters = () => {
        const isCityEmpty = analysisSaleFiltersState.city.length === 0;
        const isBoundariesEmpty = !analysisSaleFiltersState.boundaries;
        const isReportTypeEmpty = analysisSaleFiltersState.reportType.length === 0;

        if (isCityEmpty || isBoundariesEmpty || isReportTypeEmpty) {
            let message;

            switch (true) {
            case isCityEmpty:
                message = 'Musisz wybrać poprawną miejscowość';
                break;
            case isBoundariesEmpty:
                message = 'Musisz wpisać granice przedziału';
                break;
            case isReportTypeEmpty:
                message = 'Wybierz typ raportu';
                break;
            }
            activeNotification('Popraw wpisane dane', message, 'warning');

            return false;
        }

        return true;
    };

    const handleSaveOrUpdateFilter = async () => {
        if (!validateFilters()) {
            return;
        }

        const existFilter = analysisSaleFiltersState.savedFilter?.find(
            (filter) => filter.id === analysisSaleFiltersState.id
        );

        const existNameFilter = analysisSaleFiltersState.savedFilter?.find(
            (filter) => filter.name === analysisSaleFiltersState.name
        );

        let id;

        if (existNameFilter && !analysisSaleFiltersState.id) {
            activeNotification('Nazwa filtra', 'Istnieje już filtr o takiej nazwie', 'warning');

            return;
        }

        if (existFilter) {
            const id = idMap[existFilter.id].id;

            await analysisSaleApiRequests.updateFilter({
                ...getAnalysisSaleValueFromState({ ...analysisSaleFiltersState, id })
            }, analysisSaleFiltersState.module);
        } else {
            const resp = await analysisSaleApiRequests.addFilter({
                ...getAnalysisSaleValueFromState(analysisSaleFiltersState)
            }, analysisSaleFiltersState.module);

            id = resp?.data.id;
        }

        getSavedFilters(id);
    };

    const clearAnalysis = () => {
        setAnalysisSaleState(analysisChartData);
    };

    const getSavedFilters = useCallback(async (id?: number) => {
        const savedFilters = await analysisSaleApiRequests.getSavedFilters();
        const newIdMap: MappedIdType = {};
        const savedFiltersHashedId = savedFilters.map((x) => {
            const hashedId = generateUniqueId(`${x.id}:${x.module}`);
            newIdMap[hashedId] = { id: x.id, module: SaleModule[x.module] };

            return { ...x, id: hashedId };
        });

        setIdMap((prev) => ({
            ...prev,
            ...newIdMap
        }));

        setAnalysisSaleFiltersState((filters: AnalysisSaleFiltersStateType) => ({
            ...filters,
            savedFilter: savedFiltersHashedId
        }));

        if (id) {
            const hashedId = Object.keys(newIdMap).find((x) => newIdMap[x].id === id);

            await handleSetFiltersValues(Number(hashedId), newIdMap);
        }
    }, [offerType]);

    const clearFilters = useCallback((reset?: boolean) => {
        const initialFilters = initialState.analysisSaleFilterInitialState();

        if (reset) {
            setAnalysisSaleFiltersState((prev) => ({
                ...initialFilters,
                module: offerType,
                locations: prev.locations
            }));
        } else {
            setAnalysisSaleFiltersState((prev) => ({
                ...prev,
                module: offerType,
                locations: prev.locations,
                city: reset ? [] : prev.city,
                savedLocations: reset ? {} : prev.savedLocations,
                floor: initialFilters.floor,
                floors: initialFilters.floors,
                builtYear: initialFilters.builtYear,
                buildingType: initialFilters.buildingType,
                numberOfRooms: initialFilters.numberOfRooms
            }));
        }

        getSavedFilters();
        UserActions.changeErrorVisibility(dispatchUser, false);
    }, [
        dispatchUser,
        getSavedFilters,
        offerType
    ]);

    const handleChangePropertyType = useCallback((value: SaleModule) => {
        setOfferType(value);
        clearFilters();
        setAnalysisSaleFiltersState((prev) => ({
            ...prev,
            id: undefined
        }));
    }, [clearFilters]);

    const handleSetFiltersValues = async (id: number, idMapParam?: MappedIdType) => {
        const currentIdMap = idMapParam || idMap;
        const filterId = currentIdMap[id].id;
        const filterModuleType = currentIdMap[id].module;
        const filterData = await analysisSaleApiRequests.getFilterDetailsAnalysis(filterId, filterModuleType ? SaleModule[filterModuleType] : offerType) as AnalysisSaleFilterReturnData;

        if (filterData) {
            setAnalysisSaleFiltersState((filters: AnalysisSaleFiltersStateType) => ({
                ...setAnalysisSaleStateValues({ ...filterData, id, locations: analysisSaleFiltersState.locations }),
                savedFilter: filters.savedFilter
            }));
        } else {
            setAnalysisSaleFiltersState((filters: AnalysisSaleFiltersStateType) => ({
                ...filters,
                name: '',
                id: undefined
            }));
        }
    };

    const handleDeleteFilter = useCallback(async (filterId: number) => {
        const realFilterId = idMap[filterId].id;
        const filterModuleType = idMap[filterId].module;

        await analysisSaleApiRequests.removeSavedFilter(realFilterId, filterModuleType ? SaleModule[filterModuleType] : offerType);

        delete idMap[filterId];

        setAnalysisSaleFiltersState(initialState.analysisSaleFilterInitialState());
        getSavedFilters();
    }, [
        analysisSaleFiltersState.id,
        analysisSaleFiltersState.savedFilter,
        getSavedFilters
    ]);

    const handleChange = useCallback((fieldName: string, value: ObjectAndSingleValueChangeType) => {
        setAnalysisSaleFiltersState((filters: AnalysisSaleFiltersStateType) => {
            const updatedFilters = {
                ...filters,
                [fieldName]: value
            };

            return updatedFilters;
        });
    }, [analysisSaleFiltersState]);

    const handleActiveFilterChange = useCallback((fieldName: string, value: string | number | boolean | MultipleFilterType) => {
        setActiveFiltersState((filters: AnalysisSaleFiltersStateType) => ({
            ...filters,
            [fieldName]: value
        }));
    }, []);

    const getVoivodeships = useCallback(async () => {
        const locations = await filtersApiRequests.getVoivodeships();
        setAnalysisSaleFiltersState((filters: AnalysisSaleFiltersStateType) => ({
            ...filters,
            locations
        }));
    }, []);

    const handleChangeDateList = (fieldName: string, value: number) => {
        setAnalysisSaleFiltersState((filters: AnalysisSaleFiltersStateType) => ({
            ...filters,
            offerAdded: {
                from: value === -1 ? null : new Date(new Date().setDate(new Date().getDate() - value)).toISOString().split('T')[0],
                to: new Date().toISOString().split('T')[0]
            },
            [fieldName]: value
        }));
    };

    useEffect(() => {
        clearFilters();
    }, [offerType]);

    useEffect(() => {
        if (analysisSaleFiltersState.locations.length === 0) {
            getVoivodeships();
        }
    }, [analysisSaleFiltersState.locations]);

    const providerValues = {
        handleChangePropertyType,
        handleDeleteFilter,
        handleSetFiltersValues,
        handleChange,
        clearFilters,
        validateField: isFieldValid,
        validateAllFields: () => false,
        propertyType: SaleModule.SALE_FLAT,
        analysisSaleFiltersState,
        getSavedFilters,
        handleChangeDateList,
        handleActiveFilterChange,
        activeFiltersState,
        analysisSaleState,
        clearAnalysis,
        loadAnalysis,
        validateFilters,
        handleSaveOrUpdateFilter
    };

    return (
        <AnalysisSaleContext.Provider value={providerValues}>
            {children}
        </AnalysisSaleContext.Provider>
    );
};

export default AnalysisSaleContext;
