import { RentModuleType } from './types/Rent';
import { Module } from './types/SaleModels';

export const getNameFromRentModule = (module: RentModuleType) => {
    switch (module) {

    case RentModuleType.RENT_HOUSE:
        return 'Dom';
    case RentModuleType.RENT_PLOT:
        return 'Działka';
    case RentModuleType.RENT_OTHER:
        return 'Inne';
    case RentModuleType.RENT_FLAT:
    default:
        return 'Mieszkanie';
    }
};

export const getNameFromSaleModule = (module: Module) => {
    switch (module) {

    case Module.SALE_HOUSE:
        return 'Dom';
    case Module.SALE_PLOT:
        return 'Działka';
    case Module.SALE_OTHER:
        return 'Inne';
    case Module.SALE_FLAT:
    default:
        return 'Mieszkanie';

    }
};

export const getNameFromModule = (module: RentModuleType | Module): string => {
    switch (module) {
    case RentModuleType.RENT_HOUSE:
    case Module.SALE_HOUSE:
        return 'Dom';
    case RentModuleType.RENT_PLOT:
    case Module.SALE_PLOT:
        return 'Działka';
    case RentModuleType.RENT_OTHER:
    case Module.SALE_OTHER:
        return 'Inne';
    case RentModuleType.RENT_FLAT:
    case Module.SALE_FLAT:
    default:
        return 'Mieszkanie';
    }
};
