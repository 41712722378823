export const isTouchDevice = () => {
    try {
        const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

        const mq = (query: string) => {
            return window.matchMedia(query).matches;
        };

        // @ts-expect-error no types
        if ('ontouchstart' in window || typeof window.DocumentTouch !== 'undefined' && document instanceof window.DocumentTouch) {
            return true;
        }

        return mq([
            '(',
            prefixes.join('touch-enabled),('),
            'heartz',
            ')'
        ].join(''));
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error('(Touch detect failed)', e);

        return false;
    }
};
