import { FilterItem } from 'components/common/V2ActiveFilters';

export const getRangeFilters = (
    label: string,
    value: { from: string | number | null, to: string | number | null },
    filterName: string,
    suffix?: string,
    noDeleteButton?: boolean
): FilterItem[] => {
    const filters: FilterItem[] = [];

    if (value.from !== null && value.from !== '') {
        filters.push({ label: `${label} od`, value: value.from, filterName, suffix, noDeleteButton });
    }

    if (value.to !== null && value.to !== '') {
        filters.push({ label: `${label} do`, value: value.to, filterName, suffix, noDeleteButton });
    }

    return filters;
};

export const getArrayFilters = (
    label: string,
    values: (string | null)[],
    filterName: string,
    noDeleteButton?: boolean
): FilterItem[] => {
    return values.map((item, index) => ({
        label,
        value: item,
        filterName,
        index,
        noDeleteButton
    }));
};
