import React, { FC } from 'react';
import styled from 'styled-components';

import Icon, { IconEnum } from 'components/common/Icon';

const RatingStarsWrapper = styled.div`
    display: flex;
    gap: 2px;
    
    .icon {
        width: min-content;
    }
`;

interface RatingStarsProps {
    score: number;
}

const getIconType = (index: number, score: number, isDecimal: boolean, scoreInt: number) => {
    if (index <= score - 1) {
        return IconEnum.FILLED_STAR;
    } else if (index === scoreInt - 1 && isDecimal && score > index - 1) {
        return IconEnum.HALF_STAR;
    } else {
        return IconEnum.STAR;
    }
};

const RatingStars:FC <RatingStarsProps> = ({ score }) => {
    const isDecimal = score % 1 !== 0;
    const scoreInt = Math.ceil(score);

    const renderRatingsMemo = React.useMemo(() => {
        return Array.from({ length: 6 }, (_, i) => <Icon key={i} icon={getIconType(i, score, isDecimal, scoreInt)} />);
    }, [
        score,
        isDecimal,
        scoreInt
    ]);

    return (
        <RatingStarsWrapper className={'rating-stars'} data-testid="rating-stars">
            {renderRatingsMemo}
        </RatingStarsWrapper>
    );
};

export default RatingStars;
