import { filtersApiRequests } from 'utils/api-requests/filters';
import { LocationOptionsType } from 'utils/types/Locations';

export const getVoivodeshipCities = async (voivodeship: string, locations: LocationOptionsType[]) => {
    const voivodeshipId = locations.find((x) => x.label === voivodeship)?.voivodeshipNumber;
    const voivodeshipCities = locations.find((x) => x.label === voivodeship)?.cities;
    if (voivodeshipCities && voivodeshipCities.length > 0) return locations;

    const cities = await filtersApiRequests.getVoivodeshipCities(voivodeshipId!);

    return locations.map((x) => {
        if (x.voivodeshipNumber === voivodeshipId) {
            return { ...x, cities: cities };
        }

        return x;
    });
};
