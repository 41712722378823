import React, { useState } from 'react';
import { useOffers } from 'store/Offers/Context';
import styled from 'styled-components';
import { applyViewedStatus } from 'utils/modules/modulesUtils';

import BaseCard from 'components/common/Card/BaseCard';
import OfferDetails from 'components/common/Card/OfferDetails';
import OfferDetailsModal from 'components/common/OfferDetailsModal';

export const BoxContainer = styled.div`
  padding: 30px;
  background-color: var(--color-white);
  border-radius: var(--box-border-radius);
  position: relative;
  margin-bottom: 15px;
  grid-column-end: span 2;
  
  &.users-media {
    padding: 10px 0;
  }

  &.swipeable {
    @media (max-width: 1100px) {
      padding: 30px 0;
    }
  }

  & > h1 {
    padding: 10px 15px;
  }
`;

const SingleAdvertisement = ({ data }) => {
    const [detailsOpen, toggleDetailsOpen] = useState(false);
    const [preferredTab, setPreferredTab] = useState(undefined);
    const { dispatch } = useOffers();
    const isNote = typeof data.note.noteId === 'number';

    const toggleContainerDetails = (e) => {
        if (e.target.dataset.notclickable || e.target.closest('[data-notclickable]')) return;

        setPreferredTab(undefined);
        setTimeout(() => {
            applyViewedStatus(data, detailsOpen, dispatch, 'advertisement');

            toggleDetailsOpen((prev) => !prev);
        }, 100);
    };

    const openOfferTab = (name) => {
        setPreferredTab(name);
        setTimeout(() => {
            toggleDetailsOpen((prev) => !prev);
        }, 100);
    };

    // TODO: Use when print advert pdfs will be ready
    // const handleDownloadPdf = () => {
    //   setIsPrintMode(true);
    //
    //   setTimeout(async () => {
    //     const pageHeight = 825;
    //     const pdfElement = pdfRef.current;
    //     const headerElement = pdfHeaderRef.current;
    //     const footerElement = pdfFooterRef.current;
    //
    //     const canvas = await html2canvas(pdfElement,{
    //       allowTaint: true,
    //       useCORS: true,
    //       scale: 1.5,
    //     });
    //     const headerCanvas = await html2canvas(headerElement,{
    //       allowTaint: true,
    //       useCORS: true,
    //       scale: 1.5,
    //     });
    //     const footerCanvas = await html2canvas(footerElement,{
    //       allowTaint: true,
    //       useCORS: true,
    //       scale: 1.5,
    //     });
    //
    //     const dataImage = canvas.toDataURL('image/png');
    //     const dataHeader = headerCanvas.toDataURL('image/png');
    //     const dataFooter = footerCanvas.toDataURL('image/png');
    //     const imgWidth = 595;
    //     const imgHeight = canvas.height * imgWidth / canvas.width;
    //     const headerHeight = headerCanvas.height * imgWidth / headerCanvas.width;
    //     const footerHeight = footerCanvas.height * imgWidth / footerCanvas.width;
    //     let heightLeft = imgHeight;
    //     const pdf = new jsPDF('p', 'pt', 'a4');
    //     let position = 40;
    //     const pageCount = Math.ceil(imgHeight / pageHeight);
    //     let page = 1;
    //     pdf.setFont("Poppins", "sans-serif", 500);
    //     pdf.setFontSize(9);
    //     pdf.setTextColor(3, 13, 69);
    //
    //     pdf.addImage(dataHeader, 'png', 0, 0, imgWidth, headerHeight);
    //     pdf.addImage(dataImage, 'png', 0, position, imgWidth, imgHeight);
    //     pdf.addImage(dataFooter, 'png', 0, 803, imgWidth, footerHeight);
    //     pdf.text(`0${page}`, 563, 825);
    //     heightLeft -= pageHeight;
    //
    //     while (heightLeft >= 0) {
    //       position = (heightLeft - imgHeight) + 40;
    //       pdf.addPage();
    //       page++;
    //       pdf.addImage(dataImage, 'png', 0, position, imgWidth, imgHeight);
    //       pdf.addImage(dataHeader, 'png', 0, 0, imgWidth, headerHeight);
    //       pdf.addImage(dataFooter, 'png', 0, 803, imgWidth, footerHeight);
    //       pdf.text(`0${page}`, 563, 825);
    //       heightLeft -= pageHeight;
    //     }
    //     pdf.save( 'print.pdf');
    //     setIsPrintMode(false);
    //   }, 200);
    // };

    return (
        <div>
            <BaseCard
                onClick={toggleContainerDetails}
                data={data}
                type={'advertisement'}
                openOfferTab={openOfferTab}
                showNote={isNote}
            />
            <OfferDetailsModal isOpen={detailsOpen} onClose={toggleContainerDetails}>
                <OfferDetails
                    type={'advertisement'}
                    data={data}
                    onToggleContainerDetails={toggleContainerDetails}
                    preferredTab={preferredTab}
                />
            </OfferDetailsModal>
        </div>
    );
};

export default SingleAdvertisement;
