import React, { createContext, useCallback, useEffect, useState } from 'react';
import { FavoritePageFiltersType } from 'pages/FavoritesPage';
import { bailiffOffersApiRequests } from 'utils/api-requests/bailiff';
import { commonApiRequests } from 'utils/api-requests/common';
import { filtersApiRequests } from 'utils/api-requests/filters';
import { offersApiRequests } from 'utils/api-requests/offers';
import {
    getBailiffValueFromState,
    initialState,
    setBailiffStateValues
} from 'utils/state-managment/bailiff/bailiffFilter';
import {
    bailiffInitialState,
    BailiffOffersResponse,
    BailiffOfferStateType
} from 'utils/state-managment/bailiff/bailiffOffer';
import { BailiffFilterReturnData, BailiffFiltersStateType } from 'utils/types/Bailiff';
import { BailiffNoticeSearchingRequestSortTypeEnum, NoteResponse } from 'utils/types/BailiffModels';
import { ObjectAndSingleValueChangeType } from 'utils/types/InputTypes';
import { UserReactions } from 'utils/types/OfferData';

interface LoadBailiffOffersType {
    requestData?: BailiffFilterReturnData,
    page?: number,
    rows?: number
}

export interface BailiffContextType {
    bailiffFiltersState: BailiffFiltersStateType,
    bailiffOfferState: BailiffOfferStateType,
    handleChange: (name: string, value: ObjectAndSingleValueChangeType) => void,
    handleSetFiltersValues: (id: number) => void,
    handleDeleteFilter: (id: number) => void,
    handleChangeDateList: (fieldName: string, value: number) => void,
    getSavedFilters: (id?: number) => void,
    clearFilters: () => void,
    loadBailiffOffers: (bailiffOffersOptions?: LoadBailiffOffersType) => void;
    deleteNote: (id: number, noteId: number) => void,
    updateNote: (noteContent: string, advertisementId: number, noteId: number) => void,
    reactionUpdate: (id: number, city: string, reaction: UserReactions) => void,
    loadOfferDetailsAsync: (id: number) => void;
    loadNotesAsync: (id: string) => void;
    loadFavoriteOffers: (page?: number, size?: number, filters?: FavoritePageFiltersType) => void,
    clearOffers: () => void,
}

const values: BailiffContextType = {
    bailiffFiltersState: {} as BailiffFiltersStateType,
    bailiffOfferState: {} as BailiffOfferStateType,
    handleChange: () => {},
    handleSetFiltersValues: () => {},
    handleDeleteFilter: () => {},
    handleChangeDateList: () => {},
    getSavedFilters: () => {},
    clearFilters: () => {},
    loadBailiffOffers: () => {},
    deleteNote: () => {},
    updateNote: () => {},
    reactionUpdate: () => {},
    loadOfferDetailsAsync: () => {},
    loadNotesAsync: () => {},
    loadFavoriteOffers: () => {},
    clearOffers: () => {}
};

const BAILIFF_MODULE = 'BAILIFF_NOTICE';

export const BailiffContext = createContext(values);

export const BailiffProvider = ({ children }: { children: React.ReactNode }) => {
    const [bailiffFiltersState, setBailiffFiltersState] = useState(initialState.bailiffFilterInitialState());
    const [bailiffOfferState, setBailiffOfferState] = useState(bailiffInitialState);

    const handleChange = useCallback((fieldName: string, value: ObjectAndSingleValueChangeType) => {
        setBailiffFiltersState((filters: BailiffFiltersStateType) => ({
            ...filters,
            [fieldName]: value
        }));

        if (fieldName === 'sortType') {
            setBailiffOfferState((prevState) => ({
                ...prevState,
                sortType: value as BailiffNoticeSearchingRequestSortTypeEnum
            }));
        }
    }, [bailiffFiltersState]);

    const getSavedFilters = useCallback(async (id?: number) => {
        const savedFilters = await bailiffOffersApiRequests.getSavedFilters();
        setBailiffFiltersState((filters: BailiffFiltersStateType) => ({
            ...filters,
            savedFilter: savedFilters
        }));

        if (id) {
            await handleSetFiltersValues(id);
        }
    }, []);

    const clearFilters = useCallback((reset?: boolean) => {
        setBailiffFiltersState((prev) => ({
            ...initialState.bailiffFilterInitialState(),
            locations: prev.locations,
            city: reset ? [] : prev.city,
            savedLocations: reset ? {} : prev.savedLocations
        }));
        getSavedFilters();
    }, [getSavedFilters]);

    const handleDeleteFilter = useCallback(async (filterId: number) => {
        await bailiffOffersApiRequests.removeSavedFilter(filterId);

        setBailiffFiltersState(initialState.bailiffFilterInitialState());
        getSavedFilters();
    }, [
        bailiffFiltersState.filterId,
        bailiffFiltersState.savedFilter,
        getSavedFilters
    ]);

    const handleSetFiltersValues = async (id: number) => {
        const filterData = await bailiffOffersApiRequests.getFilterDetailsBailiff(id) as BailiffFilterReturnData;

        if (filterData) {
            setBailiffFiltersState((filters: BailiffFiltersStateType) => ({
                ...setBailiffStateValues({ ...filterData, locations: bailiffFiltersState.locations }),
                savedFilter: filters.savedFilter,
                filterId: id
            }));
            getVoivodeships();
        } else {
            setBailiffFiltersState((filters: BailiffFiltersStateType) => ({
                ...filters,
                name: null,
                filterId: null
            }));
        }
    };

    const handleChangeDateList = (fieldName: string, value: number) => {
        setBailiffFiltersState((filters: BailiffFiltersStateType) => ({
            ...filters,
            offerAdded: {
                from: value === -1 ? null : new Date(new Date().setDate(new Date().getDate() - value)).toISOString().split('T')[0],
                to: new Date().toISOString().split('T')[0]
            },
            [fieldName]: value
        }));
    };

    const setLoadingPage = () => {
        setBailiffOfferState((prev) => ({
            ...prev,
            isLoading: true
        }));
    };

    const loadBailiffOffers = async (bailiffOffersOptions?: LoadBailiffOffersType) => {
        const { requestData, page, rows } = bailiffOffersOptions || {};

        setLoadingPage();

        const response = await bailiffOffersApiRequests.getBailiffOffers(requestData ? requestData : getBailiffValueFromState(bailiffFiltersState), page ?? 0, rows ?? 25);

        if (response) {
            setBailiffOfferState((prev) => ({
                ...prev,
                bailiffOfferList: response,
                isLoading: false
            }));
        }
    };

    const loadFavoriteOffers = async (page?: number, size?: number, filters?: FavoritePageFiltersType) => {
        setLoadingPage();

        const response = await offersApiRequests.getUsersFavoriteOffers(BAILIFF_MODULE, page ?? 1, size ?? 25, filters) as unknown as BailiffOffersResponse;
        setBailiffOfferState((prev) => {
            const newState = {
                ...prev,
                bailiffOfferList: response,
                isLoading: false
            };

            return newState;
        });
    };

    const deleteNote = async (id: number, noteId: number) => {
        setLoadingPage();

        const validChangeStatus = await commonApiRequests.deleteNote(BAILIFF_MODULE, noteId);

        if (validChangeStatus) {
            setBailiffOfferState((prev) => {
                return {
                    ...prev,
                    notes: prev.notes!.filter((item) => item.noteId !== noteId),
                    bailiffOfferList: {
                        ...prev.bailiffOfferList,
                        content: prev.bailiffOfferList.content.map((offer) => {
                            if (offer.bailiffNoticeId === id) {
                                return {
                                    ...offer,
                                    numberOfNotes: (offer.numberOfNotes ?? 0) - 1
                                };
                            }

                            return offer;
                        })
                    },
                    isLoading: false
                };
            });
        }
    };

    const updateNote = async (noteContent: string, advertisementId: number, noteId: number) => {
        setLoadingPage();

        const validChangeStatus = await commonApiRequests.updateNote(BAILIFF_MODULE, noteContent, advertisementId, noteId) as NoteResponse;

        if (validChangeStatus) {
            if (noteId) {
                setBailiffOfferState((prev) => {
                    return {
                        ...prev,
                        notes: prev.notes!.map((item) => {
                            if (item.noteId === noteId) {
                                return {
                                    ...item,
                                    date: validChangeStatus.date,
                                    content: validChangeStatus.content
                                };
                            }

                            return item;
                        }),
                        bailifffsOfferList: {
                            ...prev.bailiffOfferList,
                            content: prev.bailiffOfferList.content.map((x) => {
                                if (x.bailiffNoticeId === advertisementId) {
                                    return {
                                        ...x,
                                        note: {
                                            ...validChangeStatus,
                                            noteId: noteId
                                        }
                                    };
                                }

                                return x;
                            })
                        },
                        isLoading: false
                    };
                });
            } else {
                setBailiffOfferState((prev) => {
                    return {
                        ...prev,
                        notes: prev.notes ? [validChangeStatus].concat(prev.notes) : [validChangeStatus],
                        bailiffOfferList: {
                            ...prev.bailiffOfferList,
                            content: prev.bailiffOfferList.content.map((x) => {
                                if (x.bailiffNoticeId === advertisementId) {
                                    return {
                                        ...x,
                                        note: {
                                            ...validChangeStatus
                                        },
                                        numberOfNotes: (x.numberOfNotes ?? 0) + 1
                                    };
                                }

                                return x;
                            })
                        },
                        isLoading: false
                    };
                });
            }
        }
    };

    const reactionUpdate = async (id: number, city: string, reaction: UserReactions) => {
        const response = await commonApiRequests.setOfferReaction(id, city, reaction, BAILIFF_MODULE);

        setBailiffOfferState((prev) => ({
            ...prev,
            bailiffOfferList: {
                ...prev.bailiffOfferList,
                content: prev.bailiffOfferList.content.map((el) => {
                    if (el.bailiffNoticeId === id) {
                        return {
                            ...el,
                            reaction: response
                        };
                    }

                    return el;
                })
            }
        }));
    };

    const loadOfferDetailsAsync = async (id: number) => {
        setLoadingPage();

        const response = await bailiffOffersApiRequests.getBailiffOfferDetails(id);

        setBailiffOfferState((prevState) => ({
            ...prevState,
            bailiffOfferList: {
                ...prevState.bailiffOfferList,
                content: prevState.bailiffOfferList.content.map((el) => {
                    if (el.bailiffNoticeId === response.bailiffNoticeId) {
                        return {
                            ...el,
                            detailedContent: response
                        };
                    }

                    return el;
                })
            },
            isLoading: false
        }));
    };

    const loadNotesAsync = async (id: string) => {
        const notes = await commonApiRequests.getNotes(BAILIFF_MODULE, id);

        setBailiffOfferState((prev) => ({
            ...prev,
            notes
        }));
    };

    const getVoivodeships = useCallback(async () => {
        const locations = await filtersApiRequests.getVoivodeships();
        setBailiffFiltersState((filters: BailiffFiltersStateType) => ({
            ...filters,
            locations,
            officeLocations: locations
        }));
    }, []);

    const clearOffers = () => {
        setBailiffOfferState(bailiffInitialState);
    };

    useEffect(() => {
        if (bailiffFiltersState.locations.length === 0 || bailiffFiltersState.officeLocations.length === 0) {
            getVoivodeships();
        }
    }, [bailiffFiltersState.locations, bailiffFiltersState.officeLocations]);

    const providerValues = {
        bailiffOfferState,
        bailiffFiltersState,
        handleChange,
        handleSetFiltersValues,
        handleDeleteFilter,
        handleChangeDateList,
        getSavedFilters,
        clearFilters,
        loadBailiffOffers,
        deleteNote,
        updateNote,
        reactionUpdate,
        loadOfferDetailsAsync,
        loadNotesAsync,
        loadFavoriteOffers,
        clearOffers
    };

    return (
        <BailiffContext.Provider value={providerValues}>
            {children}
        </BailiffContext.Provider>
    );
};

export default BailiffContext;

