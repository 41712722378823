import { Dispatch } from 'react';
import { OffersActions } from 'store/Offers/Actions';
import { Action } from 'store/Offers/Reducer';
import { ModulesType } from 'utils/types/ModulesType';
import { OfferData } from 'utils/types/OfferData';

export const applyViewedStatus = (data: OfferData, detailsOpen: boolean, dispatch: Dispatch<Action>, type: ModulesType) => {
    if (data.reaction && !data.reaction.viewedStatus && !detailsOpen) {
        const userReaction = Object.assign({}, data.reaction);
        userReaction.viewedStatus = true;
        const city = data.city || data.reaction.city || data.note.city || '';

        OffersActions.reactionUpdate(dispatch, data.id, city, type, userReaction);
    }
};
