import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.span`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  background: var(--color-alt-second);
  padding: 10px;
  border-radius: var(--border-radius);
  height: 50px;
`;

const SelectableItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: var(--border-radius);

  font-weight: var(--font-weight);
  font-size: var(--font-size-body-2);
  line-height: 20px;

  padding: 5px 10px;
  background: var(--color-white);
  color: var(--color-primary);
  cursor: pointer;
  
  border: 1px solid transparent;
  transition: border 0.1s ease-in-out;

  &:hover, &:focus, &:active, &:target, &.preferred{
    transition: border 0.1s ease-in-out;
    border: 1px solid var(--color-alt-hover);
  }
  
  &.selected {
    background: var(--color-alt); 
    color: white;
  }
`;

interface OptionsText {
    trueOptionText: string,
    falseOptionText: string,
}

interface BooleanSelectInputProperties {
    value: boolean,
    onChange: (value: boolean) => void,
    optionsText?: OptionsText,
    disabled?: boolean;
}

const BooleanSelectInput: FC<BooleanSelectInputProperties> = ({ value, onChange, optionsText }) => {
    return (
        <Container>
            <SelectableItem className={value ? 'selected' : ''} onClick={() => onChange(true)}>
                {optionsText ? optionsText.trueOptionText : 'Tak' }
            </SelectableItem>
            <SelectableItem className={!value ? 'selected' : ''} onClick={() => onChange(false)}>
                {optionsText ? optionsText.falseOptionText : 'Nie' }
            </SelectableItem>
        </Container>
    );
};

export default BooleanSelectInput;
