import React, { useState } from 'react';
import { useOffers } from 'store/Offers/Context';
import { applyViewedStatus } from 'utils/modules/modulesUtils';

import BaseCard from 'components/common/Card/BaseCard';
import OfferDetails from 'components/common/Card/OfferDetails';
import OfferDetailsModal from 'components/common/OfferDetailsModal';

const SingleRent = ({ data }) => {
    const [detailsOpen, toggleDetailsOpen] = useState(false);
    const [preferredTab, setPreferredTab] = useState(undefined);
    const { dispatch } = useOffers();
    const isNote = typeof data.note.noteId === 'number';

    const toggleContainerDetails = (e) => {
        if (e.target.dataset.notclickable || e.target.closest('[data-notclickable]')) return;

        setPreferredTab(undefined);
        setTimeout(() => {
            applyViewedStatus(data, detailsOpen, dispatch, 'rent');

            toggleDetailsOpen((prev) => !prev);
        }, 100);
    };

    const openOfferTab = (name) => {
        setPreferredTab(name);
        setTimeout(() => {
            toggleDetailsOpen((prev) => !prev);
        }, 100);
    };

    return (
        <div>
            <BaseCard
                onClick={toggleContainerDetails}
                data={data}
                type={'rent'}
                showNote={isNote}
                openOfferTab={openOfferTab}
            />
            <OfferDetailsModal isOpen={detailsOpen} onClose={toggleContainerDetails}>
                <OfferDetails
                    preferredTab={preferredTab}
                    type={'rent'}
                    data={data}
                    onToggleContainerDetails={toggleContainerDetails}
                />
            </OfferDetailsModal>
        </div>
    );
};

export default SingleRent;
