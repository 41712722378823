import React from 'react';
import clsx from 'clsx';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';

import Icon, { IconEnum } from 'components/common/Icon';

const Wrapper = styled.div<SuggestionProps>`
    width: 100%;
    font-weight: 500;
    font-size: 13px;
    line-height: 36px;
    color: var(--primary-color);
    background: var(--color-alt-second);
    border-radius: 10px;
    padding: ${(props) => props.biggerPadding ? '15px 20px' : '7px'};
    text-align: center;
    border: 1px solid transparent;
    transition: border 0.1s ease-in-out;
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: fit-content;

        span {
            width: min-content;
            padding: 0 10px;
        }
    }

    input {
        --checkbox-background-color: transparent;

        cursor: pointer;
    }

    input[type="checkbox"]:checked {
        --checkbox-color: var(--color-white);
    }

    &:hover, &:focus, &:active, &:target, &.preferred {
        transition: border 0.1s ease-in-out;
        border: 1px solid var(--color-alt-hover);
        cursor: pointer;
    }

    &.active {
        background: ${(props) => props.darkMode ? 'var(--color-primary)' : 'var(--color-alt)'};
        color: var(--color-alt-second);

        div {
            color: var(--color-white);
        }

        img {
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(105%) contrast(102%);
        }
    }
    
    &.noAction {
        ${(props) => !props.biggerPadding && 'padding: 7px 17px 7px 7px'};
    }
`;

const Button = styled.button`
    background-color: transparent;
    border: none;
    
    &:hover {
        background-color: transparent;
        cursor: pointer;
    }
`;

const ActionItems = styled.div`
`;

const ActionIcon = styled(Icon)`
    z-index: 999;
`;

const Checkbox = styled.input`
    pointer-events: none;
`;

const CheckboxLabel = styled.div`
    --font-size-body: var(--font-size-body-2);

    text-align: left;
    line-height: 140%;
    margin-right: 10px;
`;

interface SuggestionProps {
    darkMode?: boolean,
    biggerPadding?: boolean,
}

interface CheckboxTextInputProps {
    label: string,
    value: boolean,
    optionsIcon?: IconEnum,
    onAction?: (voivodeship: string) => void,
    onChange?: (label: string, value: boolean) => void,
    biggerPadding?: boolean,
}

const CheckboxTextInput = ({ label, value, optionsIcon, onAction, onChange, biggerPadding }: CheckboxTextInputProps) => {
    const { user } = useUser();

    const handleCheckbox = () => {
        if (onChange) {
            onChange(label, !value);
        }
    };

    const handleClick = (e: Event) => {
        e.stopPropagation();
        e.preventDefault();

        if (onAction) {
            onAction(label);
        }
    };

    return (
        <Wrapper
            darkMode={user.isDarkMode}
            onClick={handleCheckbox}
            className={clsx({ active: value, noAction: !onAction })}
            biggerPadding={biggerPadding}
        >
            <CheckboxLabel>
                {optionsIcon ? <Icon icon={optionsIcon}/> : null}
                {label}
            </CheckboxLabel>
            <ActionItems>
                <Checkbox
                    autocomplete="off"
                    checked={value}
                    onChange={() => {}}
                    type="checkbox"/>
                {onAction ? <Button onClick={handleClick}><ActionIcon icon={'accordion_down'}/></Button> : null}
            </ActionItems>
        </Wrapper>
    );
};

export default CheckboxTextInput;
