import React, { Dispatch } from 'react';
import { createContext, FC, ReactNode, useContext, useReducer } from 'react';
import clsx from 'clsx';
import LoginPage from 'pages/LoginPage';

import { Action, reducer } from './Reducer';
import { initialState, State } from './State';

interface ProviderProperties {
    children: ReactNode;
}

const Context = createContext<State>(initialState);
const DispatchContext = createContext<Dispatch<Action>>(() => null);

export const UserProvider: FC<ProviderProperties> = ({ children }) => {
    const [user, dispatch] = useReducer(reducer, initialState);

    return (
        <Context.Provider value={user}>
            <DispatchContext.Provider value={dispatch}>
                <div className={clsx({ light: !user.isDarkMode, dark: user.isDarkMode })}>
                    {user.isLogged && user.activePackages && user.activePackages.length > 0 && user.isLogged2 && user.activePackages2 && user.activePackages2.length > 0
                        ? children
                        : <LoginPage/>}
                </div>
            </DispatchContext.Provider>
        </Context.Provider>
    );
};

export const useUser = () => {
    return {
        user: useContext(Context),
        dispatch: useContext(DispatchContext)
    };
};
