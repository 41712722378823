import React, { FC } from 'react';
import styled from 'styled-components';

import Button from 'components/atom/Button';
import { BaseClickableLink } from 'components/HeaderSection';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  background: var(--color-white);
  margin: 30px auto 0;
  max-width: 755px;
  padding: 15px 15px 40px 15px;
  border-radius: var(--box-border-radius);

  a:first-of-type {
    text-decoration: none;
  }
  
  h1 {
    margin-top: 30px;
  }

  p {
    max-width: 330px;
    margin: 10px auto 0;
  }

  button {
    max-width: 250px;
    width: 100%;
    margin: 25px auto 10px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 280px;
  border-radius: var(--box-border-radius);
  background: var(--color-alt-second);

  img {
    filter: none !important;
  }
`;

interface StepThreeProperties {
    handleStepChange: (value: number) => void,
    isHidden?: boolean,
}

const StepThree:FC<StepThreeProperties> = ({ handleStepChange, isHidden }) => {
    return (
        <Container>
            <ImageContainer><img src={'/usersOffers.png'} alt={''}/></ImageContainer>
            <h1>{isHidden ? 'Ogłoszenie zostało dodane jako ukryte' : 'Ogłoszenie zostało dodane'}</h1>
            <p>Od teraz możesz zarządzać swoimi ogłoszeniami w zakładce <b>“Moje ogłoszenia”.</b></p>
            <a href={'/my-offers'}>
                <Button>
                    Przejdź do ogłoszeń
                </Button>
            </a>
            <BaseClickableLink onClick={() => {
                handleStepChange(1);
            }}>Dodaj kolejne ogłoszenie</BaseClickableLink>
        </Container>
    );
};

export default StepThree;
