import React, { FC } from 'react';

import { IconEnum } from 'components/common/Icon';

import AutocompleteInput from './AutocompleteInput/AutocompleteInput';

export interface FilterOptions {
    id: number;
    name: string,
    pushAlert?: boolean,
    alertEmail?: boolean,
    alertSms?: boolean,
}

interface AutocompleteFiltersInputProps {
    filterId: number | null;
    options: FilterOptions[];
    handleDeleteFilter: (id: number) => void;
    handleSetFiltersValues: (id: number) => void;
}

const AutocompleteFiltersInput: FC<AutocompleteFiltersInputProps> = ({ filterId, options, handleDeleteFilter, handleSetFiltersValues }) => {
    return (
        <AutocompleteInput
            noTyping
            optionsIcon={IconEnum.BOOKMARK}
            value={filterId}
            onChange={(id) => handleSetFiltersValues(id as number)}
            placeholder="Wybierz filtr..."
            handleDeleteFilter={(id) => handleDeleteFilter(id as number)}
            options={options.map((x) => ({
                label: x.name,
                value: x.id,
                ...typeof x.pushAlert === 'boolean' && typeof x.alertEmail === 'boolean' && typeof x.alertSms === 'boolean'
                    ? {
                        alerts: {
                            pushAlert: x.pushAlert,
                            alertEmail: x.alertEmail,
                            alertSms: x.alertSms
                        }
                    }
                    : {},
                trash: true
            }))}
        />
    );
};

export default AutocompleteFiltersInput;
