import {
    BailiffNoticeDetailsResponse,
    BailiffNoticeResponse,
    BailiffNoticeSearchingRequestSortTypeEnum,
    NoteResponse
} from 'utils/types/BailiffModels';
import { FiltersStateType } from 'utils/types/FiltersStateType';
import { ModulesType } from 'utils/types/ModulesType';

export interface BailiffOfferStateType {
    isLoading: boolean,
    bailiffOfferList: BailiffOffersResponse,
    sortType: BailiffNoticeSearchingRequestSortTypeEnum;
    filtersState: FiltersStateType;
    pageType: ModulesType;
    filteredCity: string;
    notes?: NoteResponse[],
}

export type BailiffOfferDetailsResponse = BailiffNoticeDetailsResponse;

export interface BailiffOfferResponseContent extends BailiffNoticeResponse {
    detailedContent?: BailiffOfferDetailsResponse;
}

export interface BailiffOffersResponse {
    empty: boolean,
    content: BailiffOfferResponseContent[],
    pageable: {
        pageNumber: number,
        pageSize: number,
    },
    numberOfElements: number,
    totalPages: number,
    totalElements: number,
    last: boolean,
    size: number,
    afterSearch: boolean,
}

export const bailiffInitialState: BailiffOfferStateType = {
    isLoading: false,
    bailiffOfferList: {
        empty: false,
        content: [],
        pageable: {
            pageNumber: 1,
            pageSize: 0
        },
        numberOfElements: 0,
        size: 25,
        totalPages: 0,
        totalElements: 0,
        last: false,
        afterSearch: false
    },
    sortType: BailiffNoticeSearchingRequestSortTypeEnum.DATE_DESC,
    filtersState: {} as FiltersStateType,
    pageType: 'bailiff_notice',
    filteredCity: ''
};
