import { SliderImages } from 'components/common/Slider/Slider';

export const convertToValidImagePath = (photo: string | null, module: string) => {
    return photo?.includes('http') ? photo : `${process.env.REACT_APP_API_URL_2}image/find?module=${module}&fileName=${photo}&photoSize=PHOTO_SIZE`;
};
export const convertToThumbnailPath = (photo: string, module: string) => convertToValidImagePath(photo, module).replace('PHOTO_SIZE', 'NORMAL_PHOTO');

export const createImagesForSlider = (images: string[], module: string): SliderImages[] | undefined => {
    return images.length > 0 ? images.map((photo) => ({ url: convertToValidImagePath(photo, module) })) : undefined;
};
