import styled from 'styled-components';

import Button, { ButtonProperties } from 'components/atom/Button';

export const SaveButton = styled(Button)<ButtonProperties>`
    background: var(--color-alt) !important;
    margin-bottom: 12px;
    width: 245px;
    align-self: center;

    img {
        filter: ${(props) => props.darkMode ? 'invert(77%) sepia(83%) saturate(1712%) hue-rotate(194deg) brightness(90%) contrast(103%)' : 'invert(14%) sepia(99%) saturate(2391%) hue-rotate(198deg) brightness(93%) contrast(101%)'};
    }
`;

