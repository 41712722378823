import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';
import { userApiRequests } from 'utils/api-requests/user';
import UserAddAdvertContext from 'utils/context/UserAddAdvertContext';
import { getPropertyByTypeUser } from 'utils/formatters/getPropertyByType';
import {
    featureOptions,
    formOfInvestmentCapitalOptions,
    formOfProfitOptions, investmentSecurityCapitalOptions, investmentSecurityInvestorOptions,
    investmentTimeOptions, investPriceOptions, legalStatusDealOptions,
    legalStatusInvestorOptions,
    propertyConditionOptions,
    userFloorOptions, userFloorsOptions,
    usersBuildingOptions
} from 'utils/options';
import { UploadRequestData } from 'utils/types/File';
import {
    InputType,
    LocationChangeType,
    ObjectAndSingleValueChangeType
} from 'utils/types/InputTypes';
import { SavedLocationsType } from 'utils/types/Locations';
import { V2OfferType } from 'utils/types/UsersAdvert';
import { AdvertValidatorType } from 'utils/validators/advertAddingValidator';

import V2FiltersRenderer, { ElementsToRender } from 'components/common/FiltersRenderer/V2FiltersRenderer';
import { IconEnum } from 'components/common/Icon';
import { activeNotification } from 'components/functions/activeNotification';
import { getVoivodeshipCities } from 'components/functions/locations';
import { OffersHeader, Title } from 'components/HeaderSection';

const Wrapper = styled.div`
    padding-bottom: 40px;
`;

const Container = styled.div`
    padding: 15px 20px 0;

    img {
        filter: ${(props) => props.darkMode ? 'invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(105%) contrast(102%)' : 'unset'};
    }

    @media (max-width: 1100px) {
        padding: 20px 20px 0;
    }
`;

const UserEditAdvertPage = () => {
    const [triggerValidation, setTriggerValidation] = useState<boolean>(false);
    const { handleChange, userAddAdvertFieldsState, validateField, handleFillAdvertFields, validateAllFields } = useContext(UserAddAdvertContext);
    const navigate = useNavigate();
    const { user } = useUser();

    const removeNullProps = (obj: object) => {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        const newObj = Array.isArray(obj) ? [] : {};

        Object.keys(obj).forEach((key) => {
            const value = obj[key as keyof typeof obj];

            if (value !== null) {
                // @ts-expect-error its not empty obj
                newObj[key] = removeNullProps(value);
            }
        });

        if (Object.keys(newObj).length === 0) {
            return undefined;
        }

        return newObj;
    };

    useEffect(() => {
        const savedLocations = [] as unknown as SavedLocationsType;
        // @ts-expect-error TODO: INVEST-237
        savedLocations[user.offerToEdit.city.fullName!] = { ...user.offerToEdit.city, label: user.offerToEdit.city.fullName! };
        // @ts-expect-error TODO: INVEST-237
        handleFillAdvertFields(removeNullProps({ ...userAddAdvertFieldsState, ...user.offerToEdit, searchLocationResult: userAddAdvertFieldsState.searchLocationResult ?? [], savedLocations, cityName: user.offerToEdit.city.fullName! }));
    }, [user.offerToEdit]);

    const handleDeleteEstimation = (name: string) => {
        // @ts-expect-error TODO: INVEST-237
        handleChange('costEstimate', userAddAdvertFieldsState.costEstimate?.filter((estimation) => estimation.name !== name));
    };

    const handleAddEstimation = () => {
        if (!userAddAdvertFieldsState.costEstimationTitle || !userAddAdvertFieldsState.costEstimationPrice) {
            activeNotification('Kosztorys', 'Pola kosztorysu nie mogą być puste, wpisz rodzaj kosztu oraz oraz kwotę', 'warning');

            return;
        } else {
            // @ts-expect-error TODO: INVEST-237
            handleChange('costEstimate', [...userAddAdvertFieldsState.costEstimate || [], { name: userAddAdvertFieldsState.costEstimationTitle, price: userAddAdvertFieldsState.costEstimationPrice }]);
            handleChange('costEstimationTitle', '');
            handleChange('costEstimationPrice', '');
        }
    };

    const getTitleByOfferType = (type: V2OfferType) => {
        switch (type) {
        case V2OfferType.DEAL:
            return 'Edytuj ogłoszenie - Odstąpię deal';
        case V2OfferType.INVESTOR:
            return 'Edytuj ogłoszenie - Szukam inwestora';
        case V2OfferType.CAPITAL:
            return 'Edytuj ogłoszenie - Mam kapitał';
        }
    };

    const getCities = async (voivodeship: string) => {
        const newLocations = await getVoivodeshipCities(voivodeship, userAddAdvertFieldsState.locations);

        handleChange('locations', newLocations || []);
    };

    const handleRemoveSavedLocation = (location: string) => {
        const savedLocations = userAddAdvertFieldsState.savedLocations;

        delete savedLocations[location];

        handleChange('savedLocations', savedLocations);
        handleChange('city', Object.values(savedLocations as LocationChangeType));
        handleChange('cityName', '');
    };

    const handleFloorsChange = (fieldName: string, value: ObjectAndSingleValueChangeType) => {
        handleChange('floor', (value as { from: string }).from);
        handleChange('floors', (value as { to: string }).to);
    };

    const handleUploadImage = async (params: UploadRequestData) => {
        return await userApiRequests.addImage(params, userAddAdvertFieldsState.offerType);
    };

    const handleUploadFile = async (params: UploadRequestData) => {
        return await userApiRequests.addFile(params, userAddAdvertFieldsState.offerType);
    };

    const generateImagePreview = (image?: string[]) => {
        if (!image) return [];

        return image.map((name) => ({
            preview: `${process.env.REACT_APP_API_URL_2}image/find?module=${getPropertyByTypeUser(userAddAdvertFieldsState.offerType)}&fileName=${name}&photoSize=NORMAL_PHOTO`,
            name
        }));
    };

    const handleEditOffer = () => {
        setTriggerValidation(true);

        if (validateAllFields()) {
            userApiRequests.handleEditOffer({ ...userAddAdvertFieldsState }).then(
                () => {
                    setTriggerValidation(false);
                    navigate('/my-offers');
                }
            );
        }
    };

    const handleMultipleFieldsChange = (fieldName: string[], value: ObjectAndSingleValueChangeType) => {
        handleChange(fieldName[0], (value as { from: string }).from);
        handleChange(fieldName[1], (value as { to: string }).to);
    };

    const editAdvertStructure = useMemo<ElementsToRender[]>(() => [
        {
            accordionTitle: 'Informacje podstawowe',
            oneItem: true,
            fullWidth: true,
            hideTitle: true,
            filters: [
                {
                    title: 'Tytuł ogłoszenia',
                    type: InputType.TEXT,
                    icon: IconEnum.CALENDAR,
                    placeholder: 'Wpisz tytuł ogłoszenia...',
                    additionalText: `Pozostało ${50 - (userAddAdvertFieldsState.title?.length || 0)} znaków`,
                    options: {
                        [InputType.TEXT]: {
                            contextFunctions: {
                                handleChange,
                                inputValidation: (value) => !validateField(AdvertValidatorType.NO_EMPTY_TEXT, value as string)
                            },
                            modulesProps: {
                                fieldName: 'title',
                                value: userAddAdvertFieldsState.title || '',
                                max: '50',
                                maxLength: 50,
                                errorMessage: 'Pole nie może być puste',
                                triggerValidation,
                                required: true
                            }
                        }
                    }
                }
            ]
        },
        {
            accordionTitle: 'Parametry',
            hideTitle: true,
            filters: [
                {
                    title: 'Lokalizacja',
                    type: InputType.LOCATION,
                    helperText: 'Podaj miasto. Możesz również dodać dzielnicę. Rozdziel je przecinkiem, wpisując miasto jako pierwsze, np. "Warszawa, Mokotów". Możesz podać kilka dzielnic, klikając "Wiele lokacji" np. "Warszawa, Mokotów, Służew". Więcej w zakładce "Pomoc"',
                    icon: IconEnum.MAP_PIN,
                    placeholder: 'Szukaj lokalizacji...',
                    options: {
                        [InputType.LOCATION]: {
                            modulesProps: {
                                locations: userAddAdvertFieldsState.locations,
                                searchLocationResult: userAddAdvertFieldsState.searchLocationResult,
                                savedLocations: userAddAdvertFieldsState.savedLocations,
                                singleValue: true,
                                errorMessage: 'Pole nie może być puste',
                                cityName: userAddAdvertFieldsState.cityName,
                                triggerValidation,
                                required: true,
                                savedLocationsFieldName: 'savedLocations',
                                cityFieldName: 'city'
                            },
                            contextFunctions: {
                                getCities,
                                handleChangeSingleValueField: handleChange,
                                handleChange,
                                inputValidation: (value) => !validateField(AdvertValidatorType.NO_EMPTY_TEXT, value as string),
                                handleRemoveSavedLocation
                            }
                        }
                    }
                },
                ...userAddAdvertFieldsState.offerType === V2OfferType.CAPITAL
                    ? [
                        {
                            title: 'Kwota inwestycji',
                            type: InputType.RANGE_PRICE_SELECT,
                            icon: IconEnum.PRICE,
                            placeholder: '',
                            options: {
                                [InputType.RANGE_PRICE_SELECT]: {
                                    modulesProps: {
                                        value: { from: userAddAdvertFieldsState.minimumInvestmentValue || '', to: userAddAdvertFieldsState.maximumInvestmentValue || '' },
                                        fieldName: 'minimumInvestment',
                                        options: investPriceOptions
                                    },
                                    contextFunctions: {
                                        handleChange: (_: string, value: ObjectAndSingleValueChangeType) => handleMultipleFieldsChange(['minimumInvestmentValue', 'maximumInvestmentValue'], value)
                                    }
                                }
                            }
                        }
                    ]
                    : [],
                ...userAddAdvertFieldsState.offerType === V2OfferType.DEAL
                    ? [
                        {
                            title: 'Prognozowany zysk',
                            type: InputType.NUMERIC,
                            icon: IconEnum.CALENDAR,
                            placeholder: 'Wpisz kwotę...',
                            options: {
                                [InputType.NUMERIC]: {
                                    contextFunctions: {
                                        handleChange
                                    },
                                    modulesProps: {
                                        fieldName: 'expectedProfit',
                                        value: userAddAdvertFieldsState.expectedProfit || '',
                                        suffix: 'PLN'
                                    }
                                }
                            }
                        }
                    ]
                    : [],
                ...userAddAdvertFieldsState.offerType === V2OfferType.DEAL
                    ? [
                        {
                            title: 'Cena',
                            type: InputType.NUMERIC,
                            icon: IconEnum.CALENDAR,
                            placeholder: 'Wpisz kwotę...',
                            options: {
                                [InputType.NUMERIC]: {
                                    contextFunctions: {
                                        handleChange
                                    },
                                    modulesProps: {
                                        fieldName: 'price',
                                        value: userAddAdvertFieldsState.price || '',
                                        suffix: 'PLN'
                                    }
                                }
                            }
                        }
                    ]
                    : [],
                ...userAddAdvertFieldsState.offerType === V2OfferType.DEAL
                    ? [
                        {
                            title: 'Nakłady na remont',
                            type: InputType.NUMERIC,
                            icon: IconEnum.CALENDAR,
                            placeholder: 'Wpisz kwotę...',
                            options: {
                                [InputType.NUMERIC]: {
                                    contextFunctions: {
                                        handleChange
                                    },
                                    modulesProps: {
                                        fieldName: 'renovationExpenses',
                                        value: userAddAdvertFieldsState.renovationExpenses || '',
                                        suffix: 'PLN'
                                    }
                                }
                            }
                        }
                    ]
                    : [],
                ...userAddAdvertFieldsState.offerType === V2OfferType.DEAL
                    ? [
                        {
                            title: 'Kwota do realizacji inwestycji',
                            type: InputType.NUMERIC,
                            icon: IconEnum.PRICE,
                            placeholder: 'Wpisz kwotę...',
                            options: {
                                [InputType.NUMERIC]: {
                                    modulesProps: {
                                        value: userAddAdvertFieldsState.investmentBudget || '',
                                        fieldName: 'investmentBudget',
                                        suffix: 'PLN'
                                    },
                                    contextFunctions: {
                                        handleChange
                                    }
                                }
                            }
                        }
                    ]
                    : [],
                ...userAddAdvertFieldsState.offerType === V2OfferType.DEAL
                    ? [
                        {
                            title: 'Szacowany zwrot (ROI)',
                            type: InputType.NUMERIC,
                            icon: IconEnum.PRICE,
                            placeholder: 'Wpisz kwotę...',
                            options: {
                                [InputType.NUMERIC]: {
                                    modulesProps: {
                                        value: userAddAdvertFieldsState.roi || '',
                                        fieldName: 'roi',
                                        suffix: '%'
                                    },
                                    contextFunctions: {
                                        handleChange
                                    }
                                }
                            }
                        }
                    ]
                    : [],
                ...userAddAdvertFieldsState.offerType === V2OfferType.DEAL
                    ? [
                        {
                            title: 'Odstępne',
                            type: InputType.NUMERIC,
                            icon: IconEnum.PRICE,
                            placeholder: 'Wpisz kwotę...',
                            options: {
                                [InputType.NUMERIC]: {
                                    modulesProps: {
                                        value: userAddAdvertFieldsState.leaseFee || '',
                                        fieldName: 'leaseFee',
                                        suffix: 'PLN'
                                    },
                                    contextFunctions: {
                                        handleChange
                                    }
                                }
                            }
                        }
                    ]
                    : [],
                ...userAddAdvertFieldsState.offerType === V2OfferType.DEAL || userAddAdvertFieldsState.offerType === V2OfferType.INVESTOR
                    ? [
                        {
                            title: 'Rodzaj nieruchomości',
                            type: InputType.AUTOCOMPLETE,
                            icon: IconEnum.LEAVE,
                            placeholder: 'Wybierz typ...',
                            options: {
                                [InputType.AUTOCOMPLETE]: {
                                    contextFunctions: {
                                        handleChange
                                    },
                                    modulesProps: {
                                        value: userAddAdvertFieldsState.buildingType || '',
                                        options: usersBuildingOptions,
                                        fieldName: 'buildingType'
                                    }
                                }
                            }
                        }
                    ]
                    : [],
                ...userAddAdvertFieldsState.offerType === V2OfferType.INVESTOR
                    ? [
                        {
                            title: 'Stan prawny',
                            type: InputType.AUTOCOMPLETE,
                            icon: IconEnum.LEAVE,
                            placeholder: 'Wybierz typ...',
                            options: {
                                [InputType.AUTOCOMPLETE]: {
                                    contextFunctions: {
                                        handleChange
                                    },
                                    modulesProps: {
                                        value: userAddAdvertFieldsState.legalStatus || '',
                                        fieldName: 'legalStatus',
                                        options: legalStatusInvestorOptions
                                    }
                                }
                            }
                        }
                    ]
                    : [],
                ...userAddAdvertFieldsState.offerType === V2OfferType.INVESTOR
                    ? [
                        {
                            title: 'Stan nieruchomości',
                            type: InputType.AUTOCOMPLETE,
                            icon: IconEnum.LEAVE,
                            placeholder: 'Wybierz typ...',
                            options: {
                                [InputType.AUTOCOMPLETE]: {
                                    contextFunctions: {
                                        handleChange
                                    },
                                    modulesProps: {
                                        fieldName: 'propertyCondition',
                                        value: userAddAdvertFieldsState.propertyCondition || '',
                                        options: propertyConditionOptions
                                    }
                                }
                            }
                        }
                    ]
                    : [],
                ...userAddAdvertFieldsState.offerType === V2OfferType.INVESTOR
                    ? [
                        {
                            title: 'Numer księgi wieczystej',
                            type: InputType.TEXT,
                            icon: IconEnum.LEAVE,
                            placeholder: 'Wpisz numer...',
                            options: {
                                [InputType.TEXT]: {
                                    contextFunctions: {
                                        handleChange
                                    },
                                    modulesProps: {
                                        fieldName: 'landRegistryNumber',
                                        value: userAddAdvertFieldsState.landRegistryNumber || ''
                                    }
                                }
                            }
                        }
                    ]
                    : [],
                ...userAddAdvertFieldsState.offerType === V2OfferType.INVESTOR
                    ? [
                        {
                            title: 'Piętro',
                            type: InputType.RANGE_NUMBER_SELECT,
                            icon: IconEnum.FLOOR_LEVEL,
                            placeholder: '',
                            options: {
                                [InputType.RANGE_NUMBER_SELECT]: {
                                    modulesProps: {
                                        value: { from: userAddAdvertFieldsState.floor || '', to: userAddAdvertFieldsState.floors || '' },
                                        fieldName: 'connectedFloors',
                                        options: userFloorOptions,
                                        secondInputOptions: userFloorsOptions,
                                        maxInputLength: 3,
                                        customSpacer: 'z',
                                        placeholderFrom: 'wybierz',
                                        placeholderTo: 'wybierz'
                                    },
                                    contextFunctions: {
                                        handleChange: handleFloorsChange
                                    }
                                }
                            }
                        }
                    ]
                    : [],
                ...userAddAdvertFieldsState.offerType === V2OfferType.INVESTOR
                    ? [
                        {
                            title: 'Powierzchnia',
                            type: InputType.NUMERIC,
                            icon: IconEnum.SQUARE,
                            placeholder: 'Wpisz metraż...',
                            options: {
                                [InputType.NUMERIC]: {
                                    modulesProps: {
                                        value: userAddAdvertFieldsState.area || '',
                                        fieldName: 'area',
                                        suffix: 'm²'
                                    },
                                    contextFunctions: {
                                        handleChange
                                    }
                                }
                            }
                        }
                    ]
                    : [],
                ...userAddAdvertFieldsState.offerType === V2OfferType.CAPITAL
                    ? [
                        {
                            title: 'Czas trwania inwestycji w miesiącach',
                            type: InputType.RANGE_NUMBER_SELECT,
                            icon: IconEnum.TIMER,
                            placeholder: '',
                            options: {
                                [InputType.RANGE_NUMBER_SELECT]: {
                                    modulesProps: {
                                        value: { from: userAddAdvertFieldsState.investmentTimeFrom || '', to: userAddAdvertFieldsState.investmentTimeTo || '' },
                                        fieldName: 'investmentTime',
                                        options: investmentTimeOptions,
                                        maxInputLength: 2
                                    },
                                    contextFunctions: {
                                        handleChange: (_: string, value: ObjectAndSingleValueChangeType) => handleMultipleFieldsChange(['investmentTimeFrom', 'investmentTimeTo'], value)
                                    }
                                }
                            }
                        }
                    ]
                    : [],
                ...userAddAdvertFieldsState.offerType === V2OfferType.CAPITAL
                    ? [
                        {
                            title: 'Forma zysku',
                            type: InputType.MULTIPLE_SELECT,
                            icon: IconEnum.LEAVE,
                            placeholder: 'Wybierz typ...',
                            options: {
                                [InputType.MULTIPLE_SELECT]: {
                                    contextFunctions: {
                                        handleChange
                                    },
                                    modulesProps: {
                                        fieldName: 'formOfProfit',
                                        value: userAddAdvertFieldsState.formOfProfit || [],
                                        options: formOfProfitOptions,
                                        optionsIcon: IconEnum.TWO_TYPE
                                    }
                                }
                            }
                        }
                    ]
                    : [],
                ...userAddAdvertFieldsState.offerType === V2OfferType.CAPITAL
                    ? [
                        {
                            title: 'Kwota zysku',
                            type: InputType.NUMERIC,
                            icon: IconEnum.PRICE,
                            placeholder: 'Wpisz kwotę...',
                            options: {
                                [InputType.NUMERIC]: {
                                    modulesProps: {
                                        value: userAddAdvertFieldsState.minimumProfitAmount || '',
                                        fieldName: 'minimumProfitAmount'
                                    },
                                    contextFunctions: {
                                        handleChange
                                    }
                                }
                            }
                        }
                    ]
                    : [],
                ...userAddAdvertFieldsState.offerType === V2OfferType.CAPITAL
                    ? [
                        {
                            title: 'Oczekiwana stopa zwrotu',
                            type: InputType.NUMERIC,
                            icon: IconEnum.PRICE_PART,
                            placeholder: 'Wpisz procent...',
                            options: {
                                [InputType.NUMERIC]: {
                                    modulesProps: {
                                        value: userAddAdvertFieldsState.expectedReturnRate || '',
                                        fieldName: 'expectedReturnRate',
                                        suffix: '%'
                                    },
                                    contextFunctions: {
                                        handleChange
                                    }
                                }
                            }
                        }
                    ]
                    : [],
                ...userAddAdvertFieldsState.offerType === V2OfferType.CAPITAL
                    ? [
                        {
                            title: 'Zabezpieczenie',
                            type: InputType.MULTIPLE_SELECT,
                            icon: IconEnum.LEAVE,
                            placeholder: 'Wybierz rodzaj...',
                            options: {
                                [InputType.MULTIPLE_SELECT]: {
                                    contextFunctions: {
                                        handleChange
                                    },
                                    modulesProps: {
                                        value: userAddAdvertFieldsState.investmentSecurity as unknown as string[] || [],
                                        fieldName: 'investmentSecurity',
                                        options: investmentSecurityCapitalOptions,
                                        optionsIcon: IconEnum.LEAVE
                                    }
                                }
                            }
                        }
                    ]
                    : [],
                ...userAddAdvertFieldsState.offerType === V2OfferType.CAPITAL
                    ? [
                        {
                            title: 'Forma inwestycji',
                            type: InputType.MULTIPLE_SELECT,
                            icon: IconEnum.LEAVE,
                            placeholder: 'Wybierz rodzaj...',
                            options: {
                                [InputType.MULTIPLE_SELECT]: {
                                    contextFunctions: {
                                        handleChange
                                    },
                                    modulesProps: {
                                        value: userAddAdvertFieldsState.formOfInvestment as unknown as string[] || [],
                                        fieldName: 'formOfInvestment',
                                        options: formOfInvestmentCapitalOptions,
                                        optionsIcon: IconEnum.LEAVE
                                    }
                                }
                            }
                        }
                    ]
                    : []
            ]
        },
        ...userAddAdvertFieldsState.offerType === V2OfferType.INVESTOR
            ? [
                {
                    accordionTitle: 'Parametry inwestora',
                    hideTitle: true,
                    filters: [
                        ...userAddAdvertFieldsState.offerType === V2OfferType.INVESTOR
                            ? [
                                {
                                    title: 'Podpisana umowa przedwstępna',
                                    type: InputType.RADIO,
                                    icon: IconEnum.BOOKMARK,
                                    placeholder: '',
                                    options: {
                                        [InputType.RADIO]: {
                                            modulesProps: {
                                                value: userAddAdvertFieldsState.preContract || false,
                                                fieldName: 'preContract'
                                            },
                                            contextFunctions: {
                                                handleChange
                                            }
                                        }
                                    }
                                }
                            ]
                            : [],
                        ...userAddAdvertFieldsState.offerType === V2OfferType.INVESTOR
                            ? [
                                {
                                    title: 'Mam ekipę budowlaną',
                                    type: InputType.RADIO,
                                    icon: IconEnum.OPTIONS,
                                    placeholder: '',
                                    options: {
                                        [InputType.RADIO]: {
                                            modulesProps: {
                                                value: userAddAdvertFieldsState.constructionCrew || false,
                                                fieldName: 'constructionCrew'
                                            },
                                            contextFunctions: {
                                                handleChange
                                            }
                                        }
                                    }
                                }
                            ]
                            : [],
                        ...userAddAdvertFieldsState.offerType === V2OfferType.INVESTOR
                            ? [
                                {
                                    title: 'Wkład własny',
                                    type: InputType.NUMERIC,
                                    icon: IconEnum.PRICE,
                                    placeholder: 'Wpisz kwotę...',
                                    options: {
                                        [InputType.NUMERIC]: {
                                            modulesProps: {
                                                value: userAddAdvertFieldsState.ownContribution || '',
                                                fieldName: 'ownContribution',
                                                suffix: 'PLN'
                                            },
                                            contextFunctions: {
                                                handleChange
                                            }
                                        }
                                    }
                                }
                            ]
                            : [],
                        ...userAddAdvertFieldsState.offerType === V2OfferType.INVESTOR
                            ? [
                                {
                                    title: 'Wkład inwestora',
                                    type: InputType.NUMERIC,
                                    icon: IconEnum.PRICE,
                                    placeholder: 'Wpisz kwotę...',
                                    options: {
                                        [InputType.NUMERIC]: {
                                            modulesProps: {
                                                value: userAddAdvertFieldsState.investorContribution || '',
                                                fieldName: 'investorContribution',
                                                suffix: 'PLN'
                                            },
                                            contextFunctions: {
                                                handleChange
                                            }
                                        }
                                    }
                                }
                            ]
                            : [],
                        ...userAddAdvertFieldsState.offerType === V2OfferType.INVESTOR
                            ? [
                                {
                                    title: 'Całkowita wartość inwestycji',
                                    type: InputType.NUMERIC,
                                    icon: IconEnum.PRICE,
                                    placeholder: 'Wpisz kwotę...',
                                    options: {
                                        [InputType.NUMERIC]: {
                                            modulesProps: {
                                                value: userAddAdvertFieldsState.totalInvestmentValue || '',
                                                fieldName: 'totalInvestmentValue',
                                                suffix: 'PLN'
                                            },
                                            contextFunctions: {
                                                handleChange
                                            }
                                        }
                                    }
                                }
                            ]
                            : [],
                        ...userAddAdvertFieldsState.offerType === V2OfferType.INVESTOR
                            ? [
                                {
                                    title: 'Cena sprzedaży',
                                    type: InputType.RANGE_PRICE_SELECT,
                                    icon: IconEnum.PRICE,
                                    placeholder: 'Wpisz kwotę...',
                                    options: {
                                        [InputType.RANGE_PRICE_SELECT]: {
                                            modulesProps: {
                                                value: { from: userAddAdvertFieldsState.minimumSalePrice || '', to: userAddAdvertFieldsState.maximumSalePrice || '' },
                                                fieldName: 'salePrice',
                                                options: investPriceOptions
                                            },
                                            contextFunctions: {
                                                handleChange: (_: string, value: ObjectAndSingleValueChangeType) => handleMultipleFieldsChange(['minimumSalePrice', 'maximumSalePrice'], value)
                                            }
                                        }
                                    }
                                }
                            ]
                            : [],
                        ...userAddAdvertFieldsState.offerType === V2OfferType.INVESTOR
                            ? [
                                {
                                    title: 'Szacowana data sprzedaży',
                                    type: InputType.DATE,
                                    icon: IconEnum.CALENDAR,
                                    placeholder: 'Wybierz datę',
                                    options: {
                                        [InputType.DATE]: {
                                            modulesProps: {
                                                value: userAddAdvertFieldsState.estimatedSaleDate || '',
                                                fieldName: 'estimatedSaleDate'
                                            },
                                            contextFunctions: {
                                                handleChange
                                            }
                                        }
                                    }
                                }
                            ]
                            : []
                    ]
                }
            ]
            : [],
        ...userAddAdvertFieldsState.offerType === V2OfferType.INVESTOR
            ? [
                {
                    accordionTitle: 'Kosztorys',
                    hideTitle: true,
                    alignToEnd: true,
                    filters: [
                        {
                            inputColumnPosition: '1/3',
                            title: 'Kosztorys',
                            type: InputType.TEXT,
                            icon: '' as IconEnum,
                            placeholder: 'Wpisz rodzaj kosztu...',
                            options: {
                                [InputType.TEXT]: {
                                    contextFunctions: {
                                        handleChange
                                    },
                                    modulesProps: {
                                        fieldName: 'costEstimationTitle',
                                        value: userAddAdvertFieldsState.costEstimationTitle || ''
                                    }
                                }
                            }
                        },
                        {
                            title: '',
                            type: InputType.NUMERIC,
                            icon: '' as IconEnum,
                            placeholder: 'Wpisz kwotę...',
                            options: {
                                [InputType.NUMERIC]: {
                                    modulesProps: {
                                        value: userAddAdvertFieldsState.costEstimationPrice || '',
                                        fieldName: 'costEstimationPrice',
                                        suffix: 'PLN'
                                    },
                                    contextFunctions: {
                                        handleChange
                                    }
                                }
                            }
                        },
                        {
                            title: '',
                            type: InputType.BUTTON,
                            icon: '' as IconEnum,
                            placeholder: 'Dodaj do kosztorysu',
                            options: {
                                [InputType.BUTTON]: {
                                    contextFunctions: {
                                        onClick: handleAddEstimation
                                    },
                                    modulesProps: {}
                                }
                            }
                        },
                        {
                            title: '',
                            type: InputType.COST_ESTIMATION_SUMMARY,
                            icon: '' as IconEnum,
                            placeholder: 'Wpisz',
                            options: {
                                [InputType.COST_ESTIMATION_SUMMARY]: {
                                    contextFunctions: {
                                        handleDeleteEstimation
                                    },
                                    modulesProps: {
                                        costEstimation: userAddAdvertFieldsState.costEstimate ? userAddAdvertFieldsState.costEstimate.map((cost) => ({ value: cost.price, label: cost.name })) : []
                                    }
                                }
                            }
                        }
                    ]
                }
            ]
            : [],
        {
            accordionTitle: 'Opis',
            hideTitle: true,
            oneItem: true,
            fullWidth: true,
            filters: [
                {
                    title: 'Szczegółowy opis',
                    type: InputType.TEXT,
                    icon: IconEnum.PRICE,
                    placeholder: 'Wpisz treść opisu...',
                    additionalText: `Pozostało ${10000 - (userAddAdvertFieldsState.description?.length || 0)} znaków`,
                    options: {
                        [InputType.TEXT]: {
                            modulesProps: {
                                value: userAddAdvertFieldsState.description || '',
                                fieldName: 'description',
                                textareaMode: true,
                                maxLength: 10000,
                                triggerValidation,
                                errorMessage: 'Pole nie może być puste',
                                required: true
                            },
                            contextFunctions: {
                                handleChange,
                                inputValidation: (value) => !validateField(AdvertValidatorType.NO_EMPTY_TEXT, value as string)
                            }
                        }
                    }
                }
            ]
        },
        ...userAddAdvertFieldsState.offerType === V2OfferType.DEAL
            ? [
                {
                    accordionTitle: 'Parametry 2',
                    hideTitle: true,
                    filters: [
                        ...userAddAdvertFieldsState.offerType === V2OfferType.DEAL
                            ? [
                                {
                                    title: 'Piętro',
                                    type: InputType.RANGE_NUMBER_SELECT,
                                    icon: IconEnum.FLOOR_LEVEL,
                                    placeholder: '',
                                    options: {
                                        [InputType.RANGE_NUMBER_SELECT]: {
                                            modulesProps: {
                                                value: { from: userAddAdvertFieldsState.floor || '', to: userAddAdvertFieldsState.floors || '' },
                                                fieldName: 'connectedFloors',
                                                options: userFloorOptions,
                                                secondInputOptions: userFloorsOptions,
                                                maxInputLength: 3,
                                                customSpacer: 'z',
                                                placeholderFrom: 'wybierz',
                                                placeholderTo: 'wybierz'
                                            },
                                            contextFunctions: {
                                                handleChange: handleFloorsChange
                                            }
                                        }
                                    }
                                }
                            ]
                            : [],
                        ...userAddAdvertFieldsState.offerType === V2OfferType.DEAL
                            ? [
                                {
                                    title: 'Powierzchnia',
                                    type: InputType.NUMERIC,
                                    icon: IconEnum.SQUARE,
                                    placeholder: 'Wpisz metraż...',
                                    options: {
                                        [InputType.NUMERIC]: {
                                            modulesProps: {
                                                value: userAddAdvertFieldsState.area || '',
                                                fieldName: 'area',
                                                suffix: 'm²'
                                            },
                                            contextFunctions: {
                                                handleChange
                                            }
                                        }
                                    }
                                }
                            ]
                            : [],
                        ...userAddAdvertFieldsState.offerType === V2OfferType.DEAL
                            ? [
                                {
                                    title: 'Dostępne udogodnienia',
                                    type: InputType.MULTIPLE_SELECT,
                                    icon: IconEnum.PEOPLE,
                                    placeholder: 'Wybierz typ...',
                                    options: {
                                        [InputType.MULTIPLE_SELECT]: {
                                            modulesProps: {
                                                value: userAddAdvertFieldsState.features as unknown as string[] || [],
                                                options: featureOptions,
                                                optionsIcon: IconEnum.PEOPLE,
                                                fieldName: 'features'
                                            },
                                            contextFunctions: {
                                                handleChange
                                            }
                                        }
                                    }
                                }
                            ]
                            : [],
                        ...userAddAdvertFieldsState.offerType === V2OfferType.DEAL
                            ? [
                                {
                                    title: 'Podpisana umowa przedwstępna',
                                    type: InputType.RADIO,
                                    icon: IconEnum.OPTIONS,
                                    placeholder: '',
                                    options: {
                                        [InputType.RADIO]: {
                                            modulesProps: {
                                                value: userAddAdvertFieldsState.preContract || false,
                                                fieldName: 'preContract'
                                            },
                                            contextFunctions: {
                                                handleChange
                                            }
                                        }
                                    }
                                }
                            ]
                            : [],
                        ...userAddAdvertFieldsState.offerType === V2OfferType.DEAL
                            ? [
                                {
                                    title: 'Od kiedy dostępne',
                                    type: InputType.DATE,
                                    icon: IconEnum.CALENDAR,
                                    placeholder: 'Wybierz datę...',
                                    options: {
                                        [InputType.DATE]: {
                                            modulesProps: {
                                                value: userAddAdvertFieldsState.availableFrom || '',
                                                fieldName: 'availableFrom'
                                            },
                                            contextFunctions: {
                                                handleChange
                                            }
                                        }
                                    }
                                }
                            ]
                            : [],
                        ...userAddAdvertFieldsState.offerType === V2OfferType.DEAL
                            ? [
                                {
                                    title: 'Przewidywany cel inwestycji',
                                    type: InputType.TEXT,
                                    icon: IconEnum.PRICE,
                                    placeholder: 'Wpisz cel...',
                                    options: {
                                        [InputType.TEXT]: {
                                            modulesProps: {
                                                value: userAddAdvertFieldsState.investmentGoal || '',
                                                fieldName: 'investmentGoal'
                                            },
                                            contextFunctions: {
                                                handleChange
                                            }
                                        }
                                    }
                                }
                            ]
                            : [],
                        ...userAddAdvertFieldsState.offerType === V2OfferType.DEAL
                            ? [
                                {
                                    title: 'Przewidywany przychód z najmu',
                                    type: InputType.NUMERIC,
                                    icon: IconEnum.PRICE,
                                    placeholder: 'Wpisz kwotę...',
                                    options: {
                                        [InputType.NUMERIC]: {
                                            modulesProps: {
                                                value: userAddAdvertFieldsState.rentalIncome || '',
                                                fieldName: 'rentalIncome',
                                                suffix: 'PLN'
                                            },
                                            contextFunctions: {
                                                handleChange
                                            }
                                        }
                                    }
                                }
                            ]
                            : [],
                        ...userAddAdvertFieldsState.offerType === V2OfferType.DEAL
                            ? [
                                {
                                    title: 'Stan nieruchomości',
                                    type: InputType.AUTOCOMPLETE,
                                    icon: IconEnum.LEAVE,
                                    placeholder: 'Wybierz typ...',
                                    options: {
                                        [InputType.AUTOCOMPLETE]: {
                                            contextFunctions: {
                                                handleChange
                                            },
                                            modulesProps: {
                                                fieldName: 'propertyCondition',
                                                value: userAddAdvertFieldsState.propertyCondition || '',
                                                options: propertyConditionOptions
                                            }
                                        }
                                    }
                                }
                            ]
                            : [],
                        ...userAddAdvertFieldsState.offerType === V2OfferType.DEAL
                            ? [
                                {
                                    title: 'Stan prawny',
                                    type: InputType.AUTOCOMPLETE,
                                    icon: IconEnum.LEAVE,
                                    placeholder: 'Wybierz typ...',
                                    options: {
                                        [InputType.AUTOCOMPLETE]: {
                                            contextFunctions: {
                                                handleChange
                                            },
                                            modulesProps: {
                                                value: userAddAdvertFieldsState.legalStatus || '',
                                                fieldName: 'legalStatus',
                                                options: legalStatusDealOptions
                                            }
                                        }
                                    }
                                }
                            ]
                            : [],
                        ...userAddAdvertFieldsState.offerType === V2OfferType.DEAL
                            ? [
                                {
                                    title: 'Numer księgi wieczystej',
                                    type: InputType.TEXT,
                                    icon: IconEnum.LEAVE,
                                    placeholder: 'Wpisz numer...',
                                    options: {
                                        [InputType.TEXT]: {
                                            contextFunctions: {
                                                handleChange
                                            },
                                            modulesProps: {
                                                fieldName: 'landRegistryNumber',
                                                value: userAddAdvertFieldsState.landRegistryNumber || ''
                                            }
                                        }
                                    }
                                }
                            ]
                            : []
                    ]
                }
            ]
            : [],
        ...userAddAdvertFieldsState.offerType === V2OfferType.INVESTOR || userAddAdvertFieldsState.offerType === V2OfferType.DEAL
            ? [
                {
                    accordionTitle: 'Dodaj zdjęcia',
                    oneItem: true,
                    fullWidth: true,
                    additionalText: 'Maksymalna ilość zdjęć to 8. Aby zmienić kolejność fotografii, wystarczy przytrzymać miniaturkę i przeciągnąć ją w miejsce, w które chcemy.',
                    filters: [
                        {
                            title: '',
                            type: InputType.IMAGE_UPLOAD,
                            icon: '' as IconEnum,
                            placeholder: '',
                            options: {
                                [InputType.IMAGE_UPLOAD]: {
                                    contextFunctions: {
                                        handleChange,
                                        fileUploadHandler: handleUploadImage
                                    },
                                    modulesProps: {
                                        value: generateImagePreview(userAddAdvertFieldsState.photos),
                                        fieldName: 'photos'
                                    }
                                }
                            }
                        }
                    ]
                }
            ]
            : [],
        ...userAddAdvertFieldsState.offerType === V2OfferType.INVESTOR || userAddAdvertFieldsState.offerType === V2OfferType.DEAL
            ? [
                {
                    accordionTitle: 'Dodaj pliki',
                    oneItem: true,
                    fullWidth: true,
                    additionalText: 'Maksymalny rozmiar pliku to 10MB.',
                    filters: [
                        {
                            title: '',
                            type: InputType.FILE_UPLOAD,
                            icon: '' as IconEnum,
                            placeholder: '',
                            options: {
                                [InputType.FILE_UPLOAD]: {
                                    contextFunctions: {
                                        handleChange,
                                        fileUploadHandler: handleUploadFile
                                    },
                                    modulesProps: {
                                        value: userAddAdvertFieldsState.documents || [],
                                        fieldName: 'documents'
                                    }
                                }
                            }
                        }
                    ]
                }
            ]
            : [],
        ...userAddAdvertFieldsState.offerType === V2OfferType.INVESTOR
            ? [
                {
                    accordionTitle: 'Parametry inwestora 2',
                    hideTitle: true,
                    filters: [
                        {
                            title: 'Procent',
                            type: InputType.NUMERIC,
                            icon: IconEnum.SQUARE,
                            placeholder: 'Wpisz procent...',
                            options: {
                                [InputType.NUMERIC]: {
                                    modulesProps: {
                                        value: userAddAdvertFieldsState.percentageOfInvestment || '',
                                        fieldName: 'percentageOfInvestment',
                                        suffix: '%'
                                    },
                                    contextFunctions: {
                                        handleChange
                                    }
                                }
                            }
                        },
                        {
                            title: 'Czas trwania inwestycji',
                            type: InputType.AUTOCOMPLETE,
                            icon: IconEnum.BED,
                            placeholder: 'Wybierz czas w miesiącach...',
                            options: {
                                [InputType.AUTOCOMPLETE]: {
                                    modulesProps: {
                                        value: userAddAdvertFieldsState.investmentTime || '',
                                        fieldName: 'investmentTime',
                                        options: investmentTimeOptions,
                                        maxInputLength: 2
                                    },
                                    contextFunctions: {
                                        handleChange
                                    }
                                }
                            }
                        },
                        {
                            title: 'Finansowanie',
                            type: InputType.TEXT,
                            icon: IconEnum.LEAVE,
                            placeholder: 'Wpisz...',
                            options: {
                                [InputType.TEXT]: {
                                    contextFunctions: {
                                        handleChange
                                    },
                                    modulesProps: {
                                        fieldName: 'funding',
                                        value: userAddAdvertFieldsState.funding || ''
                                    }
                                }
                            }
                        },
                        {
                            title: 'Zabezpieczenie',
                            type: InputType.MULTIPLE_SELECT,
                            icon: IconEnum.LEAVE,
                            placeholder: 'Wybierz rodzaj...',
                            options: {
                                [InputType.MULTIPLE_SELECT]: {
                                    contextFunctions: {
                                        handleChange
                                    },
                                    modulesProps: {
                                        value: userAddAdvertFieldsState.investmentSecurity as unknown as string[] || [],
                                        fieldName: 'investmentSecurity',
                                        options: investmentSecurityInvestorOptions,
                                        optionsIcon: IconEnum.LEAVE
                                    }
                                }
                            }
                        },
                        {
                            title: 'Forma inwestycji',
                            type: InputType.MULTIPLE_SELECT,
                            icon: IconEnum.LEAVE,
                            placeholder: 'Wybierz rodzaj...',
                            options: {
                                [InputType.MULTIPLE_SELECT]: {
                                    contextFunctions: {
                                        handleChange
                                    },
                                    modulesProps: {
                                        value: userAddAdvertFieldsState.formOfInvestment as unknown as string[] || [],
                                        fieldName: 'formOfInvestment',
                                        options: formOfInvestmentCapitalOptions,
                                        optionsIcon: IconEnum.LEAVE
                                    }
                                }
                            }
                        }
                    ]
                }
            ]
            : [],
        {
            accordionTitle: 'Podsumowanie',
            oneItem: true,
            filters: [
                {
                    inputColumnPosition: '4/12',
                    title: 'Edytuj ofertę',
                    type: InputType.BUTTON,
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Edytuj ofertę',
                    options: {
                        [InputType.BUTTON]: {
                            contextFunctions: {
                                onClick: handleEditOffer
                            },
                            modulesProps: {}
                        }
                    }
                }
            ]
        }
    ], [userAddAdvertFieldsState, triggerValidation]);

    return (
        <Wrapper>
            <OffersHeader>
                <Title>{getTitleByOfferType(userAddAdvertFieldsState.offerType)}</Title>
            </OffersHeader>
            <Container darkMode={user.isDarkMode}>
                <V2FiltersRenderer type={'add_offer'} elementsToRender={editAdvertStructure} bottomActions={null} />
            </Container>
        </Wrapper>
    );
};

export default UserEditAdvertPage;
