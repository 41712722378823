import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import Icon, { IconEnum } from './Icon';

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
    gap: 10px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: var(--color-white);
    border-radius: var(--box-border-radius);
    color: var(--color-primary);
`;

const FiltersRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

const Filter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-alt-second);
    padding: 10px 20px;
    border-radius: 20px;
    gap: 10px;

    span {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    p {
        font-weight: 400;
    }
`;

const ExpandButton = styled.button`
    background: none;
    padding: 5px;
    border: none;
    color: var(--color-primary);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;

    &:hover {
        outline: none;
        background: none;
        border: none;
        color: var(--color-primary);
    }

`;

export interface FilterItem {
    label: string;
    value: string | number | null | string[];
    filterName?: string;
    index?: number;
    noDeleteButton?: boolean;
    suffix?: string;
}

interface ActiveFiltersProperties {
    filters: FilterItem[];
    onChange: () => void;
    onDeleteFilter: (filterName: string, value?: string | number | null | string[], index?: number) => void;
}

const V2ActiveFilters: FC<ActiveFiltersProperties> = ({ filters, onChange, onDeleteFilter }) => {
    const [isActionPerformed, setIsActionPerformed] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    const [elementsInFirstTwoLines, setElementsInFirstTwoLines] = useState(0);

    const containerRef = useRef<HTMLDivElement>(null);

    const getCountFilterInTwoLines = () => {
        if (containerRef.current) {
            const filterElements = containerRef.current.querySelectorAll('#filter-item');
            const containerStyle = window.getComputedStyle(containerRef.current);
            const containerWidth = containerRef.current.clientWidth;
            const containerPadding = parseFloat(containerStyle.paddingLeft) + parseFloat(containerStyle.paddingRight);
            const gap = 10;

            const availableWidth = containerWidth - containerPadding;
            let currentLineWidth = 0;
            let lineCount = 1;
            let elementsInFirstTwoLines = 0;

            filterElements.forEach((filter) => {
                const filterWidth = (filter as HTMLElement).offsetWidth;

                if (currentLineWidth > 0) {
                    currentLineWidth += gap;
                }

                if (currentLineWidth + filterWidth > availableWidth) {
                    lineCount++;
                    currentLineWidth = filterWidth;
                } else {
                    currentLineWidth += filterWidth;
                }

                if (lineCount <= 2) {
                    elementsInFirstTwoLines++;
                }
            });

            setElementsInFirstTwoLines(elementsInFirstTwoLines);
        }
    };

    useEffect(() => {
        getCountFilterInTwoLines();
        window.addEventListener('resize', getCountFilterInTwoLines);

        return () => window.removeEventListener('resize', getCountFilterInTwoLines);
    }, [filters, isExpanded]);

    useEffect(() => {
        if (isActionPerformed) {
            onChange();
            setIsActionPerformed(false);
        }
    }, [filters]);

    const handleDeleteFilter = (filterName: string, value?: string | number | null | string[], index?: number) => {
        onDeleteFilter(filterName, value, index);
        setIsActionPerformed(true);
    };

    const visibleFilters = filters.slice(0, elementsInFirstTwoLines);
    const hiddenFilters = filters.slice(elementsInFirstTwoLines);

    const generateFilter = (item: FilterItem) => {
        const { label, value, filterName, index, suffix } = item;

        if (value !== null && value !== undefined && (typeof value !== 'string' || value.trim() !== '') && (!Array.isArray(value) || value.length > 0)) {
            return (
                <Filter id="filter-item" key={index !== undefined ? `${filterName}-${index}` : label}>
                    <span>{label}: <p>{Array.isArray(value) ? value.join(', ') : suffix ? `${value} ${suffix}` : value}</p></span>
                    {!item.noDeleteButton && <Icon onClick={() => handleDeleteFilter(filterName || label, value, index)} icon={IconEnum.CIRCLE_CROSS}/>}
                </Filter>
            );
        }

        return null;
    };

    return (
        <Container>
            <FiltersRow ref={containerRef}>
                {visibleFilters.map(generateFilter)}
                {isExpanded && hiddenFilters.length > 0 && hiddenFilters.map(generateFilter)}
            </FiltersRow>
            {filters.length > elementsInFirstTwoLines &&
                <ExpandButton onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded ? 'Zwiń' : `Pokaż więcej (${hiddenFilters.length})`}
                    <Icon icon={isExpanded ? IconEnum.ACCORDION_UP : IconEnum.ACCORDION_DOWN} />
                </ExpandButton>
            }
        </Container>
    );
};

export default V2ActiveFilters;

