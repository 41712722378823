import {
    UserMyOffersResponseContent,
    UserOffersResponseContent,
    UserProfileOffersResponseContent
} from 'store/User/State';
import { BoxInfoDtoModuleEnum } from 'utils/types/UserModels';

export const getMainValue = (offer: UserOffersResponseContent | UserProfileOffersResponseContent | UserMyOffersResponseContent) => {
    switch (offer.module) {
    case BoxInfoDtoModuleEnum.USER_OFFER_DEAL:
        return offer.price?.toString() || '-';
    case BoxInfoDtoModuleEnum.USER_OFFER_INVESTOR:
        return offer.investorContribution?.toString() || '-';
    case BoxInfoDtoModuleEnum.USER_OFFER_CAPITAL:
        return offer.maximumInvestmentValue?.toString() || '-';
    default:
        return '-';
    }
};
