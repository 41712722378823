import React, { FC, useEffect, useState } from 'react';
import { useOffers } from 'store/Offers/Context';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';
import { OfferData } from 'utils/types/OfferData';
import { useWindowWidth } from 'utils/useWindowWidth';

import Icon, { IconEnum } from 'components/common/Icon';
import AutocompleteInput from 'components/common/Inputs/AutocompleteInput/AutocompleteInput';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    background-color: var(--color-white);
    border-radius: var(--box-border-radius);

    color: ${({ theme }) => theme.text};
    margin: 15px auto;

    @media (min-width: 991px) {
        flex-direction: row;
    }
`;

const List = styled.ul`
    list-style: none;
    display: flex;
    padding: 0;
`;

const ListElement = styled.li`
    font-size: 14px;
    margin: 0 3px;
`;

const Button = styled.button<ButtonTypes>`
    color: var(--color-primary);
    font-weight: normal;
    background: var(--color-alt-second);
    border-radius: 50%;
    min-width: 40px;
    height: 40px;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 14px;
    margin: 0 2px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: ${(props) => props.darkMode ? 'var(--color-primary)' : 'var(--color-alt)'};
        color: var(--color-white);

        span {
            filter: ${(props) => props.darkMode ? 'unset !important' : 'invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(105%) contrast(102%)'};
        }
    }

    &.active {
        background: ${(props) => props.darkMode ? 'var(--color-primary)' : 'var(--color-alt)'};
        font-weight: 800;
        color: var(--color-white);
    }
`;

const AutoCompleteWrapper = styled.div`
    position: relative;
    width: 235px;

    & > div {
        background: transparent;
        gap: 0;
        margin-left: 15px;

        p {
            margin-right: 3px;
        }

        input:hover,
        &:hover {
            cursor: pointer;
            caret-color: transparent;
        }
    }

    .focus {
        border: none;
    }

    & > div > span {
        background: transparent;
    }

    .action {
        right: 33px;
        top: 10px;
    }

    span {
        padding: 0;
        cursor: pointer;
        caret-color: transparent;

        input {
            font-weight: 600;
        }

        &:hover {
            cursor: pointer;
            caret-color: transparent;
        }
    }
`;

const ListContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 20px;

    @media (min-width: 991px) {
        margin-top: 0;
    }
`;

interface ButtonTypes {
    darkMode?: boolean;
}

interface ClientPaginationProperties {
    onChangeRenderList: (list: OfferData[]) => void
    onChangePage: (currentPage: number, amountSites: number) => void
    isShowMoreClicked: boolean;
}

const ClientPagination: FC<ClientPaginationProperties> = ({ onChangeRenderList, onChangePage, isShowMoreClicked }) => {
    const { user } = useUser();
    const { offers } = useOffers();
    const [currentPage, setCurrentPage] = useState(1);
    const [amountRows, setAmountRows] = useState(25);
    const [amountSites, setAmountSites] = useState(0);
    const width = useWindowWidth();
    const visiblePagesDesktop = width >= 991 ? 7 : 3;

    const changeCurrentPage = (page: number) => {
        setCurrentPage(page);
        setTimeout(() => {
            document.body.scrollTo({ top: 0 });
        }, 300);
    };

    const handleChangeRows = (rows?: string | number) => {
        setAmountRows(Number(rows));
        changeCurrentPage(1);

        const amount =
            offers.list.length % Number(rows) === 0
                ? Math.floor(offers.list.length / Number(rows))
                : Math.floor(offers.list.length / Number(rows)) + 1;

        setAmountSites(amount);
    };

    useEffect(() => {
        onChangePage(currentPage, amountSites);
    }, [
        amountSites,
        currentPage,
        onChangePage
    ]);

    useEffect(() => {
        const amount =
            offers.list.length % amountRows === 0
                ? Math.floor(offers.list.length / amountRows)
                : Math.floor(offers.list.length / amountRows) + 1;

        setAmountSites(amount);

        if (!isShowMoreClicked) {
            changeCurrentPage(1);
        }
    }, [
        amountRows,
        offers.list.length,
        offers.sortType
    ]);

    useEffect(() => {
        const list = structuredClone(offers.list);
        const first = (currentPage - 1) * amountRows;

        onChangeRenderList(list.slice(first, first + amountRows));
    }, [
        amountRows,
        currentPage,
        offers.list,
        onChangeRenderList
    ]);

    const getVisiblePages = () => {
        const halfRange = Math.floor(visiblePagesDesktop / 2);
        const totalVisiblePages = Math.min(visiblePagesDesktop, amountSites);

        if (currentPage <= halfRange) {
            return Array.from({ length: totalVisiblePages }, (_, i) => i + 1);
        }

        if (currentPage >= amountSites - halfRange) {
            return Array.from(
                { length: totalVisiblePages },
                (_, i) => amountSites - totalVisiblePages + i + 1
            );
        }

        return Array.from(
            { length: totalVisiblePages },
            (_, i) => currentPage - halfRange + i
        );
    };

    const visiblePages = getVisiblePages();

    return visiblePages.length > 1
        ? <Wrapper>
            {window.innerWidth >= 991
                ? <AutoCompleteWrapper>
                    <AutocompleteInput
                        oneLine
                        goTop
                        value={amountRows}
                        onChange={handleChangeRows}
                        prefix="Wyświetlaj"
                        options={[
                            { value: 25, label: '25 pozycji' },
                            { value: 50, label: '50 pozycji' },
                            { value: 100, label: '100 pozycji' },
                            { value: 200, label: '200 pozycji' },
                            { value: 500, label: '500 pozycji' }
                        ]}
                    />
                </AutoCompleteWrapper>
                : null}
            <ListContainer>
                {currentPage !== 1 &&
                    <>
                        <Button className={'pagination-btn'} darkMode={user.isDarkMode}
                            onClick={() => changeCurrentPage(1)}>
                            <Icon icon={IconEnum.ANGLE_LEFT_TO_LINE}/>
                        </Button>
                        <Button className={'pagination-btn'} darkMode={user.isDarkMode}
                            onClick={() => changeCurrentPage(currentPage - 1)}>
                            <Icon icon={IconEnum.ANGLE_LEFT}/>
                        </Button>
                    </>
                }
                {visiblePages.map((page) => <List key={page}>
                    <ListElement key={page}>
                        <Button
                            darkMode={user.isDarkMode}
                            key={page}
                            className={page === currentPage ? 'active pagination-btn' : 'pagination-btn'}
                            onClick={() => changeCurrentPage(page)}
                        >
                            {page}
                        </Button>
                    </ListElement>
                </List>)}
                {currentPage !== amountSites
                    ? <>
                        <Button className={'pagination-btn'} darkMode={user.isDarkMode}
                            onClick={() => changeCurrentPage(currentPage + 1)}>
                            <Icon icon={IconEnum.ANGLE_RIGHT}/>
                        </Button>
                        <Button className={'pagination-btn'} darkMode={user.isDarkMode}
                            onClick={() => changeCurrentPage(amountSites)}>
                            <Icon icon={IconEnum.ANGLE_RIGHT_TO_LINE}/>
                        </Button>
                    </>
                    : null
                }
            </ListContainer>
        </Wrapper>
        : null;
};

export default ClientPagination;
