export const stringToNumber = (value?: string | number | null) => {
    if (!value || value === '') {
        return undefined;
    }

    const valueString = value.toString().replace(/\s/g, '').replace(',', '.');

    try {
        return Number(valueString);
    } catch {
        return undefined;
    }
};
