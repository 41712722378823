import React, { FC, useEffect, useState } from 'react';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Rectangle,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';

const TooltipWrapper = styled.div`
    & > div {
        position: relative;

        .text-layer {
            position: absolute;
            top: 0;
        }
    }
`;

const LegendList = styled.ul`
    list-style: none;
    display: flex;
    margin-top: 10px;
    margin-bottom: 40px;

    &.tooltip-legend {
        flex-direction: column;
        padding: 0 20px;

        span,
        p {
            --font-size-body: var(--font-size-body-2);

            margin-bottom: 5px;
            line-height: 20px;
        }

        li {
            margin-right: 0;
        }
    }

    li {
        margin-right: 30px;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;

        &:nth-of-type(1):before {
            content: "";
            background-color: ${(props) => props.darkMode ? '#CDCED3' : '#0255AA'};
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 10px;
        }

        &:nth-of-type(2):before {
            content: "";
            background-color: ${(props) => props.darkMode ? '#7E7F85' : '#A2C0DF'};
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 10px;
        }
    }
`;

interface BarWrapperProperties {
    barData: {
        name: string;
        value: number;
        percentage: string;
    }[];
    className?: string;
}

interface CursorProps {
    x?: number;
    y?: number;
    width?: number;
    height?: number;
}

interface TooltipProps {
    active?: boolean;
    payload?: { payload: { bar1: number, bar2: number } }[];
    label?: string;
}

interface TickProps {
    x?: number;
    y?: number;
    payload?: { value: string; index: number; }
}

const BarWrapper: FC<BarWrapperProperties> = ({ barData, className }) => {
    const { user } = useUser();
    const [activeBarIndex, setActiveBarIndex] = useState<number>();
    const [toolTipPosition, setToolTipPosition] = useState({ x: 0, y: 0 });
    useEffect(() => {
        const classString = className ? `.${className}` : '';
        const barCharts = document.querySelectorAll(`${classString} .recharts-bar-rectangle`);
        if (!barCharts || activeBarIndex === undefined) return;
        const barChart = barCharts[activeBarIndex];

        setToolTipPosition({
            x: barChart.getBoundingClientRect().left - barChart.parentNode!.parentNode!.parentElement!.getBoundingClientRect().left - 90,
            y: barChart.getBoundingClientRect().bottom - barChart.parentElement!.getBoundingClientRect().bottom - 95
        });
    }, [activeBarIndex, className]);

    const CustomizedCursor = (props: CursorProps) => {
        const { x, y, width, height } = props;

        return <>
            <defs>
                <linearGradient id="myGradient" gradientTransform="rotate(90)">
                    <stop offset="0%" stopColor={user.isDarkMode ? '#CDCED300' : '#0255AA00'}/>
                    <stop offset="62.5%" stopColor={user.isDarkMode ? '#CDCED31A' : '#0255AA1A'}/>
                </linearGradient>
            </defs>
            <Rectangle radius={[
                0,
                0,
                6,
                6
            ]} fill="url('#myGradient')" x={x} y={y} width={width}
            height={height! + 35}/></>;
    };

    const CustomTooltip = ({ active, payload, label }: TooltipProps) => {
        if (active && payload && payload.length) {
            return (
                <TooltipWrapper>
                    <div>
                        <svg width="290" height="100" viewBox="0 0 290 100" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0 10C0 4.47715 4.47715 0 10 0H264C269.523 0 274 4.47715 274 10V79C274 84.5229 269.523 89 264 89H150.124C147.191 89 144.405 90.2882 142.505 92.5235L138.524 97.2072C137.725 98.1468 136.275 98.1468 135.476 97.2072L131.495 92.5235C129.595 90.2882 126.809 89 123.876 89H10C4.47715 89 0 84.5229 0 79V10Z"
                                fill={user.isDarkMode ? '#0F0F0F' : '#F2F5F9'}/>
                            <path
                                d="M0.5 10C0.5 4.75329 4.75329 0.5 10 0.5H264C269.247 0.5 273.5 4.75329 273.5 10V79C273.5 84.2467 269.247 88.5 264 88.5H150.124C147.044 88.5 144.119 89.8526 142.124 92.1997L138.143 96.8834C137.544 97.5881 136.456 97.5881 135.857 96.8834L131.876 92.1997C129.881 89.8526 126.956 88.5 123.876 88.5H10C4.7533 88.5 0.5 84.2467 0.5 79V10Z"
                                stroke={user.isDarkMode ? '#F2F5F9' : '#030D45'} strokeOpacity="0.05"/>
                        </svg>
                        <div className="text-layer">
                            <LegendList darkMode={user.isDarkMode} className="tooltip-legend">
                                <p>Przedział {label}</p>
                                <li>
                                    <span>{new Intl.NumberFormat().format(payload[0].payload.bar1)} złotych</span> średnia
                                    cena
                                </li>
                                <li>
                                    <span>{new Intl.NumberFormat().format(payload[0].payload.bar2)} złotych</span> mediana
                                    ceny
                                </li>
                            </LegendList>
                        </div>
                    </div>
                </TooltipWrapper>
            );
        }

        return null;
    };

    const CustomizedTick = (props: TickProps) => {
        const { x, y, payload } = props;

        return (
            <text x={x} y={y! + 15} fill={user.isDarkMode ? '#F2F5F9' : '#030D45'} fontSize={13}
                fontWeight={payload!.index === activeBarIndex ? 600 : 400}
                textAnchor="middle">
                {payload!.value}
            </text>
        );
    };

    return (
        <ResponsiveContainer width="100%" height={400}>
            <BarChart className={className} margin={{ left: 20, bottom: 20 }} barGap={10} data={barData}
                onMouseMove={(e) => setActiveBarIndex(e.activeTooltipIndex!)}>
                <CartesianGrid strokeWidth={1} stroke={user.isDarkMode ? '#F2F5F9' : '#030D45'} strokeOpacity={0.2} vertical={false}
                    strokeDasharray="0"/>
                <XAxis dataKey="name" tickLine={false}
                    stroke={user.isDarkMode ? '#F2F5F9' : '#030D45'} strokeOpacity={0.1} tick={<CustomizedTick/>}/>
                <YAxis domain={['auto', 'auto']} tick={{ fill: user.isDarkMode ? '#F2F5F9' : '#030D45', fontWeight: 400, fontSize: 13 }} axisLine={false} tickLine={false}
                    tickFormatter={(x) => new Intl.NumberFormat().format(x)}/>
                <Tooltip content={<CustomTooltip/>}
                    position={{ y: toolTipPosition.y, x: toolTipPosition.x }}
                    allowEscapeViewBox={{ x: true, y: true }} cursor={<CustomizedCursor/>}/>
                <Bar dataKey="bar1" fill={user.isDarkMode ? '#CDCED3' : '#0255AA'} barSize={40} radius={[
                    6,
                    6,
                    0,
                    0
                ]}/>
                <Bar dataKey="bar2" fill={user.isDarkMode ? '#7E7F85' : '#A2C0DF'} barSize={40} radius={[
                    6,
                    6,
                    0,
                    0
                ]}/>
            </BarChart>
        </ResponsiveContainer>
    );
};

export default BarWrapper;
