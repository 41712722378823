import React, { FC, useCallback, useEffect, useState } from 'react';
import { UserActions } from 'store/User/Actions';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';

import Button from 'components/atom/Button';
import Indicator from 'components/atom/Indicator';
import { IconEnum } from 'components/common/Icon';
import InputContainer from 'components/common/InputContainer';
import TextInput from 'components/common/Inputs/TextInput';

const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  height: auto;
  
  align-items: center;
  justify-content: center;
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  padding: 25px;
  border-radius: 10px;
  gap: 30px;
  background: var(--color-white);
  margin: 0 10px;
  width: 100%;
  max-width: 500px;
    
    .icon {
        ${(props) => props.darkMode ? 'filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(105%) contrast(102%);' : ''};
    }
`;

const Logo = styled.img`
    max-width: 500px;
`;

const LoginPage: FC = () => {
    const { user, dispatch } = useUser();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmitForm = async (e: Event) => {
        e.preventDefault();
        await UserActions.logInUser(dispatch, email, password);
    };

    const checkIfUserIsLoggedIn = useCallback(async () => {
        await UserActions.loginByToken(dispatch);
    }, [dispatch]);

    useEffect(() => {
        checkIfUserIsLoggedIn();
    }, [checkIfUserIsLoggedIn]);

    return (
        <Container>
            <LoginForm darkMode={user.isDarkMode} onSubmit={handleSubmitForm} >
                <Logo src="/icons/investoro_pdf_logo.png" />
                <InputContainer title={'Adres email'} icon={IconEnum.PEOPLE}>
                    <TextInput
                        type={'email'}
                        placeholder={'Podaj adres email...'}
                        onChange={({ target: { value } }) => setEmail(value)}
                        value={email}
                        required
                    />
                </InputContainer>
                <InputContainer title={'Hasło'} icon={IconEnum.BLOCKED}>
                    <TextInput
                        type={'password'}
                        placeholder={'Podaj swoje hasło...'}
                        onChange={({ target: { value } }) => setPassword(value)}
                        value={password}
                        required
                    />
                </InputContainer>
                <Button actionIcon={IconEnum.SEND}>Zaloguj się do skanera</Button>
            </LoginForm>
            {user.isLoading && <Indicator/>}
        </Container>
    );
};

export default LoginPage;
