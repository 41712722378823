import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { initialState } from 'utils/state-managment/filter';
import { FiltersStateType } from 'utils/types/FiltersStateType';
import { MultipleFilterType } from 'utils/types/InputTypes';
import { ModulesType } from 'utils/types/ModulesType';

import Icon, { IconEnum } from './Icon';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  gap: 10px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: var(--color-white);
  border-radius: var(--box-border-radius);
  color: var(--color-primary);
`;

const Filter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-alt-second);
  padding: 10px 20px;
  border-radius: 20px;
  gap: 10px;

  span {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  p {
    font-weight: 400;
  }
`;

interface ActiveFiltersProperties {
    state: FiltersStateType,
    moduleType: ModulesType,
    onChange: () => void,
    changeFilterValue: (name: string, value: MultipleFilterType | string[]) => void,
}

const ActiveFilters: FC<ActiveFiltersProperties> = ({ state, moduleType, onChange, changeFilterValue }) => {
    const [isActionPerformed, setIsActionPerformed] = useState(false);
    const initialFilterValues = initialState.filterInitialState(moduleType);

    const {
        city,
        daysAmount,
        date,
        price,
        numberOfRooms,
        area,
        floor,
        builtYear,
        type,
        rentPrice,
        pricePerSquareMeter,
        depositPrice,
        wantedKeywords,
        unwantedKeywords,
        buildingType,
        offerFrom,
        typeOfMarket,
        dateRangeFilter
    } = state;

    useEffect(() => {
        if (isActionPerformed) {
            onChange();
            setIsActionPerformed(false);
        }
    }, [state]);

    const handleDeleteFilter = (filter: string | number | null) => {
        switch (filter) {
        case area.from:
            changeFilterValue('area', { from: initialFilterValues.areaFrom!, to: area.to });
            break;
        case area.to:
            changeFilterValue('area', { from: area.from, to: initialFilterValues.areaTo! });
            break;
        case date.to:
            changeFilterValue('date', { from: date.from, to: initialFilterValues.dateTo });
            break;
        case floor.from:
            changeFilterValue('floor', { from: initialFilterValues.floorFrom!, to: floor.to });
            break;
        case floor.to:
            changeFilterValue('floor', { from: floor.from, to: initialFilterValues.floorTo! });
            break;
        case builtYear.from:
            changeFilterValue('builtYear', { from: initialFilterValues.builtYearFrom!, to: builtYear.to });
            break;
        case builtYear.to:
            changeFilterValue('builtYear', { from: builtYear.from, to: initialFilterValues.builtYearTo! });
            break;
        case price.from:
            changeFilterValue('price', { from: initialFilterValues.priceFrom!, to: price.to });
            break;
        case price.to:
            changeFilterValue('price', { from: price.from, to: initialFilterValues.priceTo! });
            break;
        case numberOfRooms.from:
            changeFilterValue('numberOfRooms', { from: initialFilterValues.numberOfRoomsFrom!, to: numberOfRooms.to });
            break;
        case numberOfRooms.to:
            changeFilterValue('numberOfRooms', { from: numberOfRooms.from, to: initialFilterValues.numberOfRoomsTo! });
            break;
        case rentPrice.from:
            changeFilterValue('rentPrice', { from: initialFilterValues.rentPriceFrom!, to: rentPrice.to });
            break;
        case rentPrice.to:
            changeFilterValue('rentPrice', { from: rentPrice.from, to: initialFilterValues.rentPriceTo! });
            break;
        case pricePerSquareMeter.from:
            changeFilterValue('pricePerSquareMeter', {
                from: initialFilterValues.pricePerSquareMeterFrom!,
                to: pricePerSquareMeter.to
            });
            break;
        case pricePerSquareMeter.to:
            changeFilterValue('pricePerSquareMeter', {
                from: pricePerSquareMeter.from,
                to: initialFilterValues.pricePerSquareMeterTo!
            });
            break;
        case depositPrice.from:
            changeFilterValue('depositPrice', { from: initialFilterValues.depositPriceFrom!, to: depositPrice.to });
            break;
        case depositPrice.to:
            changeFilterValue('depositPrice', { from: depositPrice.from, to: initialFilterValues.depositPriceTo! });
            break;
        }

        setIsActionPerformed(true);
    };

    const handleDeleteFilterFromArray = (filterName: string, i: number) => {
        if ((state[filterName as keyof FiltersStateType] as string[]).length > 0) {
            changeFilterValue(filterName, (state[filterName as keyof FiltersStateType] as string[]).filter((_x: string, index: number) => index !== i));
            setIsActionPerformed(true);
        }
    };

    const generateFilter = (item: string | number | null, label: string, onClickHandler: (filter: string | number | null) => void, sufix = '') => {
        if (item) {
            return (
                <Filter>
                    <span>{label}: <p>{item}{sufix}</p></span>
                    <Icon onClick={() => onClickHandler(item)} icon={IconEnum.CIRCLE_CROSS}/>
                </Filter>
            );
        }

        return null;
    };

    const generateFilterFromArray = (item: string[], label: string, filterName: keyof FiltersStateType) => {
        return item?.length > 0 && item.map(
            (x: string, i: number) => <Filter key={i}>
                <span>{label}: <p>{x}</p></span>
                <Icon onClick={() => handleDeleteFilterFromArray(filterName, i)} icon={IconEnum.CIRCLE_CROSS}/>
            </Filter>

        );
    };

    return (
        <Container>
            {city &&
                <Filter>
                    <span>{city.charAt(0).toUpperCase() + city.slice(1)}</span>
                </Filter>}
            {daysAmount && !dateRangeFilter &&
                <Filter>
                    <span>Ogłoszenia od:<p>{daysAmount} dni</p></span>
                </Filter>}
            {date.from && dateRangeFilter &&
                <Filter>
                    <span>Data od: {date.from}</span>
                </Filter>}
            {generateFilter(date.to, 'Data do', handleDeleteFilter)}
            {generateFilterFromArray(type, 'Typ nieruchomości', 'type')}
            {generateFilter(price.from, 'Cena od', handleDeleteFilter)}
            {generateFilter(price.to, 'Cena do', handleDeleteFilter)}
            {generateFilter(pricePerSquareMeter.from, 'Cena za m² od', handleDeleteFilter)}
            {generateFilter(pricePerSquareMeter.to, 'Cena za m² do', handleDeleteFilter)}
            {generateFilter(area.from, 'Metraż od', handleDeleteFilter, 'm²')}
            {generateFilter(area.to, 'Metraż do', handleDeleteFilter, 'm²')}
            {generateFilter(numberOfRooms.from, 'Liczba pokoi od', handleDeleteFilter)}
            {generateFilter(numberOfRooms.to, 'Liczba pokoi do', handleDeleteFilter)}
            {generateFilter(floor.from === '0' ? 'parter' : floor.from, 'Piętro od', handleDeleteFilter)}
            {generateFilter(floor.to, 'Piętro do', handleDeleteFilter)}
            {generateFilter(builtYear.from, 'Rok budowy od', handleDeleteFilter)}
            {generateFilter(builtYear.to, 'Rok budowy do', handleDeleteFilter)}
            {generateFilter(rentPrice.from, 'Czynsz od', handleDeleteFilter)}
            {generateFilter(rentPrice.to, 'Czynsz do', handleDeleteFilter)}
            {generateFilter(depositPrice.from, 'Kaucja od', handleDeleteFilter)}
            {generateFilter(depositPrice.to, 'Kaucja do', handleDeleteFilter)}
            {generateFilterFromArray(buildingType, 'Typ budynku', 'buildingType')}
            {generateFilterFromArray(offerFrom, 'Oferta od', 'offerFrom')}
            {generateFilterFromArray(typeOfMarket, 'Rynek', 'typeOfMarket')}
            {generateFilterFromArray(wantedKeywords, 'Słowa pożądane', 'wantedKeywords')}
            {generateFilterFromArray(unwantedKeywords, 'Słowa niepożądane', 'unwantedKeywords')}
        </Container>
    );
};

export default ActiveFilters;
