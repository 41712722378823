import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import InfoElement from 'components/common/Card/common/InfoElement/InfoElement';
import { IconEnum } from 'components/common/Icon';

const InformationContainer = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 15px;

    @media (min-width: 991px) {
        width: 70%;
    }
`;

export interface InfoElementType {
    icon: IconEnum;
    isVisible: boolean;
    children: ReactNode;
}

interface InfoElementsProps {
    infoElements: InfoElementType[];
}

const InfoElements: FC<InfoElementsProps> = ({ infoElements }) => {
    return (
        <InformationContainer role="list">
            {infoElements.map((element, index) => <InfoElement key={index} icon={element.icon} isVisible={element.isVisible}>
                {element.children}
            </InfoElement>)}
        </InformationContainer>
    );
};

export default InfoElements;
