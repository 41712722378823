import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import Icon, { IconEnum } from 'components/common/Icon';

const HouseInfo = styled.div`
  div {
    --font-weight: 400;
  }

  display: flex;
  color: var(--color-primary);

  & > span {
    margin-right: 15px;
  }

  div > b {
    font-weight: 500;
    color: var(--color-primary);
  }

  div > b:not(:first-child) {
    margin: 0 5px;
  }
`;

interface InfoElementProperties {
  icon: IconEnum;
  children: ReactNode;
  isVisible?: boolean;
}

const InfoElement: FC<InfoElementProperties> = ({ isVisible, icon, children }) => {
    return isVisible
        ? <HouseInfo>
            <Icon icon={icon} />
            <div>
                {children}
            </div>
        </HouseInfo>
        : null;
};

export default InfoElement;
