import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { SortableContainer, SortableContainerProps, SortableElement } from 'react-sortable-hoc';
import { AxiosResponse } from 'axios';
import styled from 'styled-components';
import { arrayMoveImmutable } from 'utils/array-move';
import { UploadRequestData } from 'utils/types/File';

import Indicator from 'components/atom/Indicator';
import Icon, { IconEnum } from 'components/common/Icon';

const ImageDropzone = styled.div`
    width: 245px;
    height: 270px;
    border-radius: var(--image-border-radius);
    background: var(--color-alt-second);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .upload-icon {
        margin-top: 50%;
        background: var(--color-white);
        border-radius: 50%;
        width: 30px;
        height: 30px;
    }
`;

const ImageDropzoneLabel = styled.p`
    --font-size-body: var(--font-size-body-2);

    text-align: center;
    opacity: 0.7;
    padding: 0 45px;
    margin-top: 10px;
`;

const SortableListWrapper = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(245px, 245px));
    gap: 15px 44px;
`;

const ImageUploaderWrapper = styled.div`
    display: flex;
`;

const SortableItemWrapper = styled.div`
    width: 245px;
    height: 270px;
    position: relative;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: var(--image-border-radius);
    }
`;

const ImageNumber = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    background: var(--color-white);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
        width: 15px;
        height: 15px;
    }
`;

interface SortEndProps {
    oldIndex: number;
    newIndex: number;
}

interface SortableItemValueType extends File {
    id: string;
    preview: string;
}

interface SortableListProps extends SortableContainerProps {
    items: SortableItemValueType[];
    setImages: Dispatch<SetStateAction<SortableItemValueType[]>>;
    onRemove: (e: Event) => void;
    setActiveImage: (value: number) => void;
    activeImage: number;
    onImageChange: (imgName: string) => void;
    imageUploadHandler: (params: UploadRequestData) => Promise<AxiosResponse<string>>;
}

interface SortableElementProps {
    value: SortableItemValueType;
    sortIndex: number;
    index: number;
    activeImage: number;
    setActiveImage: (value: number) => void;
    onRemove: (e: Event) => void;
}

interface ImageUploadProps {
    onChange: (value: string[]) => void;
    value: { preview: string, name: string; }[];
    imageUploadHandler: (params: UploadRequestData) => Promise<AxiosResponse<string>>;
}

const SortableItem: React.ComponentClass<SortableElementProps> = SortableElement(({ value, sortIndex, activeImage, setActiveImage, onRemove }: SortableElementProps) => <SortableItemWrapper onMouseEnter={() => setActiveImage(sortIndex)}
    onMouseLeave={() => setActiveImage(-1)}>
    <ImageNumber onClick={onRemove}>
        {activeImage === sortIndex ? <Icon icon={IconEnum.CROSS}/> : sortIndex + 1}
    </ImageNumber>
    <img src={value.preview} alt="preview"/>
</SortableItemWrapper>);

const SortableList: React.ComponentClass<SortableListProps> = SortableContainer(({ items, setImages, onRemove, setActiveImage, activeImage, onImageChange, imageUploadHandler }: SortableListProps) => {
    const [isLoadingPhoto, setIsLoadingPhoto] = useState(false);
    const onDrop = useCallback(
        <T extends File>(acceptedFiles: T[]) => {
            setIsLoadingPhoto(true);
            const uploadPromises = acceptedFiles.map(async (file) => {
                return await imageUploadHandler({ file }).then((imgName) => {
                    onImageChange(imgName as unknown as string);

                    const newImage = Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        id: imgName as unknown as string
                    });

                    setImages((prevImages) => [
                        ...prevImages,
                        newImage
                    ]);
                });
            });

            Promise.all(uploadPromises)
                .then(() => {
                    setIsLoadingPhoto(false);
                })
                .catch(() => {
                    setIsLoadingPhoto(false);
                });
        },
        [items]
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: { 'image/*': [
            '.jpg',
            '.jpeg',
            '.png',
            '.webp',
            '.tiff',
            '.bmp',
            '.gif'
        ] },
        maxFiles: 8,
        multiple: false
    });

    return (
        <SortableListWrapper>
            {items.map((value, index) => <SortableItem activeImage={activeImage} setActiveImage={setActiveImage} onRemove={onRemove}
                key={`item-${value.id}`} index={index} sortIndex={index} value={value}/>)}
            {items.length < 8 &&
                <ImageDropzone {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isLoadingPhoto
                        ? <Indicator fitParentContainer />
                        : <>
                            <Icon classNames={'upload-icon'} icon={IconEnum.PLUS}/>
                            <ImageDropzoneLabel>Naciśnij lub przeciągnij, aby dodać zdjęcia</ImageDropzoneLabel>
                        </>
                    }
                </ImageDropzone>
            }
        </SortableListWrapper>
    );
});

const ImageUploader: FC<ImageUploadProps> = ({ onChange, value, imageUploadHandler }) => {
    const convertValueToSortableItem = (value: { preview: string, name: string; }[]) => {
        if (value.length <= 0) return value;

        if (value.length > 0 && typeof value[0] === 'object') {
            return value;
        }

        return value.map((imgName, preview) => {
            return {
                id: imgName,
                preview
            };
        });
    };

    const [images, setImages] = useState<SortableItemValueType[]>([]);

    useEffect(() => {
        if (images.length <= 0) {
            setImages(convertValueToSortableItem(value) as SortableItemValueType[]);
        }
    }, [value]);

    const [activeImage, setActiveImage] = useState(-1);

    const removeImage = (e: Event) => {
        e.preventDefault();
        e.stopPropagation();

        onChange(value.filter((_, index) => index !== activeImage).map(({ name }) => name));
        setImages((prevImages) => prevImages.filter((_, index) => index !== activeImage));
    };

    const onSortEnd = ({ oldIndex, newIndex }: SortEndProps) => {
        onChange(arrayMoveImmutable(value.map(({ name }) => name), oldIndex, newIndex));
        setImages(arrayMoveImmutable(images, oldIndex, newIndex));
    };

    const handleImageChange = (imgName: string) => {
        onChange([...value.map(({ name }) => name), imgName]);
    };

    return (
        <ImageUploaderWrapper>
            <SortableList items={images} setImages={setImages} onSortEnd={onSortEnd} onRemove={removeImage} imageUploadHandler={imageUploadHandler}
                setActiveImage={setActiveImage} activeImage={activeImage} distance={1} axis="xy" onImageChange={handleImageChange} />
        </ImageUploaderWrapper>
    );
};

export default ImageUploader;
