export const getStringFromDate = (date: string, setNextDay?: boolean) => {
    const dateDiff = setNextDay ? -1 : 1;
    const fromDate = new Date(date);
    fromDate.setDate(fromDate.getDate() - dateDiff);
    const year = fromDate.getFullYear();
    const month = String(fromDate.getMonth() + 1).padStart(2, '0');
    const day = String(fromDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};
