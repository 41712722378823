import React, { FC, ReactElement, useEffect, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AnalysisPage from 'pages/AnalysisPage';
import MySettingsPage from 'pages/MySettingsPage';
import styled from 'styled-components';
import { AnalysisSaleProvider } from 'utils/context/AnalysisSaleContext';

import Header from './components/Header';
import Navigation from './components/Navigation';
import BailiffPage from './pages/BailiffPage';
import FavoritesPage from './pages/FavoritesPage';
import MyOffersPage from './pages/MyOffersPage';
import OwnerProfilePage from './pages/OwnerProfilePage';
import RentPage from './pages/RentPage';
import SalesPage from './pages/SalesPage';
import SingleBailiffDetailPage from './pages/SingleDetailsPages/SingleBailiffDetailPage';
import SingleRentDetailPage from './pages/SingleDetailsPages/SingleRentDetailPage';
import SingleSaleDetailPage from './pages/SingleDetailsPages/SingleSaleDetailPage';
import UserAddAdvertPage from './pages/UserAddAdvertPage';
import UserAdvertsPage from './pages/UserAdvertsPage';
import UserEditAdvertPage from './pages/UserEditAdvertPage';
import UserMessagesPage from './pages/UserMessagesPage';
import UserOffersMiniPage from './pages/UserOffersMiniPage';
import { useUser } from './store/User/Context';
import { BailiffProvider } from './utils/context/BailiffContext';
import { SaleProvider } from './utils/context/SaleContext';
import { UserAddAdvertProvider } from './utils/context/UserAddAdvertContext';
import { Packages } from './utils/enums/packages';

import 'react-notifications-component/dist/theme.css';

const ModulesPageRenderer = React.lazy(() => import('./pages/ModulesPageRenderer'));
const AddPagesForm = React.lazy(() => import('./components/adminForm/AddPagesForm'));

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: 1100px) {
        @media not all and (pointer: coarse) {
            width: calc(100% - 100px);
            margin-left: 100px;
        }
    }
`;

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1600px;
    margin: auto;
    position: relative;
`;

// Kolejność przepisywania starych modułów na nowe:
// 1. Stworzenie własnego komponentu danej strony np. RentPage
// 2. Przepisanie logiki z UsersModuleRenderer do nowego komponentu
// 3. Stworzenie unikalnego contextu dla danej strony na podstawie UserFiltersContext np. RentContext
// 4. Przepisanie logiki z UserFiltersContext do nowego kontekstu i dostosowanie pod moduł
// 5. Przy miejscach w których jest używane stare api, dodać w pliku api-requests nazwe typu zmienianego np. rent aby odpalane było nowe api

const App: FC = () => {
    const { user, dispatch } = useUser();

    const MainWrapper = useMemo(() => ({ children }: { children: ReactElement }) => {
        useEffect(() => {
            document.body.classList.remove('light', 'dark');
            document.body.classList.add(user.isDarkMode ? 'dark' : 'light');
        }, [dispatch, user.isDarkMode]);

        return (
            <Header>
                <Navigation activePackages={user.activePackages}/>
                <ContentContainer>
                    {children}
                </ContentContainer>
            </Header>
        );
    }, [
        user.activePackages,
        dispatch,
        user.isDarkMode
    ]);

    return (
        <MainContainer>
            <Routes>
                <Route
                    path="/users-offers-mini"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <UserOffersMiniPage/>
                        </React.Suspense>
                    }
                />
                <Route
                    path="/favorites"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <BailiffProvider>
                                    <SaleProvider>
                                        <FavoritesPage/>
                                    </SaleProvider>
                                </BailiffProvider>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/my-settings"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <MySettingsPage/>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/users"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <UserAdvertsPage type={'users'}/>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/add-offer"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <UserAddAdvertProvider>
                                    <UserAddAdvertPage/>
                                </UserAddAdvertProvider>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/my-offers"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <UserAddAdvertProvider>
                                    <MyOffersPage/>
                                </UserAddAdvertProvider>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/messages"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <UserMessagesPage/>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/profile/:id"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <OwnerProfilePage/>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/edit-offer"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <UserAddAdvertProvider>
                                    <UserEditAdvertPage/>
                                </UserAddAdvertProvider>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                {user.activePackages.includes(Packages.ROLE_ADVERTISEMENT)
                                    ? <SaleProvider>
                                        <SalesPage/>
                                    </SaleProvider>
                                    : <Navigate to={{ pathname: '/' }}/>}
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/auctions"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                {user.activePackages.includes(Packages.ROLE_AUCTION)
                                    ? <ModulesPageRenderer type={'auction'}/>
                                    : <Navigate to={{ pathname: '/' }}/>}
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/tender"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                {user.activePackages.includes(Packages.ROLE_AUCTION)
                                    ? <ModulesPageRenderer type={'tender'}/>
                                    : <Navigate to={{ pathname: '/' }}/>}
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/bailiff-notices"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                {user.activePackages.includes(Packages.ROLE_BAILIFFNOTICE)
                                    ? <BailiffProvider>
                                        <BailiffPage/>
                                    </BailiffProvider>
                                    : <Navigate to={{ pathname: '/' }}/>}
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/cooperatives"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                {user.activePackages.includes(Packages.ROLE_COOPERATIVE)
                                    ? <ModulesPageRenderer type={'cooperative'}/>
                                    : <Navigate to={{ pathname: '/' }}/>}
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/analysis"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                {user.activePackages.includes(Packages.ROLE_ANALYSIS)
                                    ? <AnalysisSaleProvider>
                                        <AnalysisPage/>
                                    </AnalysisSaleProvider>
                                    : <Navigate to={{ pathname: '/' }}/>}
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/rent"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                {user.activePackages.includes(Packages.ROLE_RENT)
                                    ? <RentPage/>
                                    : <Navigate to={{ pathname: '/' }}/>}
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/sale/:hash"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <SingleSaleDetailPage/>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/rent/:hash"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <SingleRentDetailPage/>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/bailiff-notice/:hash"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <SingleBailiffDetailPage/>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/admin-form"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                {user.activePackages.includes(Packages.ROLE_ADMIN)
                                    ? <AddPagesForm/>
                                    : <Navigate to={{ pathname: '/' }}/>}
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
            </Routes>
        </MainContainer>
    );
};

export default App;
