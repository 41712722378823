import React, { FC } from 'react';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: ${({ fitParentContainer }) => fitParentContainer ? 'absolute' : 'fixed'};
  top: 0;
  left: 0;
  height: ${({ fitParentContainer }) => fitParentContainer ? '100%' : '100vh'};
  width: ${({ fitParentContainer }) => fitParentContainer ? '100%' : '100vw'};
  background: rgba(${(props) => props.darkMode ? '0, 0, 0' : '255, 255, 255'}, 0.6);
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Root = styled.div`
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;

    .dot {
        position: absolute;
        width: 8px;
        height: 8px;
        background: ${(props) => props.darkMode ? '#242425' : '#E1E3E6'};
        border-radius: 50%;
        animation: rotating-dots 2s linear infinite;
    }

    .dot:nth-child(1) {
        animation-delay: -1.4s;
        top: 3.06%;
        left: 50%;
    }

    .dot:nth-child(2) {
        animation-delay: -1.2s;
        top: 14.64%;
        left: 85.36%;
    }

    .dot:nth-child(3) {
        animation-delay: -1s;
        top: 50%;
        left: 96.94%;
    }

    .dot:nth-child(4) {
        animation-delay: -0.8s;
        top: 85.36%;
        left: 85.36%;
    }

    .dot:nth-child(5) {
        animation-delay: -0.6s;
        top: 96.94%;
        left: 50%;
    }

    .dot:nth-child(6) {
        animation-delay: -0.4s;
        top: 85.36%;
        left: 14.64%;
    }

    .dot:nth-child(7) {
        animation-delay: -0.2s;
        top: 50%;
        left: 3.06%;
    }

    .dot:nth-child(8) {
        animation-delay: 0.0s;
        top: 14.64%;
        left: 14.64%;
    }

    @keyframes rotating-dots {
        0% {
            background: ${(props) => props.darkMode ? '#242425' : '#E1E3E6'};
        }
        25% {
            width: 10px;
            height: 10px;
            background: var(--color-primary);
        }
        50% {
            width: 14px;
            height: 14px;
            background: var(--color-alt);
        }
        75% {
            width: 10px;
            height: 10px;
            background: var(--color-primary);
        }
        100% {
            background: ${(props) => props.darkMode ? '#242425' : '#E1E3E6'};
        }
    }
`;

interface IndicatorPropsType {
    fitParentContainer?: boolean;
}

const Indicator: FC<IndicatorPropsType> = ({ fitParentContainer }) => {
    const { user } = useUser();

    return (
        <Wrapper fitParentContainer={fitParentContainer} darkMode={user.isDarkMode}>
            <Root darkMode={user.isDarkMode}>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
            </Root>
        </Wrapper>
    );
};

export default Indicator;
