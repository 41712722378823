import { FC } from 'react';
import React from 'react';
import { ModulesType } from 'utils/types/ModulesType';
import { OfferData } from 'utils/types/OfferData';

import { BoxContainer } from 'components/sales/SingleAdvertisement';

interface AdditionalTabProps {
    data: OfferData;
    type: ModulesType
}

const AdditionalTab: FC<AdditionalTabProps> = ({ data }) => {
    return (
        <>
            <BoxContainer>
                {/* @ts-expect-error fix after refactoring*/}
                {data.detailedContent.metaValue && data.detailedContent.metaValue.length > 0 &&
                    <div>
                        <h3>Słowa kluczowe</h3>
                        {/* @ts-expect-error fix after refactoring*/}
                        {data.detailedContent.metaValue.map((meta) => {
                            return <span><b>{meta.key}</b> - <p>{meta.value}</p></span>;
                        })}
                    </div>
                }
            </BoxContainer>
        </>
    );
};

export default AdditionalTab;
