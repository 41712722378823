import { V2OfferType } from 'utils/types/UsersAdvert';

export const getUserModuleName = (type: V2OfferType) => {
    switch (type) {
    case V2OfferType.DEAL:
        return 'Odstąpię deal';
    case V2OfferType.INVESTOR:
        return 'Szukam inwestora';
    case V2OfferType.CAPITAL:
    default:
        return 'Mam kapitał';
    }
};
