import React, { FC, useEffect, useState } from 'react';
import { UserActions } from 'store/User/Actions';
import { useUser } from 'store/User/Context';
import { MessagesAdvertType, MessageSenders, UserMessages } from 'store/User/State';
import styled from 'styled-components';
import { userApiRequests } from 'utils/api-requests/user';
import { useProfileStatus } from 'utils/hooks/useProfileStatus';

import Indicator from 'components/atom/Indicator';
import AccordionCard from 'components/common/AccordionCard';
import Folder from 'components/common/Chat/Folder';
import Messages from 'components/common/Chat/Messages';
import FiltersContentWrapper from 'components/common/FiltersContentWrapper';
import { IconEnum } from 'components/common/Icon';
import Module from 'components/common/Module';
import UserNotActiveInfo from 'components/common/UserNotActiveInfo';
import HeaderSection from 'components/HeaderSection';

const Wrapper = styled.div`
    .active .accordion-card-content {
        row-gap: 10px;
    }
`;

const NoItemsInfo = styled.div`
    text-align: center;
    margin-top: 50px;
    margin-left: 15px;
    margin-right: 15px;
    min-height: 300px;
    height: fit-content;
    padding: 30px 0;
    background: var(--color-white);
    border-radius: var(--box-border-radius);
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    h1 {
        --color-primary: ${(props) => props.darkMode ? '#fff' : '#000'};
        line-height: 30px;
        padding: 40px;
    }

    div {
        margin-top: 15px;
        padding: 0 40px;

        p {
            line-height: 30px;
            margin-top: 10px;

            &:first-child {
                margin-bottom: 35px;
            }
        }
    }
`;

const FiltersContainer = styled.div`
    padding-left: 20px;

    @media (max-width: 1100px) {
        padding-right: 20px;
    }
`;

interface UserDetails {
    name: string;
    title: string;
    mainPhoto: string | null;
    price: number | null;
    module: string;
    advertisementId: string;
}

const UserMessagesPage: FC = () => {
    const { isProfileActive } = useProfileStatus();
    const [activeFilter, setActiveFilter] = useState<MessagesAdvertType>(MessagesAdvertType.SALE);
    const { user, dispatch } = useUser();
    const [messages, setMessages] = useState<UserMessages[]>([]);
    const [userDetails, setUserDetails] = useState<UserDetails>();
    const [contentIsVisible, setContentIsVisible] = useState<string | null>(messages.length > 0 ? messages[0].messageId : null);
    const activeMessage = user.userMessageList && user.userMessageList.length > 0 ? user.userMessageList.find((item) => item.advertisementId === contentIsVisible) : null;
    const userActiveMessageImg = activeMessage ? activeMessage.messages[0].photo : undefined;

    useEffect(() => {
        UserActions.loadUserMessagesAsync(dispatch, activeFilter);
    }, [activeFilter]);

    useEffect(() => {
        if (messages.length > 0) {
            const intervalId = setInterval(() => {
                UserActions.loadUserMessagesAsync(dispatch, activeFilter);
            }, 5000);

            return () => clearInterval(intervalId);
        }
    }, [messages]);

    const handleMessageClick = (messageId: string, name: string, title: string, mainPhoto: string | null, price: number | null, module: string, advertisementId: string) => {
        userApiRequests.readMessage(messageId).then(() => {
            UserActions.loadUserMessagesAsync(dispatch, activeFilter);
        });
        userApiRequests.getMessagesDetail(messageId).then((response) => {
            // @ts-expect-error axios response type
            setMessages(response);
            setUserDetails({
                name,
                title,
                mainPhoto,
                price,
                module,
                advertisementId
            });
        });
    };

    const handleSendMessage = (message: string) => {
        const messageSender = activeFilter === MessagesAdvertType.SALE ? MessageSenders.AD_OWNER : MessageSenders.INTERESTED;
        userApiRequests.sendMessage(userDetails!.module, messageSender, message, userDetails!.advertisementId, messages[0].messageId).then(() => {
            userApiRequests.getMessagesDetail(messages[0].messageId).then((response) => {
                // @ts-expect-error axios response type
                setMessages(response);
            });
        });
    };

    const handleChangeModule = (module: MessagesAdvertType) => {
        setActiveFilter(module);
        setContentIsVisible(messages.length > 0 ? messages[0].messageId : null);
        setMessages([]);
    };

    const handleRemoveMessages = () => {
        userApiRequests.removeMessages(messages[messages.length - 1].messageId).then(() => {
            UserActions.loadUserMessagesAsync(dispatch, activeFilter);
            setMessages([]);
            setUserDetails(undefined);
        });
    };

    return (
        <Wrapper id={'offer-list'}>
            <HeaderSection type={'my_offer'}/>
            {isProfileActive
                ? <FiltersContentWrapper className={'active'}>
                    <FiltersContainer>
                        <AccordionCard type={'favorites'} title={'Wybierz moduł'}>
                            <Module isActive={activeFilter === MessagesAdvertType.SALE} icon={IconEnum.BAG_WHITE}
                                title={'Sprzedaje'} onClick={() => handleChangeModule(MessagesAdvertType.SALE)}/>
                            <Module isActive={activeFilter === MessagesAdvertType.BUY} icon={IconEnum.CALENDAR}
                                title={'Kupuję'} onClick={() => handleChangeModule(MessagesAdvertType.BUY)}/>
                        </AccordionCard>
                        {user.userMessageList && user.userMessageList.length > 0
                            ? <AccordionCard type={'messages'} title={'Foldery'}>
                                {user.userMessageList.map((item, index) => <Folder visibleContentId={contentIsVisible} setVisibleContentId={setContentIsVisible}
                                    key={index} item={item} onMessageClick={handleMessageClick}/>)}
                            </AccordionCard>
                            : null}
                    </FiltersContainer>
                    {messages.length > 0 && userDetails
                        ? <Messages onSendMessage={handleSendMessage} price={userDetails.price?.toString() || ''}
                            photo={userDetails.mainPhoto ? `${process.env.REACT_APP_API_URL_2}image/find?module=${userDetails.module}&fileName=${userDetails.mainPhoto}&photoSize=CHAT_PHOTO` : '/defaultImg.png'}
                            username={userDetails.name} title={userDetails.title}
                            userPhoto={userActiveMessageImg ? `${process.env.REACT_APP_API_URL_2}image/find?module=PROFILE&fileName=${userActiveMessageImg}&photoSize=CHAT_PHOTO` : undefined}
                            onRemove={handleRemoveMessages}
                            messageOwnerType={activeFilter === MessagesAdvertType.SALE ? MessageSenders.AD_OWNER : MessageSenders.INTERESTED}
                            messages={messages}/>
                        : null}
                    {user.userMessageList && user.userMessageList.length <= 0
                        ? <NoItemsInfo darkMode={user.isDarkMode}>
                            <h1>Nie wybrano wiadomości</h1>
                            <div>
                                <p>Wybierz z lewej strony ogłoszenie z folderu, a następnie kliknij wybraną wiadomość.</p>
                                <p>Jeśli nie widzisz folderu, to oznacza, że jeszcze z nikim nie wymieniłeś/aś
                                    wiadomości.</p>
                            </div>
                        </NoItemsInfo>
                        : null}
                </FiltersContentWrapper>
                : <UserNotActiveInfo isDarkMode={user.isDarkMode} />}
            {user.isLoading && <Indicator/>}
        </Wrapper>
    );
};

export default UserMessagesPage;
