import { createContext } from 'react';
import {
    FiltersStateType
} from 'utils/types/FiltersStateType';
import { MultipleFilterType } from 'utils/types/InputTypes';

export type ValueChangeType = string | number | boolean | MultipleFilterType | string[];

interface WorkUtilsType {
    handleDeleteFilter: (filterId: string) => Promise<void>,
    handleSetFiltersValues: (filterId: string) => Promise<void>,
    handleChangeDateList: (fieldName: string, value: number) => void,
    handleChange: (fieldName: string, value: ValueChangeType) => void,
    handleActiveFilterChange: (fieldName: string, value: string | number | boolean | MultipleFilterType) => void,
    clearFilters: () => void,
    submitRecommendedPage?: (message: string) => void,
    filtersState: FiltersStateType,
    activeFiltersState: FiltersStateType,
    getSavedFilters: () => Promise<void>,
}

const workUtilsValues: WorkUtilsType = {
    handleDeleteFilter: () => Promise.resolve(),
    handleSetFiltersValues: () => Promise.resolve(),
    handleChangeDateList: () => {},
    handleChange: () => {},
    handleActiveFilterChange: () => {},
    clearFilters: () => {},
    submitRecommendedPage: () => {},
    filtersState: {} as FiltersStateType,
    activeFiltersState: {} as FiltersStateType,
    getSavedFilters: () => Promise.resolve()
};

const FilterUtilsContext = createContext(workUtilsValues);

export default FilterUtilsContext;
