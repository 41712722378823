import React from 'react';
import clsx from 'clsx';
import { useOffers } from 'store/Offers/Context';
import styled from 'styled-components';
import { RentProvider } from 'utils/context/RentContext';

import Indicator from 'components/atom/Indicator';
import FiltersContentWrapper from 'components/common/FiltersContentWrapper';
import HeaderSection from 'components/HeaderSection';
import FiltersRent from 'components/rent/FiltersRent';
import RentOffers from 'components/rent/RentOffers';

const Container = styled.div`
    padding: 15px 20px 0;
`;

const RentPage = () => {
    const { offers } = useOffers();

    return (
        <div>
            <RentProvider>
                <HeaderSection type={'rent'} />
                <FiltersContentWrapper className={clsx('offers-list', { active: offers.rentList?.content.length > 0 || offers.rentList?.empty && offers.rentList?.afterSearch })}>
                    <Container id="filters">
                        <FiltersRent />
                    </Container>
                    <RentOffers />
                    {offers.isLoading && <Indicator/>}
                </FiltersContentWrapper>
            </RentProvider>
        </div>
    );
};

export default RentPage;
