import React, { FC, useRef } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { PropertyType } from 'utils/enums/propertyType';
import { getNormalizedPropertyType } from 'utils/formatters/getNormalizedPropertyType';
import { usersPropertyTypeOptions } from 'utils/options';
import { ModulesType } from 'utils/types/ModulesType';
import { OfferData } from 'utils/types/OfferData';
import { useWindowWidth } from 'utils/useWindowWidth';

import AccordionCard from 'components/common/AccordionCard';
import { Title } from 'components/common/Card/common/CardBodyDefault';
import Icon, { IconEnum } from 'components/common/Icon';
import { BoxContainer } from 'components/sales/SingleAdvertisement';

interface DetailsTabsProps {
    type: ModulesType;
    data: OfferData;
    description?: string[];
}

const AddressWrap = styled.p`
  padding: 10px 15px;
  color: var(--color-primary);
`;

export const Grid = styled.div`
    //display: flex;
    //flex-direction: row;
    //flex-wrap: wrap;
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-height: 530px;
    padding: 10px 15px;
    gap: 20px;

    @media (max-width: 1100px) {
        overflow: auto;
        scrollbar-width: thin;
        scrollbar-color: var(--color-alt-second-10) var(--color-background);
        scroll-behavior: smooth;
        white-space: nowrap;
        max-width: 100%;
        padding-bottom: 30px;

        ::-webkit-scrollbar-thumb {
            background-color: var(--color-primary);
            border: none;
        }

        ::-webkit-scrollbar {
            height: 4px;
        }
    }

`;

export const DetailedInfoContainer = styled.div`
    display: flex;
    align-items: center;

    span {
        margin-right: 10px;
    }
`;

export const Value = styled.p`
    font-weight: 500;
    color: var(--color-primary);
    margin-bottom: 0;
`;

export const DetailedInfo = styled.div`
    --font-weight: 400;
    color: var(--color-primary);
`;

export const DetailsDescription = styled.p<{ $media?: boolean; }>`
    --font-weight: 400;

    display: flex;
    gap: 5px;
    padding: ${(props) => props.media ? '0 15px' : '10px 15px'};
    color: var(--color-primary);

    img {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(105%) contrast(102%);
    }
`;

const SwipeRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 100%;
    background: linear-gradient(270deg, var(--color-white) 65.18%, rgba(255, 255, 255, 0) 100%);
    border-radius: 10px;
    z-index: 1;

    span {
        height: 100%;
        width: 100%;
    }
`;

const SwipeLeft = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 100%;
    background: linear-gradient(90deg, var(--color-white) 65.18%, rgba(255, 255, 255, 0) 100%);
    border-radius: 10px;
    z-index: 1;

    span {
        height: 100%;
        width: 100%;
    }
`;

const PageBreaker = styled.div`
    margin-bottom: 80px;
`;

const DetailsTab: FC<DetailsTabsProps> = ({ type, data, description }) => {
    const gridRef = useRef<HTMLDivElement>(null);

    { /* @ts-expect-error TODO: need to change type of props included in component*/ }
    const date = data.tooltipDate || data.lastUpdated ? dayjs(data.tooltipDate || data.lastUpdated).format('DD.MM.YYYY, HH:mm') : dayjs(data.dateAdded).format('DD.MM.YYYY, HH:mm');
    const width = useWindowWidth();

    { /* @ts-expect-error TODO: need to change type of props included in component*/ }
    const price = data.price || data.originalPrice || data.priceBeforeModification;
    // @ts-expect-error TODO: will be changed after refactoring
    const estateType = usersPropertyTypeOptions.find(({ value }) => value === (data.adType || getNormalizedPropertyType(data.module)))?.label;

    const swipeToRight = () => {
        const scrollPosition = gridRef.current!.scrollLeft;
        const scrollWidth = gridRef.current!.scrollWidth;
        const clientWidth = gridRef.current!.clientWidth;

        if (scrollPosition + clientWidth < scrollWidth && window.innerWidth < 991) {
            gridRef.current!.scrollLeft += 100;
        }
    };

    const swipeToLeft = () => {
        const scrollPosition = gridRef.current!.scrollLeft;
        const clientWidth = gridRef.current!.clientWidth;

        if (scrollPosition + clientWidth > 0 && window.innerWidth < 991) {
            gridRef.current!.scrollLeft -= 100;
        }
    };

    const translateCategory = (category: string) => {
        switch (category) {
        case 'media':
            return 'Media';
        case 'security':
            return 'Zabezpieczenie';
        case 'equipment':
            return 'Wyposażenie';
        case 'fence':
            return 'Ogrodzenie budynku';
        case 'attic':
            return 'Poddasze';
        case 'roof':
            return 'Dach';
        case 'roofMaterial':
            return 'Pokrycie dachu';
        case 'access':
            return 'Droga dojazdowa';
        case 'neighborhood':
            return 'Otoczenie';
        case 'warehouseRooms':
            return 'Pomieszczenia gospodarcze';
        case 'buildingPurpose':
            return 'Przeznaczenie budynku';
        case 'additionalInformation':
            return 'Informacje dodatkowe';
        }
    };

    const mediaList =
        [
            { media: data.media },
            { security: data.security },
            { equipment: data.equipment },
            { fence: data.fence },
            { attic: data.attic },
            { roof: data.roof },
            { roofMaterial: data.roofMaterial },
            { access: data.access },
            { neighborhood: data.neighborhood },
            { warehouseRooms: data.warehouseRooms },
            { buildingPurpose: data.buildingPurpose },
            { additionalInformation: data.additionalInformation }
        ];

    const isMediaListEmpty = mediaList.every((obj) => {
        const value = Object.values(obj)[0];

        return value === undefined || Array.isArray(value) && value.length === 0;
    });

    return (
        <>
            <BoxContainer>
                <Title>{data.title ?? data.kwNumber ?? '-'}</Title>
                <AddressWrap>{data.location ?? data.city ?? '-'}</AddressWrap>
            </BoxContainer>

            {(type === 'advertisement' || type === 'rent' || type === 'RENT_FLAT' || type === 'RENT_PLOT' || type === 'RENT_HOUSE' || type === 'RENT_OTHER' || type === 'users' || type === 'my_offer' || type === 'profile') &&
                <BoxContainer>
                    {width < 991 && <SwipeLeft onClick={swipeToLeft}>
                        <Icon icon={IconEnum.ANGLE_LEFT}/>
                    </SwipeLeft>}
                    <Grid ref={gridRef}>
                        {data.tooltipDate
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.SQUARE_TIMER}/>
                                <DetailedInfo>
                                    Ostatnia modyfikacja:
                                    <Value>{dayjs(data.dateAdded).format('DD.MM.YYYY, HH:mm')}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {/* @ts-expect-error TODO: will be fixed after refactor*/}
                        {data.lastUpdated
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.SQUARE_TIMER}/>
                                <DetailedInfo>
                                    Ostatnia modyfikacja:
                                    {/* @ts-expect-error TODO: will be fixed after refactor*/}
                                    <Value>{dayjs(data.lastUpdated).format('DD.MM.YYYY, HH:mm')}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {date && type !== 'rent'
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.CALENDAR}/>
                                <DetailedInfo>
                                    Data dodania:
                                    <Value>{date}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {type === 'rent'
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.CALENDAR}/>
                                <DetailedInfo>
                                    Data dodania:
                                    <Value>{dayjs(data.dateAdded).format('DD.MM.YYYY, HH:mm')}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {type !== 'rent' && type !== 'users' && type !== 'my_offer' && type !== 'profile' && data.originalPrice
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.PRICE}/>
                                <DetailedInfo>
                                    Pierwotna cena:
                                    <Value>{`${data.originalPrice} złotych`}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {price
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.PRICE}/>
                                <DetailedInfo>
                                    Aktualna cena:
                                    <Value>{`${price} złotych`}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {(type === 'users' || type === 'my_offer' || type === 'profile') && data.rentPrice
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.PRICE}/>
                                <DetailedInfo>
                                    Czynsz
                                    <Value>{`${data.rentPrice} zł/msc`}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {/* @ts-expect-error TODO: need to change type of props included in component*/}
                        {data.depositPrice && type === 'rent' || (type === 'users' || type === 'my_offer' || type === 'profile' && (data.adTypeEnum || getNormalizedPropertyType(data.module)) === OfferType.RENT)
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.PRICE_PART}/>
                                <DetailedInfo>
                                    Kaucja:
                                    <Value>{`${data.depositPrice} zł`}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {data.pricePerSquareMeter
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.PRICE_PART}/>
                                <DetailedInfo>
                                    Aktualna cena za m<sup>2</sup>:
                                    <Value
                                        dangerouslySetInnerHTML={{ __html: `${data.pricePerSquareMeter} zł/m<sup>2</sup>` }}/>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {data.area
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.SQUARE}/>
                                <DetailedInfo>
                                    Metraż:
                                    <Value
                                        dangerouslySetInnerHTML={{ __html: `${data.area} m<sup>2</sup>` }}/>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {type !== 'users' && type !== 'my_offer' && type !== 'profile' && data.numberOfRooms
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.BED}/>
                                <DetailedInfo>
                                    Liczba pokoi:
                                    <Value>{`${data.numberOfRooms} pokoje`}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {type === 'users' || type === 'my_offer' || type === 'profile' && data.adType === PropertyType.FLAT || data.adType === PropertyType.HOUSE && data.numberOfRooms
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.BED}/>
                                <DetailedInfo>
                                    Liczba pokoi:
                                    <Value>{`${data.numberOfRooms} pokoje`}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {type !== 'users' && type !== 'my_offer' && type !== 'profile' && data.floor
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.FLOOR_LEVEL}/>
                                <DetailedInfo>
                                    Piętro:
                                    <Value>{`${data.floor} piętro`}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {type === 'users' || type === 'my_offer' || type === 'profile' && data.adType === PropertyType.FLAT || data.adType === PropertyType.OTHER && data.floor
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.FLOOR_LEVEL}/>
                                <DetailedInfo>
                                    Piętro:
                                    <Value>{`${data.floor} piętro`}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {/* @ts-expect-error TODO: need to change type of props included in component*/}
                        {type === 'users' || type === 'my_offer' || type === 'profile' || type === 'rent' && (data.adTypeEnum || getNormalizedPropertyType(data.module)) === PropertyType.HOUSE && data.floors
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.FLOOR_LEVEL}/>
                                <DetailedInfo>
                                    Liczba pięter:
                                    {/* @ts-expect-error TODO: need to change type of props included in component*/}
                                    <Value>{`${data.floors} piętro`}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {/* {type !== 'rent' ? <DetailedInfoContainer>*/}
                        {/*    <Icon icon={"floor_level"}/>*/}
                        {/*    <DetailedInfo>*/}
                        {/*        Piętra w budynku:*/}
                        {/*        <Value> - pięter</Value>*/}
                        {/*    </DetailedInfo>*/}
                        {/* </DetailedInfoContainer> : null}*/}
                        {type !== 'users' && type !== 'my_offer' && type !== 'profile' && type !== 'rent' && data.type
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.LEAVE}/>
                                <DetailedInfo>
                                    Typ nieruchomości:
                                    <Value>{data.type}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {type === 'users' || type === 'my_offer' || type === 'profile' || type === 'rent' && estateType
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.LEAVE}/>
                                <DetailedInfo>
                                    Typ nieruchomości:
                                    <Value>{estateType}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {type === 'users' || type === 'my_offer' || type === 'profile'
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.LEAVE}/>
                                <DetailedInfo>
                                    Właściciel nieruchomości:
                                    <Value>{data.propertyType ?? 'Brak'}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {/* @ts-expect-error TODO: need to change type of props included in component*/}
                        {type === 'users' || type === 'my_offer' || type === 'profile' && data.otherAdType
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.LEAVE}/>
                                <DetailedInfo>
                                    Rodzaj budynku ogłoszenia:
                                    {/* @ts-expect-error TODO: need to change type of props included in component*/}
                                    <Value>{data.otherAdType ?? 'Brak'}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {type === 'users' || type === 'my_offer' || type === 'profile' && data.adType !== PropertyType.PLOT
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.LEAVE}/>
                                <DetailedInfo>
                                    Forma własności:
                                    <Value>{data.propertyForm ?? 'Brak'}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {type === 'users' || type === 'my_offer' || type === 'profile' && data.adType === PropertyType.HOUSE
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.LEAVE}/>
                                <DetailedInfo>
                                    Dom rekreacyjny:
                                    <Value>{data.recreational ?? 'Brak'}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {type === 'users' || type === 'my_offer' || type === 'profile' && data.adType === PropertyType.PLOT
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.LEAVE}/>
                                <DetailedInfo>
                                    Typ działki:
                                    <Value>{data.plotType ?? 'Brak'}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {type === 'users' || type === 'my_offer' || type === 'profile' && data.adType === PropertyType.OTHER
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.LEAVE}/>
                                <DetailedInfo>
                                    Konstrukcja budynku:
                                    <Value>{data.construction ?? 'Brak'}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {type === 'users' || type === 'my_offer' || type === 'profile' && data.adType === PropertyType.OTHER
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.LEAVE}/>
                                <DetailedInfo>
                                    Parking:
                                    <Value>{data.parking ?? 'Brak'}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {type === 'users' || type === 'my_offer' || type === 'profile' && data.adType === PropertyType.OTHER
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.LEAVE}/>
                                <DetailedInfo>
                                    Posadzka:
                                    <Value>{data.flooring ?? 'Brak'}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {type === 'users' || type === 'my_offer' || type === 'profile' && data.adType === PropertyType.HOUSE || data.adType === PropertyType.FLAT
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.LEAVE}/>
                                <DetailedInfo>
                                    Materiał wykonania budynku:
                                    <Value>{data.buildingMaterial ?? 'Brak'}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {type === 'users' || type === 'my_offer' || type === 'profile' && data.adType === PropertyType.HOUSE || data.adType === PropertyType.FLAT
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.LEAVE}/>
                                <DetailedInfo>
                                    Rodzaj okien budynku:
                                    <Value>{data.window ?? 'Brak'}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {type === 'users' || type === 'my_offer' || type === 'profile' && data.adType !== PropertyType.PLOT
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.LEAVE}/>
                                <DetailedInfo>
                                    Stan wykończenia budynku:
                                    <Value>{data.finishingCondition ?? 'Brak'}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {type === 'users' || type === 'my_offer' || type === 'profile' && data.adType === PropertyType.HOUSE || data.adType === PropertyType.FLAT
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.LEAVE}/>
                                <DetailedInfo>
                                    Ogrzewanie:
                                    <Value>{data.heating ?? 'Brak'}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {data.adType !== PropertyType.OTHER && data.adType !== PropertyType.PLOT && data.buildingType
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.TWO_TYPE}/>
                                <DetailedInfo>
                                    Rodzaj budynku:
                                    <Value>{data.buildingType}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {data.adType !== PropertyType.PLOT && data.builtYear
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.CALENDAR}/>
                                <DetailedInfo>
                                    Rok budowy:
                                    <Value>{data.builtYear}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {data.adType !== PropertyType.PLOT
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.WORK}/>
                                <DetailedInfo>
                                    Rodzaj rynku:
                                    <Value>{data.typeOfMarket ?? 'Brak'}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {type !== 'users' && type !== 'my_offer' && type !== 'profile' && data.offerFrom
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.PEOPLE}/>
                                <DetailedInfo>
                                    Oferta od:
                                    <Value>{data.offerFrom}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                        {type !== 'my_offer' && type !== 'users' && type !== 'profile' && (data.portal.name || data.portal)
                            ? <DetailedInfoContainer>
                                <Icon icon={IconEnum.LINK}/>
                                <DetailedInfo>
                                    Portal:
                                    <Value>{(data.portal.name || data.portal)}</Value>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                            : null}
                    </Grid>
                    {width < 991 && <SwipeRight onClick={swipeToRight}>
                        <Icon icon={IconEnum.ANGLE_RIGHT}/>
                    </SwipeRight>}
                </BoxContainer>
            }

            {type === 'bailiff_notice' &&
                <BoxContainer>
                    <Grid>
                        <DetailedInfoContainer>
                            <Icon icon={IconEnum.CALENDAR}/>
                            <DetailedInfo>
                                Data dodania:
                                <Value>{data.dateAndSignature ?? 'Brak'}</Value>
                            </DetailedInfo>
                        </DetailedInfoContainer>
                    </Grid>
                </BoxContainer>
            }
            {(type === 'users' || type === 'my_offer' || type === 'profile') && !isMediaListEmpty
                ? <BoxContainer className={clsx({ 'users-media': type === 'users' || type === 'my_offer' })}>
                    {
                        mediaList.map((categories) => {
                            return Object.entries(categories!).map(([key, value]) => {
                                return value &&
                                    <AccordionCard usersMedia lineAbove title={translateCategory(key)} type={type}>
                                        {value.map((item: string, index: number) => {
                                            return <DetailsDescription media key={index}><Icon
                                                icon={IconEnum.APPROVE}/>{item.slice(0, 1).toUpperCase() + item.slice(1)}
                                            </DetailsDescription>;
                                        })}
                                    </AccordionCard>;
                            });
                        })
                    }
                </BoxContainer>
                : null}
            <BoxContainer>
                <Title>Szczegóły ogłoszenia:</Title>
                {description && description.length > 0
                    ? description.map((paragraph, index) => {
                        return <><DetailsDescription key={index}>{paragraph}</DetailsDescription><PageBreaker/></>;
                    })
                    : <DetailsDescription>{data.description}</DetailsDescription>}
            </BoxContainer>
        </>
    );
};

export default DetailsTab;
