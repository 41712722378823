import React, { FC, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';

import Icon, { IconEnum } from 'components/common/Icon';
import { BoxContainer } from 'components/sales/SingleAdvertisement';

const Title = styled.h2`
    width: 100%;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 16px;
    display: flex;
    gap: 10px;
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    gap: 10px;

    div {
        background: var(--color-alt-second);
    }
`;

const Input = styled.textarea`
    background: transparent;
    width: 100%;
    outline: none;
    border: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: var(--color-primary);
    resize: none;
    height: auto;
`;

const InputIcon = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(color-white);
    
    &.icon-in-editor {
        background-color: ${(props) => props.darkMode ? 'var(--color-white)' : 'inherit'};
    }

    &:hover {
        background: var(--color-alt);
        cursor: pointer;

        span {
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(105%) contrast(102%);
        }
    }
`;

const InputContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background: var(--color-alt-second);
    border-radius: var(--box-border-radius);
`;

const NoteContent = styled.p`
    --font-weight: 400;
`;

export interface BaseNote {
    noteId: number;
    ownerId?: string,
    content: string;
    date: string;
}

export interface ExtendedNote extends BaseNote {
    originalNote: boolean;
    noteOwner: boolean;
}

export type NoteType = BaseNote | ExtendedNote;

export function isExtendedNote (note: NoteType): note is ExtendedNote {
    return 'originalNote' in note;
}

export interface V2NoteTabProps {
    notes?: NoteType[];
    handleDelete: (noteId: string) => void;
    handleSaveNote: (value: string, id?: string) => Promise<void>;
}

const V2NoteTab: FC<V2NoteTabProps> = ({ notes, handleDelete, handleSaveNote }) => {
    const { user } = useUser();
    const [value, setValue] = useState('');
    const [editedValue, setEditedValue] = useState('');
    const [isEditMode, setEditMode] = useState(false);
    const [editModeId, setEditModeId] = useState<string | number>(0);

    const inputRef = useRef<HTMLTextAreaElement | null>(null);
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.style.height = 'auto';
            inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
        }
    }, [value, editedValue]);

    const onSave = async () => {
        if (isEditMode) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            handleSaveNote(editedValue, editModeId);
            setEditMode(false);
        } else {
            handleSaveNote(value);
        }
        setValue('');
    };

    const handleEdit = (noteId: number) => {
        // jeśli dana notatka nie istnieje, to nie pozwalamy na edycję, jeśli istnieje, to ustawiamy wartość
        notes?.find((item) => item.noteId === noteId && setEditedValue(item.content));
        setEditModeId(noteId);
        setEditMode(true);
    };

    const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const textarea = event.target;
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;

        if (isEditMode) {
            setEditedValue(textarea.value);
        } else {
            setValue(textarea.value);
        }
    };

    return (
        <>
            {
                notes?.map((item) => <BoxContainer key={item.noteId} data-notclickable={true}>
                    <Title>
                        {dayjs(item.date).format('DD.MM.YYYY, HH:mm')} • Notatka
                        <Actions>
                            {isExtendedNote(item) && item.originalNote
                                ? <InputIcon onClick={() => handleEdit(item.noteId)}>
                                    <Icon icon={IconEnum.EDIT}/>
                                </InputIcon>
                                : null}
                            <InputIcon onClick={() => handleDelete(item.noteId.toString()!)}>
                                <Icon icon={IconEnum.DELETE}/>
                            </InputIcon>
                        </Actions>
                    </Title>
                    {isEditMode && editModeId === item.noteId
                        ? <InputContainer>
                            <Input
                                rows={1}
                                placeholder={'Wpisz treść notatki....'}
                                value={editedValue}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setEditedValue(e.target.value)}
                            />
                            <InputIcon onClick={onSave}>
                                <Icon icon={IconEnum.SEND}/>
                            </InputIcon>
                        </InputContainer>
                        : <NoteContent>
                            {item.content}
                        </NoteContent>}
                </BoxContainer>)
            }
            <BoxContainer data-notclickable={true}>
                <Title>Dodaj nową notatkę</Title>
                <InputContainer>
                    <Input
                        rows={1}
                        placeholder={'Wpisz treść notatki....'}
                        value={value}
                        onChange={handleInput}
                    />
                    <InputIcon darkMode={user.isDarkMode} className={'icon-in-editor'} onClick={onSave}>
                        <Icon icon={IconEnum.SEND}/>
                    </InputIcon>
                </InputContainer>
            </BoxContainer>
        </>
    );
};

export default V2NoteTab;
