import React, { ReactNode } from 'react';
import { store } from 'react-notifications-component';

import Icon, { IconEnum } from 'components/common/Icon';

export const activeNotification = (title: string, message: ReactNode, type: 'danger' | 'success' | 'warning' | 'info' | 'default' | undefined, duration = 3000) => {
    let notificationMessage = message;

    switch (type) {
    case 'danger':
        notificationMessage = <div className="notification-message"><Icon icon={IconEnum.INFO_WHITE}/>{message}</div>;

        break;
    case 'success':
        notificationMessage = <div className="notification-message"><Icon icon={IconEnum.DANGER}/>{message}</div>;

        break;
    case 'warning':
        notificationMessage = <div className="notification-message"><Icon icon={IconEnum.APPROVE}/>{message}</div>;

        break;
    default:
        break;
    }

    store.addNotification({
        title,
        message: notificationMessage,
        type,
        width: 400,
        insert: 'top',
        container: 'bottom-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
            duration,
            onScreen: true,
            pauseOnHover: true
        }
    });
};
