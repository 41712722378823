import { LocationOptionsType, SavedLocationsType, SearchLocationResultType } from './Locations';

export enum InputType {
    DATE = 'date',
    FLEXIBLE_DATE = 'flexibleDate',
    RADIO = 'radio',
    AUTOCOMPLETE = 'autocomplete',
    AUTOCOMPLETE_PROPERTY = 'autocompleteProperty',
    MULTIPLE_SELECT = 'multipleSelect',
    LOCATION = 'location',
    FILTER_AUTOCOMPLETE = 'filterAutocomplete',
    RANGE_PRICE_SELECT = 'rangePriceSelect',
    RANGE_METERS_SELECT = 'rangeMetersSelect',
    RANGE_NUMBER_SELECT = 'rangeNumberSelect',
    KEYWORDS_TEXT = 'keywordsText',
    NOTIFICATIONS = 'notifications',
    TEXT = 'text',
    SAVE_BUTTON = 'saveButton',
    BUTTON = 'button',
    NUMERIC = 'numeric',
    IMAGE_UPLOAD = 'imageUpload',
    FILE_UPLOAD = 'fileUpload',
    COST_ESTIMATION_SUMMARY = 'costEstimationSummary',
}

export interface MultipleFilterType {
    from: string | number | null;
    to: string | number | null;
}

export interface RangeFilterType {
    from?: string;
    to?: string;
}

type ValueChangeType = string | number | boolean | string[];

export type LocationChangeType = LocationOptionsType[] | SearchLocationResultType[] | SavedLocationsType;

export type ObjectAndSingleValueChangeType = ValueChangeType | MultipleFilterType | LocationChangeType;
