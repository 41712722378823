import React, { FC, forwardRef, useEffect, useState } from 'react';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';
import { AnalysisAreaRow, AnalysisTimeRow, TimeRange } from 'utils/types/AnalysisModels';

import { AreaRangeAverageMeterAndTotalPrice } from './components/AreaRangeAverageMeterAndTotalPrice';
import { AreaRangePie } from './components/AreaRangePie';
import { AreaRangeSquareMeterBars } from './components/AreaRangeSquareMeterBars';
import { AreaRangeTable } from './components/AreaRangeTable';
import { AreaRangeTotalPriceBars } from './components/AreaRangeTotalPriceBars';
import { TimeRangeAverageMeterAndArea } from './components/TimeRangeAverageMeterAndArea';
import { TimeRangeAverageMeterAndTotalPrice } from './components/TimeRangeAverageMeterAndTotalPrice';
import { TimeRangeAverageTotalPriceAndArea } from './components/TimeRangeAverageTotalPriceAndArea';
import { TimeRangePie } from './components/TimeRangePie';
import { TimeRangeSquareMeterBars } from './components/TimeRangeSquareMeterBars';
import { TimeRangeTable } from './components/TimeRangeTable';
import { TimeRangeTotalPriceBars } from './components/TimeRangeTotalPriceBars';
import { TrendRangeAverageTotalPriceAndArea } from './components/TrendRangeAverageTotalPriceAndArea';

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const ContainerStyle = styled.div`
  padding: 60px 20px 0px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1100px) {
    padding: 20px 20px 0px;
  }
`;

export const HeaderStyle = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  width: 100%;
  margin: 50px 0;
`;

export const Table = styled.table`
  table-layout: fixed;
  border-collapse: collapse;

  sup {
    font-size: 8px;
  }

  th {
    padding: 15px 20px;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
  }

  .growth-red,
  .growth-green {
    --font-weight: 400;
    --font-size-body: var(--font-size-body-2);

    margin-left: 10px;
  }

  .growth-green {
    color: #0BAC25;

    &:before {
      content: "+";
    }
  }

  .growth-red {
    color: #BA000B;

    &:before {
      content: "-";
    }
  }

  tbody {
    --row-radius: 6px;

    tr {
      td:first-child {
          white-space: nowrap;
      }
      
      &:nth-child(2n - 1) {
        td {
          background-color: var(--color-alt-second);
        }

        td:first-child {
          border-top-left-radius: var(--row-radius);
          border-bottom-left-radius: var(--row-radius);
          text-align: left;
        }

        td:last-child {
          border-top-right-radius: var(--row-radius);
          border-bottom-right-radius: var(--row-radius);
        }
      }

      &:last-child {
        td {
          font-weight: 600;
        }
      }

      td {
        padding: 15px 0 15px 20px;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;

        &.border-td-none {
          text-align: right;
        }

        &:first-child {
          font-weight: 600;
        }

        &.procent {
          padding: 15px 20px 15px 0;
          text-align: left;
        }
      }
    }
  }
`;

export const Title = styled.h2`
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 12px;
`;

export const SectionBox = styled.div`
  background-color: var(--color-white);
  border-radius: var(--box-border-radius);
  padding: 30px;
  margin-bottom: 15px;
`;

export const RadioContainer = styled.div`
  display: flex;

  input {
    margin-right: 10px;
  }
`;

export const LegendList = styled.ul`
  list-style: none;
  display: flex;
  margin-top: 10px;
  margin-bottom: 40px;

  &.tooltip-legend {
    flex-direction: column;
    padding: 0 20px;

    span,
    p {
      --font-size-body: var(--font-size-body-2);
      margin-bottom: 5px;
      line-height: 20px;
    }

    li {
      margin-right: 0;
    }
  }

  li {
    margin-right: 30px;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;

    &:nth-of-type(1):before {
      content: "";
      ${(props) => !props.customDots ? props.darkMode ? 'background-color: #CDCED3' : 'background-color: #0255AA' : ''};
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
    }

    &:nth-of-type(2):before {
      content: "";
      ${(props) => !props.customDots ? props.darkMode ? 'background-color: #7E7F85' : 'background-color: #A2C0DF' : ''};
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
    }
  }
`;

export const DotsList = styled.li`
  margin-right: 30px;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;

  &:before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
  }

  &:before {
    background-color: ${(props) => props.fill};
  }
`;

export const BoxContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 30px;
  margin-bottom: 20px;

  @media (min-width: 991px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const Box = styled.div`
  background-color: var(--color-alt-second);
  padding: 10px 20px;
  border-radius: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p:first-child {
    line-height: 20px;
    font-size: 13px;
    opacity: 0.7;
    font-weight: 500;
  }

  p:not(:first-child) {
    font-weight: 500;
    font-size: 13px;
    line-height: 30px;
  }
`;

const NoItemsInfo = styled.div`
  text-align: center;
  margin-top: 50px;
  margin-left: 15px;
  margin-right: 15px;
  min-height: 300px;
  height: fit-content;
  padding: 30px 0;
  background: var(--color-white);
  border-radius: var(--box-border-radius);
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  h1 {
    --color-primary: ${(props) => props.darkMode ? '#fff' : '#000'};
    line-height: 30px;
    padding: 40px;
  }

  div {
    margin-top: 15px;
    padding: 0 40px;

    p {
      line-height: 30px;
      margin-top: 10px;

      &:first-child {
        margin-bottom: 35px;
      }
    }
  }
`;

export const Section = styled.div`
  margin-top: 60px;
`;

export interface AnalysisElements {
    areaAnalysisList?: AnalysisAreaRow[];
    timeAnalysisList?: AnalysisTimeRow[];
    trendResult?: string[];
}

interface AnalysisSaleListProps {
    showList: boolean;
    showNoItemsText: boolean;
    analysis: AnalysisElements;
    ref: React.LegacyRef<HTMLDivElement>;
    timeRange: string;
}

const AnalysisSaleList: FC<AnalysisSaleListProps> = forwardRef(({ showList, showNoItemsText, analysis, timeRange }, ref) => {
    const [trendData, setTrendData] = useState<number[]>([]);

    const { user } = useUser();

    useEffect(() => {
        if (analysis.trendResult != null && (analysis.timeAnalysisList && analysis.timeAnalysisList.length > 1)) {
            const trendDataArray = [];
            let multiplier;

            switch (timeRange) {
            case TimeRange.Miesiac:
                multiplier = 30;
                break;
            case TimeRange.Tydzien:
                multiplier = 7;
                break;
            case TimeRange.Kwartal:
                multiplier = 120;
                break;
            case TimeRange.Polrocze:
                multiplier = 182;
                break;
            case TimeRange.Rok:
            default:
                multiplier = 365;
                break;
            }

            for (let i = 0; i < analysis.timeAnalysisList.length - 1; i++) {
                trendDataArray.push(Number(analysis.trendResult[3]) * i * multiplier + Number(analysis.trendResult[4]));
            }
            setTrendData(trendDataArray);
        }
    }, [
        timeRange,
        analysis.timeAnalysisList,
        analysis.trendResult
    ]);

    const allTransactionsAmount = analysis.areaAnalysisList?.find((x) => x.areaLabel === 'Ogółem')?.transactionAmount;
    const allTransactionsRangesAmount = analysis.timeAnalysisList?.find((x) => x.timeRangeLabel === 'Ogółem')?.transactionAmount;

    const getUsedValue = (usedAmount: number, index: number, array: AnalysisAreaRow[] | AnalysisTimeRow[]) => {
        if (!index) return usedAmount;

        return array
            .slice(0, index)
            .map((x) => x.transactionAmount)
            .reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                usedAmount
            );
    };

    const transactionCountData = analysis.areaAnalysisList?.map((x, i) => ({
        name: x.areaLabel,
        value: x.transactionAmount,
        percentage: `${(x.transactionAmount / (allTransactionsAmount || 1) * 100).toFixed(0)}%`,
        usedValue: getUsedValue(x.transactionAmount, i, analysis.areaAnalysisList || [])
    }));

    transactionCountData?.pop();

    const transactionRangesData = analysis.timeAnalysisList?.map((x, i) => ({
        name: x.timeRangeLabel,
        value: x.transactionAmount,
        percentage: `${(x.transactionAmount / (allTransactionsRangesAmount || 1) * 100).toFixed(0)}%`,
        usedValue: getUsedValue(x.transactionAmount, i, analysis.timeAnalysisList || [])
    })).filter((x) => x.name !== 'Ogółem');

    const averagePriceByArea = analysis.areaAnalysisList?.map((x) => ({
        name: x.areaLabel.replace('100-∞', '100+').replace('-', ' - '),
        bar1: x.avgPricePerSquareMeter,
        bar2: x.medianPricePerSquareMeter
    })).slice(0, -1);
    const totalPriceByArea = analysis.areaAnalysisList?.map((x) => ({
        name: x.areaLabel.replace('100-∞', '100+').replace('-', ' - '), bar1: x.avgPrice, bar2: x.medianPrice
    })).slice(0, -1);
    const averagePriceByMeter = analysis.areaAnalysisList?.map((x) => ({
        name: x.areaLabel.replace('100-∞', '100+').replace('-', ' - '), line: x.avgPrice, bar: x.avgPricePerSquareMeter
    })).slice(0, -1);
    const averagePriceByTime = analysis.timeAnalysisList?.map((x) => ({
        name: x.timeRangeLabel.replace('100-∞', '100+').replace('-', ' - '),
        bar1: x.avgPricePerSquareMeter,
        bar2: x.medianPricePerSquareMeter
    })).slice(0, -1);
    const totalPriceByTime = analysis.timeAnalysisList?.map((x) => ({
        name: x.timeRangeLabel.replace('100-∞', '100+').replace('-', ' - '), bar1: x.avgPrice, bar2: x.medianPrice
    })).slice(0, -1);
    const averagePriceAreaByTime = analysis.timeAnalysisList?.map((x) => ({
        name: x.timeRangeLabel.replace('100-∞', '100+').replace('-', ' - '),
        line: x.avgPrice,
        bar: x.avgPricePerSquareMeter
    })).slice(0, -1);
    const averagePriceMeterByTime = analysis.timeAnalysisList?.map((x) => ({
        name: x.timeRangeLabel.replace('100-∞', '100+').replace('-', ' - '),
        line: x.avgArea,
        bar: x.avgPricePerSquareMeter
    })).slice(0, -1);
    const averageAllPriceByTime = analysis.timeAnalysisList?.map((x) => ({
        name: x.timeRangeLabel.replace('100-∞', '100+').replace('-', ' - '), line: x.avgArea, bar: x.avgPrice
    })).slice(0, -1);
    const trendDataChart = analysis.timeAnalysisList?.map((x, i) => ({
        name: x.timeRangeLabel.replace('-', ' - '),
        errorY: [x.minPricePerSquareMeter, x.maxPricePerSquareMeter],
        dot: x.avgPricePerSquareMeter,
        y: x.maxPricePerSquareMeter,
        line: trendData[i]
    })).slice(0, -1);
    // const rgbColor = user.isDarkMode ? '242, 245, 249' : '2, 85, 170';

    return (
        <ContainerStyle ref={ref}>
            {/* {isPrintMode ?*/}
            {/*    <AnalysisPdfPage ref={ref} analysis={analysis} transactionCountData={transactionCountData}*/}
            {/*        rgbColor={rgbColor}*/}
            {/*        averagePriceByArea={averagePriceByArea} totalPriceByArea={totalPriceByArea}*/}
            {/*        averagePriceByMeter={averagePriceByMeter} transactionRangesData={transactionRangesData}*/}
            {/*        averagePriceByTime={averagePriceByTime} totalPriceByTime={totalPriceByTime}*/}
            {/*        averagePriceAreaByTime={averagePriceAreaByTime}*/}
            {/*        averagePriceMeterByTime={averagePriceMeterByTime}*/}
            {/*        averageAllPriceByTime={averageAllPriceByTime} trendDataChart={trendDataChart}*/}
            {/*        type={'analysis'}/>*/}
            {/*    : null}*/}
            {showList
                ? <>
                    {analysis.areaAnalysisList && analysis.areaAnalysisList.length > 0 && <>
                        <Title>Raport wg przedziałów powierzchni</Title>
                        <AreaRangeTable analysis={analysis}/>
                        <AreaRangePie transactionCountData={transactionCountData}/>
                        <AreaRangeSquareMeterBars averagePriceByArea={averagePriceByArea}/>
                        <AreaRangeTotalPriceBars totalPriceByArea={totalPriceByArea}/>
                        <AreaRangeAverageMeterAndTotalPrice averagePriceByMeter={averagePriceByMeter}/>
                    </>}
                    {analysis.timeAnalysisList && analysis.timeAnalysisList.length > 0 && <Section>
                        <Title>Raport według czasu</Title>
                        <TimeRangeTable analysis={analysis}/>
                        <TimeRangePie transactionRangesData={transactionRangesData} />
                        <TimeRangeSquareMeterBars averagePriceByTime={averagePriceByTime}/>
                        <TimeRangeTotalPriceBars totalPriceByTime={totalPriceByTime}/>
                        <TimeRangeAverageMeterAndTotalPrice averagePriceAreaByTime={averagePriceAreaByTime}/>
                        <TimeRangeAverageMeterAndArea averagePriceMeterByTime={averagePriceMeterByTime}/>
                        <TimeRangeAverageTotalPriceAndArea averageAllPriceByTime={averageAllPriceByTime}/>
                    </Section>}
                    {/* TODO: Do odkomentowania po naprawie*/}
                    {analysis.trendResult && analysis.trendResult.length > 0 && <Section>
                        <Title>Raport według trendu</Title>
                        <TrendRangeAverageTotalPriceAndArea trendDataChart={trendDataChart} analysis={analysis}/>
                    </Section>}
                </>
                : showNoItemsText
                    ? <NoItemsInfo darkMode={user.isDarkMode}>
                        <h1>Nie znaleźliśmy żadnych ogłoszeń</h1>
                        <div><p>Obecnie w naszej bazie nie posiadamy ogłoszeń, które spełniają twoje kryteria.
                            Spróbuj
                            zmienić kryteria i wyszukaj jeszcze raz.</p></div>
                    </NoItemsInfo>
                    : null}
        </ContainerStyle>);
});

export default AnalysisSaleList;
