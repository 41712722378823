import React, { FC, forwardRef } from 'react';
import styled from 'styled-components';

import { AlertType } from 'components/common/Card/common/InteractiveIconsPopup';

const Container = styled.span`
    display: flex;
    gap: 10px;
    background: var(--color-alt-second);
    padding: 10px;
    border-radius: var(--border-radius);
    height: 50px;

    &.disabled {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.5;
    }
`;

const SelectableItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 5px 10px;
    background: var(--color-white);
    color: var(--color-primary);
    cursor: pointer;
    border-radius: var(--border-radius);

    font-weight: var(--font-weight);
    font-size: var(--font-size-body-2);
    line-height: 20px;

    flex: 1;

    border: 1px solid transparent;
    transition: border 0.1s ease-in-out;

    &:hover {
        transition: border 0.1s ease-in-out;
        border: 1px solid var(--color-alt-hover);
    }

    &.selected {
        background: var(--color-alt);
        color: white;
    }
`;

interface BadgeSelectInputProperties {
    value: { label: string, name: string, value: boolean, type?: AlertType }[],
    onChange: (toggleValueName: string, type?: AlertType) => void,
    disabled?: boolean,
}
const BadgeSelectInput: FC<BadgeSelectInputProperties> = forwardRef<HTMLDivElement, BadgeSelectInputProperties>(({
    disabled,
    value,
    onChange,
    ...rest
}, ref) => {
    return (
        <div {...rest} ref={ref}>
            <Container className={disabled ? 'disabled' : ''}>
                {value.map((x) => <SelectableItem key={x.name} className={x.value ? 'selected' : ''}
                    onClick={() => !disabled && onChange(x.name, x.type)}>
                    {x.label}
                </SelectableItem>)}
            </Container>
        </div>
    );
});

export default BadgeSelectInput;
