import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

const Options = styled.div`
    position: absolute;
    right: 0;
    ${({ isInModal }) => isInModal ? 'top: 55px;' : 'bottom: 55px;'}
    background: var(--color-white);
    border-radius: var(--box-border-radius);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: auto;
`;

const ActionWrapper = styled.div`
    background-color: var(--color-alt-second);
    border-radius: 20px;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    gap: 8px;
    
    p {
        text-align: center;
    }

    &:hover {
        transition: background-color ease-in .2s;
        background-color: var(--color-alt);
        cursor: pointer;

        p {
            color: white;
        }

        img {
            filter: ${(props) => props.darkMode ? 'none' : 'brightness(0) invert(1)'};
        }
    }
`;

interface OfferActionsProps {
    elements: {
        onClick: (event: MouseEvent) => void;
        darkMode?: boolean;
        children: ReactNode;
    }[];
    isInModal?: boolean;
}

const OfferActions: FC<OfferActionsProps> = ({ elements, isInModal }) => {
    return (
        <Options isInModal={isInModal}>
            {elements.map((element, index) => <ActionWrapper key={index} onClick={element.onClick} darkMode={element.darkMode}>
                {element.children}
            </ActionWrapper>)}
        </Options>
    );
};

export default OfferActions;
