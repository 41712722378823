import React, { useContext } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import BailiffContext from 'utils/context/BailiffContext';
import { bailiffSortOptions } from 'utils/options';
import { getBailiffValueFromState } from 'utils/state-managment/bailiff/bailiffFilter';
import { BailiffNoticeSearchingRequestSortTypeEnum } from 'utils/types/BailiffModels';

import Indicator from 'components/atom/Indicator';
import BailiffOffers from 'components/bailiff/BailiffOffers';
import FiltersBailiff from 'components/bailiff/FiltersBailiff';
import FiltersContentWrapper from 'components/common/FiltersContentWrapper';
import V2HeaderSection from 'components/V2HeaderSection';

const Container = styled.div`
    padding: 15px 20px 0;
`;

const PageContainer = styled.div`
    height: 100%;
`;

const BailiffPage = () => {
    const { bailiffOfferState, clearOffers, handleChange, loadBailiffOffers, bailiffFiltersState } = useContext(BailiffContext);

    const handleChangeSort = (sortType: string) => {
        handleChange('sortType', sortType);
        loadBailiffOffers({
            requestData: {
                ...getBailiffValueFromState(bailiffFiltersState),
                sortType: sortType as BailiffNoticeSearchingRequestSortTypeEnum
            },
            page: 1,
            rows: 25
        });
    };

    // const handleCSVFile = useCallback(() => {
    //     saleOffersApiRequests.getSaleOffersCSVFile(getSaleValueFromState(saleFiltersState), getNormalizedSaleModuleName(saleFiltersState.module));
    // }, [saleFiltersState]);

    return (
        <PageContainer>
            <V2HeaderSection
                title={'Informacje o zadłużeniach'}
                offersCount={bailiffOfferState.bailiffOfferList?.totalElements}
                sortType={bailiffOfferState.bailiffOfferList.content?.length ? bailiffOfferState.sortType : undefined}
                sortOptions={bailiffSortOptions}
                handleChangeSort={handleChangeSort}
                handleBackToFilters={bailiffOfferState.bailiffOfferList.content?.length || bailiffOfferState.bailiffOfferList.empty ? clearOffers : undefined}
            />
            <FiltersContentWrapper className={clsx('offers-list', { active: bailiffOfferState.bailiffOfferList?.content && bailiffOfferState.bailiffOfferList?.content.length > 0 || bailiffOfferState.bailiffOfferList?.empty })}>
                <Container id="filters">
                    <FiltersBailiff/>
                </Container>
                <BailiffOffers/>
                {bailiffOfferState.isLoading && <Indicator/>}
            </FiltersContentWrapper>
        </PageContainer>
    );
};

export default BailiffPage;
