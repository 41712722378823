import React, { useContext, useState } from 'react';
import { UserActions } from 'store/User/Actions';
import { useUser } from 'store/User/Context';
import UserFiltersContext from 'utils/context/UserFiltersContext';

import BaseCard from 'components/common/Card/BaseCard';
import OfferDetails from 'components/common/Card/OfferDetails';
import OfferDetailsModal from 'components/common/OfferDetailsModal';

const SingleUsers = ({ data, type }) => {
    const [detailsOpen, toggleDetailsOpen] = useState(false);
    const [preferredTab, setPreferredTab] = useState(undefined);
    const isNote = data.note && typeof data.note.noteId === 'number';
    const { dispatch } = useUser();
    const { propertyType } = useContext(UserFiltersContext);

    const toggleContainerDetails = (e) => {
        if (e.target.dataset.notclickable || e.target.closest('[data-notclickable]')) return;
        type === 'users' && UserActions.loadOfferDetailsAsync(dispatch, propertyType, data.advertisementId);
        type === 'my_offer' && UserActions.loadMyOfferDetailsAsync(dispatch, data.module, data.advertisementId);
        type === 'profile' && UserActions.loadProfileOfferDetailsAsync(dispatch, data.module, data.advertisementId);

        setPreferredTab(undefined);
        setTimeout(() => {
            toggleDetailsOpen((prev) => !prev);
        }, 100);
    };

    const openOfferTab = (name) => {
        setPreferredTab(name);
        setTimeout(() => {
            toggleDetailsOpen((prev) => !prev);
        }, 100);
    };

    return (
        <div>
            <BaseCard
                onClick={toggleContainerDetails}
                data={data}
                type={type}
                showNote={isNote}
                openOfferTab={openOfferTab}
            />
            <OfferDetailsModal isOpen={detailsOpen} onClose={toggleContainerDetails}>
                <OfferDetails
                    preferredTab={preferredTab}
                    type={type}
                    data={{ ...data, ...data.detailedContent, city: data.city || data.detailedContent?.city.fullName, adType: data.adType, thumbnailPath: data.photos.length > 0 ? `${process.env.REACT_APP_API_URL_2}image/find?fileName=${data.photos[0]}&photoSize=NORMAL_PHOTO&module=USER_OFFER_FLAT` : '/defaultImg.png' }}
                    onToggleContainerDetails={toggleContainerDetails}
                    isUsersDetails={detailsOpen}
                />
            </OfferDetailsModal>
        </div>
    );
};

export default SingleUsers;
