import React, { FC } from 'react';
import styled from 'styled-components';

import { TabType } from 'components/common/Tabs/V2Tabs';

import Bottom, { BottomProps } from './Bottom/Bottom';
import PriceContainer, { LinkProps } from './PriceContainer/PriceContainer';
import Top from './Top/Top';
import InfoContainer, { RatingContainerProps } from './InfoContainer';

const Wrapper = styled.div`
    display: grid;
    grid-template-areas:
          "left right" 
          "left right" 
          "left right"
          "bottom bottom";
    gap: 5px 15px;
`;

export const Title = styled.h1`
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-break: anywhere;
`;

export type V2CardBodyProperties = {
    preTitle?: string;
    ratingScore?: number | null;
    showUserOptions?: boolean;
    title: string,
    lowestPriceLabel?: boolean;
    privateOfferLabel?: boolean;
    oldPrice?: string;
    link?: LinkProps;
    mainValue?: string;
    subMainValues?: (React.ReactNode | string)[];
    openOfferTab?: (tab: TabType) => void;
    ratingContainer?: RatingContainerProps;
} & BottomProps;

const V2CardBodyDefault: FC<V2CardBodyProperties> = ({
    preTitle,
    ratingScore,
    showUserOptions,
    userDetails,
    title,
    infoElements,
    lowestPriceLabel,
    privateOfferLabel,
    oldPrice,
    link,
    modifications,
    notInteractiveElements,
    isNotInteractive,
    interactiveElements,
    mainValue,
    subMainValues,
    showMessages,
    setShowMessages,
    openOfferTab,
    ratingContainer
}) => {
    return (
        <Wrapper>
            <Top preTitle={preTitle} ratingScore={ratingScore} showUserOptions={showUserOptions}
                userDetails={userDetails} lowestPriceLabel={lowestPriceLabel} privateOfferLabel={privateOfferLabel}/>
            <InfoContainer title={title} infoElements={infoElements}
                isUpperLabel={lowestPriceLabel || privateOfferLabel} ratingContainer={ratingContainer} openOfferTab={openOfferTab} />
            <PriceContainer isUpperLabel={lowestPriceLabel || privateOfferLabel} oldPrice={oldPrice} mainValue={mainValue}
                subMainValues={subMainValues}
                link={link} modifications={modifications}/>
            <Bottom infoElements={infoElements} modifications={modifications} userDetails={userDetails}
                notInteractiveElements={notInteractiveElements} isNotInteractive={isNotInteractive}
                interactiveElements={interactiveElements} showMessages={showMessages} setShowMessages={setShowMessages} openOfferTab={openOfferTab} />
        </Wrapper>
    );
};

export default V2CardBodyDefault;
