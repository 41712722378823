import React, { FC } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { convertPrice } from 'utils/convertPrice';
import { ModulesType } from 'utils/types/ModulesType';
import { OfferData } from 'utils/types/OfferData';

import Icon, { IconEnum } from 'components/common/Icon';

import InfoBadge from './common/InfoBadge';
import InteractiveIcons from './common/InteractiveIcons';
import { CardImageContainer, ImgStyled, InfoBoxContainer } from './BaseCard';

const Link = styled.a`
    display: flex;
    gap: 5px;
`;

interface MediumCardProperties {
    data: OfferData;
    type: ModulesType;
}

const Right = styled.div`
    grid-area: right;
    display: flex;
    flex-direction: column;

    @media (max-width: 1100px) {
        grid-area: 2;
    }
`;

const PriceValue = styled.h1`
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: var(--color-alt);
    padding: 0;
`;

const ImageContainer = styled(CardImageContainer)`
    grid-area: left;
    height: 150px;
    width: 245px;

    @media (max-width: 1100px) {
        width: 100%;
    }
`;

const Wrapper = styled.div`
    display: grid;
    grid-template-areas:
          "left center right";
    grid-template-columns: 245px 1fr 1fr;
    gap: 20px;

    @media (max-width: 1100px) {
        grid-template-columns: 1fr;
        grid-template-areas: unset;
    }
`;

const Left = styled.div`
    grid-area: left;
    width: min-content;

    @media (max-width: 1100px) {
        grid-area: 1 / span 2;
        width: 100%;
    }
`;

const Center = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    grid-area: center;

    @media (max-width: 1100px) {
        grid-area: 2;
    }
`;

const Title = styled.h1`
    padding: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
`;

const PriceContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 5px;
    grid-area: right;
`;

const IconsWrapper = styled.div`
    margin-top: auto;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-self: center;
    flex-wrap: wrap;
    gap: 10px;

    @media (max-width: 1100px) {
        grid-area: 3 / span 2;
        justify-content: flex-start;
    }
`;

const MediumCard: FC<MediumCardProperties> = ({ data, type }) => {
    const preventDefaultHandle = (e: Event) => {
        e.stopPropagation();
    };

    return (
        <Wrapper>
            <Left>
                {type === 'shortInfo' && <ImageContainer>
                    <ImgStyled src={data.thumbnailPath || '/defaultImg.png'}/>
                    <InfoBoxContainer>
                        <InfoBadge isSuccess={!data.isModified} isWarning={data.isModified}>
                            {dayjs(data.dateAdded).format('DD.MM.YYYY, HH:mm')}
                        </InfoBadge>
                        {data.offerFrom && <InfoBadge isWhite>
                            {data.offerFrom}
                        </InfoBadge>}
                        {data.dateAdded && <InfoBadge isWhite>
                            {dayjs(data.dateAdded).format('DD.MM.YYYY')}
                        </InfoBadge>}
                    </InfoBoxContainer>
                </ImageContainer>}
            </Left>
            <Center>
                {type === 'shortInfo' &&
                    <Title>{data.title}</Title>
                }
            </Center>
            <Right>
                <PriceContainer>
                    {type === 'shortInfo' &&
                        <>
                            <PriceValue>{convertPrice(data.price) ?? '-'}</PriceValue>
                            <Link href={data.link} target="_blank" rel="noopener noreferrer" onClick={preventDefaultHandle}>
                                <Icon icon={IconEnum.LINK}/>{data.portal}
                            </Link>
                        </>
                    }
                </PriceContainer>
                {window.innerWidth >= 991
                    ? <IconsWrapper>
                        <InteractiveIcons disabled={!data.phone} icon={IconEnum.PHONE} isLink
                            text={<a href={`tel:${data.phone}`}>{data.phone}</a>}/>
                        <InteractiveIcons disabled icon={IconEnum.INFO} isLink text={'Zobacz szczegóły'}/>
                    </IconsWrapper>
                    : null}
            </Right>
            {window.innerWidth < 991
                ? <IconsWrapper>
                    <InteractiveIcons disabled={!data.phone} icon={IconEnum.PHONE} isLink
                        text={<a href={`tel:${data.phone}`}>{data.phone}</a>}/>
                    <InteractiveIcons disabled icon={IconEnum.INFO} isLink text={'Zobacz szczegóły'}/>
                </IconsWrapper>
                : null}
        </Wrapper>
    );
};

export default MediumCard;
