import React, { FC, useState } from 'react';

import { AlertType } from 'components/common/Card/common/InteractiveIconsPopup';
import { IconEnum } from 'components/common/Icon';
import InputContainer from 'components/common/InputContainer';
import Tooltip from 'components/common/Tooltip/Tooltip';

import BadgeSelectInput from './BadgeSelectInput';

interface NotificationsInputProperties {
    notifications: {
        alertSms: boolean,
        alertEmail: boolean,
        pushAlert: boolean,
    },
    handleChangeNotificationAlert: (name: string, id: number) => void,
    filterId: number,
    title: string,
    icon: IconEnum,
    helperText: string,
}

const NotificationsInput: FC<NotificationsInputProperties> = ({
    notifications,
    handleChangeNotificationAlert,
    filterId,
    helperText,
    title,
    icon
}) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);

    return (
        <>
            {!filterId &&
                <Tooltip showTooltip={showTooltip} alertsTooltip referenceElement={referenceElement}
                    text={helperText}/>}
            <InputContainer title={title} icon={icon}>
                <BadgeSelectInput
                    // @ts-expect-error it is needed like that for tooltip library
                    ref={setReferenceElement}
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                    disabled={!filterId}
                    value={[
                        {
                            label: 'SMS',
                            name: 'alertSms',
                            value: notifications.alertSms,
                            type: AlertType.SMS
                        },
                        {
                            label: 'E-mail',
                            name: 'alertEmail',
                            value: notifications.alertEmail,
                            type: AlertType.EMAIL
                        },
                        {
                            label: 'Push',
                            name: 'pushAlert',
                            value: notifications.pushAlert,
                            type: AlertType.PUSH
                        }
                    ]}
                    onChange={(name) => handleChangeNotificationAlert(name, filterId)}
                />
            </InputContainer>
        </>
    );
};

export default NotificationsInput;
