/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * Typ nieruchomości
 * @example "SALE_FLAT"
 */
export enum Module {
  SALE_FLAT = "SALE_FLAT",
  SALE_HOUSE = "SALE_HOUSE",
  SALE_PLOT = "SALE_PLOT",
  SALE_OTHER = "SALE_OTHER",
}

export interface OpinionUpdateInformation {
  /**
   * Id opinii
   * @format int64
   * @example 1
   */
  opinionId: number;
  /**
   * Średnia ocena społeczności
   * @format double
   * @example 3
   */
  communityScore: number;
  /**
   * Liczba komentarzy społeczności
   * @format int32
   * @example 2
   */
  numberOfCommunityComments: number;
  /**
   * Liczba opinii społeczności
   * @format int32
   * @example 1
   */
  numberOfCommunityOpinions: number;
}

export interface LocationDto {
  /**
   * @format int32
   * @example 14
   */
  voivodeshipNumber?: number;
  /**
   * @format int32
   * @example 19
   */
  countyNumber?: number;
  /**
   * @format int32
   * @example 15
   */
  communityNumber?: number;
  /**
   * @format int32
   * @example 4
   */
  kindNumber?: number;
  /**
   * @format int32
   * @example 1
   */
  subRegionNumber?: number;
  /**
   * @format int32
   * @example 969379
   */
  regionNumber?: number;
  /**
   * @format int32
   * @example 12548
   */
  streetNumber?: number;
  /**
   * Pełna nazwa
   * @example "Rybaki"
   */
  fullName?: string;
}

/** Oferta od */
export enum OfferFromEnum {
  OsobaPrywatna = "Osoba prywatna",
  Agencja = "Agencja",
}

/** Typ działki */
export enum PlotBuildingTypeEnum {
  Budowlana = "Budowlana",
  Rolna = "Rolna",
  Lesna = "Leśna",
  Pozostale = "Pozostałe",
}

export interface PlotsFilterDto {
  /**
   * Nazwa filtra
   * @example "Nazwa testowa"
   */
  name?: string;
  city: LocationDto[];
  /**
   * Powierzchnia nieruchomości od
   * @format double
   * @min 0
   * @example 100
   */
  areaFrom?: number;
  /**
   * Powierzchnia nieruchomości do
   * @format double
   * @min 0
   * @example 200
   */
  areaTo?: number;
  /**
   * Cena za metr kwadratowy od
   * @format double
   * @min 0
   * @example 100
   */
  pricePerSquareMeterFrom?: number;
  /**
   * Cena za metr kwadratowy do
   * @format double
   * @min 0
   * @example 200
   */
  pricePerSquareMeterTo?: number;
  /**
   * Cena nieruchomości od
   * @format double
   * @min 0
   * @example 1000000
   */
  priceFrom?: number;
  /**
   * Cena nieruchomości do
   * @format double
   * @min 0
   * @example 2000000
   */
  priceTo?: number;
  module: Module;
  /**
   * Powiadomienia SMS
   * @example true
   */
  alertSms: boolean;
  /**
   * Powiadomienia Email
   * @example true
   */
  alertEmail: boolean;
  /**
   * Powiadomienia Push
   * @example true
   */
  pushAlert: boolean;
  /**
   * Wartości zerowe
   * @example true
   */
  includeZeroArea: boolean;
  /**
   * Modyfikacje ogłoszenia
   * @example true
   */
  modification: boolean;
  /**
   * Podbicie ogłoszenia
   * @example true
   */
  tossedUp: boolean;
  /**
   * Grupowanie duplikatów
   * @example true
   */
  groupDuplicate: boolean;
  /**
   * Opóźnienie powiadomień
   * @format int32
   */
  notificationsDelay: number;
  /**
   * Data dodania od
   * Czas w formacie LocalDate
   * @format date
   * @example "2023-12-12"
   */
  offerAddedFrom?: string;
  /**
   * Data dodania do
   * Czas w formacie LocalDate
   * @format date
   * @example "2023-12-12"
   */
  offerAddedTo?: string;
  /**
   * Ogłoszenia z liczby dni
   * @format int32
   * @example 7
   */
  daysAmount?: number;
  sortType: SortType;
  /**
   * Pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  wantedKeywords?: string[];
  /**
   * Nie pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  unwantedKeywords?: string[];
  /**
   * Pożądane numery telefonów
   * @example ["123467890"]
   */
  wantedPhoneNumbers?: string[];
  /**
   * Nie pożądane numery telefonów
   * @example ["123467890"]
   */
  unwantedPhoneNumbers?: string[];
  offerFrom?: OfferFromEnum[];
  /**
   * Id filtra
   * @format int64
   */
  id: number;
  buildingType?: PlotBuildingTypeEnum[];
}

export enum SortType {
  DATE_DESC = "DATE_DESC",
  DATE_ASC = "DATE_ASC",
  PRICE_DESC = "PRICE_DESC",
  PRICE_ASC = "PRICE_ASC",
  PRICE_PER_SQUARE_METER_ASC = "PRICE_PER_SQUARE_METER_ASC",
  PRICE_PER_SQUARE_METER_DESC = "PRICE_PER_SQUARE_METER_DESC",
  MODIFICATION_DATE = "MODIFICATION_DATE",
  SCORE_ASC = "SCORE_ASC",
  SCORE_DESC = "SCORE_DESC",
}

/** Typ budynku */
export enum OtherBuildingTypeEnum {
  Garaze = "Garaże",
  Hale = "Hale",
  Pozostale = "Pozostałe",
}

export interface OtherFilterDto {
  /**
   * Nazwa filtra
   * @example "Nazwa testowa"
   */
  name?: string;
  city: LocationDto[];
  /**
   * Powierzchnia nieruchomości od
   * @format double
   * @min 0
   * @example 100
   */
  areaFrom?: number;
  /**
   * Powierzchnia nieruchomości do
   * @format double
   * @min 0
   * @example 200
   */
  areaTo?: number;
  /**
   * Cena za metr kwadratowy od
   * @format double
   * @min 0
   * @example 100
   */
  pricePerSquareMeterFrom?: number;
  /**
   * Cena za metr kwadratowy do
   * @format double
   * @min 0
   * @example 200
   */
  pricePerSquareMeterTo?: number;
  /**
   * Cena nieruchomości od
   * @format double
   * @min 0
   * @example 1000000
   */
  priceFrom?: number;
  /**
   * Cena nieruchomości do
   * @format double
   * @min 0
   * @example 2000000
   */
  priceTo?: number;
  module: Module;
  /**
   * Powiadomienia SMS
   * @example true
   */
  alertSms: boolean;
  /**
   * Powiadomienia Email
   * @example true
   */
  alertEmail: boolean;
  /**
   * Powiadomienia Push
   * @example true
   */
  pushAlert: boolean;
  /**
   * Wartości zerowe
   * @example true
   */
  includeZeroArea: boolean;
  /**
   * Modyfikacje ogłoszenia
   * @example true
   */
  modification: boolean;
  /**
   * Podbicie ogłoszenia
   * @example true
   */
  tossedUp: boolean;
  /**
   * Grupowanie duplikatów
   * @example true
   */
  groupDuplicate: boolean;
  /**
   * Opóźnienie powiadomień
   * @format int32
   */
  notificationsDelay: number;
  /**
   * Data dodania od
   * Czas w formacie LocalDate
   * @format date
   * @example "2023-12-12"
   */
  offerAddedFrom?: string;
  /**
   * Data dodania do
   * Czas w formacie LocalDate
   * @format date
   * @example "2023-12-12"
   */
  offerAddedTo?: string;
  /**
   * Ogłoszenia z liczby dni
   * @format int32
   * @example 7
   */
  daysAmount?: number;
  sortType: SortType;
  /**
   * Pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  wantedKeywords?: string[];
  /**
   * Nie pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  unwantedKeywords?: string[];
  /**
   * Pożądane numery telefonów
   * @example ["123467890"]
   */
  wantedPhoneNumbers?: string[];
  /**
   * Nie pożądane numery telefonów
   * @example ["123467890"]
   */
  unwantedPhoneNumbers?: string[];
  offerFrom?: OfferFromEnum[];
  /**
   * Id filtra
   * @format int64
   */
  id: number;
  /**
   * Rok budowy nieruchomości od
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYearFrom?: number;
  /**
   * Rok budowy nieruchomości do
   * @format int32
   * @max 2030
   * @example 2018
   */
  builtYearTo?: number;
  /**
   * Piętro od
   * @format int32
   * @example 4
   */
  floorFrom?: number;
  /**
   * Piętro do
   * @format int32
   * @example 4
   */
  floorTo?: number;
  typeOfMarket?: TypeOfMarketEnum[];
  buildingType?: OtherBuildingTypeEnum[];
}

/** Typ rynku */
export enum TypeOfMarketEnum {
  Pierwotny = "Pierwotny",
  Wtorny = "Wtórny",
}

/** Typ budynku */
export enum HouseBuildingTypeEnum {
  Wolnostojacy = "Wolnostojący",
  Blizniak = "Bliźniak",
  Szeregowiec = "Szeregowiec",
  Pozostale = "Pozostałe",
}

export interface HousesFilterDto {
  /**
   * Nazwa filtra
   * @example "Nazwa testowa"
   */
  name?: string;
  city: LocationDto[];
  /**
   * Powierzchnia nieruchomości od
   * @format double
   * @min 0
   * @example 100
   */
  areaFrom?: number;
  /**
   * Powierzchnia nieruchomości do
   * @format double
   * @min 0
   * @example 200
   */
  areaTo?: number;
  /**
   * Cena za metr kwadratowy od
   * @format double
   * @min 0
   * @example 100
   */
  pricePerSquareMeterFrom?: number;
  /**
   * Cena za metr kwadratowy do
   * @format double
   * @min 0
   * @example 200
   */
  pricePerSquareMeterTo?: number;
  /**
   * Cena nieruchomości od
   * @format double
   * @min 0
   * @example 1000000
   */
  priceFrom?: number;
  /**
   * Cena nieruchomości do
   * @format double
   * @min 0
   * @example 2000000
   */
  priceTo?: number;
  module: Module;
  /**
   * Powiadomienia SMS
   * @example true
   */
  alertSms: boolean;
  /**
   * Powiadomienia Email
   * @example true
   */
  alertEmail: boolean;
  /**
   * Powiadomienia Push
   * @example true
   */
  pushAlert: boolean;
  /**
   * Wartości zerowe
   * @example true
   */
  includeZeroArea: boolean;
  /**
   * Modyfikacje ogłoszenia
   * @example true
   */
  modification: boolean;
  /**
   * Podbicie ogłoszenia
   * @example true
   */
  tossedUp: boolean;
  /**
   * Grupowanie duplikatów
   * @example true
   */
  groupDuplicate: boolean;
  /**
   * Opóźnienie powiadomień
   * @format int32
   */
  notificationsDelay: number;
  /**
   * Data dodania od
   * Czas w formacie LocalDate
   * @format date
   * @example "2023-12-12"
   */
  offerAddedFrom?: string;
  /**
   * Data dodania do
   * Czas w formacie LocalDate
   * @format date
   * @example "2023-12-12"
   */
  offerAddedTo?: string;
  /**
   * Ogłoszenia z liczby dni
   * @format int32
   * @example 7
   */
  daysAmount?: number;
  sortType: SortType;
  /**
   * Pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  wantedKeywords?: string[];
  /**
   * Nie pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  unwantedKeywords?: string[];
  /**
   * Pożądane numery telefonów
   * @example ["123467890"]
   */
  wantedPhoneNumbers?: string[];
  /**
   * Nie pożądane numery telefonów
   * @example ["123467890"]
   */
  unwantedPhoneNumbers?: string[];
  offerFrom?: OfferFromEnum[];
  /**
   * Id filtra
   * @format int64
   */
  id: number;
  /**
   * Liczba pokoi w domu od
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRoomsFrom?: number;
  /**
   * Liczba pokoi w domu do
   * @format int32
   * @min 0
   * @example 5
   */
  numberOfRoomsTo?: number;
  /**
   * Rok budowy nieruchomości od
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYearFrom?: number;
  /**
   * Rok budowy nieruchomości do
   * @format int32
   * @max 2030
   * @example 2018
   */
  builtYearTo?: number;
  typeOfMarket?: TypeOfMarketEnum[];
  buildingType?: HouseBuildingTypeEnum[];
  /**
   * Liczba pieter od
   * Pojedynczy wybór
   * @format int32
   * @example 0
   */
  floorFrom?: null;
  /**
   * Liczba pieter do
   * Pojedynczy wybór
   * @format int32
   * @example 2
   */
  floorTo?: null;
}

/**
 * Typ budynku
 * @example "Blok"
 */
export enum FlatBuildingTypeEnum {
  Blok = "Blok",
  Apartamentowiec = "Apartamentowiec",
  Kamienica = "Kamienica",
  Pozostale = "Pozostałe",
}

export interface FlatsFilterDto {
  /**
   * Nazwa filtra
   * @example "Nazwa testowa"
   */
  name?: string;
  city: LocationDto[];
  /**
   * Powierzchnia nieruchomości od
   * @format double
   * @min 0
   * @example 100
   */
  areaFrom?: number;
  /**
   * Powierzchnia nieruchomości do
   * @format double
   * @min 0
   * @example 200
   */
  areaTo?: number;
  /**
   * Cena za metr kwadratowy od
   * @format double
   * @min 0
   * @example 100
   */
  pricePerSquareMeterFrom?: number;
  /**
   * Cena za metr kwadratowy do
   * @format double
   * @min 0
   * @example 200
   */
  pricePerSquareMeterTo?: number;
  /**
   * Cena nieruchomości od
   * @format double
   * @min 0
   * @example 1000000
   */
  priceFrom?: number;
  /**
   * Cena nieruchomości do
   * @format double
   * @min 0
   * @example 2000000
   */
  priceTo?: number;
  module: Module;
  /**
   * Powiadomienia SMS
   * @example true
   */
  alertSms: boolean;
  /**
   * Powiadomienia Email
   * @example true
   */
  alertEmail: boolean;
  /**
   * Powiadomienia Push
   * @example true
   */
  pushAlert: boolean;
  /**
   * Wartości zerowe
   * @example true
   */
  includeZeroArea: boolean;
  /**
   * Modyfikacje ogłoszenia
   * @example true
   */
  modification: boolean;
  /**
   * Podbicie ogłoszenia
   * @example true
   */
  tossedUp: boolean;
  /**
   * Grupowanie duplikatów
   * @example true
   */
  groupDuplicate: boolean;
  /**
   * Opóźnienie powiadomień
   * @format int32
   */
  notificationsDelay: number;
  /**
   * Data dodania od
   * Czas w formacie LocalDate
   * @format date
   * @example "2023-12-12"
   */
  offerAddedFrom?: string;
  /**
   * Data dodania do
   * Czas w formacie LocalDate
   * @format date
   * @example "2023-12-12"
   */
  offerAddedTo?: string;
  /**
   * Ogłoszenia z liczby dni
   * @format int32
   * @example 7
   */
  daysAmount?: number;
  sortType: SortType;
  /**
   * Pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  wantedKeywords?: string[];
  /**
   * Nie pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  unwantedKeywords?: string[];
  /**
   * Pożądane numery telefonów
   * @example ["123467890"]
   */
  wantedPhoneNumbers?: string[];
  /**
   * Nie pożądane numery telefonów
   * @example ["123467890"]
   */
  unwantedPhoneNumbers?: string[];
  offerFrom?: OfferFromEnum[];
  /**
   * Id filtra
   * @format int64
   */
  id: number;
  /**
   * Liczba pokoi w mieszkaniu od
   * @format int32
   * @min 0
   * @example 1
   */
  numberOfRoomsFrom?: number;
  /**
   * Liczba pokoi w mieszkaniu do
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRoomsTo?: number;
  /**
   * Rok budowy nieruchomości od
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYearFrom?: number;
  /**
   * Rok budowy nieruchomości do
   * @format int32
   * @max 2030
   * @example 2018
   */
  builtYearTo?: number;
  typeOfMarket?: TypeOfMarketEnum[];
  buildingType?: FlatBuildingTypeEnum[];
  /**
   * Piętro na którym jest mieszkanie od
   * @format int32
   * @example 4
   */
  floorFrom?: number;
  /**
   * Piętro na którym jest mieszkanie do
   * @format int32
   * @example 4
   */
  floorTo?: number;
  /**
   * Piętra w budynku od
   * @format int32
   * @example 4
   */
  floorsFrom?: number;
  /**
   * Piętra w budynku do
   * @format int32
   * @example 4
   */
  floorsTo?: number;
}

export interface CustomPage {
  content?: object[];
  pageable?: PageableObject;
  /** @format int32 */
  size?: number;
  /** @format int32 */
  number?: number;
  sort?: SortObject;
  /** @format int32 */
  totalPages?: number;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
}

export interface PageableObject {
  /** @format int64 */
  offset?: number;
  sort?: SortObject;
  paged?: boolean;
  unpaged?: boolean;
  /** @format int32 */
  pageNumber?: number;
  /** @format int32 */
  pageSize?: number;
}

export interface SortObject {
  empty?: boolean;
  unsorted?: boolean;
  sorted?: boolean;
}

/** Informacje o blokadzie */
export type BlockageInfoDto = {
  /**
   * Id oryginalnego ogłoszenia zablokowanego
   * Id oryginalnego ogłoszenia mogą się zmienić podczas działania aplikacji
   * @format int64
   * @example 1
   */
  originalAdvertisementId: number;
  module: Module;
  /**
   * Czas blokady w dniach
   * @format int32
   * @example 14
   */
  days: number;
  /**
   * Data blokady
   * @format date-time
   */
  blockedDate: string;
  /**
   * Nazwa użytkownika blokującego ogłoszenie
   * @example "Jan Kowalski"
   */
  userName: string;
  /**
   * Czy blokada jest stała
   * @example true
   */
  permanentlyBlocked: boolean;
} | null;

/** Moja opinia */
export interface MyOpinion {
  /**
   * Id opinii
   * @format int64
   */
  id: number;
  /**
   * Data dodania
   * @format date-time
   */
  dateAdded: string;
  /**
   * Liczba gwiazdek
   * @format int32
   * @example 1
   */
  numberOfStars: number;
  /**
   * Opis opinii
   * @example "xyz"
   */
  content?: string | null;
}

export interface NoteDto {
  /**
   * Id notatki
   * @format int64
   * @example 1
   */
  noteId: number;
  /**
   * Tekst
   * @example "Przykładowy tekst"
   */
  content: string;
  /**
   * Data dodania notatki
   * @format date-time
   */
  date: string;
  /**
   * Czy notatka pochodzi z oryginalnego ogłoszenia
   * @example true
   */
  originalNote: boolean;
  /**
   * Nazwa użytkownika
   * @example "Jan Kowalski"
   */
  userName?: string;
  /**
   * Czy użytkownik jest właścicielem notatki
   * @example true
   */
  noteOwner: boolean;
}

export interface ReactionDto {
  /** Like Status */
  likeStatus?: boolean;
  /** Unlike Status */
  unlikeStatus?: boolean;
  /** Viewed Status */
  viewedStatus?: boolean;
  /** Outdated Status */
  outdatedStatus?: boolean;
  /** Arranged Status */
  arrangedStatus?: boolean;
  /** Phone Status */
  phoneStatus?: boolean;
  /** Hide Status */
  hideStatus?: boolean;
  /** OpenedStatus */
  openedStatus?: boolean;
}

export interface SalePlotDto {
  /**
   * Id ogłoszenia
   * @format int64
   */
  advertisementId: number;
  /**
   * Id zaszyfrowane
   * @example "wdavd==24cd"
   */
  encryptedId: string;
  /** Zdjęcia */
  photos?: string[];
  /** Lokalizacja */
  location: string;
  /**
   * Data dodania
   * Czas w formacie LocalDateTime
   * @format date-time
   */
  dateAdded: string;
  /**
   * Data modyfikacji
   * Czas w formacie LocalDateTime
   * @format date-time
   */
  lastUpdated?: string;
  /**
   * Tytuł
   * @example "Mieszkanie"
   */
  title: string;
  module: Module;
  /**
   * Ocena nieruchomości
   * @format double
   * @example 5
   */
  score: number;
  /**
   * Precyzja oceny
   * Od 0 do 6
   * @format int32
   * @example 2
   */
  scorePrecision: number;
  /**
   * Cena nieruchomości przed modyfikacją
   * @format double
   * @example 2000000
   */
  priceBeforeModification?: number;
  /**
   * Cena nieruchomości
   * @format double
   * @example 100000
   */
  price?: number;
  /**
   * PricePerSquareMeter
   * @format double
   * @example 50
   */
  pricePerSquareMeter?: number;
  /**
   * Powierzchnia
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Numer telefonu
   * @example "1435463364"
   */
  phoneNumber?: string;
  /**
   * Liczba duplikatów
   * @format int32
   * @example 3
   */
  numberOfDuplicates: number;
  /**
   * Liczba modyfikacji
   * @format int32
   * @example 3
   */
  numberOfModifications: number;
  /**
   * Liczba podbić
   * @format int32
   * @example 3
   */
  numberOfRaises: number;
  /**
   * Portal
   * @example "Morizon"
   */
  portal: string;
  /** Link */
  link: string;
  /**
   * W duplikatach znajduje się niższa cena
   * @example true
   */
  duplicateWithLowestPrice: boolean;
  /**
   * W duplikatach znajduje się oferta prywatna
   * @example true
   */
  duplicateWithPrivateOffer: boolean;
  reaction?: ReactionDto;
  note?: NoteDto;
  /**
   * Średnia ocena społeczności
   * @format double
   * @example 3
   */
  communityScore: number;
  /**
   * Liczba komentarzy społeczności
   * @format int32
   * @example 2
   */
  numberOfCommunityComments: number;
  /**
   * Liczba opinii społeczności
   * @format int32
   * @example 1
   */
  numberOfCommunityOpinions: number;
  myOpinion?: MyOpinion;
  /**
   * Liczba wszystkich notatek
   * @format int32
   * @example 1
   */
  numberOfNotes?: number | null;
  blockageInfo?: BlockageInfoDto;
  buildingType?: PlotBuildingTypeEnum;
}

export interface SalePlotDtoPage {
  content?: SalePlotDto[];
  pageable?: PageableObject;
  /** @format int32 */
  size?: number;
  /** @format int32 */
  number?: number;
  sort?: SortObject;
  /** @format int32 */
  totalPages?: number;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
}

export interface SaleOtherDto {
  /**
   * Id ogłoszenia
   * @format int64
   */
  advertisementId: number;
  /**
   * Id zaszyfrowane
   * @example "wdavd==24cd"
   */
  encryptedId: string;
  /** Zdjęcia */
  photos?: string[];
  /** Lokalizacja */
  location: string;
  /**
   * Data dodania
   * Czas w formacie LocalDateTime
   * @format date-time
   */
  dateAdded: string;
  /**
   * Data modyfikacji
   * Czas w formacie LocalDateTime
   * @format date-time
   */
  lastUpdated?: string;
  /**
   * Tytuł
   * @example "Mieszkanie"
   */
  title: string;
  module: Module;
  /**
   * Ocena nieruchomości
   * @format double
   * @example 5
   */
  score: number;
  /**
   * Precyzja oceny
   * Od 0 do 6
   * @format int32
   * @example 2
   */
  scorePrecision: number;
  /**
   * Cena nieruchomości przed modyfikacją
   * @format double
   * @example 2000000
   */
  priceBeforeModification?: number;
  /**
   * Cena nieruchomości
   * @format double
   * @example 100000
   */
  price?: number;
  /**
   * PricePerSquareMeter
   * @format double
   * @example 50
   */
  pricePerSquareMeter?: number;
  /**
   * Powierzchnia
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Numer telefonu
   * @example "1435463364"
   */
  phoneNumber?: string;
  /**
   * Liczba duplikatów
   * @format int32
   * @example 3
   */
  numberOfDuplicates: number;
  /**
   * Liczba modyfikacji
   * @format int32
   * @example 3
   */
  numberOfModifications: number;
  /**
   * Liczba podbić
   * @format int32
   * @example 3
   */
  numberOfRaises: number;
  /**
   * Portal
   * @example "Morizon"
   */
  portal: string;
  /** Link */
  link: string;
  /**
   * W duplikatach znajduje się niższa cena
   * @example true
   */
  duplicateWithLowestPrice: boolean;
  /**
   * W duplikatach znajduje się oferta prywatna
   * @example true
   */
  duplicateWithPrivateOffer: boolean;
  reaction?: ReactionDto;
  note?: NoteDto;
  /**
   * Średnia ocena społeczności
   * @format double
   * @example 3
   */
  communityScore: number;
  /**
   * Liczba komentarzy społeczności
   * @format int32
   * @example 2
   */
  numberOfCommunityComments: number;
  /**
   * Liczba opinii społeczności
   * @format int32
   * @example 1
   */
  numberOfCommunityOpinions: number;
  myOpinion?: MyOpinion;
  /**
   * Liczba wszystkich notatek
   * @format int32
   * @example 1
   */
  numberOfNotes?: number | null;
  blockageInfo?: BlockageInfoDto;
  /**
   * Piętro/Liczba pięter w budynku
   * @example "2/10"
   */
  floor?: string;
  /**
   * Rok budowy
   * @format int32
   * @example 2000
   */
  builtYear?: number;
  buildingType?: OtherBuildingTypeEnum;
}

export interface SaleOtherDtoPage {
  content?: SaleOtherDto[];
  pageable?: PageableObject;
  /** @format int32 */
  size?: number;
  /** @format int32 */
  number?: number;
  sort?: SortObject;
  /** @format int32 */
  totalPages?: number;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
}

export interface SaleHouseDto {
  /**
   * Id ogłoszenia
   * @format int64
   */
  advertisementId: number;
  /**
   * Id zaszyfrowane
   * @example "wdavd==24cd"
   */
  encryptedId: string;
  /** Zdjęcia */
  photos?: string[];
  /** Lokalizacja */
  location: string;
  /**
   * Data dodania
   * Czas w formacie LocalDateTime
   * @format date-time
   */
  dateAdded: string;
  /**
   * Data modyfikacji
   * Czas w formacie LocalDateTime
   * @format date-time
   */
  lastUpdated?: string;
  /**
   * Tytuł
   * @example "Mieszkanie"
   */
  title: string;
  module: Module;
  /**
   * Ocena nieruchomości
   * @format double
   * @example 5
   */
  score: number;
  /**
   * Precyzja oceny
   * Od 0 do 6
   * @format int32
   * @example 2
   */
  scorePrecision: number;
  /**
   * Cena nieruchomości przed modyfikacją
   * @format double
   * @example 2000000
   */
  priceBeforeModification?: number;
  /**
   * Cena nieruchomości
   * @format double
   * @example 100000
   */
  price?: number;
  /**
   * PricePerSquareMeter
   * @format double
   * @example 50
   */
  pricePerSquareMeter?: number;
  /**
   * Powierzchnia
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Numer telefonu
   * @example "1435463364"
   */
  phoneNumber?: string;
  /**
   * Liczba duplikatów
   * @format int32
   * @example 3
   */
  numberOfDuplicates: number;
  /**
   * Liczba modyfikacji
   * @format int32
   * @example 3
   */
  numberOfModifications: number;
  /**
   * Liczba podbić
   * @format int32
   * @example 3
   */
  numberOfRaises: number;
  /**
   * Portal
   * @example "Morizon"
   */
  portal: string;
  /** Link */
  link: string;
  /**
   * W duplikatach znajduje się niższa cena
   * @example true
   */
  duplicateWithLowestPrice: boolean;
  /**
   * W duplikatach znajduje się oferta prywatna
   * @example true
   */
  duplicateWithPrivateOffer: boolean;
  reaction?: ReactionDto;
  note?: NoteDto;
  /**
   * Średnia ocena społeczności
   * @format double
   * @example 3
   */
  communityScore: number;
  /**
   * Liczba komentarzy społeczności
   * @format int32
   * @example 2
   */
  numberOfCommunityComments: number;
  /**
   * Liczba opinii społeczności
   * @format int32
   * @example 1
   */
  numberOfCommunityOpinions: number;
  myOpinion?: MyOpinion;
  /**
   * Liczba wszystkich notatek
   * @format int32
   * @example 1
   */
  numberOfNotes?: number | null;
  blockageInfo?: BlockageInfoDto;
  /**
   * Liczba pokoi
   * @format int32
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Liczba pięter w budynku
   * @example "10"
   */
  floor?: string;
  /**
   * Rok budowy
   * @format int32
   * @example 2000
   */
  builtYear?: number;
  buildingType?: HouseBuildingTypeEnum;
  offerFrom?: OfferFromEnum;
  typeOfMarket?: TypeOfMarketEnum;
}

export interface SaleHouseDtoPage {
  content?: SaleHouseDto[];
  pageable?: PageableObject;
  /** @format int32 */
  size?: number;
  /** @format int32 */
  number?: number;
  sort?: SortObject;
  /** @format int32 */
  totalPages?: number;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
}

export interface SaleFlatDto {
  /**
   * Id ogłoszenia
   * @format int64
   */
  advertisementId: number;
  /**
   * Id zaszyfrowane
   * @example "wdavd==24cd"
   */
  encryptedId: string;
  /** Zdjęcia */
  photos?: string[];
  /** Lokalizacja */
  location: string;
  /**
   * Data dodania
   * Czas w formacie LocalDateTime
   * @format date-time
   */
  dateAdded: string;
  /**
   * Data modyfikacji
   * Czas w formacie LocalDateTime
   * @format date-time
   */
  lastUpdated?: string;
  /**
   * Tytuł
   * @example "Mieszkanie"
   */
  title: string;
  module: Module;
  /**
   * Ocena nieruchomości
   * @format double
   * @example 5
   */
  score: number;
  /**
   * Precyzja oceny
   * Od 0 do 6
   * @format int32
   * @example 2
   */
  scorePrecision: number;
  /**
   * Cena nieruchomości przed modyfikacją
   * @format double
   * @example 2000000
   */
  priceBeforeModification?: number;
  /**
   * Cena nieruchomości
   * @format double
   * @example 100000
   */
  price?: number;
  /**
   * PricePerSquareMeter
   * @format double
   * @example 50
   */
  pricePerSquareMeter?: number;
  /**
   * Powierzchnia
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Numer telefonu
   * @example "1435463364"
   */
  phoneNumber?: string;
  /**
   * Liczba duplikatów
   * @format int32
   * @example 3
   */
  numberOfDuplicates: number;
  /**
   * Liczba modyfikacji
   * @format int32
   * @example 3
   */
  numberOfModifications: number;
  /**
   * Liczba podbić
   * @format int32
   * @example 3
   */
  numberOfRaises: number;
  /**
   * Portal
   * @example "Morizon"
   */
  portal: string;
  /** Link */
  link: string;
  /**
   * W duplikatach znajduje się niższa cena
   * @example true
   */
  duplicateWithLowestPrice: boolean;
  /**
   * W duplikatach znajduje się oferta prywatna
   * @example true
   */
  duplicateWithPrivateOffer: boolean;
  reaction?: ReactionDto;
  note?: NoteDto;
  /**
   * Średnia ocena społeczności
   * @format double
   * @example 3
   */
  communityScore: number;
  /**
   * Liczba komentarzy społeczności
   * @format int32
   * @example 2
   */
  numberOfCommunityComments: number;
  /**
   * Liczba opinii społeczności
   * @format int32
   * @example 1
   */
  numberOfCommunityOpinions: number;
  myOpinion?: MyOpinion;
  /**
   * Liczba wszystkich notatek
   * @format int32
   * @example 1
   */
  numberOfNotes?: number | null;
  blockageInfo?: BlockageInfoDto;
  /**
   * Liczba pokoi
   * @format int32
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Piętro/Liczba pięter w budynku
   * @example "2/10"
   */
  floor?: string;
  /**
   * Rok budowy
   * @format int32
   * @example 2000
   */
  builtYear?: number;
  buildingType?: FlatBuildingTypeEnum;
  offerFrom?: OfferFromEnum;
  typeOfMarket?: TypeOfMarketEnum;
}

export interface SaleFlatDtoPage {
  content?: SaleFlatDto[];
  pageable?: PageableObject;
  /** @format int32 */
  size?: number;
  /** @format int32 */
  number?: number;
  sort?: SortObject;
  /** @format int32 */
  totalPages?: number;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
}

export interface MetaValueDto {
  /** Klucz */
  key: string;
  /** Wartość */
  value?: string;
}

export interface ModificationDateDto {
  /**
   * Data modyfikacji
   * @format date-time
   */
  date: string;
  modificationDetail?: ModificationDto[];
}

export interface ModificationDto {
  /** Zmodyfikowane pole */
  field: string;
  /** Nowa wartość modyfikacji */
  newValue?: string;
  /** Stara wartość modyfikacji */
  oldValue?: string;
}

export interface SaleHashDto {
  /**
   * Id ogłoszenia
   * @format int64
   */
  advertisementId: number;
  /**
   * Id zaszyfrowane
   * @example "wdavd==24cd"
   */
  encryptedId: string;
  /** Zdjęcia */
  photos?: string[];
  /** Lokalizacja */
  location: string;
  /**
   * Data dodania
   * Czas w formacie LocalDateTime
   * @format date-time
   */
  dateAdded: string;
  /**
   * Data modyfikacji
   * Czas w formacie LocalDateTime
   * @format date-time
   */
  lastUpdated?: string;
  /**
   * Tytuł
   * @example "Mieszkanie"
   */
  title: string;
  /**
   * Opis
   * @example "Mieszkanie"
   */
  description: string;
  module: Module;
  /**
   * Ocena nieruchomości
   * @format double
   * @example 5
   */
  score: number;
  /**
   * Precyzja oceny
   * Od 0 do 6
   * @format int32
   * @example 2
   */
  scorePrecision: number;
  /**
   * Cena nieruchomości przed modyfikacją
   * @format double
   * @example 2000000
   */
  priceBeforeModification?: number;
  /**
   * Cena
   * @format double
   * @example 100000
   */
  price?: number;
  /**
   * PricePerSquareMeter
   * @format double
   * @example 50
   */
  pricePerSquareMeter?: number;
  /**
   * Powierzchnia
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Numer telefonu
   * @example "1435463364"
   */
  phoneNumber?: string;
  /**
   * Liczba duplikatów
   * @format int32
   * @example 3
   */
  numberOfDuplicates: number;
  /**
   * Liczba modyfikacji
   * @format int32
   * @example 3
   */
  numberOfModifications: number;
  /**
   * Liczba podbić
   * @format int32
   * @example 3
   */
  numberOfRaises: number;
  /**
   * Portal
   * @example "Morizon"
   */
  portal: string;
  /** Link */
  link: string;
  /**
   * W duplikatach znajduje się niższa cena
   * @example true
   */
  duplicateWithLowestPrice: boolean;
  /**
   * W duplikatach znajduje się oferta prywatna
   * @example true
   */
  duplicateWithPrivateOffer: boolean;
  reaction?: ReactionDto;
  note?: NoteDto[];
  modifications?: ModificationDateDto[];
  metaValue?: MetaValueDto[];
  /**
   * Liczba wszystkich notatek
   * @format int32
   * @example 1
   */
  numberOfNotes?: number;
  /**
   * Średnia ocena społeczności
   * @format double
   * @example 3
   */
  communityScore: number;
  /**
   * Liczba komentarzy społeczności
   * @format int32
   * @example 2
   */
  numberOfCommunityComments: number;
  /**
   * Liczba opinii społeczności
   * @format int32
   * @example 1
   */
  numberOfCommunityOpinions: number;
  myOpinion?: MyOpinion;
  /**
   * Liczba pokoi
   * @format int32
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Piętro/Liczba pięter w budynku
   * @example "2/10"
   */
  floor?: string;
  /**
   * Rok budowy
   * @format int32
   * @example 2000
   */
  builtYear?: number;
  /**
   * Typ budynku
   * @example "Blok"
   */
  buildingType?: string;
  offerFrom?: OfferFromEnum;
  typeOfMarket?: TypeOfMarketEnum;
}

export interface SaleOpinionResult {
  /** Lista opinii ogłoszenia */
  opinions?: SingleSaleOpinion[];
  /**
   * Ocena społeczności
   * @format double
   * @example 4.2
   */
  communityScore: number;
  /**
   * Precyzja oceny przez aplikację
   * @format int32
   * @example 2
   */
  scorePrecision: number;
  /**
   * Ocena aplikacji
   * @format double
   * @example 2.5
   */
  score: number;
}

/** Lista opinii ogłoszenia */
export interface SingleSaleOpinion {
  /**
   * Id opinii
   * @format int64
   * @example 1
   */
  opinionId: number;
  /**
   * Data dodania opini
   * @format date-time
   */
  dateAdded: string;
  /**
   * Opis opinii
   * @example "xyz"
   */
  content?: string | null;
  /**
   * Liczba gwiazdek
   * @format int32
   * @example 2
   */
  numberOfStars: number;
  /**
   * Status opinii
   * Wartość true kiedy ta opinia należy do aktualnie zalogowanego użytkownika
   * @example true
   */
  loggedUserOpinion: boolean;
}

export interface SavedFiltersDto {
  /**
   * Id filtra
   * @format int64
   * @example 1
   */
  id?: number;
  /**
   * Nazwa filtra
   * @example "Nazwa testowa"
   */
  name?: string;
  module?: Module;
  /**
   * Powiadomienia SMS
   * @example true
   */
  alertSms?: boolean;
  /**
   * Powiadomienia Email
   * @example true
   */
  alertEmail?: boolean;
  /**
   * Powiadomienia Push
   * @example true
   */
  pushAlert?: boolean;
}

export interface DuplicatePlotDto {
  /**
   * Id ogłoszenia
   * @format int64
   */
  advertisementId: number;
  /** Zdjęcia */
  photos?: string[];
  /** Lokalizacja */
  location: string;
  /**
   * Data dodania
   * Czas w formacie LocalDateTime
   * @format date-time
   */
  dateAdded: string;
  /**
   * Data modyfikacji
   * Czas w formacie LocalDateTime
   * @format date-time
   */
  lastUpdated?: string;
  /**
   * Tytuł
   * @example "Mieszkanie"
   */
  title: string;
  module?: Module;
  /**
   * Ocena nieruchomości
   * @format double
   * @example 5
   */
  score: number;
  /**
   * Cena nieruchomości przed modyfikacją
   * @format double
   * @example 2000000
   */
  priceBeforeModification?: number;
  /**
   * Cena nieruchomości
   * @format double
   * @example 100000
   */
  price?: number;
  /**
   * Powierzchnia
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Numer telefonu
   * @example "143546334"
   */
  phoneNumber?: string;
  /**
   * Liczba modyfikacji
   * @format int32
   * @example 3
   */
  numberOfModifications?: number;
  /**
   * Liczba podbić
   * @format int32
   * @example 3
   */
  numberOfRaises?: number;
  /**
   * Portal
   * @example "Morizon"
   */
  portal: string;
  /** Link */
  link: string;
  reaction?: ReactionDto;
  note?: NoteDto;
  buildingType?: PlotBuildingTypeEnum;
}

export interface DuplicateOtherDto {
  /**
   * Id ogłoszenia
   * @format int64
   */
  advertisementId: number;
  /** Zdjęcia */
  photos?: string[];
  /** Lokalizacja */
  location: string;
  /**
   * Data dodania
   * Czas w formacie LocalDateTime
   * @format date-time
   */
  dateAdded: string;
  /**
   * Data modyfikacji
   * Czas w formacie LocalDateTime
   * @format date-time
   */
  lastUpdated?: string;
  /**
   * Tytuł
   * @example "Mieszkanie"
   */
  title: string;
  module?: Module;
  /**
   * Ocena nieruchomości
   * @format double
   * @example 5
   */
  score: number;
  /**
   * Cena nieruchomości przed modyfikacją
   * @format double
   * @example 2000000
   */
  priceBeforeModification?: number;
  /**
   * Cena nieruchomości
   * @format double
   * @example 100000
   */
  price?: number;
  /**
   * Powierzchnia
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Numer telefonu
   * @example "143546334"
   */
  phoneNumber?: string;
  /**
   * Liczba modyfikacji
   * @format int32
   * @example 3
   */
  numberOfModifications?: number;
  /**
   * Liczba podbić
   * @format int32
   * @example 3
   */
  numberOfRaises?: number;
  /**
   * Portal
   * @example "Morizon"
   */
  portal: string;
  /** Link */
  link: string;
  reaction?: ReactionDto;
  note?: NoteDto;
  /**
   * Piętro/Liczba pięter w budynku
   * @example "2/10"
   */
  floor?: string;
  /**
   * Rok budowy
   * @format int32
   * @example 2000
   */
  builtYear?: number;
  buildingType?: OtherBuildingTypeEnum;
}

export interface DuplicateHouseDto {
  /**
   * Id ogłoszenia
   * @format int64
   */
  advertisementId: number;
  /** Zdjęcia */
  photos?: string[];
  /** Lokalizacja */
  location: string;
  /**
   * Data dodania
   * Czas w formacie LocalDateTime
   * @format date-time
   */
  dateAdded: string;
  /**
   * Data modyfikacji
   * Czas w formacie LocalDateTime
   * @format date-time
   */
  lastUpdated?: string;
  /**
   * Tytuł
   * @example "Mieszkanie"
   */
  title: string;
  module?: Module;
  /**
   * Ocena nieruchomości
   * @format double
   * @example 5
   */
  score: number;
  /**
   * Cena nieruchomości przed modyfikacją
   * @format double
   * @example 2000000
   */
  priceBeforeModification?: number;
  /**
   * Cena nieruchomości
   * @format double
   * @example 100000
   */
  price?: number;
  /**
   * Powierzchnia
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Numer telefonu
   * @example "143546334"
   */
  phoneNumber?: string;
  /**
   * Liczba modyfikacji
   * @format int32
   * @example 3
   */
  numberOfModifications?: number;
  /**
   * Liczba podbić
   * @format int32
   * @example 3
   */
  numberOfRaises?: number;
  /**
   * Portal
   * @example "Morizon"
   */
  portal: string;
  /** Link */
  link: string;
  reaction?: ReactionDto;
  note?: NoteDto;
  /**
   * Liczba pokoi
   * @format int32
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Liczba pięter w budynku
   * @example "10"
   */
  floor?: string;
  /**
   * Rok budowy
   * @format int32
   * @example 2000
   */
  builtYear?: number;
  buildingType?: HouseBuildingTypeEnum;
  offerFrom?: OfferFromEnum;
  typeOfMarket?: TypeOfMarketEnum;
}

export interface DuplicateFlatDto {
  /**
   * Id ogłoszenia
   * @format int64
   */
  advertisementId: number;
  /** Zdjęcia */
  photos?: string[];
  /** Lokalizacja */
  location: string;
  /**
   * Data dodania
   * Czas w formacie LocalDateTime
   * @format date-time
   */
  dateAdded: string;
  /**
   * Data modyfikacji
   * Czas w formacie LocalDateTime
   * @format date-time
   */
  lastUpdated?: string;
  /**
   * Tytuł
   * @example "Mieszkanie"
   */
  title: string;
  module?: Module;
  /**
   * Ocena nieruchomości
   * @format double
   * @example 5
   */
  score: number;
  /**
   * Cena nieruchomości przed modyfikacją
   * @format double
   * @example 2000000
   */
  priceBeforeModification?: number;
  /**
   * Cena nieruchomości
   * @format double
   * @example 100000
   */
  price?: number;
  /**
   * Powierzchnia
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Numer telefonu
   * @example "143546334"
   */
  phoneNumber?: string;
  /**
   * Liczba modyfikacji
   * @format int32
   * @example 3
   */
  numberOfModifications?: number;
  /**
   * Liczba podbić
   * @format int32
   * @example 3
   */
  numberOfRaises?: number;
  /**
   * Portal
   * @example "Morizon"
   */
  portal: string;
  /** Link */
  link: string;
  reaction?: ReactionDto;
  note?: NoteDto;
  /**
   * Liczba pokoi
   * @format int32
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Piętro/Liczba pięter w budynku
   * @example "2/10"
   */
  floor?: string;
  /**
   * Rok budowy
   * @format int32
   * @example 2000
   */
  builtYear?: number;
  buildingType?: FlatBuildingTypeEnum;
  offerFrom?: OfferFromEnum;
  typeOfMarket?: TypeOfMarketEnum;
}

export interface SalePlotDetailDto {
  /**
   * Id ogłoszenia
   * @format int64
   */
  advertisementId: number;
  /**
   * Id zaszyfrowane
   * @example "wdavd==24cd"
   */
  encryptedId: string;
  /** Zdjęcie główne */
  photo?: string;
  /** Lokalizacja */
  location: string;
  /**
   * Data dodania
   * Czas w formacie LocalDateTime
   * @format date-time
   */
  dateAdded: string;
  /**
   * Data modyfikacji
   * Czas w formacie LocalDateTime
   * @format date-time
   */
  modificationDate?: string;
  /**
   * Tytuł
   * @example "Mieszkanie"
   */
  title: string;
  /**
   * Opis
   * @example "Mieszkanie"
   */
  description: string;
  module: Module;
  /**
   * Ocena nieruchomości
   * @format double
   * @example 5
   */
  score: number;
  /**
   * Precyzja oceny
   * Od 0 do 6
   * @format int32
   * @example 2
   */
  scorePrecision: number;
  /**
   * Cena nieruchomości przed modyfikacją
   * @format double
   * @example 2000000
   */
  priceBeforeModification?: number;
  /**
   * Cena
   * @format double
   * @example 100000
   */
  price?: number;
  /**
   * PricePerSquareMeter
   * @format double
   * @example 50
   */
  pricePerSquareMeter?: number;
  /**
   * Powierzchnia
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Numer telefonu
   * @example "1435463364"
   */
  phoneNumber?: string;
  /**
   * Liczba duplikatów
   * @format int32
   * @example 3
   */
  numberOfDuplicates: number;
  /**
   * Liczba modyfikacji
   * @format int32
   * @example 3
   */
  numberOfModifications: number;
  /**
   * Liczba podbić
   * @format int32
   * @example 3
   */
  numberOfRaises: number;
  /**
   * Portal
   * @example "Morizon"
   */
  portal: string;
  /** Link */
  link: string;
  /**
   * W duplikatach znajduje się niższa cena
   * @example true
   */
  duplicateWithLowestPrice: boolean;
  /**
   * W duplikatach znajduje się oferta prywatna
   * @example true
   */
  duplicateWithPrivateOffer: boolean;
  reaction?: ReactionDto;
  note?: NoteDto[];
  modifications?: ModificationDateDto[];
  metaValue?: MetaValueDto[];
  buildingType?: PlotBuildingTypeEnum;
}

export interface SaleOtherDetailDto {
  /**
   * Id ogłoszenia
   * @format int64
   */
  advertisementId: number;
  /**
   * Id zaszyfrowane
   * @example "wdavd==24cd"
   */
  encryptedId: string;
  /** Zdjęcie główne */
  photo?: string;
  /** Lokalizacja */
  location: string;
  /**
   * Data dodania
   * Czas w formacie LocalDateTime
   * @format date-time
   */
  dateAdded: string;
  /**
   * Data modyfikacji
   * Czas w formacie LocalDateTime
   * @format date-time
   */
  modificationDate?: string;
  /**
   * Tytuł
   * @example "Mieszkanie"
   */
  title: string;
  /**
   * Opis
   * @example "Mieszkanie"
   */
  description: string;
  module: Module;
  /**
   * Ocena nieruchomości
   * @format double
   * @example 5
   */
  score: number;
  /**
   * Precyzja oceny
   * Od 0 do 6
   * @format int32
   * @example 2
   */
  scorePrecision: number;
  /**
   * Cena nieruchomości przed modyfikacją
   * @format double
   * @example 2000000
   */
  priceBeforeModification?: number;
  /**
   * Cena
   * @format double
   * @example 100000
   */
  price?: number;
  /**
   * PricePerSquareMeter
   * @format double
   * @example 50
   */
  pricePerSquareMeter?: number;
  /**
   * Powierzchnia
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Numer telefonu
   * @example "1435463364"
   */
  phoneNumber?: string;
  /**
   * Liczba duplikatów
   * @format int32
   * @example 3
   */
  numberOfDuplicates: number;
  /**
   * Liczba modyfikacji
   * @format int32
   * @example 3
   */
  numberOfModifications: number;
  /**
   * Liczba podbić
   * @format int32
   * @example 3
   */
  numberOfRaises: number;
  /**
   * Portal
   * @example "Morizon"
   */
  portal: string;
  /** Link */
  link: string;
  /**
   * W duplikatach znajduje się niższa cena
   * @example true
   */
  duplicateWithLowestPrice: boolean;
  /**
   * W duplikatach znajduje się oferta prywatna
   * @example true
   */
  duplicateWithPrivateOffer: boolean;
  reaction?: ReactionDto;
  note?: NoteDto[];
  modifications?: ModificationDateDto[];
  metaValue?: MetaValueDto[];
  /**
   * Piętro/Liczba pięter w budynku
   * @example "2/10"
   */
  floor?: string;
  /**
   * Rok budowy
   * @format int32
   * @example 2000
   */
  builtYear?: number;
  buildingType?: OtherBuildingTypeEnum;
}

export interface SaleHouseDetailDto {
  /**
   * Id ogłoszenia
   * @format int64
   */
  advertisementId: number;
  /**
   * Id zaszyfrowane
   * @example "wdavd==24cd"
   */
  encryptedId: string;
  /** Zdjęcie główne */
  photo?: string;
  /** Lokalizacja */
  location: string;
  /**
   * Data dodania
   * Czas w formacie LocalDateTime
   * @format date-time
   */
  dateAdded: string;
  /**
   * Data modyfikacji
   * Czas w formacie LocalDateTime
   * @format date-time
   */
  modificationDate?: string;
  /**
   * Tytuł
   * @example "Mieszkanie"
   */
  title: string;
  /**
   * Opis
   * @example "Mieszkanie"
   */
  description: string;
  module: Module;
  /**
   * Ocena nieruchomości
   * @format double
   * @example 5
   */
  score: number;
  /**
   * Precyzja oceny
   * Od 0 do 6
   * @format int32
   * @example 2
   */
  scorePrecision: number;
  /**
   * Cena nieruchomości przed modyfikacją
   * @format double
   * @example 2000000
   */
  priceBeforeModification?: number;
  /**
   * Cena
   * @format double
   * @example 100000
   */
  price?: number;
  /**
   * PricePerSquareMeter
   * @format double
   * @example 50
   */
  pricePerSquareMeter?: number;
  /**
   * Powierzchnia
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Numer telefonu
   * @example "1435463364"
   */
  phoneNumber?: string;
  /**
   * Liczba duplikatów
   * @format int32
   * @example 3
   */
  numberOfDuplicates: number;
  /**
   * Liczba modyfikacji
   * @format int32
   * @example 3
   */
  numberOfModifications: number;
  /**
   * Liczba podbić
   * @format int32
   * @example 3
   */
  numberOfRaises: number;
  /**
   * Portal
   * @example "Morizon"
   */
  portal: string;
  /** Link */
  link: string;
  /**
   * W duplikatach znajduje się niższa cena
   * @example true
   */
  duplicateWithLowestPrice: boolean;
  /**
   * W duplikatach znajduje się oferta prywatna
   * @example true
   */
  duplicateWithPrivateOffer: boolean;
  reaction?: ReactionDto;
  note?: NoteDto[];
  modifications?: ModificationDateDto[];
  metaValue?: MetaValueDto[];
  /**
   * Liczba pokoi
   * @format int32
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Liczba pięter w budynku
   * @example "10"
   */
  floor?: string;
  /**
   * Rok budowy
   * @format int32
   * @example 2000
   */
  builtYear?: number;
  buildingType?: HouseBuildingTypeEnum;
  offerFrom?: OfferFromEnum;
  typeOfMarket?: TypeOfMarketEnum;
}

export interface SaleFlatDetailDto {
  /**
   * Id ogłoszenia
   * @format int64
   */
  advertisementId: number;
  /**
   * Id zaszyfrowane
   * @example "wdavd==24cd"
   */
  encryptedId: string;
  /** Zdjęcie główne */
  photo?: string;
  /** Lokalizacja */
  location: string;
  /**
   * Data dodania
   * Czas w formacie LocalDateTime
   * @format date-time
   */
  dateAdded: string;
  /**
   * Data modyfikacji
   * Czas w formacie LocalDateTime
   * @format date-time
   */
  modificationDate?: string;
  /**
   * Tytuł
   * @example "Mieszkanie"
   */
  title: string;
  /**
   * Opis
   * @example "Mieszkanie"
   */
  description: string;
  module: Module;
  /**
   * Ocena nieruchomości
   * @format double
   * @example 5
   */
  score: number;
  /**
   * Precyzja oceny
   * Od 0 do 6
   * @format int32
   * @example 2
   */
  scorePrecision: number;
  /**
   * Cena nieruchomości przed modyfikacją
   * @format double
   * @example 2000000
   */
  priceBeforeModification?: number;
  /**
   * Cena
   * @format double
   * @example 100000
   */
  price?: number;
  /**
   * PricePerSquareMeter
   * @format double
   * @example 50
   */
  pricePerSquareMeter?: number;
  /**
   * Powierzchnia
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Numer telefonu
   * @example "1435463364"
   */
  phoneNumber?: string;
  /**
   * Liczba duplikatów
   * @format int32
   * @example 3
   */
  numberOfDuplicates: number;
  /**
   * Liczba modyfikacji
   * @format int32
   * @example 3
   */
  numberOfModifications: number;
  /**
   * Liczba podbić
   * @format int32
   * @example 3
   */
  numberOfRaises: number;
  /**
   * Portal
   * @example "Morizon"
   */
  portal: string;
  /** Link */
  link: string;
  /**
   * W duplikatach znajduje się niższa cena
   * @example true
   */
  duplicateWithLowestPrice: boolean;
  /**
   * W duplikatach znajduje się oferta prywatna
   * @example true
   */
  duplicateWithPrivateOffer: boolean;
  reaction?: ReactionDto;
  note?: NoteDto[];
  modifications?: ModificationDateDto[];
  metaValue?: MetaValueDto[];
  /**
   * Liczba pokoi
   * @format int32
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Piętro/Liczba pięter w budynku
   * @example "2/10"
   */
  floor?: string;
  /**
   * Rok budowy
   * @format int32
   * @example 2000
   */
  builtYear?: number;
  buildingType?: FlatBuildingTypeEnum;
  offerFrom?: OfferFromEnum;
  typeOfMarket?: TypeOfMarketEnum;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "http://192.168.31.12:8080";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title OpenAPI definition
 * @version v0
 * @baseUrl http://192.168.31.12:8080
 */
export class Api<SecurityDataType extends unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  saleOffer = {
    /**
     * No description
     *
     * @tags Tworzenie opinii
     * @name Update
     * @summary Aktualizacja opinii użytkownika
     * @request PUT:/sale-offer/opinion/update
     * @secure
     */
    update: (
      query: {
        /** Moduł, typ nieruchomości */
        module: Module;
      },
      data: string,
      params: RequestParams = {},
    ) =>
      this.http.request<OpinionUpdateInformation, object | string>({
        path: `/sale-offer/opinion/update`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami
     * @name PlotsFilterDetail
     * @summary Edycja filtra działki
     * @request PUT:/sale-offer/filter/edit/plot
     * @secure
     */
    plotsFilterDetail: (data: PlotsFilterDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/sale-offer/filter/edit/plot`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami
     * @name OtherFilterDetail
     * @summary Edycja filtra pozostałych typów ogłoszeń
     * @request PUT:/sale-offer/filter/edit/other
     * @secure
     */
    otherFilterDetail: (data: OtherFilterDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/sale-offer/filter/edit/other`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami
     * @name HousesFilterDetail
     * @summary Edycja filtra domu
     * @request PUT:/sale-offer/filter/edit/house
     * @secure
     */
    housesFilterDetail: (data: HousesFilterDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/sale-offer/filter/edit/house`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami
     * @name FlatsFilterDetail
     * @summary Edycja filtra mieszkania
     * @request PUT:/sale-offer/filter/edit/flat
     * @secure
     */
    flatsFilterDetail: (data: FlatsFilterDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/sale-offer/filter/edit/flat`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tworzenie opinii
     * @name Create
     * @summary Tworzenie nowej opinii
     * @request POST:/sale-offer/opinion/create
     * @secure
     */
    create: (
      query: {
        /** Moduł, typ nieruchomości */
        module: Module;
      },
      data: string,
      params: RequestParams = {},
    ) =>
      this.http.request<OpinionUpdateInformation, object | string>({
        path: `/sale-offer/opinion/create`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami
     * @name CreateNewFilter
     * @summary Dodanie filtra działki
     * @request POST:/sale-offer/filter/add/plot
     * @secure
     */
    createNewFilter: (data: PlotsFilterDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/sale-offer/filter/add/plot`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami
     * @name CreateNewFilter1
     * @summary Dodanie filtra pozostałych typów ogłoszenia
     * @request POST:/sale-offer/filter/add/other
     * @secure
     */
    createNewFilter1: (data: OtherFilterDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/sale-offer/filter/add/other`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami
     * @name CreateNewFilter2
     * @summary Dodanie filtra domu
     * @request POST:/sale-offer/filter/add/house
     * @secure
     */
    createNewFilter2: (data: HousesFilterDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/sale-offer/filter/add/house`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami
     * @name CreateNewFilter3
     * @summary Dodanie filtra mieszkania
     * @request POST:/sale-offer/filter/add/flat
     * @secure
     */
    createNewFilter3: (data: FlatsFilterDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/sale-offer/filter/add/flat`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tworzenie excel-a do ogłoszeń sprzedaży
     * @name DownloadPlotsExcel
     * @summary Pobieranie pliku excel działek
     * @request POST:/sale-offer/excel/plot
     * @secure
     */
    downloadPlotsExcel: (data: PlotsFilterDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/sale-offer/excel/plot`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tworzenie excel-a do ogłoszeń sprzedaży
     * @name DownloadOtherExcel
     * @summary Pobieranie pliku excel pozostałych
     * @request POST:/sale-offer/excel/other
     * @secure
     */
    downloadOtherExcel: (data: OtherFilterDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/sale-offer/excel/other`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tworzenie excel-a do ogłoszeń sprzedaży
     * @name DownloadHousesExcel
     * @summary Pobieranie pliku excel domów
     * @request POST:/sale-offer/excel/house
     * @secure
     */
    downloadHousesExcel: (data: HousesFilterDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/sale-offer/excel/house`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tworzenie excel-a do ogłoszeń sprzedaży
     * @name DownloadFlatExcel
     * @summary Pobieranie pliku excel mieszkań
     * @request POST:/sale-offer/excel/flat
     * @secure
     */
    downloadFlatExcel: (data: FlatsFilterDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/sale-offer/excel/flat`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wyszukiwanie ogłoszeń i ich szczegółów
     * @name DownloadPlotsAdvertisements
     * @summary Pobieranie ogłoszeń działek
     * @request POST:/sale-offer/advertisement/plot
     * @secure
     */
    downloadPlotsAdvertisements: (
      query: {
        /** @format int32 */
        size: number;
        /** @format int32 */
        page: number;
      },
      data: PlotsFilterDto,
      params: RequestParams = {},
    ) =>
      this.http.request<SalePlotDtoPage, object>({
        path: `/sale-offer/advertisement/plot`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wyszukiwanie ogłoszeń i ich szczegółów
     * @name DownloadOtherAdvertisements
     * @summary Pobieranie ogłoszeń pozostałych
     * @request POST:/sale-offer/advertisement/other
     * @secure
     */
    downloadOtherAdvertisements: (
      query: {
        /** @format int32 */
        size: number;
        /** @format int32 */
        page: number;
      },
      data: OtherFilterDto,
      params: RequestParams = {},
    ) =>
      this.http.request<SaleOtherDtoPage, object>({
        path: `/sale-offer/advertisement/other`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wyszukiwanie ogłoszeń i ich szczegółów
     * @name DownloadHousesAdvertisements
     * @summary Pobieranie ogłoszeń domów
     * @request POST:/sale-offer/advertisement/house
     * @secure
     */
    downloadHousesAdvertisements: (
      query: {
        /** @format int32 */
        size: number;
        /** @format int32 */
        page: number;
      },
      data: HousesFilterDto,
      params: RequestParams = {},
    ) =>
      this.http.request<SaleHouseDtoPage, object>({
        path: `/sale-offer/advertisement/house`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wyszukiwanie ogłoszeń i ich szczegółów
     * @name DownloadFlatAdvertisements
     * @summary Pobieranie ogłoszeń mieszkań
     * @request POST:/sale-offer/advertisement/flat
     * @secure
     */
    downloadFlatAdvertisements: (
      query: {
        /** @format int32 */
        size: number;
        /** @format int32 */
        page: number;
      },
      data: FlatsFilterDto,
      params: RequestParams = {},
    ) =>
      this.http.request<SaleFlatDtoPage, object>({
        path: `/sale-offer/advertisement/flat`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wyszukiwanie szczegółów ogłoszeń - hash
     * @name FullHashAdvertisement
     * @summary Ogłoszenie mieszkań wraz z wszystkimi danymi wyszukiwane po hash
     * @request GET:/sale-offer/single-advertisement/detail
     * @secure
     */
    fullHashAdvertisement: (
      query: {
        hash: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SaleHashDto, object>({
        path: `/sale-offer/single-advertisement/detail`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tworzenie opinii
     * @name FindAllByRent
     * @summary Wyszukiwanie opinii ogłoszenia
     * @request GET:/sale-offer/opinion/find
     * @secure
     */
    findAllByRent: (
      query: {
        /** Moduł, typ nieruchomości */
        module: Module;
        /**
         * Id ogłoszenia
         * @format int64
         */
        advertisementId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SaleOpinionResult, string>({
        path: `/sale-offer/opinion/find`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami
     * @name FindUserFilters
     * @summary Lista wszystkich filtrów użytkownika
     * @request GET:/sale-offer/filter/list
     * @secure
     */
    findUserFilters: (
      query?: {
        module?: Module;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SavedFiltersDto[], object>({
        path: `/sale-offer/filter/list`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami
     * @name PlotsFilterDetail1
     * @summary Szczegóły filtra działki
     * @request GET:/sale-offer/filter/detail/plot
     * @secure
     */
    plotsFilterDetail1: (
      query: {
        /** @format int64 */
        id: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PlotsFilterDto, object>({
        path: `/sale-offer/filter/detail/plot`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami
     * @name OtherFilterDetail1
     * @summary Szczegóły filtra pozostałych
     * @request GET:/sale-offer/filter/detail/other
     * @secure
     */
    otherFilterDetail1: (
      query: {
        /** @format int64 */
        id: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<OtherFilterDto, object>({
        path: `/sale-offer/filter/detail/other`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami
     * @name HouseFilterDetail
     * @summary Szczegóły filtra domu
     * @request GET:/sale-offer/filter/detail/house
     * @secure
     */
    houseFilterDetail: (
      query: {
        /** @format int64 */
        id: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<HousesFilterDto, object>({
        path: `/sale-offer/filter/detail/house`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami
     * @name FlatsFilterDetail1
     * @summary Szczegóły filtra mieszkań
     * @request GET:/sale-offer/filter/detail/flat
     * @secure
     */
    flatsFilterDetail1: (
      query: {
        /** @format int64 */
        id: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<FlatsFilterDto, object>({
        path: `/sale-offer/filter/detail/flat`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wyszukiwanie duplikatów ogłoszeń
     * @name FindPlotsDuplicate
     * @summary Wszystkie duplikaty duplikatów działek
     * @request GET:/sale-offer/duplicate/find/plot
     * @secure
     */
    findPlotsDuplicate: (
      query: {
        /** @format int64 */
        advertisementId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<DuplicatePlotDto, object>({
        path: `/sale-offer/duplicate/find/plot`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wyszukiwanie duplikatów ogłoszeń
     * @name FindOtherDuplicate
     * @summary Wszystkie duplikaty duplikatów pozostałych
     * @request GET:/sale-offer/duplicate/find/other
     * @secure
     */
    findOtherDuplicate: (
      query: {
        /** @format int64 */
        advertisementId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<DuplicateOtherDto, object>({
        path: `/sale-offer/duplicate/find/other`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wyszukiwanie duplikatów ogłoszeń
     * @name FindHousesDuplicate
     * @summary Wszystkie duplikaty duplikatów domów
     * @request GET:/sale-offer/duplicate/find/house
     * @secure
     */
    findHousesDuplicate: (
      query: {
        /** @format int64 */
        advertisementId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<DuplicateHouseDto, object>({
        path: `/sale-offer/duplicate/find/house`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wyszukiwanie duplikatów ogłoszeń
     * @name FindFlatsDuplicate
     * @summary Wszystkie duplikaty duplikatów mieszkań
     * @request GET:/sale-offer/duplicate/find/flat
     * @secure
     */
    findFlatsDuplicate: (
      query: {
        /** @format int64 */
        advertisementId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<DuplicateFlatDto, object>({
        path: `/sale-offer/duplicate/find/flat`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wyszukiwanie ogłoszeń i ich szczegółów
     * @name FullPlotAdvertisement
     * @summary Ogłoszenie działek wraz z wszystkimi danymi
     * @request GET:/sale-offer/advertisement/detail/plot
     * @secure
     */
    fullPlotAdvertisement: (
      query: {
        /** @format int64 */
        advertisementId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SalePlotDetailDto, object>({
        path: `/sale-offer/advertisement/detail/plot`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wyszukiwanie ogłoszeń i ich szczegółów
     * @name FullOtherAdvertisement
     * @summary Ogłoszenie pozostałych wraz z wszystkimi danymi
     * @request GET:/sale-offer/advertisement/detail/other
     * @secure
     */
    fullOtherAdvertisement: (
      query: {
        /** @format int64 */
        advertisementId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SaleOtherDetailDto, object>({
        path: `/sale-offer/advertisement/detail/other`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wyszukiwanie ogłoszeń i ich szczegółów
     * @name FullHouseAdvertisement
     * @summary Ogłoszenie domów wraz z wszystkimi danymi
     * @request GET:/sale-offer/advertisement/detail/house
     * @secure
     */
    fullHouseAdvertisement: (
      query: {
        /** @format int64 */
        advertisementId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SaleHouseDetailDto, object>({
        path: `/sale-offer/advertisement/detail/house`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wyszukiwanie ogłoszeń i ich szczegółów
     * @name FullFlatAdvertisement
     * @summary Ogłoszenie mieszkań wraz z wszystkimi danymi
     * @request GET:/sale-offer/advertisement/detail/flat
     * @secure
     */
    fullFlatAdvertisement: (
      query: {
        /** @format int64 */
        advertisementId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SaleFlatDetailDto, object>({
        path: `/sale-offer/advertisement/detail/flat`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tworzenie opinii
     * @name Delete
     * @summary Usuwanie opinii użytkownika
     * @request DELETE:/sale-offer/opinion/delete
     * @secure
     */
    delete: (
      query: {
        module: Module;
        /** @format int64 */
        opinionId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<OpinionUpdateInformation, object | string>({
        path: `/sale-offer/opinion/delete`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description ID - filter id
     *
     * @tags Zarządzanie filtrami
     * @name DeleteFilter
     * @summary Usuwanie filtra
     * @request DELETE:/sale-offer/filter/delete
     * @secure
     */
    deleteFilter: (
      query: {
        module: Module;
        /** @format int64 */
        id: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<object, object>({
        path: `/sale-offer/filter/delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),
  };
}
