import { FC, useState } from 'react';
import React from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import styled from 'styled-components';

import 'dayjs/locale/pl';

const MessagesWrapper = styled.div`
    width: ${(props) => props.fullWidth ? null : '270px'};
    border-radius: var(--box-border-radius);
    box-shadow: ${(props) => props.isTab ? '0, 0, 0, 0 ' : '0 0 40px 0 rgba(0, 0, 0, 0.10)'};
    background: var(--color-white);
    ${(props) => props.fullWidth ? 'max-height: 600px' : null};
    ${(props) => props.fullWidth ? 'height: calc(-40% + 100vh)' : null};
    ${(props) => props.fullWidth ? 'margin: 15px' : null};
    display: flex;
    flex-direction: column;
    z-index: 100;
`;

interface ChatWindowProps {
    referenceElement?: Element | null;
    children?: React.ReactNode;
    isPopup?: boolean;
    isTab?: boolean;
}

const ChatWindow: FC<ChatWindowProps> = ({
    referenceElement,
    children,
    isPopup,
    isTab
}) => {
    const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'top',
        modifiers: [
            {
                name: 'preventOverflow',
                options: {
                    altAxis: true,
                    padding: { right: 20 }
                }
            },
            {
                name: 'offset',
                options: {
                    offset: [0, 10]
                }
            }
        ]
    });

    const handleClickPopper = (e: Event) => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        isPopup
            ? createPortal(
                <MessagesWrapper onClick={handleClickPopper} ref={setPopperElement}
                    style={styles.popper} {...attributes.popper}>
                    {children}
                </MessagesWrapper>,
                document.querySelector('#offer-list')!
            )
            : <MessagesWrapper fullWidth isTab={isTab}>
                {children}
            </MessagesWrapper>
    );
};

export default ChatWindow;
