import React, { FC, ReactNode, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';
import { isTouchDevice } from 'utils/checkIsTouchDevice';

import Icon, { IconEnum } from 'components/common/Icon';
import { TabType } from 'components/common/Tabs/V2Tabs';
import Tooltip from 'components/common/Tooltip/Tooltip';
import UserPhotoIcon from 'components/common/UserPhotoIcon';

// TODO: dodać aby za pomocą propki elementy układały się po lewej lub po prawej

const hoverStyles = (darkMode: boolean) => `
    transition: background-color ease-in .2s;
    background-color: var(--color-alt);
    cursor: pointer;

    img {
        filter: ${darkMode ? 'unset' : 'invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(105%) contrast(102%)'};
    }

    & .user-photo-icon-img {
        filter: none;
    }
`;

const InteractiveIconsWrapper = styled.div`
    position: relative;
    background-color: var(--color-alt-second);
    border-radius: 50%;
    min-width: 40px;
    height: 40px;
    display: grid;

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
        cursor: not-allowed;
    }

    &.active {
        ${(props) => hoverStyles(props.darkMode)}
    }

    &.hover {
        &:hover {
            ${(props) => hoverStyles(props.darkMode)}
        }
     }

    &.is-blocked {
        pointer-events: none;
    }

    &.is-link.no-text {
        span {
            margin-right: 0;
        }

        a {
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &.is-link {
        display: flex;
        align-items: center;
        padding: 10px;
        border-radius: 20px;
        color: var(--color-primary);

        a {
            color: var(--color-primary);
            text-decoration: none;

            &:hover {
                color: white;
            }
        }

        &:hover a {
            color: white;
        }

        span {
            margin-right: 10px;
        }

        & .user-photo-icon-img {
            margin-right: 10px;
        }
    }

    span {
        align-self: center;
        justify-self: center;
    }

    &.message {
        display: flex;
        width: fit-content;
        margin-left: auto;
        margin-bottom: 15px;
        align-items: center;
        padding: 10px;
        border-radius: 20px;
        color: var(--color-primary);
        gap: 10px;
        @media (max-width: 990px) {
            margin-left: 0;
        }
    }

    @media (min-width: 990px) {
        &.modal-message {
            position: absolute;
            top: 210px;
            left: 25px;
        }

        &.modal-phone {
            position: absolute;
            top: 195px;
            left: -80px;

            &:hover {
                p {
                    color: var(--color-white);
                }

            }
        }

        &.is-photo {
            position: absolute;
            padding: 10px;
            top: 195px;
            right: -50px;
        }

        &.is-my-offer {
            :hover {
                transition: none;
                background-color: var(--color-alt-second);
                cursor: unset;

                p {
                    color: var(--color-primary);
                }

                img {
                    filter: unset;
                }
            }
        }

        &.is-user-name {
            position: absolute;
            top: 140px;
            left: -270px;
            display: flex;
            align-items: center;
            padding: 10px;
            border-radius: 20px;
            color: var(--color-primary);

            @media (max-width: 990px) {
                top: 215px;
            }
        }
    }

    &:hover {
        p {
            color: white;
        }
    }
`;
const Counter = styled.span`
    --font-weight: 700;
    --font-size-body: var(--font-size-body-4);
    position: absolute;
    top: -5px;
    right: -5px;
    background: var(--color-primary);
    color: var(--color-white);
    border-radius: 50%;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export interface InteractiveIconsProperties {
    disabled?: boolean;
    active?: boolean;
    activePhoneNumber?:boolean;
    isLink?: boolean;
    isMessageBtn?: boolean;
    isPhoneBtn?: boolean;
    isPhotoBtn?: boolean;
    isUserNameBtn?: boolean;
    noText?: boolean;
    bottomTooltip?: boolean;
    isMyOffer?: boolean;
    icon?: IconEnum;
    text?: ReactNode;
    counter?: number;
    onClick?: (e: React.MouseEvent) => void;
    tooltipText?: string;
    userPhoto?: string;
    isModalOpen?: boolean;
    openTabOnClick?: TabType;
}

const InteractiveIcons: FC<InteractiveIconsProperties> = ({
    counter,
    isLink,
    isMessageBtn,
    isPhoneBtn,
    isPhotoBtn,
    isUserNameBtn,
    active,
    disabled,
    icon,
    text,
    onClick,
    noText,
    bottomTooltip,
    tooltipText,
    isModalOpen,
    isMyOffer,
    userPhoto
}) => {
    const { user } = useUser();
    const [showTooltip, setShowTooltip] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);
    const [isTouch, setIsTouch] = useState(false);
    const [isBlocked, setIsBlocked] = useState(false);

    useEffect(() => {
        setIsTouch(isTouchDevice());
    }, []);

    const handleClick = (event: React.MouseEvent) => {
        event.stopPropagation();

        if (isBlocked) return;

        if (onClick) onClick(event);

        setIsBlocked(true);
        setTimeout(() => setIsBlocked(false), 400);
    };

    return (
        <>
            <InteractiveIconsWrapper bottomTooltip={bottomTooltip} ref={setReferenceElement} darkMode={user.isDarkMode}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                onClick={handleClick} className={clsx({
                    disabled: disabled,
                    active: active,
                    'is-link': isLink,
                    'is-my-offer': isMyOffer,
                    message: isMessageBtn,
                    'modal-phone': isModalOpen && isPhoneBtn,
                    'modal-message': isModalOpen && isMessageBtn,
                    'is-photo': isModalOpen && isPhotoBtn,
                    'is-user-name': isModalOpen && isUserNameBtn,
                    'no-text': noText,
                    'show-triangle': tooltipText && tooltipText.length > 0,
                    hover: !isTouch,
                    'is-blocked': isBlocked
                })}>
                {counter && <Counter>{counter}</Counter>}
                {icon && !userPhoto && <Icon icon={icon}/>}
                {isUserNameBtn && userPhoto && <UserPhotoIcon img={userPhoto}/>}
                {text}
            </InteractiveIconsWrapper>
            <Tooltip referenceElement={referenceElement} showTooltip={showTooltip} text={tooltipText} bottomTooltip={bottomTooltip}/>
        </>
    );
};
export default InteractiveIcons;
