import {
    UserAddAdvertStateType, UserAdvertEditReturnData, V2OfferType
} from 'utils/types/UsersAdvert';

const setStateValues = (state: UserAdvertEditReturnData): UserAddAdvertStateType => {
    // const dateFromWithDefault = state.offerAddedFrom || (new Date(((new Date()).setDate((new Date()).getDate() - 3)))).toISOString();

    // @ts-expect-error TODO: INVEST-237
    return {
        ...state,
        city: state.city || [],
        ...{ floor: state.floor ?? '' },
        ...{ floors: state.floors ?? '' },
        availableFrom: state.availableFrom ? state.availableFrom : ''
    };
};

const userAddAdvertInitialState = setStateValues({
    offerType: V2OfferType.DEAL,
    city: [],
    advertisementId: '',
    title: '',
    description: '',
    cityName: '',
    locations: [],
    savedLocations: {},
    searchLocationResult: [],
    buildingType: undefined,
    savedFilter: [],
    preContract: false,
    constructionCrew: false
});

export const initialState = {
    initialState: () => {
        return userAddAdvertInitialState;
    }
};
