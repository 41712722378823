import React, { Dispatch } from 'react';
import { createContext, FC, ReactNode, useContext, useReducer } from 'react';

import { Action, reducer } from './Reducer';
import { initialState, State } from './State';

interface ProviderProperties {
    children: ReactNode;
}

const Context = createContext<State>(initialState);
const DispatchContext = createContext<Dispatch<Action>>(() => null);

export const OffersProvider: FC<ProviderProperties> = ({ children }) => {
    const [filters, dispatch] = useReducer(reducer, initialState);

    return (
        <Context.Provider value={filters}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </Context.Provider>
    );
};

export const useOffers = () => {
    return {
        offers: useContext(Context),
        dispatch: useContext(DispatchContext)
    };
};
