import React, { createContext, useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { UserActions } from 'store/User/Actions';
import { useUser } from 'store/User/Context';
import { filtersApiRequests } from 'utils/api-requests/filters';
import { userApiRequests } from 'utils/api-requests/user';
import { getEnumPropertyByTypeUser } from 'utils/formatters/getPropertyByType';
import { initialState, setStateValues } from 'utils/state-managment/user/userFilter';
import { ObjectAndSingleValueChangeType } from 'utils/types/InputTypes';
import { BoxInfoDtoModuleEnum } from 'utils/types/UserModels';
import { UsersFiltersStateType, V2OfferType } from 'utils/types/UsersAdvert';
import { AdvertValidatorType, isFieldValid } from 'utils/validators/advertAddingValidator';

interface UserFiltersType {
    handleChangeOfferType: (value: V2OfferType) => void,
    offerType: V2OfferType,
    usersAdvertsFiltersState: UsersFiltersStateType
    handleDeleteFilter: (id: number) => void,
    handleChangeDateList: (fieldName: string, value: number) => void,
    handleSetFiltersValues: (id: number) => void
    handleChange: (name: string, value: ObjectAndSingleValueChangeType) => void
    validateField: (name: AdvertValidatorType, value: ObjectAndSingleValueChangeType) => boolean
    clearFilters: (reset?: boolean) => void
    getSavedFilters: (id?: number) => void
}

const values: UserFiltersType = {
    handleChangeOfferType: () => {
    },
    handleDeleteFilter: () => {
    },
    handleSetFiltersValues: () => {
    },
    handleChangeDateList: () => {
    },
    handleChange: () => {
    },
    clearFilters: () => {
    },
    validateField: () => false,
    offerType: V2OfferType.DEAL,
    usersAdvertsFiltersState: {} as UsersFiltersStateType,
    getSavedFilters: () => {
    }
};

const UserFiltersContext = createContext(values);

export const UserFiltersProvider = ({ children }: { children: React.ReactNode }) => {
    const [offerType, setOfferType] = useState<V2OfferType>(V2OfferType.DEAL);
    const [usersAdvertsFiltersState, setUsersAdvertsFiltersState] = useState(initialState.filterInitialState());
    const { dispatch: dispatchUser } = useUser();

    const handleSetFiltersValues = async (id: number) => {
        const filterModuleType = usersAdvertsFiltersState.savedFilter.find((x) => x.filterId === id)?.module;
        const filterData = await userApiRequests.getFilter(id, filterModuleType ? getEnumPropertyByTypeUser(BoxInfoDtoModuleEnum[filterModuleType]) : offerType);

        if (filterData) {
            setUsersAdvertsFiltersState((filters: UsersFiltersStateType) => ({
                ...setStateValues({ ...filterData, locations: usersAdvertsFiltersState.locations }),
                savedFilter: filters.savedFilter
            }));
        } else {
            setUsersAdvertsFiltersState((filters: UsersFiltersStateType) => ({
                ...filters,
                name: null,
                id: null
            }));
        }
    };

    const getSavedFilters = useCallback(async (id?: number) => {
        const savedFilters = await userApiRequests.getSavedFilters();

        setUsersAdvertsFiltersState((filters: UsersFiltersStateType) => ({
            ...filters,
            savedFilter: savedFilters
        }));

        if (id) {
            await handleSetFiltersValues(id);
        }
    }, [offerType, usersAdvertsFiltersState.locations]);

    const handleDeleteFilter = useCallback(async (filterId: number) => {
        const filterModuleType = usersAdvertsFiltersState.savedFilter.find((x) => x.filterId === filterId)?.module;
        await userApiRequests.deleteFilter(filterId, filterModuleType ? getEnumPropertyByTypeUser(BoxInfoDtoModuleEnum[filterModuleType]) : offerType);

        setUsersAdvertsFiltersState((filters: UsersFiltersStateType) => ({
            ...initialState.filterInitialState(),
            locations: filters.locations
        }));

        await getSavedFilters();
    }, [
        usersAdvertsFiltersState.filterId,
        usersAdvertsFiltersState.savedFilter,
        getSavedFilters
    ]);

    const handleChangeOfferType = (value: V2OfferType) => {
        setOfferType(value);
        handleChange('offerType', value);
        clearFilters();
        setUsersAdvertsFiltersState((prev) => ({
            ...prev,
            id: undefined
        }));
    };

    const handleChange = useCallback((fieldName: string, value: ObjectAndSingleValueChangeType) => {
        setUsersAdvertsFiltersState((filters: UsersFiltersStateType) => ({
            ...filters,
            [fieldName]: value
        }));
    }, [usersAdvertsFiltersState]);

    const clearFilters = useCallback(async (reset?: boolean) => {
        const initialFilters = initialState.filterInitialState();

        if (reset) {
            setUsersAdvertsFiltersState((prev) => ({
                ...initialFilters,
                locations: prev.locations
            }));
        } else {
            setUsersAdvertsFiltersState((prev) => ({
                ...initialFilters,
                offerType: prev.offerType,
                locations: prev.locations,
                savedLocations: prev.savedLocations,
                city: prev.city,
                dateAdded: prev.dateAdded,
                daysAmount: prev.daysAmount
            }));
        }
        await getSavedFilters();

        await UserActions.changeErrorVisibility(dispatchUser, false);
    }, [
        dispatchUser,
        getSavedFilters,
        offerType
    ]);

    const getVoivodeships = useCallback(async () => {
        const locations = await filtersApiRequests.getVoivodeships();
        setUsersAdvertsFiltersState((filters: UsersFiltersStateType) => ({
            ...filters,
            locations
        }));
    }, []);

    useEffect(() => {
        clearFilters();
    }, [offerType]);

    useEffect(() => {
        UserActions.clearOffers(dispatchUser);
    }, [dispatchUser]);

    useEffect(() => {
        if (usersAdvertsFiltersState.locations) {
            getVoivodeships();
        }
    }, []);

    const handleChangeDateList = (fieldName: string, value: number) => {
        setUsersAdvertsFiltersState((filters: UsersFiltersStateType) => ({
            ...filters,
            offerAdded: {
                from: value === -1
                    ? null
                    : dayjs().subtract(value, 'day').toISOString()
                        .split('T')[0],
                to: null
            },
            [fieldName]: value
        }));
    };

    const userFiltersContextValues = {
        handleChangeOfferType,
        offerType,
        usersAdvertsFiltersState,
        handleDeleteFilter,
        handleSetFiltersValues,
        handleChange,
        clearFilters,
        getSavedFilters,
        validateField: isFieldValid,
        handleChangeDateList
    };

    return (
        <UserFiltersContext.Provider value={userFiltersContextValues}>
            {children}
        </UserFiltersContext.Provider>
    );
};

export default UserFiltersContext;
