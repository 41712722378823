import React, { FC, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { MessageSenders } from 'store/User/State';
import styled from 'styled-components';

import InteractiveIcons, { InteractiveIconsProperties } from 'components/common/Card/common/InteractiveIcons';
import { User } from 'components/common/Card/common/V2CardBodyDefault/Top/Top';
import Messages from 'components/common/Chat/Messages';
import { IconEnum } from 'components/common/Icon';
import Slider, { SliderImages } from 'components/common/Slider/Slider';
import { TabType } from 'components/common/Tabs/V2Tabs';

import NotInteractiveIcons, { NotInteractiveElement } from './NotInteractiveIcons';

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    align-self: center;
    flex-wrap: wrap;
    gap: 10px;
    position: relative;

    @media (min-width: 991px) {
        justify-content: flex-end;
    }

    &.notInteractive {
        justify-content: space-between;
        flex-wrap: nowrap;
    }

    .interactive-phone {
        display: flex;
        align-items: center;
        padding: 10px;
        border-radius: 20px;
        color: var(--color-primary);

        a {
            color: var(--color-primary);

            &:hover {
                color: white;
            }
        }

        span {
            margin-right: 10px;
        }
    }
`;

const MessagesIconWrapper = styled.div`
    width: fit-content;
    ${(props) => props.isModalOpen ? 'margin-top: 220px; margin-left: -150px;' : 'margin-left: auto;'};

    @media (max-width: 991px) {
        display: none;
    }
`;

export interface InteractiveElements extends InteractiveIconsProperties {
    additionalJSXSibling?: JSX.Element;
    visibleDuringModal?: boolean;
    sliderElements?: SliderElements;
}

interface SliderElements {
    photos: SliderImages[];
    title: string;
}

export interface InteractiveIconsWrapperProperties {
    className?: string;
    isModalOpen?: boolean;
    userDetails?: User;
    isNotInteractive?: boolean; // type === 'my_offer' && !isModalOpen
    notInteractiveElements?: NotInteractiveElement[];
    interactiveElements?: InteractiveElements[];
    isMyOffersPage?: boolean;
    isUsersOffersPage?: boolean;
    isAdOwner?: boolean;
    showMessages?: boolean;
    setShowMessages?: (value: boolean) => void;
    openOfferTab?: (tab: TabType) => void;
}

const V2InteractiveIconsWrapper: FC<InteractiveIconsWrapperProperties> = ({
    className,
    isModalOpen,
    userDetails,
    isNotInteractive,
    notInteractiveElements,
    interactiveElements,
    showMessages,
    setShowMessages,
    openOfferTab
}) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [referenceElement, setReferenceElement] = useState(null);
    const [showSlider, setShowSlider] = useState(false);
    const memoizedInteractiveElements = useMemo(() => interactiveElements, [interactiveElements]);

    const handleMessages = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        setShowMessages && setShowMessages(!showMessages);
    };

    const toggleIsModalSlider = () => {
        setShowSlider(!showSlider);
    };

    const handleInteractiveIconsClick = (e: React.MouseEvent, element: InteractiveElements) => {
        if (element.sliderElements) {
            toggleIsModalSlider();
        } else if (element.openTabOnClick) {
            openOfferTab && openOfferTab(element.openTabOnClick);
        } else {
            element.onClick && element.onClick(e);
        }
    };

    return (
        <>
            {showMessages && userDetails && userDetails.userName && !isModalOpen &&
                <Messages username={userDetails.userName} referenceElement={referenceElement}
                    onClose={handleMessages} title={userDetails.title}
                    photo={userDetails.offerPhoto}
                    userPhoto={userDetails.userPhoto}
                    price={userDetails.price}
                    onSendMessage={userDetails.handleSendMessages}
                    messages={userDetails.userMessages}
                    messageOwnerType={MessageSenders.INTERESTED}
                    isPopup
                />}
            {userDetails && userDetails.showMessageButton && !isModalOpen &&
                <MessagesIconWrapper isModalOpen={isModalOpen} ref={setReferenceElement}>
                    <InteractiveIcons icon={IconEnum.EMAIL} isModalOpen={isModalOpen} isMessageBtn
                        onClick={handleMessages}
                        text={<p>Wyślij wiadomość</p>}
                    />
                </MessagesIconWrapper> }
            <Wrapper ref={wrapperRef} data-testid="interactive-icons-wrapper" className={clsx(className, { notInteractive: isNotInteractive })}>
                {notInteractiveElements && notInteractiveElements.length > 0
                    ? <NotInteractiveIcons notInteractiveElements={notInteractiveElements}/>
                    : null}
                {memoizedInteractiveElements && memoizedInteractiveElements.map((element, index) => <React.Fragment key={index}>
                    {element.visibleDuringModal
                        ? isModalOpen
                            ? <InteractiveIcons key={index} {...element} onClick={(e: React.MouseEvent) => handleInteractiveIconsClick(e, element)}/>
                            : null
                        : <InteractiveIcons key={index} {...element} onClick={(e: React.MouseEvent) => handleInteractiveIconsClick(e, element)} bottomTooltip={isModalOpen}/> }
                    {element.additionalJSXSibling ? element.additionalJSXSibling : null}
                    {element.sliderElements && showSlider
                        ? <Slider
                            images={element.sliderElements.photos}
                            title={element.sliderElements.title} modalOpen modalMode toggleIsModalSlider={toggleIsModalSlider} />
                        : null}
                </React.Fragment>)}
            </Wrapper>
            {showMessages && userDetails && userDetails.userName && isModalOpen &&
                <Messages username={userDetails.userName} referenceElement={referenceElement}
                    onClose={handleMessages} title={userDetails.title}
                    photo={userDetails.offerPhoto}
                    userPhoto={userDetails.userPhoto}
                    price={userDetails.price}
                    onSendMessage={userDetails.handleSendMessages}
                    messages={userDetails.userMessages}
                    messageOwnerType={MessageSenders.INTERESTED}
                    isPopup
                />}
            {userDetails && userDetails.showMessageButton && isModalOpen
                ? <MessagesIconWrapper isModalOpen={isModalOpen} ref={setReferenceElement}>
                    <InteractiveIcons
                        icon={IconEnum.EMAIL}
                        isModalOpen={isModalOpen}
                        isMessageBtn
                        onClick={handleMessages}
                        text={<p>Wyślij wiadomość</p>}
                    />
                </MessagesIconWrapper>
                : null}
        </>
    );
};

export default V2InteractiveIconsWrapper;
