import React, { FC } from 'react';
import styled from 'styled-components';

import { IconEnum } from './Icon';
import Module from './Module';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    background: var(--color-white);
    border-radius: var(--box-border-radius);
`;

interface ModuleItem {
    title: string;
    icon: IconEnum;
    isActive: boolean;
    onClick: () => void;
}

interface StaticModuleListProps {
    modules: ModuleItem[];
}

const StaticModuleList: FC<StaticModuleListProps> = ({ modules }) => {
    return (
        <Container>
            {modules.map((module, index) => <Module
                key={index}
                isActive={module.isActive}
                icon={module.icon}
                title={module.title}
                onClick={module.onClick}
            />)}
        </Container>
    );
};

export default StaticModuleList;
