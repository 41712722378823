import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';

import Store from './Store';

const tagManagerArgs = {
    gtmId: 'GTM-MTZQQD9K'
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <Store />
);
