import React, { FC } from 'react';
import styled from 'styled-components';

import Icon, { IconEnum } from './Icon';

const ButtonWithIcon = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    outline: none;
    
    &:hover {
        background: none;
    }
`;

interface IconButtonProperties {
    icon: IconEnum
    onClick?: () => void
}

const IconButton: FC<IconButtonProperties> = ({ icon, onClick }) => {
    return (
        <ButtonWithIcon onClick={onClick}>
            <Icon icon={icon} />
        </ButtonWithIcon>
    );
};

export default IconButton;
