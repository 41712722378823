import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        font-family: 'Poppins', sans-serif !important;

        //Fonts
        --font-weight: 500;
        --font-size-body-1: 14px;
        --font-size-body-2: 13px;
        --font-size-body-3: 12px;
        --font-size-body-4: 10px;
        --font-size-body: var(--font-size-body-1);

        //Border
        --border-radius: 40px;
        --input-border-radius-default: 25px;
        --input-border-radius-list: 10px;
        --image-border-radius: 6px;
        --box-border-radius: 10px;

        // z-index
        --z-index-options-menu: 100;
        --z-index-nav-badge: 2;

        .light {
            //Colors
            --color-primary: #030D45;
            --color-primary-70: #030D45B3;
            --color-alt: #0255AA;
            --color-alt-hover: #1a65b2;
            --color-alt-second: #F2F5F9;
            --color-alt-second-10: #a7acaf;
            --color-white: #FFFFFF;
            --color-error: #BA000B;
            --color-warning: #ED811D;
            --color-success: #0BAC25;
            --color-background: #D9D9D9;
            --color-scroll: #E0E0E0;
            --color-last-message: #E1E5EC;

            //Other
            --checkbox-color: var(--color-primary);
            --checkbox-background-color: var(--color-white);
            --radio-color: var(--color-primary);
            --radio-background-color: var(--color-white);
            --active-message-color: var(--color-alt);

            background-color: var(--color-alt-second);
        }

        .dark {
            //Colors
            --color-primary: #F2F5F9;
            --color-primary-70: #F2F5F9B3;
            --color-alt: #0255AA;
            --color-alt-hover: #2B2933;
            --color-alt-second: #0F0F0F;
            --color-alt-second-10: #a7acaf;
            --color-white: #201F26;
            --color-error: #BA000B;
            --color-warning: #C36C1B;
            --color-success: #0B8E21;
            --color-background: #D9D9D9;
            --color-scroll: #1A1A1A;
            --color-last-message: #E1E5EC;

            //Other
            --checkbox-color: var(--color-primary);
            --checkbox-background-color: var(--color-white);
            --radio-color: var(--color-primary);
            --radio-background-color: var(--color-white);

            background-color: var(--color-alt-second);

            #offer-list .icon:not(.icon-in-button),
            .offers-list .icon:not(.icon-in-button),
            .header-input-container .on-dark-theme,
            .dark-mode-icons .icon:not(.icon-in-button),
            #filters .icon:not(.icon-in-button) {
                filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(105%) contrast(102%);
            }
        }

        scrollbar-width: thin;
    }

    *::-webkit-scrollbar {
        width: 4px;
    }

    *::-webkit-scrollbar-track {
        background: var(--color-white)
    }

    *::-webkit-scrollbar-thumb {
        background-color: var(--color-scroll);
        border-radius: 2px;
        border: 3px solid var(--color-background);
    }

    html {
        font-size: 16px;
        height: 100%;
        overflow-y: hidden;
    }

    h1 {
        margin: 0;
        font-size: 18px;
        font-weight: var(--font-weight);
        color: var(--color-primary);

        @media (min-width: 961px) {
            font-size: 20px;
        }
    }

    h2 {
        margin: 0;
        font-size: 16px;
        font-weight: var(--font-weight);
        color: var(--color-primary);
    }

    p, span, div, input, li {
        margin: 0;
        font-weight: var(--font-weight);
        font-size: var(--font-size-body);
        color: var(--color-primary);
    }

    button {
        background-color: var(--color-alt-second);
        color: var(--color-primary);
    }

    button:active,
    button:hover {
        background-color: var(--color-alt);
        color: #fff;
    }

    input[type="checkbox"] {
        -webkit-appearance: none;
        appearance: none;
        background-color: var(--checkbox-background-color);
        margin: 0;
        font: inherit;
        color: currentColor;
        width: 20px;
        height: 20px;
        border: 2px solid var(--checkbox-color);
        border-radius: 2px;
        display: grid;
        place-content: center;
    }

    input[type="checkbox"]:checked::before {
        content: "";
        width: 3px;
        height: 6px;
        transition: 120ms transform ease-in-out;
        border: solid var(--checkbox-color);
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        margin-bottom: 2px;
    }

    input[type="radio"] {
        appearance: none;
        background-color: var(--radio-background-color);
        margin: 0;
        font: inherit;
        color: currentColor;
        width: 20px;
        height: 20px;
        border: 2px solid var(--radio-color);
        border-radius: 50%;
        transform: translateY(-0.075em);
        display: grid;
        place-content: center;
    }

    input[type="radio"]:checked::before {
        content: "";
        width: 5px;
        height: 8px;
        transition: 120ms transform ease-in-out;
        border: solid var(--checkbox-color);
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }

    body {
        margin: 0;
        padding: 0;
        height: 100%;
        scroll-behavior: smooth;
        overflow-y: scroll;
        scrollbar-width: thin;
        -webkit-overflow-scrolling: touch;

        .dark {
            background-color: #0F0F0F;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
`;

export default GlobalStyle;
