import { RentModuleType } from 'utils/types/Rent';
import { BoxEditDtoModuleEnum, BoxInfoDtoModuleEnum } from 'utils/types/UserModels';
import { V2OfferType } from 'utils/types/UsersAdvert';

export const getPropertyByTypeUser = (type: V2OfferType) => {
    switch (type) {
    case V2OfferType.CAPITAL:
        return BoxEditDtoModuleEnum.USER_OFFER_CAPITAL;
    case V2OfferType.INVESTOR:
        return BoxEditDtoModuleEnum.USER_OFFER_INVESTOR;
    case V2OfferType.DEAL:
    default:
        return BoxEditDtoModuleEnum.USER_OFFER_DEAL;
    }
};

export const getEnumPropertyByTypeUser = (enumOfferType: BoxInfoDtoModuleEnum | BoxEditDtoModuleEnum) => {
    switch (enumOfferType) {
    case BoxInfoDtoModuleEnum.USER_OFFER_INVESTOR:
        return V2OfferType.INVESTOR;
    case BoxInfoDtoModuleEnum.USER_OFFER_CAPITAL:
        return V2OfferType.CAPITAL;
    case BoxInfoDtoModuleEnum.USER_OFFER_DEAL:
    default:
        return V2OfferType.DEAL;
    }
};

export const getPropertyByTypeRent = (type: RentModuleType) => {
    switch (type) {
    case RentModuleType.RENT_HOUSE:
        return 'RENT_HOUSE';
    case RentModuleType.RENT_PLOT:
        return 'RENT_PLOT';
    case RentModuleType.RENT_OTHER:
        return 'RENT_OTHER';
    case RentModuleType.RENT_FLAT:
    default:
        return 'RENT_FLAT';
    }
};
