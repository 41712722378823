import React, { FC } from 'react';
import ReactNotification from 'react-notifications-component';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import styled, { ThemeProvider } from 'styled-components';

import { activeNotification } from './components/functions/activeNotification';
import { OffersProvider } from './store/Offers/Context';
import { UserProvider } from './store/User/Context';
import GlobalStyle from './styles/GlobalStyle';
import { theme } from './styles/theme';
import { userApiRequests } from './utils/api-requests/user';
import App from './App';

import 'react-notifications-component/dist/theme.css';

const Notification = styled.div`
    .notification-danger {
        background-color: var(--color-error);
        border-left: none;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        border-radius: var(--box-border-radius);

        .timer-filler,
        .timer {
            background-color: var(--color-error);
        }
    }

    .notification-warning {
        background-color: var(--color-warning);
        border-left: none;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        border-radius: var(--box-border-radius);

        .timer-filler,
        .timer {
            background-color: var(--color-warning);
        }
    }

    .notification-success {
        background-color: var(--color-success);
        border-left: none;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        border-radius: var(--box-border-radius);

        .timer-filler,
        .timer {
            background-color: var(--color-success);
        }
    }

    .notification-message {
        display: flex;
        color: #F2F5F9;

        span {
            margin-right: 10px;
        }
    }

    .notification-container-bottom-right {
        width: fit-content;
    }
`;

export const axiosApiInstance = axios.create();
export const axiosApiInstance2 = axios.create();

axiosApiInstance.defaults.baseURL = process.env.REACT_APP_API_URL;
axiosApiInstance2.defaults.baseURL = process.env.REACT_APP_API_URL_2;
axiosApiInstance.defaults.withCredentials = true;
axiosApiInstance2.defaults.withCredentials = true;
axiosApiInstance.defaults.headers.post['Content-Type'] = 'application/json';
axiosApiInstance2.defaults.headers.post['Content-Type'] = 'application/json';

const Store: FC = () => {
    axiosApiInstance.interceptors.response.use((response) => {
        return response;
    }, async function (error) {
        const isRefreshed = error.request.responseURL.includes('api/auth/refreshToken');
        if (isRefreshed) return;

        const isRefreshing = localStorage.getItem('isRefreshing') === 'true';
        if (isRefreshing) return;

        const originalRequest = error.config;

        if (error.response.status === 403 && error.response.data?.code?.includes('incorrect_password')) {
            activeNotification(
                'Błędne hasło',
                'Wpisz poprawne hasło',
                'danger'
            );

            return Promise.reject(error);
        }

        if (error.response.status === 403) {
            const refreshToken = localStorage.getItem('MonitorApiRefreshToken') || Cookies.get('MonitorApiRefresh');

            if (refreshToken) {
                localStorage.setItem('isRefreshing', 'true');
                const refreshedUser = await userApiRequests.authenticateByToken(refreshToken);

                if (refreshedUser && typeof refreshedUser === 'object') {
                    localStorage.setItem('MonitorApiToken', refreshedUser.token);
                    localStorage.setItem('MonitorApiRefreshToken', refreshedUser.refreshToken);
                    axiosApiInstance.defaults.headers.common = { Authorization: `Bearer ${refreshedUser.token}` };
                    originalRequest.headers['Authorization'] = `Bearer ${refreshedUser.token}`;
                    localStorage.removeItem('isRefreshing');

                    return axiosApiInstance(originalRequest);
                }

                localStorage.removeItem('isRefreshing');
            }

            localStorage.removeItem('MonitorApiToken');
            localStorage.removeItem('MonitorApiRefreshToken');
            localStorage.removeItem('MonitorRoles');

            Cookies.remove('MonitorApiToken');
            Cookies.remove('MonitorApiToken', { path: '', domain: '.investoro.pl' });
            Cookies.remove('MonitorApiRefresh');
            Cookies.remove('MonitorApiRefresh', { path: '', domain: '.investoro.pl' });
            Cookies.remove('MonitorRoles');
            Cookies.remove('MonitorRoles', { path: '', domain: '.investoro.pl' });

            activeNotification(
                'Sesja wygasła',
                'Zaloguj się ponownie',
                'danger'
            );

            window.location.replace('/');
        } else {
            return Promise.reject(error);
        }
    });

    axiosApiInstance2.interceptors.response.use((response) => {
        return response;
    }, async function (error) {
        const isRefreshed = error.request.responseURL.includes('auth/refreshToken');
        if (isRefreshed) return;

        const isRefreshing = localStorage.getItem('isRefreshing2') === 'true';
        if (isRefreshing) return;

        const originalRequest = error.config;

        if (error.response.status === 403 && error.response.data?.code?.includes('incorrect_password')) {
            activeNotification(
                'Błędne hasło',
                'Wpisz poprawne hasło',
                'danger'
            );

            return Promise.reject(error);
        }

        if (error.response.status === 403) {
            const refreshToken = localStorage.getItem('MonitorApiRefreshToken2') || Cookies.get('MonitorApiRefresh2');

            if (refreshToken) {
                localStorage.setItem('isRefreshing2', 'true');
                const refreshedUser = await userApiRequests.authenticateByToken2(refreshToken);

                if (refreshedUser && typeof refreshedUser === 'object') {
                    localStorage.setItem('MonitorApiToken2', refreshedUser.token);
                    localStorage.setItem('MonitorApiRefreshToken2', refreshedUser.refreshToken);
                    axiosApiInstance2.defaults.headers.common = { Authorization: `Bearer ${refreshedUser.token}` };
                    originalRequest.headers['Authorization'] = `Bearer ${refreshedUser.token}`;
                    localStorage.removeItem('isRefreshing2');

                    return axiosApiInstance2(originalRequest);
                }

                localStorage.removeItem('isRefreshing2');
            }

            localStorage.removeItem('MonitorApiToken2');
            localStorage.removeItem('MonitorApiRefreshToken2');
            localStorage.removeItem('MonitorRoles2');

            Cookies.remove('MonitorApiToken2');
            Cookies.remove('MonitorApiToken2', { path: '', domain: '.investoro.pl' });
            Cookies.remove('MonitorApiRefresh2');
            Cookies.remove('MonitorApiRefresh2', { path: '', domain: '.investoro.pl' });
            Cookies.remove('MonitorRoles2');
            Cookies.remove('MonitorRoles2', { path: '', domain: '.investoro.pl' });

            activeNotification(
                'Sesja wygasła',
                'Zaloguj się ponownie',
                'danger'
            );

            window.location.replace('/');
        } else {
            return Promise.reject(error);
        }
    });

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <GlobalStyle/>
                <Notification>
                    <ReactNotification/>
                </Notification>
                <UserProvider>
                    <OffersProvider>
                        <App/>
                    </OffersProvider>
                </UserProvider>
            </ThemeProvider>
        </BrowserRouter>
    );
};

export default Store;
