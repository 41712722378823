import React, { FC } from 'react';

import CardDetails, { CardDetailsProperties } from 'components/common/Card/CardDetails/CardDetails';

export interface SingleOfferDetailsRendererProps {
    detailsCardData: CardDetailsProperties & { id: number; }
}

const SingleOfferDetailsRenderer: FC<SingleOfferDetailsRendererProps> = ({ detailsCardData }) => {
    return (
        <CardDetails
            {...detailsCardData}
            preferredTab={1}
        />
    );
};

export default SingleOfferDetailsRenderer;
