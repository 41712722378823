import React, { FC, useState } from 'react';

import BaseCardMini from 'components/common/Card/BaseCardMini';
import CardDetails, { CardDetailsProperties } from 'components/common/Card/CardDetails/CardDetails';
import V2BaseCard, { V2BaseCardProperties } from 'components/common/Card/V2BaseCard';
import OfferDetailsModal from 'components/common/OfferDetailsModal';
import { TabType } from 'components/common/Tabs/V2Tabs';

export interface SingleOfferProps {
    baseCardContent: V2BaseCardProperties;
    detailsCardData: CardDetailsProperties;
    onSingleOfferClick?: (e: React.MouseEvent, detailsOpen: boolean) => void;
    mobile?: boolean;
}

const SingleOffer:FC <SingleOfferProps> = ({ mobile, baseCardContent, detailsCardData, onSingleOfferClick }) => {
    const [detailsOpen, toggleDetailsOpen] = useState(false);
    const [preferredTab, setPreferredTab] = useState(TabType.DETAILS);

    const openOfferTab = (name: TabType) => {
        setPreferredTab(name);
        setTimeout(() => {
            toggleDetailsOpen((prev) => !prev);
        }, 100);
    };

    const handleBaseCardClick = (e: React.MouseEvent) => {
        // @ts-expect-error TODO: INVEST-256 find a better way for not clickable elements
        if (e.target.dataset.notclickable || e.target.closest('[data-notclickable]')) return;

        // @ts-expect-error TODO: INVEST-256 find a better way for not clickable elements
        if (e.key === 'Escape') {
            toggleDetailsOpen(false);

            return;
        }

        if (onSingleOfferClick) onSingleOfferClick(e, detailsOpen);

        setPreferredTab(TabType.DETAILS);
        setTimeout(() => {
            toggleDetailsOpen((prev) => !prev);
        }, 100);
    };

    return (
        <div>
            {mobile
                ? <BaseCardMini
                    {...baseCardContent}
                    openOfferTab={openOfferTab}
                />
                : <V2BaseCard
                    {...baseCardContent}
                    onClick={handleBaseCardClick}
                    openOfferTab={openOfferTab}
                />
            }
            {/* @ts-expect-error will be changed after refactoring*/}
            <OfferDetailsModal isOpen={detailsOpen} onClose={handleBaseCardClick}>
                <CardDetails
                    {...detailsCardData}
                    onToggleContainerDetails={handleBaseCardClick}
                    preferredTab={preferredTab}
                />
            </OfferDetailsModal>
        </div>
    );
};

export default SingleOffer;
