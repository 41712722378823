import { FC } from 'react';
import React from 'react';

import V2BaseCard, { V2BaseCardProperties } from 'components/common/Card/V2BaseCard';
import { BoxContainer } from 'components/sales/SingleAdvertisement';

export type DuplicatesType = V2BaseCardProperties & { id?: number; };

export interface V2DuplicatesTabProps {
    duplicates?: DuplicatesType[];
}

const V2DuplicatesTab: FC<V2DuplicatesTabProps> = ({ duplicates }) => {
    return (
        <>
            {duplicates?.map((item, index) => <BoxContainer key={index}>
                <V2BaseCard
                    {...item}
                    onClick={(e) => e?.preventDefault()}
                    openOfferTab={() => { }}
                />
            </BoxContainer>)}
        </>
    );
};

export default V2DuplicatesTab;
