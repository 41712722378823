import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface FiltersContentWrapperProps {
  children: ReactNode;
  className: string;
}

const Wrapper = styled.div`
    &.active {
        display: grid;
        grid-template-columns: 25% 75%;

        @media (max-width: 1100px) {
            grid-template-columns: 100%;
        }

        .accordion-card-content {
            row-gap: 25px;
        }

        .move-last span {
            grid-area: unset;
        }

        .accordion-card-content {
            grid-template-columns: 1fr;
            grid-template-areas: unset;
        }

        .accordion-card-clickable {
            justify-content: space-between;
        }

        .sticky-bottom-wrapper {
            grid-area: unset !important;
        }
        
        #filters {
            padding-right: 0;

            @media (max-width: 1100px) {
                display: none;
            }
        }
    }
`;

const FiltersContentWrapper: FC<FiltersContentWrapperProps> = ({ children, className }) => {
    return (
        <Wrapper className={className}>
            {children}
        </Wrapper>
    );
};

export default FiltersContentWrapper;
