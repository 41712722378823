import { FC } from 'react';

interface StepperProperties {
    step: number,
    SecondStep: JSX.Element,
    ThirdStep: JSX.Element,
    FirstStep: JSX.Element
}

const Stepper: FC<StepperProperties> = ({ step, SecondStep, ThirdStep, FirstStep }) => {
    switch (step) {
    case 1:
        return FirstStep;
    case 2:
        return SecondStep;
    case 3:
        return ThirdStep;
    default:
        return FirstStep;
    }
};

export default Stepper;
