export const theme = {
    primary: 'black',
    secondary: 'white',
    main: '#2550aa', // blue from button
    light: '#eee',
    middle: '#aaa',
    button: '#2550aa',
    buttonDisabled: '#B7B2B2',
    header: '#2550aa',
    bg: '#F1F2F6',
    text: '#0e1726',
    textColor: '#515365',
    font: {
        xs: '1.2rem', // ~12px
        sm: '1.3rem', // ~13px
        md: '1.4rem', // ~14px
        lg: '1.6rem', // ~16px
        xl: '1.8rem', // ~18px
        xxl: '2.2rem'
    }
};
