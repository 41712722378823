import React, { useState } from 'react';

import { HeaderStyle, RadioContainer, SectionBox, Table, TableContainer, Top } from 'components/analysis/AnalysisSaleList';

export const AreaRangeTable = ({ analysis, isPrintMode = false }) => {
    const [hideAreaCols, setHideAreaCols] = useState(true);

    return (
        <SectionBox>
            <HeaderStyle>
                <Top>
                    <h2>Dane według przedziałów powierzchni</h2>
                    {!isPrintMode
                        ? <RadioContainer>
                            <input onChange={(e) => setHideAreaCols(!e.target.checked)}
                                checked={!hideAreaCols}
                                type="checkbox"
                                id="area"/>
                            <label htmlFor="area">Więcej danych</label>
                        </RadioContainer>
                        : null}
                </Top>
            </HeaderStyle>

            <TableContainer>
                <Table id="area-table">
                    <thead>
                        <tr>
                            <th>Powierzchnia</th>
                            <th>Liczba Ofert</th>
                            <th>Średnia cena m<sup>2</sup></th>
                            {!hideAreaCols && <th>Min cena m<sup>2</sup></th>}
                            {!hideAreaCols && <th>Max cena m<sup>2</sup></th>}
                            {!hideAreaCols && <th>Mediana ceny m<sup>2</sup></th>}
                            {!hideAreaCols && <th>Odchylenie standardowe ceny m<sup>2</sup></th>}
                            <th>Średnia cena całkowita</th>
                            {!hideAreaCols && <th>Min cena całkowita</th>}
                            {!hideAreaCols && <th>Max cena całkowita</th>}
                            {!hideAreaCols && <th>Mediana ceny całkowitej</th>}
                            {!hideAreaCols && <th>Odchylenie standardowe ceny całkowitej</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {analysis.areaAnalysisList.map((item) => {
                            return <tr key={item.areaLabel}>
                                <td>{item.areaLabel}</td>
                                <td>{item.transactionAmount.toLocaleString('fr', { useGrouping: true })}</td>
                                <td>{item.avgPricePerSquareMeter.toLocaleString('fr', { useGrouping: true })}</td>
                                {!hideAreaCols &&
                <td>{item.minPricePerSquareMeter.toLocaleString('fr', { useGrouping: true })}</td>}
                                {!hideAreaCols &&
                <td>{item.maxPricePerSquareMeter.toLocaleString('fr', { useGrouping: true })}</td>}
                                {!hideAreaCols &&
                <td>{item.medianPricePerSquareMeter.toLocaleString('fr', { useGrouping: true })}</td>}
                                {!hideAreaCols &&
                <td>{item.sdPricePerSquareMeter.toLocaleString('fr', { useGrouping: true })}</td>}
                                <td>{item.avgPrice.toLocaleString('fr', { useGrouping: true })}</td>
                                {!hideAreaCols && <td>{item.minPrice.toLocaleString('fr', { useGrouping: true })}</td>}
                                {!hideAreaCols && <td>{item.maxPrice.toLocaleString('fr', { useGrouping: true })}</td>}
                                {!hideAreaCols && <td>{item.medianPrice.toLocaleString('fr', { useGrouping: true })}</td>}
                                {!hideAreaCols && <td>{item.sdPrice.toLocaleString('fr', { useGrouping: true })}</td>}
                            </tr>;
                        })}
                    </tbody>
                </Table>
            </TableContainer>
        </SectionBox>

    );
};
