/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface BailiffNoticeFilterResponse {
  /**
   * Id filtra
   * @format int64
   * @example 1
   */
  filterId: number;
  /**
   * Nazwa filtra
   * @example "Any filter name"
   */
  name: string;
  /**
   * Czas opóźnień powiadomień
   * @format int32
   * @default 0
   * @example 720
   */
  notificationsDelay: number;
  /**
   * Powiadomienia sms
   * @example true
   */
  alertSms?: boolean | null;
  /**
   * Powiadomienia email
   * @example true
   */
  alertEmail?: boolean | null;
  /**
   * Powiadomienia push
   * @example true
   */
  alertPush?: boolean | null;
  /** Lokalizacje zadłużonych nieruchomości */
  locations?: FilterLocation[];
  /** Lokalizacje kancelarii */
  officeLocations?: FilterLocation[];
  /**
   * Pożądane słowa kluczowe
   * @example ["XYZ","YXZ","YZX"]
   */
  wantedKeywords?: (string | null)[];
  /**
   * Niepożądane słowa kluczowe
   * @example ["XYZ","YXZ","YZX"]
   */
  unwantedKeywords?: (string | null)[];
  /**
   * Typy nieruchomości
   * @example ["Mieszkania","Domy","Grunty"]
   */
  types?: BailiffNoticeFilterResponseTypesEnum;
  /**
   * Ogłoszenia z liczby dni
   * @format int32
   * @min 1
   * @example 3
   */
  daysAmount?: number | null;
  /**
   * Data dodania od
   * @format date
   * @example "2024-01-01"
   */
  dateAddedFrom?: string | null;
  /**
   * Data dodania do
   * @format date
   * @example "2024-01-04"
   */
  dateAddedTo?: string | null;
  /**
   * Akceptuj puste wartości
   * @example true
   */
  includeZeroArea?: boolean | null;
  /**
   * Moduł
   * @default "BAILIFF_NOTICE"
   */
  module?: string;
}

/** Lokalizacja filtra */
export interface FilterLocation {
  /**
   * Numer województwa
   * @format int32
   */
  voivodeshipNumber?: number | null;
  /**
   * Numer powiatu
   * @format int32
   */
  countyNumber?: number | null;
  /**
   * Numer gminy
   * @format int32
   */
  communityNumber?: number | null;
  /**
   * Numer typu gminy, miejscowości
   * @format int32
   */
  kindNumber?: number | null;
  /**
   * Numer części miejscowości
   * W przypadku officeLocation to pole nie jest obsługiwane
   * @format int32
   */
  subRegionNumber?: number | null;
  /**
   * Numer miasta, dzielnicy, części itp.
   * W przypadku officeLocation to pole nie jest obsługiwane
   * @format int32
   */
  regionNumber?: number | null;
  /**
   * Numer ulicy
   * W przypadku officeLocation to pole nie jest obsługiwane
   * @format int32
   */
  streetNumber?: number | null;
  /**
   * Nazwa miejscowości
   * @example "Mazowieckie, Warszawa"
   */
  name?: string;
}

export interface BailiffNoticeFilterCreateRequest {
  /**
   * Nazwa filtra
   * @example "Any filter name"
   */
  name: string;
  /**
   * Czas opóźnień powiadomień
   * @format int32
   * @default 0
   * @example 720
   */
  notificationsDelay: number;
  /**
   * Powiadomienia sms
   * @example true
   */
  alertSms?: boolean | null;
  /**
   * Powiadomienia email
   * @example true
   */
  alertEmail?: boolean | null;
  /**
   * Powiadomienia push
   * @example true
   */
  alertPush?: boolean | null;
  /** Lokalizacje zadłużonych nieruchomości */
  locations?: FilterLocation[];
  /** Lokalizacje kancelarii */
  officeLocations?: FilterLocation[];
  /**
   * Pożądane słowa kluczowe
   * @example ["XYZ","YXZ","YZX"]
   */
  wantedKeywords?: (string | null)[];
  /**
   * Niepożądane słowa kluczowe
   * @example ["XYZ","YXZ","YZX"]
   */
  unwantedKeywords?: (string | null)[];
  /**
   * Typy nieruchomości
   * @example ["Mieszkania","Domy","Grunty"]
   */
  types?: BailiffNoticeFilterCreateRequestTypesEnum;
  /**
   * Ogłoszenia z liczby dni
   * @format int32
   * @min 1
   * @example 3
   */
  daysAmount?: number | null;
  /**
   * Data dodania od
   * @format date
   * @example "2024-01-01"
   */
  dateAddedFrom?: string | null;
  /**
   * Data dodania do
   * @format date
   * @example "2024-01-04"
   */
  dateAddedTo?: string | null;
  /**
   * Akceptuj puste wartości
   * @example true
   */
  includeZeroArea?: boolean | null;
}

export interface BailiffNoticeSearchingRequest {
  /**
   * Domyślnie Sortowanie
   * Do wyboru DATE_DESC oraz DATE_ASC
   */
  sortType?: BailiffNoticeSearchingRequestSortTypeEnum;
  /**
   * Ogłoszenia z liczby dni
   * @format int32
   * @example 3
   */
  daysAmount?: number | null;
  /**
   * Data dodania od
   * @format date
   * @example "2024-01-01"
   */
  dateAddedFrom?: string | null;
  /**
   * Data dodania do
   * @format date
   * @example "2024-01-01"
   */
  dateAddedTo?: string | null;
  /**
   * Typ nieruchomości
   * @example ["Mieszkania","Grunty"]
   */
  types?: (string | null)[];
  /**
   * Akceptuj puste wartości
   * @example true
   */
  includeZeroArea?: boolean | null;
  /**
   * Niepożądane słowa kluczowe
   * @example ["XYZ","YXZ","YZX"]
   */
  unwantedKeywords?: (string | null)[];
  /**
   * Pożądane słowa kluczowe
   * @example ["XYZ","YXZ","YZX"]
   */
  wantedKeywords?: (string | null)[];
  /** Lokalizacje zadłużonych nieruchomości */
  locations?: Location[];
  /** Lokalizacje kancelarii */
  officeLocations?: Location[];
}

/** Lokalizacja */
export interface Location {
  /**
   * Numer województwa
   * @format int32
   */
  voivodeshipNumber?: number | null;
  /**
   * Numer powiatu
   * @format int32
   */
  countyNumber?: number | null;
  /**
   * Numer gminy
   * @format int32
   */
  communityNumber?: number | null;
  /**
   * Numer typu gminy, miejscowości
   * @format int32
   */
  kindNumber?: number | null;
  /**
   * Numer części miejscowości
   * W przypadku officeLocation to pole nie jest obsługiwane
   * @format int32
   */
  subRegionNumber?: number | null;
  /**
   * Numer miasta, dzielnicy, części itp.
   * W przypadku officeLocation to pole nie jest obsługiwane
   * @format int32
   */
  regionNumber?: number | null;
  /**
   * Numer ulicy
   * W przypadku officeLocation to pole nie jest obsługiwane
   * @format int32
   */
  streetNumber?: number | null;
}

export interface BailiffNoticeResponse {
  /**
   * Id ogłoszenia
   * @format int64
   */
  bailiffNoticeId: number;
  /** Id ogłoszenia */
  encryptedId: string;
  /**
   * Moduł
   * @example "BAILIFF_NOTICE"
   */
  module?: string;
  /**
   * Lokalizacja zadłużonej nieruchomości
   * @example "Wielkopolskie, Leszczyński, Święciechowa, Ul. Leszczyńska"
   */
  location: string;
  /**
   * Liczba notatek
   * @format int32
   * @example 2
   */
  numberOfNotes: number;
  newestNote?: NoteResponse;
  reaction?: ReactionResponse;
  /**
   * Link do ogłoszenia
   * @example "https://licytacje.komornik.pl/link"
   */
  link: string;
  /**
   * Data dodania ogłoszenia
   * @example "17.12.2027, 09:53"
   */
  dateAdded: string;
  /**
   * Sygnatura
   * @example "GKm 9/23"
   */
  signature?: string;
  /**
   * Księga wieczysta
   * @example "XY1Z/00055315/5"
   */
  kwNumber?: string;
  /**
   * Data oszacowania
   * @example "17.11.2027, 09:53"
   */
  estimateDate?: string;
  /**
   * Lokalizacja kancelarii
   * @example "Wielkopolskie, Leszno"
   */
  officeLocation?: string;
  /**
   * Typ nieruchomości
   * @example "Mieszkania"
   */
  type?: string;
  /**
   * Zdjęcie
   * @example "20240725141005_gNnqQucA.webp"
   */
  image?: string;
  /**
   * Nazwa kancelarii komorniczej
   * @example "Komornik Sądowy przy Sądzie Rejonowym w Żywcu"
   */
  office: string;
  blockageInfo?: BlockageInfoDto;
}

/** Informacje o blokadzie */
export type BlockageInfoDto = {
  /**
   * Id oryginalnego ogłoszenia zablokowanego
   * Id oryginalnego ogłoszenia mogą się zmienić podczas działania aplikacji
   * @format int64
   * @example 1
   */
  originalAdvertisementId: number;
  /**
   * Czas blokady w dniach
   * @format int32
   * @example 14
   */
  days: number;
  /**
   * Data blokady
   * @format date-time
   */
  blockedDate: string;
  /**
   * Nazwa użytkownika blokującego ogłoszenie
   * @example "Jan Kowalski"
   */
  userName: string;
  /**
   * Czy blokada jest stała
   * @example true
   */
  permanentlyBlocked: boolean;
} | null;

/**
 * Notatka
 * @example {"noteId":null,"content":"Dowolny opis","date":"2024-08-22T07:34:12.770Z"}
 */
export interface NoteResponse {
  /**
   * Id notatki
   * @format int64
   * @example 1
   */
  noteId: number;
  /**
   * Treść notatki
   * @example "Dowolna treść"
   */
  content: string;
  /**
   * Data dodania notatki
   * @format date-time
   */
  date: string;
}

/**
 * Reakcja
 * @example {"likeStatus":true,"unlikeStatus":false,"viewedStatus":false,"outdatedStatus":false,"arrangedStatus":false,"phoneStatus":false,"hideStatus":false,"openedStatus":false}
 */
export interface ReactionResponse {
  /**
   * Status 'lubię'
   * @example true
   */
  likeStatus?: boolean;
  /**
   * Status 'nie lubię'
   * @example true
   */
  unlikeStatus?: boolean;
  /**
   * Status 'obejrzane'
   * @example true
   */
  viewedStatus?: boolean;
  /**
   * Status 'nieaktualne'
   * @example true
   */
  outdatedStatus?: boolean;
  /**
   * Status 'spotkania'
   * @example true
   */
  arrangedStatus?: boolean;
  /**
   * Status 'telefonu'
   * @example true
   */
  phoneStatus?: boolean;
  /**
   * Status 'ukrycia
   * @example true
   */
  hideStatus?: boolean;
  /**
   * Status 'otwarte'
   * @example true
   */
  openedStatus?: boolean;
}

export interface Page {
  /** @format int32 */
  totalPages?: number;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  size?: number;
  content?: object[];
  /** @format int32 */
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  /** @format int32 */
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface PageableObject {
  /** @format int64 */
  offset?: number;
  sort?: SortObject;
  paged?: boolean;
  unpaged?: boolean;
  /** @format int32 */
  pageNumber?: number;
  /** @format int32 */
  pageSize?: number;
}

export interface SortObject {
  empty?: boolean;
  unsorted?: boolean;
  sorted?: boolean;
}

export interface BailiffNoticeDetailsHashResponse {
  /**
   * Id ogłoszenia
   * @format int64
   * @example 1
   */
  bailiffNoticeId: number;
  /** Id ogłoszenia */
  encryptedId: string;
  /**
   * Nazwa kancelarii komorniczej
   * @example "Komornik Sądowy przy Sądzie Rejonowym w Żywcu"
   */
  office: string;
  /**
   * Sygnatura
   * @example "GKm 9/23"
   */
  signature?: string;
  /**
   * Księga wieczysta
   * @example "XY1Z/00055315/5"
   */
  kwNumber?: string;
  /**
   * Data oszacowania
   * @example "17.11.2027, 09:53"
   */
  estimateDate?: string;
  /**
   * Lokalizacja kancelarii
   * @example "Wielkopolskie, Leszno"
   */
  officeLocation?: string;
  /**
   * Lokalizacja zadłużonej nieruchomości
   * @example "Wielkopolskie, Leszczyński, Święciechowa, Ul. Leszczyńska"
   */
  location: string;
  /**
   * Data dodania ogłoszenia
   * @example "17.12.2027, 09:53"
   */
  dateAdded: string;
  /**
   * Link do ogłoszenia
   * @example "https://licytacje.komornik.pl/link"
   */
  link: string;
  /**
   * Typ nieruchomości
   * @example "Mieszkania"
   */
  type?: string;
  /**
   * Opis
   * @example "Dowolny opis"
   */
  description?: string;
  reaction?: ReactionResponse;
  /**
   * Notatka
   * @example {"noteId":null,"content":"Dowolny opis","date":"2024-08-22T07:34:12.770Z"}
   */
  notes?: NoteResponse[];
  /**
   * Liczba notatek
   * @format int32
   * @example 2
   */
  numberOfNotes: number;
  /**
   * Zdjęcie
   * @example "20240725141005_gNnqQucA.webp"
   */
  image?: string;
}

export interface BailiffNoticeFilterListResponse {
  /**
   * Id filtra
   * @format int64
   * @example 1
   */
  filterId: number;
  /** Nazwa filtra */
  name: string;
  /**
   * Powiadomienia sms
   * @example true
   */
  alertSms: boolean;
  /**
   * Powiadomienia email
   * @example true
   */
  alertEmail: boolean;
  /**
   * Powiadomienia push
   * @example true
   */
  alertPush: boolean;
}

export interface BailiffNoticeDetailsResponse {
  /**
   * Id ogłoszenia
   * @format int64
   * @example 1
   */
  bailiffNoticeId: number;
  /** Id ogłoszenia */
  encryptedId: string;
  /**
   * Nazwa kancelarii komorniczej
   * @example "Komornik Sądowy przy Sądzie Rejonowym w Żywcu"
   */
  office: string;
  /**
   * Sygnatura
   * @example "GKm 9/23"
   */
  signature?: string;
  /**
   * Księga wieczysta
   * @example "XY1Z/00055315/5"
   */
  kwNumber?: string;
  /**
   * Data oszacowania
   * @example "17.11.2027, 09:53"
   */
  estimateDate?: string;
  /**
   * Lokalizacja kancelarii
   * @example "Wielkopolskie, Leszno"
   */
  officeLocation?: string;
  /**
   * Lokalizacja zadłużonej nieruchomości
   * @example "Wielkopolskie, Leszczyński, Święciechowa, Ul. Leszczyńska"
   */
  location: string;
  /**
   * Data dodania ogłoszenia
   * @example "17.12.2027, 09:53"
   */
  dateAdded: string;
  /**
   * Link do ogłoszenia
   * @example "https://licytacje.komornik.pl/link"
   */
  link: string;
  /**
   * Typ nieruchomości
   * @example "Mieszkania"
   */
  type?: string;
  /**
   * Opis
   * @example "Dowolny opis"
   */
  description?: string;
  reaction?: ReactionResponse;
  /**
   * Notatka
   * @example {"noteId":null,"content":"Dowolny opis","date":"2024-08-22T07:34:12.770Z"}
   */
  notes?: NoteResponse[];
  /**
   * Zdjęcie
   * @example "20240725141005_gNnqQucA.webp"
   */
  image?: string;
}

/**
 * Typy nieruchomości
 * @example ["Mieszkania","Domy","Grunty"]
 */
export enum BailiffNoticeFilterResponseTypesEnum {
  Mieszkania = "Mieszkania",
  Domy = "Domy",
  Grunty = "Grunty",
  Pozostale = "Pozostałe",
}

/**
 * Typy nieruchomości
 * @example ["Mieszkania","Domy","Grunty"]
 */
export enum BailiffNoticeFilterCreateRequestTypesEnum {
  Mieszkania = "Mieszkania",
  Domy = "Domy",
  Grunty = "Grunty",
  Pozostale = "Pozostałe",
}

/**
 * Domyślnie Sortowanie
 * Do wyboru DATE_DESC oraz DATE_ASC
 */
export enum BailiffNoticeSearchingRequestSortTypeEnum {
  DATE_DESC = "DATE_DESC",
  DATE_ASC = "DATE_ASC",
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "http://192.168.31.12:8080";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title OpenAPI definition
 * @version v0
 * @baseUrl http://192.168.31.12:8080
 */
export class Api<SecurityDataType extends unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  bailiffNoticeOffer = {
    /**
     * No description
     *
     * @tags bailiff-notice-filter-controller
     * @name EditFilter
     * @summary Edycja filtra
     * @request PUT:/bailiff-notice-offer/filter/edit
     * @secure
     */
    editFilter: (data: BailiffNoticeFilterResponse, params: RequestParams = {}) =>
      this.http.request<object, void>({
        path: `/bailiff-notice-offer/filter/edit`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags bailiff-notice-filter-controller
     * @name CreateFilter
     * @summary Tworzenie nowego filtra
     * @request POST:/bailiff-notice-offer/filter/create
     * @secure
     */
    createFilter: (data: BailiffNoticeFilterCreateRequest, params: RequestParams = {}) =>
      this.http.request<number, void>({
        path: `/bailiff-notice-offer/filter/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags bailiff-notice-controller
     * @name FindBailiffNotices
     * @summary Pobieranie listy ogłoszeń
     * @request POST:/bailiff-notice-offer/advertisement
     * @secure
     */
    findBailiffNotices: (
      query: {
        /** @format int32 */
        page: number;
        /** @format int32 */
        size: number;
      },
      data: BailiffNoticeSearchingRequest,
      params: RequestParams = {},
    ) =>
      this.http.request<BailiffNoticeResponse, any>({
        path: `/bailiff-notice-offer/advertisement`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wyszukiwanie szczegółów ogłoszeń - hash
     * @name FullHashAdvertisement
     * @summary Ogłoszenie komornicze wraz z wszystkimi danymi wyszukiwane po hash
     * @request GET:/bailiff-notice-offer/single-advertisement/detail
     * @secure
     */
    fullHashAdvertisement: (
      query: {
        hash: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<BailiffNoticeDetailsHashResponse, object>({
        path: `/bailiff-notice-offer/single-advertisement/detail`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags bailiff-notice-filter-controller
     * @name FindUserFilters
     * @summary Pobieranie listy filtrów użytkownika (Bez szczegółów)
     * @request GET:/bailiff-notice-offer/filter/list
     * @secure
     */
    findUserFilters: (params: RequestParams = {}) =>
      this.http.request<BailiffNoticeFilterListResponse, any>({
        path: `/bailiff-notice-offer/filter/list`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags bailiff-notice-filter-controller
     * @name GetFilterDetails
     * @summary Pobieranie wszystkie szczegóły poszczególnego filtra
     * @request GET:/bailiff-notice-offer/filter/details
     * @secure
     */
    getFilterDetails: (
      query: {
        /**
         * Id filtra
         * @format int64
         */
        id: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<BailiffNoticeFilterResponse, string>({
        path: `/bailiff-notice-offer/filter/details`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags bailiff-notice-controller
     * @name GetBailiffNoticeDetails
     * @summary Pobieranie szczegółów podanego ogłoszenia
     * @request GET:/bailiff-notice-offer/advertisement/details
     * @secure
     */
    getBailiffNoticeDetails: (
      query: {
        /** @format int64 */
        id: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<BailiffNoticeDetailsResponse, any>({
        path: `/bailiff-notice-offer/advertisement/details`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags bailiff-notice-filter-controller
     * @name DeleteFilter
     * @summary Usuwanie filtra
     * @request DELETE:/bailiff-notice-offer/filter/delete
     * @secure
     */
    deleteFilter: (
      query: {
        /**
         * Id filtra
         * @format int64
         */
        id: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<object, void>({
        path: `/bailiff-notice-offer/filter/delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),
  };
}
