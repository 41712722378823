import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Button from 'components/atom/Button';

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: fit-content;
    padding: 30px 100px;
    background: var(--color-white);
    border-radius: var(--box-border-radius);
    min-height: 400px;

    h1 {
        --color-primary: ${(props) => props.darkMode ? '#fff' : '#000'};
        line-height: 30px;
        margin-bottom: 20px;
    }
    
    p {
        margin-bottom: 20px;
    }
    
    a {
        text-decoration: none;
    }
`;

const UserNotActiveInfo = ({ isDarkMode }: { isDarkMode: boolean }) => {
    return (
        <InfoWrapper darkMode={isDarkMode}>
            <h1>Twój profil nie został aktywowany</h1>
            <p>Kliknij w poniższy przycisk aby aktywować profil</p>
            <Link to={'/my-settings'}>
                <Button>
                    Przejdź do profilu
                </Button>
            </Link>
        </InfoWrapper>
    );
};

export default UserNotActiveInfo;
