import {
    UserMyOffersResponseContent,
    UserOffersResponseContent,
    UserProfileOffersResponseContent
} from 'store/User/State';

import { BoxInfoDtoModuleEnum } from './types/UserModels';

export const getSubmainValues = (offer: UserOffersResponseContent | UserProfileOffersResponseContent | UserMyOffersResponseContent) => {
    switch (offer.module) {
    case BoxInfoDtoModuleEnum.USER_OFFER_DEAL:
        return offer.roi ? [`ROI: ${offer.roi}%`] : [];
    case BoxInfoDtoModuleEnum.USER_OFFER_INVESTOR:
        return offer.percentageOfInvestment ? [`${offer.percentageOfInvestment}%`] : [];
    case BoxInfoDtoModuleEnum.USER_OFFER_CAPITAL:
        return offer.expectedReturnRate ? [`ROI: ${offer.expectedReturnRate}%`] : [];
    default:
        return [];
    }
};
