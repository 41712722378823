import React, { useState } from 'react';
import { useOffers } from 'store/Offers/Context';
import { applyViewedStatus } from 'utils/modules/modulesUtils';

import MiniCard from 'components/common/Card/MiniCard';
import OfferDetails from 'components/common/Card/OfferDetails';
import OfferDetailsModal from 'components/common/OfferDetailsModal';

const SingleCooperative = ({ data }) => {
    const [detailsOpen, toggleDetailsOpen] = useState(false);
    const [preferredTab, setPreferredTab] = useState(undefined);
    const { dispatch } = useOffers();
    const isNote = typeof data.note.noteId === 'number';

    const toggleContainerDetails = (e) => {
        if (e.target.dataset.notclickable || e.target.closest('[data-notclickable]')) return;

        setPreferredTab(undefined);
        setTimeout(() => {
            applyViewedStatus(data, detailsOpen, dispatch, 'bailiff_notice');

            toggleDetailsOpen((prev) => !prev);
        }, 100);
    };

    const openOfferTab = (name) => {
        setPreferredTab(name);
        setTimeout(() => {
            toggleDetailsOpen((prev) => !prev);
        }, 100);
    };

    return (
        <>
            <MiniCard onClick={toggleContainerDetails} data={data} type={'bailiff_notice'} openOfferTab={openOfferTab}
                showNote={isNote}/>
            <OfferDetailsModal isOpen={detailsOpen} onClose={toggleContainerDetails}>
                <OfferDetails
                    type={'bailiff_notice'}
                    data={data}
                    onToggleContainerDetails={toggleContainerDetails}
                    preferredTab={preferredTab}
                />
            </OfferDetailsModal>
        </>
    );
};

export default SingleCooperative;
