import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';

import Icon, { IconEnum } from 'components/common/Icon';

const InfoBox = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: 20px;
    width: max-content;

    span {
        margin-right: 10px;
        filter: unset !important;
    }

    &.is-white {
        background: var(--color-white);
        color: var(--color-primary);
    }

    &.is-warning {
        background: var(--color-warning);
        color: ${(props) => props.darkMode ? 'var(--color-primary)' : 'var(--color-white)'};
    }

    &.is-success {
        background: var(--color-success);
        color: ${(props) => props.darkMode ? 'var(--color-primary)' : 'var(--color-white)'};
    }

    &.is-gray {
        background: var(--color-alt-second);
        color: var(--color-primary);
    }
`;

interface InfoBadgeProperties {
    isSuccess?: boolean,
    isWarning?: boolean,
    isWhite?: boolean,
    isGray?: boolean,
    showInfoIcon?: boolean,
    children: ReactNode
    onMouseEnter?: () => void
    onMouseLeave?: () => void
    onClick?: (e: Event) => void
}

const InfoBadge: FC<InfoBadgeProperties> = ({
    isSuccess,
    isWarning,
    isWhite,
    isGray,
    children,
    onMouseEnter,
    onMouseLeave,
    onClick,
    showInfoIcon
}) => {
    const { user } = useUser();

    return (
        <InfoBox darkMode={user.isDarkMode} onClick={onClick} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}
            className={clsx({
                'is-success': isSuccess,
                'is-warning': isWarning,
                'is-white': isWhite,
                'is-gray': isGray
            })}>
            {(isWarning || showInfoIcon) && <Icon icon={IconEnum.INFO_WHITE}/>}
            {children}
        </InfoBox>
    );
};

export default InfoBadge;
