import React, { FC, useContext, useMemo, useState } from 'react';
import { OffersActions } from 'store/Offers/Actions';
import { useOffers } from 'store/Offers/Context';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';
import FilterUtilsContext from 'utils/context/FiltersUtils';
import { getStringFromDate } from 'utils/getStringFromDate';
import { MultipleFilterType } from 'utils/types/InputTypes';
import { ModulesType } from 'utils/types/ModulesType';
import { OfferData } from 'utils/types/OfferData';

import Button from './atom/Button';
import SingleAuction from './auctions/SingleAuction';
import SingleBailiff from './bailiff/SingleBailiff';
import ActiveFilters from './common/ActiveFilters';
import ClientPagination from './common/Pagination/ClientPagination';
import SingleCooperative from './cooperatives/SingleCooperative';
import { getValueFromState } from './functions/advertisementsFuncs';
import SingleRent from './rent/SingleRent';
import SingleAdvertisement from './sales/SingleAdvertisement';
import SingleUsers from './users/SingleUsers';

const Container = styled.div`
  padding: 60px 20px 0;
    
    @media(max-width: 1100px) {
        padding: 20px 20px 0;
    }
`;

const ContainerList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const ShowMoreSection = styled.div`
  margin: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ShowMoreButton = styled(Button)`
  background: var(--color-alt) !important;
  margin-bottom: 12px;

  img {
    filter: ${(props) => props.darkMode ? 'invert(77%) sepia(83%) saturate(1712%) hue-rotate(194deg) brightness(90%) contrast(103%)' : 'invert(14%) sepia(99%) saturate(2391%) hue-rotate(198deg) brightness(93%) contrast(101%)'};
  }
`;

const ShowMoreDescription = styled.p`
  --font-size-body: var(--font-size-body-3);

  opacity: .7;
  line-height: 18px;
  text-align: center;
`;

const NoItemsInfo = styled.div`
  text-align: center;
  margin-top: 50px;
  margin-left: 15px;
  margin-right: 15px;
  min-height: 300px;
  height: fit-content;
  padding: 30px 0;
  background: var(--color-white);
  border-radius: var(--box-border-radius);
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  h1 {
    --color-primary: ${(props) => props.darkMode ? '#fff' : '#000'};
    line-height: 30px;
    padding: 40px;
  }

  div {
    margin-top: 15px;
    padding: 0 40px;

    p {
      line-height: 30px;
      margin-top: 10px;

      &:first-child {
        margin-bottom: 35px;
      }
    }
  }
`;

interface MainListProperties {
    type: ModulesType,
    showList?: boolean,
    showNoItemsText?: boolean,
}

const MainList: FC<MainListProperties> = ({ type, showList, showNoItemsText }) => {
    const [renderList, setRenderList] = useState<OfferData[]>([]);
    const [isLastPage, setIsLastPage] = useState<boolean>(false);
    const [isBtnClicked, setIsBtnClicked] = useState<boolean>(false);
    const { offers, dispatch } = useOffers();
    const { user } = useUser();
    const { activeFiltersState, handleChange, handleActiveFilterChange } = useContext(FilterUtilsContext);

    const handleChangePage = (currentPage: number, amountSites: number) => {
        setIsLastPage(currentPage === amountSites);
    };

    const handleOlderAdverts = () => {
        const date = offers.filtersState.dateFrom ? new Date(new Date(offers.filtersState.dateFrom).setDate(new Date(offers.filtersState.dateFrom).getDate() - 6)) : null;
        const dateString = date && getStringFromDate(date.toDateString());

        setIsBtnClicked(true);
        OffersActions.loadOffersAsync(dispatch, offers.pageType, offers.filteredCity, {
            ...offers.filtersState,
            // @ts-expect-error TODO: need to specify if date here can be null
            dateFrom: dateString
        }).then(() => {
            setTimeout(() => {
                setIsBtnClicked(false);
            }, 300);
        });
        setIsLastPage(false);
    };

    const changeFilterValue = (name: string, value: MultipleFilterType) => {
        handleChange(name, value);
        handleActiveFilterChange(name, value);
    };

    const handleActiveFiltersChange = () => {
        OffersActions.loadOffersAsync(dispatch, type, activeFiltersState.city, getValueFromState(activeFiltersState, type));
    };

    const OfferRendererContainer = useMemo(() => {
        switch (type) {
        case 'bailiff_notice':
            return SingleBailiff;
        case 'cooperative':
            return SingleCooperative;
        case 'auction':
        case 'tender':
            return SingleAuction;
        case 'rent':
            return SingleRent;
        case 'users':
        case 'my_offer':
            return SingleUsers;
        case 'advertisement':
        default:
            return SingleAdvertisement;
        }
    }, [type]);

    const renderedElements = useMemo(() => renderList.map((el) => {
        if (type === 'tender' && el.portal.link.includes('licytacje.komornik.pl')) return null;
        if (type === 'auction' && !el.portal.link.includes('licytacje.komornik.pl')) return null;

        return (
            <OfferRendererContainer
                key={el.id}
                data={el}
                type={type}
            />
        );
    }), [
        OfferRendererContainer,
        renderList,
        type
    ]);

    const activeFilters = useMemo(() => {
        if (Object.keys(activeFiltersState).length > 0) {
            return (
                <ActiveFilters state={activeFiltersState} moduleType={type} onChange={handleActiveFiltersChange}
                    // @ts-expect-error TODO: need to types
                    changeFilterValue={changeFilterValue}/>
            );
        }
    }, [renderList, activeFiltersState]);

    return (
        showList || showNoItemsText
            ? <Container id="offer-list">
                {activeFilters}
                {showList
                    ? <>
                        <ContainerList>
                            {renderedElements}
                        </ContainerList>
                        {isLastPage && offers.pageType
                            ? <ShowMoreSection>
                                <ShowMoreButton darkMode={user.isDarkMode} onClick={handleOlderAdverts}>
                                    Pokaż starsze ogłoszenia...
                                </ShowMoreButton>
                                <ShowMoreDescription>
                                    Skończyły się ogłoszenia w wybranym przedziale.
                                </ShowMoreDescription>
                                <ShowMoreDescription>
                                    Aby zobaczyć starsze ogłoszenia naciśnij powyższy przycisk.
                                </ShowMoreDescription>
                            </ShowMoreSection>
                            : null}
                        <ClientPagination isShowMoreClicked={isBtnClicked} onChangeRenderList={setRenderList}
                            onChangePage={handleChangePage} />
                    </>
                    : showNoItemsText
                        ? <NoItemsInfo darkMode={user.isDarkMode}>
                            <h1>Nie znaleźliśmy żadnych ogłoszeń</h1>
                            <div><p>Obecnie w naszej bazie nie posiadamy ogłoszeń, które spełniają twoje kryteria.
                                Spróbuj
                                zmienić kryteria i wyszukaj jeszcze raz.</p>

                            <p>Chcesz otrzymać powiadomienie, jeśli znajdziemy nieruchomość spełniającą Twoje
                                kryteria?
                                Zapisz swoje wyszukiwanie, aby otrzymywać powiadomienia.</p></div>
                        </NoItemsInfo>
                        : null}
            </Container>
            : null
    );
};

export default MainList;
