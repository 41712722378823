import React, { useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import AnalysisSaleContext from 'utils/context/AnalysisSaleContext';

import AnalysisSaleList, { AnalysisElements } from 'components/analysis/AnalysisSaleList';
import FiltersAnalysisSale from 'components/analysis/FiltersAnalysisSale';
import Indicator from 'components/atom/Indicator';
import FiltersContentWrapper from 'components/common/FiltersContentWrapper';
import V2HeaderSection from 'components/V2HeaderSection';

const Container = styled.div`
    padding: 15px 20px 0;
`;

const PageContainer = styled.div`
    height: 100%;
`;

const AnalysisPage = () => {
    const { analysisSaleState, analysisSaleFiltersState } = useContext(AnalysisSaleContext);
    const [data, setData] = useState<AnalysisElements>({ areaAnalysisList: [], timeAnalysisList: [], trendResult: [] });
    const { chartData, afterSearch } = analysisSaleState;
    const analysisPrepared = data.areaAnalysisList && data.areaAnalysisList.length > 0 ||
        data.timeAnalysisList && data.timeAnalysisList.length > 0 ||
        data.trendResult && data.trendResult.length > 0;
    const pdfRef = useRef();

    useEffect(() => {
        if (chartData && chartData.length > 0) {
            const [{ analysisAreaRows: areaAnalysisList, analysisTimeRows: timeAnalysisList, trendResult }] = chartData || {};

            setData({ areaAnalysisList, timeAnalysisList, trendResult });
        }
    }, [chartData]);

    return (
        <PageContainer>
            <V2HeaderSection
                title={'Analiza cen ofertowych'}
            />
            <FiltersContentWrapper className={clsx('offers-list', { active: analysisPrepared || afterSearch })}>
                <Container id="filters">
                    <FiltersAnalysisSale />
                </Container>
                {/* @ts-expect-error dont need ref now*/}
                <AnalysisSaleList ref={pdfRef} showList={!!analysisPrepared} analysis={data}
                    showNoItemsText={!analysisPrepared && afterSearch} timeRange={analysisSaleFiltersState.timeRange}/>
                {analysisSaleState.isLoading && <Indicator/>}
            </FiltersContentWrapper>
        </PageContainer>
    );
};

export default AnalysisPage;
