import { ObjectAndSingleValueChangeType } from 'utils/types/InputTypes';

export enum AdvertValidatorType {
    NO_EMPTY_TEXT = 'noEmptyText',
}

const checkIfEmptyValidation = (value: ObjectAndSingleValueChangeType) => {
    return typeof value === 'string' ? value?.length === 0 : true;
};

export const ensureString = (value: string | undefined | null): string => {
    return value ?? '';
};

export const isFieldValid = (validationType: AdvertValidatorType, value: ObjectAndSingleValueChangeType) => {
    if (validationType === AdvertValidatorType.NO_EMPTY_TEXT) {
        return !checkIfEmptyValidation(value);
    }

    return false;
};
