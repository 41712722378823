
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';
import { ActiveFilterEnum } from 'utils/enums/ActiveFilterEnum';
import { Packages } from 'utils/enums/packages';
import { useBailiffHook } from 'utils/hooks/useBailiffHook';
import { useRentHook } from 'utils/hooks/useRentHook';
import { useSalesHook } from 'utils/hooks/useSalesHook';
import { ModulesType } from 'utils/types/ModulesType';
import { Module as SaleModule } from 'utils/types/SaleModels';

import Button from 'components/atom/Button';
import Indicator from 'components/atom/Indicator';
import AccordionCard from 'components/common/AccordionCard';
import InteractiveIcons from 'components/common/Card/common/InteractiveIcons';
import FiltersContentWrapper from 'components/common/FiltersContentWrapper';
import { IconEnum } from 'components/common/Icon';
import InputContainer from 'components/common/InputContainer';
import TextInput from 'components/common/Inputs/TextInput';
import Module from 'components/common/Module';
import HeaderSection from 'components/HeaderSection';
import MainList from 'components/MainList';
import V2OfferList, { OfferListProperties } from 'components/offerList/V2OfferList';
import V2HeaderSection from 'components/V2HeaderSection';

const Wrapper = styled.div`
    .active .accordion-card-content {
        row-gap: 10px;
    }
`;

const FiltersContainer = styled.div`
    padding-left: 20px;

    @media (max-width: 1100px) {
        padding-right: 20px;
    }
`;

export interface FavoritePageFiltersType {
    likeStatus: boolean;
    unlikeStatus: boolean;
    viewedStatus: boolean;
    noteStatus: boolean;
    arrangedStatus: boolean;
    phoneStatus: boolean;
    hideStatus: boolean;
    desiredNotePhrase: string;
}

const FavoritesPage: FC = () => {
    const [selectedSaleModules, setSelectedSaleModules] = useState<SaleModule[]>([
        SaleModule.SALE_FLAT,
        SaleModule.SALE_HOUSE,
        SaleModule.SALE_PLOT,
        SaleModule.SALE_OTHER
    ]);

    const [activeFilter, setActiveFilter] = useState<ActiveFilterEnum>(ActiveFilterEnum.ADVERTISEMENT);

    const [filters, setFilters] = useState<FavoritePageFiltersType>({
        likeStatus: false,
        unlikeStatus: false,
        viewedStatus: false,
        noteStatus: false,
        arrangedStatus: false,
        hideStatus: false,
        phoneStatus: false,
        desiredNotePhrase: ''
    });

    const [searchPhrase, setSearchPhrase] = useState('');
    const { user } = useUser();
    const {
        handleChangePage,
        handleFavoriteSearch,
        renderList,
        handleActiveFiltersChange,
        offers
    } = useRentHook(filters, activeFilter);
    const {
        handleChangePage: handleSaleChangePage,
        handleFavoriteSearch: handleSaleFavoriteSearch,
        renderList: saleRenderList,
        saleOfferState
    } = useSalesHook(filters, activeFilter, selectedSaleModules);
    const {
        handleChangePage: handleBailiffChangePage,
        handleFavoriteSearch: handleBailiffFavoriteSearch,
        renderList: bailiffRenderList,
        bailiffOfferState
    } = useBailiffHook(filters, activeFilter);

    const [advertisementCount, setAdvertisementCount] = useState(0);
    const [bailiffCount, setBailiffCount] = useState(0);

    useEffect(() => {
        setAdvertisementCount(saleOfferState.saleOfferList.totalElements);
    }, [saleOfferState.saleOfferList.totalElements]);

    useEffect(() => {
        setSelectedSaleModules([
            SaleModule.SALE_FLAT,
            SaleModule.SALE_HOUSE,
            SaleModule.SALE_PLOT,
            SaleModule.SALE_OTHER
        ]);
    }, [activeFilter]);

    useEffect(() => {
        setBailiffCount(bailiffOfferState.bailiffOfferList.totalElements);
    }, [bailiffOfferState.bailiffOfferList.totalElements]);

    const getOffersCount = (activeFilter: ActiveFilterEnum) => {
        switch (activeFilter) {
        case ActiveFilterEnum.BAILIFF_NOTICE:
            return bailiffCount;
        case ActiveFilterEnum.ADVERTISEMENT:
        default:
            return advertisementCount;
        }
    };

    const handleFilterChange = (activeFilter: ModulesType) => {
        let filter = activeFilter;

        if (activeFilter === 'tender') {
            filter = 'auction';
        }

        if (activeFilter === 'advertisement') {
            handleSaleFavoriteSearch(selectedSaleModules);
        } else if (activeFilter === 'bailiff_notice') {
            handleBailiffFavoriteSearch();
        } else {
            handleFavoriteSearch(filter);
        }
    };

    useEffect(() => {
        handleFilterChange(activeFilter);
    }, [activeFilter, selectedSaleModules]);

    const handleFilter = (type: keyof FavoritePageFiltersType) => {
        let phrase = searchPhrase;

        if (type === 'noteStatus') {
            setSearchPhrase('');
            phrase = '';
        }

        setFilters({ ...filters, desiredNotePhrase: phrase, [type]: !filters[type] });
    };

    const handleChangeFilter = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        if (value.length >= 1) {
            setFilters({ ...filters, desiredNotePhrase: value, noteStatus: true });
        } else {
            setFilters({ ...filters, desiredNotePhrase: value, noteStatus: false });
        }

        setSearchPhrase(value);
    };

    const handleSearch = () => {
        setFilters({ ...filters, desiredNotePhrase: searchPhrase });
        handleFilterChange(activeFilter);
    };

    const onClearFilters = () => {
        setFilters({
            likeStatus: false,
            unlikeStatus: false,
            viewedStatus: false,
            noteStatus: false,
            arrangedStatus: false,
            hideStatus: false,
            phoneStatus: false,
            desiredNotePhrase: ''
        });
        setSearchPhrase('');
    };

    const [offersListProps, setOffersListProps] = useState<OfferListProperties>({
        totalPages: offers.rentList?.totalPages,
        handleChangePage,
        lastPage: offers.rentList?.last,
        pageNumber: offers.rentList?.pageable?.pageNumber || 1,
        showList: !offers.rentList?.empty && offers.rentList?.content?.length > 0,
        handleActiveFiltersChange,
        showNoItemsText: offers.rentList && offers.rentList.empty && offers.rentList.content.length === 0 && offers.rentList.afterSearch,
        renderList
    });

    const handleSaleModuleToggle = (module: SaleModule) => {
        setSelectedSaleModules((prev) => {
            if (prev.includes(module)) {
                if (prev.length === 1) {
                    return prev;
                }
                const updatedModules = prev.filter((m) => m !== module);

                return updatedModules;
            } else {
                return [...prev, module];
            }
        });
    };

    const setOffersListPropsBasedOnFilter = () => {
        if (activeFilter === 'advertisement') {
            setOffersListProps({
                totalPages: saleOfferState.saleOfferList?.totalPages,
                handleChangePage: (currentPage: number, rows?: number) => handleSaleChangePage(currentPage, rows),
                lastPage: saleOfferState.saleOfferList?.last,
                pageNumber: saleOfferState.saleOfferList?.pageable?.pageNumber || 1,
                showList: !saleOfferState.saleOfferList?.empty && saleOfferState.saleOfferList?.content?.length > 0,
                renderList: saleRenderList,
                showNoItemsText: saleOfferState.saleOfferList && saleOfferState.saleOfferList.empty && saleOfferState.saleOfferList.content.length === 0 && saleOfferState.saleOfferList.afterSearch
            });
        } else if (activeFilter === 'bailiff_notice') {
            setOffersListProps({
                totalPages: bailiffOfferState.bailiffOfferList?.totalPages,
                handleChangePage: (currentPage: number, rows?: number) => handleBailiffChangePage(currentPage, rows),
                lastPage: bailiffOfferState.bailiffOfferList?.last,
                pageNumber: bailiffOfferState.bailiffOfferList?.pageable?.pageNumber || 1,
                showList: !bailiffOfferState.bailiffOfferList?.empty && bailiffOfferState.bailiffOfferList?.content?.length > 0,
                renderList: bailiffRenderList,
                showNoItemsText: bailiffOfferState.bailiffOfferList && bailiffOfferState.bailiffOfferList.empty && bailiffOfferState.bailiffOfferList.content.length === 0 && bailiffOfferState.bailiffOfferList.afterSearch
            });
        } else {
            setOffersListProps({
                totalPages: offers.rentList?.totalPages,
                handleChangePage,
                lastPage: offers.rentList?.last,
                pageNumber: offers.rentList?.pageable?.pageNumber || 1,
                showList: !offers.rentList?.empty && offers.rentList?.content?.length > 0,
                showNoItemsText: offers.rentList && offers.rentList.empty && offers.rentList.content.length === 0 && offers.rentList.afterSearch,
                renderList
            });
        }
    };

    useEffect(() => {
        setOffersListPropsBasedOnFilter();
    }, [
        activeFilter,
        offers.rentList,
        saleOfferState.saleOfferList,
        renderList,
        saleRenderList,
        selectedSaleModules,
        bailiffOfferState.bailiffOfferList,
        bailiffRenderList
    ]);

    return (
        <Wrapper id={'offer-list'}>
            {activeFilter === ActiveFilterEnum.ADVERTISEMENT || activeFilter === ActiveFilterEnum.BAILIFF_NOTICE
                ? <V2HeaderSection title={'Ulubione'} offersCount={getOffersCount(activeFilter)}/>
                : <HeaderSection type={'favorites'} activeFavoriteOfferType={activeFilter}/>}
            <FiltersContentWrapper className={'active'}>
                <FiltersContainer>
                    <AccordionCard type={'favorites'} title={'Wybierz moduł'}>
                        <Module isActive={activeFilter === 'advertisement'} icon={IconEnum.BAG_WHITE}
                            title={'Ogłoszenia sprzedaży'} onClick={() => setActiveFilter(ActiveFilterEnum.ADVERTISEMENT)}/>
                        <Module isActive={activeFilter === 'RENT_FLAT'} icon={IconEnum.CALENDAR_WHITE}
                            title={'Ogłoszenia wynajmu mieszkań'} onClick={() => setActiveFilter(ActiveFilterEnum.RENT_FLAT)}/>
                        <Module isActive={activeFilter === 'RENT_HOUSE'} icon={IconEnum.CALENDAR_WHITE}
                            title={'Ogłoszenia wynajmu domów'}
                            onClick={() => setActiveFilter(ActiveFilterEnum.RENT_HOUSE)}/>
                        <Module isActive={activeFilter === 'RENT_PLOT'} icon={IconEnum.CALENDAR_WHITE}
                            title={'Ogłoszenia wynajmu działek'}
                            onClick={() => setActiveFilter(ActiveFilterEnum.RENT_PLOT)}/>
                        <Module isActive={activeFilter === 'RENT_OTHER'} icon={IconEnum.CALENDAR_WHITE}
                            title={'Ogłoszenia wynajmu inne'}
                            onClick={() => setActiveFilter(ActiveFilterEnum.RENT_OTHER)}/>
                        {user.activePackages.includes(Packages.ROLE_AUCTION) &&
                            <Module isActive={activeFilter === ActiveFilterEnum.TENDER} icon={IconEnum.PEOPLES_2_WHITE} title={'Przetargi'}
                                onClick={() => setActiveFilter(ActiveFilterEnum.TENDER)}/>}
                        {user.activePackages.includes(Packages.ROLE_AUCTION) &&
                            <Module isActive={activeFilter === ActiveFilterEnum.AUCTION} icon={IconEnum.PEOPLES_3_WHITE}
                                title={'Licytacje komornicze'} onClick={() => setActiveFilter(ActiveFilterEnum.AUCTION)}/>}
                        {user.activePackages.includes(Packages.ROLE_COOPERATIVE) &&
                            <Module isActive={activeFilter === ActiveFilterEnum.COOPERATIVE} icon={IconEnum.SQUARE_WHITE}
                                title={'Przetargi lokalne'} onClick={() => setActiveFilter(ActiveFilterEnum.COOPERATIVE)}/>}
                        {user.activePackages.includes(Packages.ROLE_BAILIFFNOTICE) &&
                            <Module isActive={activeFilter === ActiveFilterEnum.BAILIFF_NOTICE} icon={IconEnum.LIST_WHITE}
                                title={'Informacje o zadłużeniach'}
                                onClick={() => setActiveFilter(ActiveFilterEnum.BAILIFF_NOTICE)}/>}
                    </AccordionCard>
                    {activeFilter === 'advertisement' &&
                        <AccordionCard type={'favorites'} title={'Wybierz typy nieruchomości'}>
                            <Module
                                isActive={selectedSaleModules.includes(SaleModule.SALE_FLAT)}
                                title={'Ogłoszenia sprzedaży mieszkań'}
                                icon={IconEnum.CALENDAR_WHITE}
                                onClick={() => handleSaleModuleToggle(SaleModule.SALE_FLAT)}
                            />
                            <Module
                                isActive={selectedSaleModules.includes(SaleModule.SALE_HOUSE)}
                                title={'Ogłoszenia sprzedaży domów'}
                                icon={IconEnum.CALENDAR_WHITE}
                                onClick={() => handleSaleModuleToggle(SaleModule.SALE_HOUSE)}
                            />
                            <Module
                                isActive={selectedSaleModules.includes(SaleModule.SALE_PLOT)}
                                title={'Ogłoszenia sprzedaży działek'}
                                icon={IconEnum.CALENDAR_WHITE}
                                onClick={() => handleSaleModuleToggle(SaleModule.SALE_PLOT)}
                            />
                            <Module
                                isActive={selectedSaleModules.includes(SaleModule.SALE_OTHER)}
                                title={'Ogłoszenia sprzedaży inne'}
                                icon={IconEnum.CALENDAR_WHITE}
                                onClick={() => handleSaleModuleToggle(SaleModule.SALE_OTHER)}
                            />
                        </AccordionCard>
                    }
                    <AccordionCard directionRow type={'favorites'} title={'Wyświetlaj tylko'}>
                        <InteractiveIcons icon={IconEnum.LIKE} tooltipText="Lubię to" active={filters.likeStatus}
                            onClick={() => handleFilter('likeStatus')}/>
                        <InteractiveIcons icon={IconEnum.DISLIKE} tooltipText="Nie lubię tego" active={filters.unlikeStatus}
                            onClick={() => handleFilter('unlikeStatus')}/>
                        <InteractiveIcons icon={IconEnum.MESSAGE} tooltipText="Notatka" active={filters.noteStatus}
                            onClick={() => handleFilter('noteStatus')}/>
                        <InteractiveIcons icon={IconEnum.BLOCKED} tooltipText="Ogłoszenie nieaktualne"
                            active={filters.hideStatus}
                            onClick={() => handleFilter('hideStatus')}/>
                        <InteractiveIcons icon={IconEnum.PHONE} tooltipText="Przeprowadzona rozmowa"
                            active={filters.phoneStatus}
                            onClick={() => handleFilter('phoneStatus')}/>
                        <InteractiveIcons icon={IconEnum.PEOPLES_3} tooltipText="Umówione spotkanie"
                            active={filters.arrangedStatus}
                            onClick={() => handleFilter('arrangedStatus')}/>
                    </AccordionCard>
                    <AccordionCard type={'favorites'} disabledCollapse>
                        <InputContainer
                            title={'Szukaj w notatkach'}
                            icon={IconEnum.NOTE}>
                            <TextInput
                                type={'text'}
                                placeholder={'Wpisz szukany tekst...'}
                                onChange={handleChangeFilter}
                                value={searchPhrase}
                                icon={IconEnum.SEARCH}
                            />
                        </InputContainer>
                    </AccordionCard>
                    <AccordionCard type={'favorites'} disabledCollapse>
                        <InputContainer title={'Zastosuj filtry'} icon={IconEnum.DICTIONARY} actionText={'Resetuj'}
                            onActionClick={onClearFilters}>
                            <Button onClick={handleSearch}>
                                Przeglądaj oferty...
                            </Button>
                        </InputContainer>
                    </AccordionCard>
                </FiltersContainer>
                {activeFilter === 'RENT_FLAT' || activeFilter === 'RENT_PLOT' || activeFilter === 'RENT_HOUSE' || activeFilter === 'RENT_OTHER' || activeFilter === 'advertisement' || activeFilter === 'bailiff_notice'
                    ? <V2OfferList {...offersListProps} />
                    : <MainList type={activeFilter} showList={offers.list.length > 0 && !offers.list[0].noItems}/>}
            </FiltersContentWrapper>
            {offers.isLoading && <Indicator/>}
        </Wrapper>
    );
};

export default FavoritesPage;
