import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import FiltersContentWrapper from 'components/common/FiltersContentWrapper';
import { IconEnum } from 'components/common/Icon';
import StaticModuleList from 'components/common/StaticModuleList';
import ActivePackagesSettings from 'components/mySettings/ActivePackagesSettings';
import MyDataSettings from 'components/mySettings/MyDataSettings/MyDataSettings';
import NotificationsSettings from 'components/mySettings/NotificationsSettings';
import V2HeaderSection from 'components/V2HeaderSection';

const Wrapper = styled.div`
    .active .accordion-card-content {
        row-gap: 10px;
    }
`;

const FiltersContainer = styled.div`
    padding: 70px 0 20px 20px;

    @media (max-width: 1100px) {
        padding-right: 20px;
    }
`;

const Container = styled.div`
    padding: 15px 20px 0;

    @media (max-width: 1100px) {
        padding: 20px 20px 0;
    }
`;

enum MySettingsActiveModule {
    MY_DATA = 'Moje dane',
    NOTIFICATIONS = 'Powiadomienia',
    PACKAGE_INFORMATION = 'Informacje o pakiecie',
    INVOICES = 'Faktury'
}

const MySettingsPage = () => {
    const myDataRef = useRef(null);
    const notificationsRef = useRef(null);
    const packageInfoRef = useRef(null);
    const [activeModule, setActiveModule] = useState<MySettingsActiveModule>(MySettingsActiveModule.MY_DATA);

    const handleModuleClick = (module: MySettingsActiveModule, ref: React.RefObject<HTMLDivElement>) => {
        setActiveModule(module);
        ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const modules = [
        {
            title: 'Moje dane',
            icon: IconEnum.BAG_WHITE,
            isActive: activeModule === MySettingsActiveModule.MY_DATA,
            onClick: () => handleModuleClick(MySettingsActiveModule.MY_DATA, myDataRef)
        },
        {
            title: 'Powiadomienia',
            icon: IconEnum.CALENDAR_WHITE,
            isActive: activeModule === MySettingsActiveModule.NOTIFICATIONS,
            onClick: () => handleModuleClick(MySettingsActiveModule.NOTIFICATIONS, notificationsRef)
        },
        {
            title: 'Informacje o pakiecie',
            icon: IconEnum.PEOPLES_2_WHITE,
            isActive: activeModule === MySettingsActiveModule.PACKAGE_INFORMATION,
            onClick: () => handleModuleClick(MySettingsActiveModule.PACKAGE_INFORMATION, packageInfoRef)
        }
        // {
        //    title: 'Faktury',
        //   icon: IconEnum.PEOPLES_3_WHITE,
        //    isActive: activeModule === MySettingsActiveModule.INVOICES,
        //    onClick: () => handleModuleClick(MySettingsActiveModule.INVOICES, invoicesRef)
        // }
    ];

    return (
        <Wrapper>
            <V2HeaderSection title={'Ustawienia'}/>
            <FiltersContentWrapper className={'active'}>
                <FiltersContainer className="dark-mode-icons">
                    <StaticModuleList modules={modules} />
                </FiltersContainer>
                <Container className="dark-mode-icons">
                    <MyDataSettings
                        ref={myDataRef}
                        title="Moje dane"
                    />
                    <NotificationsSettings
                        ref={notificationsRef}
                        title="Powiadomienia"
                    />
                    <ActivePackagesSettings
                        ref={packageInfoRef}
                        title="Aktywne pakiety"
                    />
                    {/* <InvoicesSettings*/}
                    {/*    ref={invoicesRef}*/}
                    {/*    title='Ostatnie zamówienia'*/}
                    {/* />*/}
                </Container>
            </FiltersContentWrapper>
        </Wrapper>
    );
};

export default MySettingsPage;
