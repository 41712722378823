export const comparePriceAscending = (a, b) => {
    const priceA = a.price || a.originalPrice;
    const priceB = b.price || b.originalPrice;
    const bandA = priceA ? parseInt(priceA?.replace(/\s/g, '')) : 0;
    const bandB = priceB ? parseInt(priceB?.replace(/\s/g, '')) : 0;

    let comparison = 0;
    if (bandA > bandB) comparison = 1;
    else if (bandA < bandB) comparison = -1;

    return comparison;
};

export const compareRentPriceAscending = (a, b) => {
    const bandA = a.rentPrice;
    const bandB = b.rentPrice;

    let comparison = 0;
    if (bandA > bandB) comparison = 1;
    else if (bandA < bandB) comparison = -1;

    return comparison;
};

export const comparePriceDescending = (a, b) => {
    const priceA = a.price || a.originalPrice;
    const priceB = b.price || b.originalPrice;
    const bandA = priceA ? parseInt(priceA?.replace(/\s/g, '')) : 0;
    const bandB = priceB ? parseInt(priceB?.replace(/\s/g, '')) : 0;

    let comparison = 0;
    if (bandA > bandB) comparison = -1;
    else if (bandA < bandB) comparison = 1;

    return comparison;
};

export const compareRentPriceDescending = (a, b) => {
    const bandA = a.rentPrice;
    const bandB = b.rentPrice;

    let comparison = 0;
    if (bandA > bandB) comparison = -1;
    else if (bandA < bandB) comparison = 1;

    return comparison;
};

export const compareDateAscending = (a, b) => {
    const bandA = a.dateAdded || a.dateAddedToDataBase;
    const bandB = b.dateAdded || b.dateAddedToDataBase;

    let comparison = 0;
    if (bandA > bandB) comparison = 1;
    else if (bandA < bandB) comparison = -1;

    return comparison;
};

export const compareDateDescending = (a, b) => {
    const bandA = a.dateAdded || a.dateAddedToDataBase;
    const bandB = b.dateAdded || b.dateAddedToDataBase;

    let comparison = 0;
    if (bandA > bandB) comparison = -1;
    else if (bandA < bandB) comparison = 1;

    return comparison;
};

export const compareAuctionDateAscending = (a, b) => {
    const bandA = a.auctionDate;
    const bandB = b.auctionDate;

    let comparison = 0;
    if (bandA > bandB) comparison = 1;
    else if (bandA < bandB) comparison = -1;

    return comparison;
};

export const compareAuctionDateDescending = (a, b) => {
    const bandA = a.auctionDate;
    const bandB = b.auctionDate;

    let comparison = 0;
    if (bandA > bandB) comparison = -1;
    else if (bandA < bandB) comparison = 1;

    return comparison;
};

export const comparePricePerSquareMeterAscending = (a, b) => {
    const bandA = a.pricePerSquareMeter;
    const bandB = b.pricePerSquareMeter;

    let comparison = 0;
    if (bandA > bandB) comparison = 1;
    else if (bandA < bandB) comparison = -1;

    return comparison;
};

export const comparePricePerSquareMeterDescending = (a, b) => {
    const bandA = a.pricePerSquareMeter;
    const bandB = b.pricePerSquareMeter;

    let comparison = 0;
    if (bandA > bandB) comparison = -1;
    else if (bandA < bandB) comparison = 1;

    return comparison;
};

export const compareByModifyDate = (a, b) => {
    const diffA = Math.abs(new Date(a.dateAdded) - new Date(a.tooltipDate));
    const diffB = Math.abs(new Date(b.dateAdded) - new Date(b.tooltipDate));

    if (!a.tooltipDate) return 1;
    if (!b.tooltipDate) return -1;

    if (diffA > diffB) return -1;
    if (diffB > diffA) return 1;

    return 0;
};
