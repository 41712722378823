const getTitleByType = (type: string) => {
    switch (type) {
    case 'auction':
        return 'Licytacje komornicze';
    case 'tender':
        return 'Przetargi';
    case 'bailiff_notice':
        return 'Informacje o zadłużeniach';
    case 'cooperative':
        return 'Przetargi lokalne';
    case 'analysis':
        return 'Analiza cen ofertowych';
    case 'rent':
        return 'Ogłoszenia wynajmu';
    case 'favorites':
        return 'Ulubione';
    case 'users':
        return 'Ogłoszenia użytkowników';
    case 'add_offer':
        return 'Dodaj ogłoszenie';
    case 'my_offer':
        return 'Moje ogłoszenia';
    case 'profile':
        return 'Ogłoszenia użytkownika';
    case 'advertisement':
    default:
        return 'Ogłoszenia sprzedaży';
    }
};

export default getTitleByType;
