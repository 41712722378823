import React from 'react';
import dayjs from 'dayjs';
import { UserMyOffersResponseContent, UserOffersResponseContent, UserProfileOffersResponseContent } from 'store/User/State';
import { formatFilterPrice } from 'utils/formatFilterPrice';
import { getEnumPropertyByTypeUser } from 'utils/formatters/getPropertyByType';
import { getUserModuleName } from 'utils/formatters/getUserModuleName';

import { InfoElementType } from 'components/common/Card/common/V2CardBodyDefault/InfoElements';
import { IconEnum } from 'components/common/Icon';

type Offer = UserOffersResponseContent | UserMyOffersResponseContent | UserProfileOffersResponseContent;

const generateDetailsTabElements = (offer: Offer) => {
    const costEstimation: string = offer.detailedContent && offer.detailedContent.costEstimate && offer.detailedContent.costEstimate.length > 0 ? offer.detailedContent.costEstimate.map((service) => `${service.name}: ${formatFilterPrice(service.price)} zł`).join('<br>') : '';

    return [
        {
            icon: IconEnum.CALENDAR,
            text: 'Data dodania:',
            value: dayjs(offer.dateAdded).format('DD.MM.YYYY, HH:mm')
        },
        {
            icon: IconEnum.BUILDING,
            text: 'Rodzaj oferty:',
            value: `${getUserModuleName(getEnumPropertyByTypeUser(offer.module))}`
        },
        ...offer.detailedContent && offer.detailedContent.price
            ? [
                {
                    icon: IconEnum.PRICE,
                    text: 'Cena:',
                    value: `${offer.detailedContent.price} zł`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.totalInvestmentValue
            ? [
                {
                    icon: IconEnum.PRICE,
                    text: 'Całkowita wartość inwestycji:',
                    value: `${formatFilterPrice(offer.detailedContent.totalInvestmentValue)} zł`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.investorContribution
            ? [
                {
                    icon: IconEnum.WALLET,
                    text: 'Wkład inwestora:',
                    value: `${formatFilterPrice(offer.detailedContent.investorContribution)} zł`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.ownContribution
            ? [
                {
                    icon: IconEnum.WALLET,
                    text: 'Wkład własny:',
                    value: `${formatFilterPrice(offer.detailedContent.ownContribution)} zł`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.percentageOfInvestment
            ? [
                {
                    icon: IconEnum.PRICE_PART,
                    text: 'Procent inwestycji:',
                    value: `${offer.detailedContent.percentageOfInvestment}%`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.investmentBudget
            ? [
                {
                    icon: IconEnum.PRICE,
                    text: 'Kwota do zrealizowania inwestycji:',
                    value: `${formatFilterPrice(offer.detailedContent.investmentBudget)} zł`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.leaseFee
            ? [
                {
                    icon: IconEnum.PRICE,
                    text: 'Odstępne:',
                    value: `${formatFilterPrice(offer.detailedContent.leaseFee)} zł`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.renovationExpenses
            ? [
                {
                    icon: IconEnum.PRICE,
                    text: 'Nakład na remont:',
                    value: `${formatFilterPrice(offer.detailedContent.renovationExpenses)} zł`
                }
            ]
            : [],
        ...offer.expectedProfit
            ? [
                {
                    icon: IconEnum.PRICE,
                    text: 'Prognozowany zysk:',
                    value: `${formatFilterPrice(offer.expectedProfit)} zł`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.roi
            ? [
                {
                    icon: IconEnum.PRICE,
                    text: 'ROI:',
                    value: `${offer.detailedContent.roi}%`
                }
            ]
            : [],
        ...offer.buildingType
            ? [
                {
                    icon: IconEnum.TWO_TYPE,
                    text: 'Rodzaj budynku:',
                    value: offer.buildingType
                }
            ]
            : [],
        ...offer.area
            ? [
                {
                    icon: IconEnum.SQUARE,
                    text: 'Metraż:',
                    value: `${offer.area} m<sup>2</sup>`
                }
            ]
            : [],
        ...offer.floor
            ? [
                {
                    icon: IconEnum.FLOOR_LEVEL,
                    text: 'Piętro:',
                    value: `${offer.floor} piętro`
                }
            ]
            : [],
        ...offer.floors
            ? [
                {
                    icon: IconEnum.FLOOR_LEVEL,
                    text: 'Liczba pięter:',
                    value: `${offer.floors}`
                }
            ]
            : [],
        ...offer.legalStatus
            ? [
                {
                    icon: IconEnum.BOOKMARK,
                    text: 'Stan prawny:',
                    value: offer.legalStatus
                }
            ]
            : [],
        ...offer.propertyCondition
            ? [
                {
                    icon: IconEnum.BUILDING,
                    text: 'Stan nieruchomości:',
                    value: offer.propertyCondition
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.preContract
            ? [
                {
                    icon: IconEnum.DICTIONARY,
                    text: 'Podpisana umowa przedwstępna:',
                    value: offer.detailedContent.preContract ? 'Tak' : 'Nie'
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.investmentGoal
            ? [
                {
                    icon: IconEnum.BOOKMARK,
                    text: 'Przewidywany cel inwestycji:',
                    value: offer.detailedContent.investmentGoal
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.rentalIncome
            ? [
                {
                    icon: IconEnum.WALLET,
                    text: 'Przewidywany przychód z najmu:',
                    value: `${formatFilterPrice(offer.detailedContent.rentalIncome)} zł`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.availableFrom
            ? [
                {
                    icon: IconEnum.CALENDAR,
                    text: 'Dostępne od:',
                    value: dayjs(offer.detailedContent.availableFrom).format('DD.MM.YYYY')
                }
            ]
            : [],
        ...offer.minimumInvestmentValue
            ? [
                {
                    icon: IconEnum.PRICE,
                    text: 'Minimalna kwota inwestycji:',
                    value: `${formatFilterPrice(offer.minimumInvestmentValue)} zł`
                }
            ]
            : [],
        ...offer.maximumInvestmentValue
            ? [
                {
                    icon: IconEnum.PRICE,
                    text: 'Maksymalna kwota inwestycji:',
                    value: `${formatFilterPrice(offer.maximumInvestmentValue)} zł`
                }
            ]
            : [],
        ...offer.expectedReturnRate
            ? [
                {
                    icon: IconEnum.PRICE_PART,
                    text: 'Oczekiwana stopa zwrotu:',
                    value: `${offer.expectedReturnRate}%`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.minimumProfitAmount
            ? [
                {
                    icon: IconEnum.PRICE,
                    text: 'Minimalna kwota zysku:',
                    value: `${formatFilterPrice(offer.detailedContent.minimumProfitAmount)} zł`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.formOfProfit && offer.detailedContent.formOfProfit.length > 0
            ? [
                {
                    icon: IconEnum.PRICE,
                    text: 'Forma zysku:',
                    value: `${offer.detailedContent.formOfProfit}`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.minimumSalePrice
            ? [
                {
                    icon: IconEnum.PRICE,
                    text: 'Minimalna cena sprzedaży:',
                    value: `${formatFilterPrice(offer.detailedContent.minimumSalePrice)} zł`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.maximumSalePrice
            ? [
                {
                    icon: IconEnum.PRICE,
                    text: 'Maksymalna cena sprzedaży:',
                    value: `${formatFilterPrice(offer.detailedContent.maximumSalePrice)} zł`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.estimatedSaleDate
            ? [
                {
                    icon: IconEnum.CALENDAR,
                    text: 'Szacowana data sprzedaży:',
                    value: dayjs(offer.detailedContent.estimatedSaleDate).format('DD.MM.YYYY')
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.constructionCrew
            ? [
                {
                    icon: IconEnum.DICTIONARY,
                    text: 'Mam ekipę budowlaną:',
                    value: offer.detailedContent.constructionCrew ? 'Tak' : 'Nie'
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.investmentAmountFrom
            ? [
                {
                    icon: IconEnum.PRICE,
                    text: 'Kwota inwestycji od:',
                    value: `${formatFilterPrice(offer.detailedContent.investmentAmountFrom)} zł`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.investmentAmountTo
            ? [
                {
                    icon: IconEnum.PRICE,
                    text: 'Kwota inwestycji do:',
                    value: `${formatFilterPrice(offer.detailedContent.investmentAmountTo)} zł`
                }
            ]
            : [],
        ...offer.investmentTimeTo || offer.investmentTimeFrom
            ? [
                {
                    icon: IconEnum.SQUARE_TIMER,
                    text: 'Czas trwania inwestycji w miesiącach:',
                    value: `${offer.investmentTimeFrom ? `od ${offer.investmentTimeFrom}` : ''} ${offer.investmentTimeTo ? `od ${offer.investmentTimeTo}` : ''}`
                }
            ]
            : [],
        ...offer.investmentTime
            ? [
                {
                    icon: IconEnum.SQUARE_TIMER,
                    text: 'Czas inwestycji w miesiącach:',
                    value: `${offer.investmentTime}`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.funding
            ? [
                {
                    icon: IconEnum.WALLET,
                    text: 'Finansowanie:',
                    value: `${offer.detailedContent.funding}`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.investmentSecurity && (offer.detailedContent.investmentSecurity as []).length > 0
            ? [
                {
                    icon: IconEnum.WORK,
                    text: 'Zabezpieczenie:',
                    value: `${(offer.detailedContent.investmentSecurity as unknown as []).join(', ')}`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.formOfInvestment && (offer.detailedContent.formOfInvestment as []).length > 0
            ? [
                {
                    icon: IconEnum.WORK,
                    text: 'Forma inwestycji:',
                    value: `${(offer.detailedContent.formOfInvestment as unknown as []).join(', ')}`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.details
            ? [
                {
                    icon: IconEnum.WORK,
                    text: 'Szczegóły ogłoszenia:',
                    value: `${offer.detailedContent.details}`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.landRegistryNumber
            ? [
                {
                    icon: IconEnum.NOTE,
                    text: 'Numer księgi wieczystej:',
                    value: offer.detailedContent.landRegistryNumber
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.features && offer.detailedContent.features.length > 0
            ? [
                {
                    icon: IconEnum.BOOKMARK,
                    text: 'Udogodnienia:',
                    value: `${(offer.detailedContent.features as unknown as []).join(', ')}`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.costEstimate && offer.detailedContent.costEstimate.length > 0
            ? [
                {
                    icon: IconEnum.PRICE,
                    text: 'Kosztorys:',
                    value: costEstimation
                }
            ]
            : []
    ];
};

const generateInfoElements = (offer: Offer): InfoElementType[] => {
    return [
        {
            icon: IconEnum.BUILDING,
            isVisible: !!offer.module,
            children: <b>{getUserModuleName(getEnumPropertyByTypeUser(offer.module))}</b>
        },
        {
            icon: IconEnum.PRICE,
            isVisible: !!offer.totalInvestmentValue,
            children: <><b>Cena: {formatFilterPrice(offer.totalInvestmentValue)}</b> zł</>
        },
        {
            icon: IconEnum.WALLET,
            isVisible: !!offer.leaseFee,
            children: <><b>Odstępne: {formatFilterPrice(offer.leaseFee)}</b> zł</>
        },
        {
            icon: IconEnum.PRICE,
            isVisible: !!offer.minimumInvestmentValue,
            children: <><b>Minimalna kwota: {formatFilterPrice(offer.minimumInvestmentValue)}</b> zł</>
        },
        {
            icon: IconEnum.PRICE,
            isVisible: !!offer.minimumProfitAmount,
            children: <><b>Zysk: {formatFilterPrice(offer.minimumProfitAmount)}</b> zł</>
        },
        {
            icon: IconEnum.SQUARE,
            isVisible: !!offer.area,
            children: <><b>{offer.area}</b> m<sup>2</sup></>
        },
        {
            icon: IconEnum.TWO_TYPE,
            isVisible: !!offer.buildingType,
            children: <><b>{offer.buildingType}</b></>
        },
        {
            icon: IconEnum.FLOOR_LEVEL,
            isVisible: !!offer.floor,
            children: <><b>{offer.floor}</b> piętro</>
        },
        {
            icon: IconEnum.BUILDING,
            isVisible: !!offer.propertyCondition,
            children: <><b>{offer.propertyCondition}</b></>
        },
        {
            icon: IconEnum.BUILDING,
            isVisible: !!offer.legalStatus,
            children: <><b>{offer.legalStatus}</b></>
        }
    ];
};

export const userOfferListFunctions = {
    generateDetailsTabElements,
    generateInfoElements
};
