import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { AxiosResponse } from 'axios';
import styled from 'styled-components';
import { UploadRequestData } from 'utils/types/File';

import Indicator from 'components/atom/Indicator';
import Icon, { IconEnum } from 'components/common/Icon';

const FileDropzone = styled.div`
    position: relative;
    width: 245px;
    height: 270px;
    border-radius: var(--image-border-radius);
    background: var(--color-alt-second);
    display: flex;
    flex-direction: column;
    align-items: center;

    .file-icon,
    .upload-icon {
        margin-top: 50%;
        background: var(--color-white);
        border-radius: 50%;
        width: 30px;
        height: 30px;
    }

    .file-icon {
        background: unset;
        opacity: 0.7;
        width: 40px;
        height: 40px;
    }
`;

const FileDropzoneLabel = styled.p`
    --font-size-body: var(--font-size-body-2);

    text-align: center;
    opacity: 0.7;
    padding: 0 45px;
    margin-top: 10px;
`;

const ListWrapper = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(245px, 245px));
    gap: 15px 44px;
`;

const UploaderWrapper = styled.div`
    display: flex;
`;

const FileNumber = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    background: var(--color-white);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .icon {
        width: 15px;
        height: 15px;
    }
`;

const ItemWrapper = styled.div`
    width: 245px;
    height: 270px;
    position: relative;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: var(--image-border-radius);
    }
`;

interface ItemValueType extends File {
    id: string;
}

interface ListProps {
    items: ItemValueType[];
    setFiles: Dispatch<SetStateAction<ItemValueType[]>>;
    onRemove: (e: React.MouseEvent<Element, MouseEvent>) => void;
    activeFile: number;
    setActiveFile: ActiveFileSetter;
    onFileChange: (imgName: string) => void;
    fileUploadHandler: (params: UploadRequestData) => Promise<AxiosResponse<string>>;
}

type ActiveFileSetter = (value: number) => void;

interface ItemProps {
    value: ItemValueType;
    sortIndex: number;
    index: number;
    activeFile: number;
    setActiveFile: ActiveFileSetter;
    onRemove: (e: React.MouseEvent<Element, MouseEvent>) => void;
}

interface FileUploadProps {
    onChange: (value: string[]) => void;
    value: string[];
    fileUploadHandler: (params: UploadRequestData) => Promise<AxiosResponse<string>>;
}

const generateIconFromExtension = (extension: string): IconEnum => {
    switch (extension) {
    case 'pdf':
        return IconEnum.PDF;
    case 'csv':
    case 'xlsx':
    case 'xls':
        return IconEnum.CSV;
    case 'doc':
    case 'docx':
    case 'rtf':
    case 'txt':
        return IconEnum.TXT;
    default:
        return IconEnum.UNKNOWN_FILE;
    }
};

const getExtension = (fileName: string): string => {
    return fileName.split('.').pop() || '';
};

const Item: FC<ItemProps> = ({
    value,
    sortIndex,
    activeFile,
    setActiveFile,
    onRemove
}) => <ItemWrapper onMouseEnter={() => setActiveFile(sortIndex)} onMouseLeave={() => setActiveFile(-1)}>
    <FileNumber onClick={onRemove}>
        {activeFile === sortIndex ? <Icon icon={IconEnum.CROSS}/> : sortIndex + 1}
    </FileNumber>
    <FileDropzone>
        <Icon classNames={'file-icon'} icon={generateIconFromExtension(getExtension(value.name))}/>
        <FileDropzoneLabel>{value.name}</FileDropzoneLabel>
    </FileDropzone>
</ItemWrapper>;

const List: FC<ListProps> = ({
    items,
    setFiles,
    onRemove,
    activeFile,
    setActiveFile,
    onFileChange,
    fileUploadHandler
}) => {
    const [isLoadingFile, setIsLoadingFile] = useState(false);
    const onDrop = useCallback(<T extends File>(acceptedFiles: T[]) => {
        setIsLoadingFile(true);
        const uploadPromises = acceptedFiles.map(async (file) => {
            return await fileUploadHandler({ file }).then((fileName) => {
                onFileChange(fileName as unknown as string);

                const newFile = Object.assign(file, {
                    id: fileName as unknown as string
                });

                setFiles((prevFiles) => [...prevFiles, newFile]);
            });
        });

        Promise.all(uploadPromises)
            .then(() => {
                setIsLoadingFile(false);
            })
            .catch(() => {
                setIsLoadingFile(false);
            });
    }, [items]);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: { 'application/*': [
            '.pdf',
            '.csv',
            '.doc',
            '.docx',
            '.rtf',
            '.xlsx',
            '.xls',
            '.txt'
        ] },
        maxFiles: 8,
        multiple: false,
        maxSize: 10000000
    });

    return (
        <ListWrapper>
            {items.length > 0
                ? items.map((value, index) => <Item activeFile={activeFile} onRemove={onRemove} setActiveFile={setActiveFile}
                    key={`item-${value.id}`} index={index} sortIndex={index} value={value}/>)
                : null}
            {items.length < 8 &&
                <FileDropzone {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isLoadingFile
                        ? <Indicator fitParentContainer/>
                        : <>
                            <Icon classNames={'upload-icon'} icon={IconEnum.PLUS}/>
                            <FileDropzoneLabel>Naciśnij lub przeciągnij, aby dodać pliki</FileDropzoneLabel>
                        </>
                    }

                </FileDropzone>
            }
        </ListWrapper>
    );
};

const FileUploader: FC<FileUploadProps> = ({ onChange, value, fileUploadHandler }) => {
    const convertValueToSortableItem = (value: string[]) => {
        if (value.length <= 0) return value;

        if (value.length > 0 && typeof value[0] === 'object') {
            return value;
        }

        return value.map((imgName) => {
            return {
                id: imgName,
                name: imgName
            };
        });
    };

    const [files, setFiles] = useState<ItemValueType[]>([]);

    useEffect(() => {
        if (files.length === 0) {
            setFiles(convertValueToSortableItem(value) as ItemValueType[]);
        }
    }, [value]);

    const [activeFile, setActiveFile] = useState(-1);

    const removeImage = (e: React.MouseEvent<Element, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();

        onChange(value.filter((_, index) => index !== activeFile));
        setFiles((prevImages) => prevImages.filter((_, index) => index !== activeFile));
    };

    const handleFileChange = (imgName: string) => {
        onChange([...value, imgName]);
    };

    return (
        <UploaderWrapper>
            <List items={files} setFiles={setFiles} onRemove={removeImage}
                fileUploadHandler={fileUploadHandler} activeFile={activeFile} setActiveFile={setActiveFile}
                onFileChange={handleFileChange}/>
        </UploaderWrapper>
    );
};

export default FileUploader;
