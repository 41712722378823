import React, { FC, MouseEvent, useMemo, useState } from 'react';
import styled from 'styled-components';

import Icon, { IconEnum } from 'components/common/Icon';

const Container = styled.div`
    display: flex;
`;

interface RatingSetterType {
    rating: number;
    setRating?: (rating: number) => void;
    isChangeable?: boolean;
}

const RatingSetter:FC<RatingSetterType> = ({ rating, setRating, isChangeable = true }) => {
    const [activeHoveredRating, setActiveHoveredRating] = useState<number | null>(0);
    const memoizedRating = useMemo(() => [
        1,
        2,
        3,
        4,
        5
    ].map((value) => <div key={value} onMouseEnter={() => isChangeable && setActiveHoveredRating(value)} onMouseLeave={() => isChangeable && setActiveHoveredRating(null)}>
        <Icon
            onClick={(e) => handleRating(e, value)}
            icon={(activeHoveredRating || rating) >= value ? IconEnum.FILLED_STAR : IconEnum.STAR}
        />
    </div>), [
        activeHoveredRating,
        isChangeable,
        rating
    ]);

    const handleRating = (e: MouseEvent, value: number) => {
        if (!setRating || !isChangeable) return;
        e.stopPropagation();
        setRating(value);
    };

    return (
        <Container className={'rating-setter'}>
            {memoizedRating}
        </Container>
    );
};

export default RatingSetter;
