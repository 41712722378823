import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';

import Icon, { IconEnum } from './Icon';

const DeleteModal = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;

    top: 0;
    left: 0;

    z-index: 1;

    display: flex;
    align-items: flex-start;
    text-align: center;
    justify-content: center;

    h1 {
        line-height: 30px;
        margin: 0 0 4px 0;

    }

    h2 {
        font-size: var(--font-size-body-1);
        margin: 0 0 10px 0;

    }

    p {
        font-size: var(--font-size-body-1);
        font-weight: 400;
        margin: 0 0 20px 0;
    }
`;

const ModalBackground = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${(props) => props.darkMode ? 'rgba(7, 7, 7, 0.7)' : 'rgba(242, 245, 249, 0.7)'};
`;

const ModalContentContainer = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    max-width: 600px;
    max-height: 95vh;
    border-radius: var(--box-border-radius);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: auto;
    z-index: 1;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.04);
`;

const Content = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    padding: 40px 70px;
    overflow-y: scroll;
    background-color: var(--color-white);
    border-radius: 10px;
`;

const ModalCloseButton = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  right: 15px;
  top: 15px;
  padding: 10px;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
    
  &:hover {
    transition: transform 0.2s ease-in-out;
    transform: rotateZ(90deg) scale(1.1);
  }

  z-index: 2;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
`;

const Button = styled.button`
    color: var(--color-primary);
    font-weight: normal;
    background: var(--color-alt-second);
    border-radius: 20px;
    width: 160px;
    height: 40px;
    padding: 10px 60px;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 14px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        color: var(--color-primary);
        background: var(--color-alt-second);
    }

    &.delete {
        background: var(--color-error);
        color: var(--color-white);
    }
`;

const Trash = styled.div`
    padding: 4px;
    border: 1px solid transparent;
    border-radius: 50%;
    min-width: 40px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    span {
        margin-right: 0;
    }
  
  &.iconText {
    border-radius: 20px;
  }
`;

interface OfferDetailsModalProperties {
    onChange: () => void,
    filter: string;
    title: string;
    description?: string;
    iconText?: string;
}

const TrashWithModal: FC<OfferDetailsModalProperties> = ({
    onChange,
    filter,
    title,
    description,
    iconText
}) => {
    const { user } = useUser();
    const [isOpenModal, setIsOpenModal] = useState(false);

    const toggleModal = (e: Event) => {
        e.stopPropagation();
        setIsOpenModal((prev) => !prev);
    };

    const handleDelete = (e: Event) => {
        e.stopPropagation();
        toggleModal(e);
    };

    const handleConfirm = (e: Event) => {
        toggleModal(e);
        onChange();
    };

    return (
        <>
            <Trash className={clsx({ iconText: 'iconText' })} onClick={(e:Event) => handleDelete(e)}>
                <Icon icon={IconEnum.DELETE} />
                {iconText && <p>{iconText}</p>}
            </Trash>
            {isOpenModal &&
                <DeleteModal>
                    <ModalBackground darkMode={user.isDarkMode} onClick={(e: Event) => toggleModal(e)}/>
                    <ModalContentContainer>
                        <ModalCloseButton onClick={(e: Event) => toggleModal(e)}>
                            <Icon icon={IconEnum.CROSS}/>
                        </ModalCloseButton>
                        <Content>
                            <h1>{title}</h1>
                            <h2>{filter}</h2>
                            <p>{description}</p>
                            <ButtonsWrapper>
                                <Button onClick={(e: Event) => toggleModal(e)}>Anuluj</Button>
                                <Button className={'delete'} onClick={(e: Event) => handleConfirm(e)}>Usuń</Button>
                            </ButtonsWrapper>
                        </Content>
                    </ModalContentContainer>
                </DeleteModal>
            }
        </>
    );
};

export default TrashWithModal;
