import { AxiosResponse } from 'axios';
import { FavoritePageFiltersType } from 'pages/FavoritesPage';
import qs from 'qs';
import { axiosApiInstance, axiosApiInstance2 } from 'Store';
import { RentOfferResponseContent, RentOffersResponse } from 'store/Offers/State';
import { convertPrice } from 'utils/convertPrice';
import { NormalizedPropertyType } from 'utils/formatters/getNormalizedPropertyType';
import { stringToNumber } from 'utils/formatters/stringToNumber';
import { BailiffOffersResponse } from 'utils/state-managment/bailiff/bailiffOffer';
import { SaleOffersResponse } from 'utils/state-managment/sale/saleOffer';
import { CitiesType } from 'utils/types/Locations';
import { ModulesType } from 'utils/types/ModulesType';
import { OfferData, OfferNoteResponseType, UserReactions } from 'utils/types/OfferData';
import { RentModuleType, RentOfferRequestData } from 'utils/types/Rent';

import { activeNotification } from 'components/functions/activeNotification';
import { OfferRequestData } from 'components/functions/advertisementsFuncs';
import { compareDateDescending } from 'components/Sort';

const getFilterTypeByType = (type: ModulesType) => {
    switch (type) {
    case 'advertisement':
        return 'advertisement';
    case 'reactions':
        return 'new-front/reaction';
    case 'notes':
        return 'new-front/note';
    case 'auction':
    case 'tender':
        return 'auction';
    case 'bailiff_notice':
        return 'bailiffNotice';
    case 'cooperative':
        return 'cooperative';
    case 'users':
        return 'users';
    case 'rent':
    default:
        return 'rent';
    }
};

const getRentOffers = (params: RentOfferRequestData, propertyType: NormalizedPropertyType, page: number, size: number): Promise<RentOffersResponse[]> => {
    return axiosApiInstance2({
        method: 'post',
        url: `/rent-offer/advertisement/${propertyType}`,
        params: {
            page,
            size
        },
        data: {
            ...params,
            offerAdded: { from: params.offerAddedFrom, to: params.offerAddedTo },
            priceFrom: stringToNumber(params.priceFrom),
            priceTo: stringToNumber(params.priceTo),
            areaFrom: stringToNumber(params.areaFrom),
            areaTo: stringToNumber(params.areaTo),
            pricePerSquareMeterFrom: stringToNumber(params.pricePerSquareMeterFrom),
            pricePerSquareMeterTo: stringToNumber(params.pricePerSquareMeterTo),
            rentPriceFrom: stringToNumber(params.rentPriceFrom),
            rentPriceTo: stringToNumber(params.rentPriceTo),
            depositPriceFrom: stringToNumber(params.depositPriceFrom),
            depositPriceTo: stringToNumber(params.depositPriceTo),
            daysAmount: params.dateRangeFilter ? null : params.daysAmount,
            offerAddedFrom: params.dateRangeFilter ? params.offerAddedFrom : null,
            offerAddedTo: params.dateRangeFilter ? params.offerAddedTo : null
        }
    })
        .then((resp) => {
            if (resp.data) {
                return {
                    ...resp.data,
                    content: resp.data.content.map((el: RentOfferResponseContent) => ({
                        ...el,
                        city: el.location,
                        price: el.price ? convertPrice(el.price) : '',
                        pricePerSquareMeter: el.pricePerSquareMeter ? convertPrice(el.pricePerSquareMeter) : '',
                        reaction: {
                            likeStatus: !!el.reaction?.likeStatus,
                            unlikeStatus: !!el.reaction?.unlikeStatus,
                            viewedStatus: !!el.reaction?.viewedStatus,
                            outdatedStatus: !!el.reaction?.outdatedStatus,
                            arrangedStatus: !!el.reaction?.arrangedStatus,
                            phoneStatus: !!el.reaction?.phoneStatus,
                            hideStatus: !!el.reaction?.hideStatus,
                            openedStatus: !!el.reaction?.openedStatus
                        }
                    }))
                };
            }

            return { empty: true, content: [], afterSearch: true };
        })
        .catch(() => {
            activeNotification(
                'Nie znaleziono wyników',
                'Wyszukiwanie nie powiodło się, spróbuj jeszcze raz.',
                'warning'
            );

            return { empty: true, content: [], afterSearch: true };
        });
};

const getOffersCSVFile = (params: RentOfferRequestData, propertyType: NormalizedPropertyType) => {
    return axiosApiInstance2({
        method: 'post',
        responseType: 'blob',
        url: `/rent-offer/excel/${propertyType}`,
        data: {
            ...params,
            offerAdded: { from: params.offerAddedFrom, to: params.offerAddedTo },
            priceFrom: stringToNumber(params.priceFrom),
            priceTo: stringToNumber(params.priceTo),
            areaFrom: stringToNumber(params.areaFrom),
            areaTo: stringToNumber(params.areaTo),
            pricePerSquareMeterFrom: stringToNumber(params.pricePerSquareMeterFrom),
            pricePerSquareMeterTo: stringToNumber(params.pricePerSquareMeterTo),
            rentPriceFrom: stringToNumber(params.rentPriceFrom),
            rentPriceTo: stringToNumber(params.rentPriceTo),
            depositPriceFrom: stringToNumber(params.depositPriceFrom),
            depositPriceTo: stringToNumber(params.depositPriceTo),
            daysAmount: params.dateRangeFilter ? null : params.daysAmount,
            offerAddedFrom: params.dateRangeFilter ? params.offerAddedFrom : null,
            offerAddedTo: params.dateRangeFilter ? params.offerAddedTo : null
        }
    })
        .then((resp) => {
            const url = URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement('a');

            link.setAttribute('href', url);
            link.setAttribute('download', 'offers.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        });
};

const getOtherOffers = (type: ModulesType, city: string | CitiesType[], filters: OfferRequestData): Promise<OfferData[]> => {
    return axiosApiInstance({
        method: 'get',
        url: `/new-front/${getFilterTypeByType(type)}/findByFilter/${city}/`,
        params: {
            ...filters,
            priceFrom: filters.priceFrom ? Number((filters.priceFrom as string).replace(/\s/g, '').replace(',', '.')) : null,
            priceTo: filters.priceTo ? Number((filters.priceTo as string).replace(/\s/g, '').replace(',', '.')) : null,
            areaFrom: filters.areaFrom ? Number(filters.areaFrom.toString().replace(',', '.')) : null,
            areaTo: filters.areaTo ? Number(filters.areaTo.toString().replace(',', '.')) : null,
            rentPriceFrom: filters.rentPriceFrom ? Number((filters.rentPriceFrom as string).replace(/\s/g, '')) : null,
            rentPriceTo: filters.rentPriceTo ? Number((filters.rentPriceTo as string).replace(/\s/g, '')) : null,
            pricePerSquareMeterFrom: filters.pricePerSquareMeterFrom ? Number((filters.pricePerSquareMeterFrom as string).replace(/\s/g, '').replace(',', '.')) : null,
            pricePerSquareMeterTo: filters.pricePerSquareMeterTo ? Number((filters.pricePerSquareMeterTo as string).replace(/\s/g, '').replace(',', '.')) : null,
            boundaries: undefined,
            reportType: undefined,
            timeRange: undefined
        },
        paramsSerializer: function (params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        }
    })
        .then((resp) => {
            if (resp.data && resp.data.length > 0) {
                return resp.data.map((x: OfferData) => {
                    const mods = [
                        ...x.advertisementModifications ?? [],
                        ...x.modifications ?? []
                    ];

                    const duplications = [
                        ...x.advertisementDuplicates ?? [],
                        ...x.duplications ?? []
                    ];

                    return {
                        city,
                        ...x,
                        reaction: {
                            ...x.reaction
                        },
                        // @ts-expect-error TODO: will be changed after old modules will be deleted
                        id: x.bailiffNoticeId || x.auctionId || x.cooperativeId || x.noticeId || x.rentId || x.advertisementId || x.id,
                        thumbnailPath: x.thumbnailPath ? x.thumbnailPath.replace(' ', '%20') : '/defaultImg.png',
                        depositPrice: x.depositPrice
                            ? x.depositPrice
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                .replace('.', ',')
                            : undefined,
                        rentPrice: x.rentPrice
                            ? x.rentPrice
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                .replace('.', ',')
                            : undefined,
                        price: x.price
                            ? x.price
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                .replace('.', ',')
                            : undefined,
                        originalPrice: x.originalPrice
                            ? x.originalPrice
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                .replace('.', ',')
                            : undefined,
                        pricePerSquare: x.pricePerSquareMeter
                            ? x.pricePerSquareMeter
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                .replace('.', ',')
                            : undefined,
                        portal: {
                            link: (typeof x.portal === 'object' ? x.link ?? x.portal.link : x.link) ?? '',
                            name: (typeof x.portal === 'object' ? x.portal.portalName ?? x.portal : x.portal) ?? ''
                        },
                        isModified: mods.length > 0,
                        modifications: mods,
                        duplications,
                        filtersState: filters,
                        pageType: type,
                        filteredCity: city
                    };
                })
                    .sort(compareDateDescending);
            }

            return [{ noItems: true }];
        })
        .catch(() => {
            activeNotification(
                'Nie znaleziono wyników',
                'Wyszukiwanie nie powiodło się, spróbuj jeszcze raz.',
                'warning'
            );

            return [{ noItems: true }];
        });
};

const getOffers = (type: ModulesType, city: string | CitiesType[], filters: OfferRequestData | RentOfferRequestData, propertyType?: NormalizedPropertyType, page?: number, size?: number): Promise<OfferData[] | RentOffersResponse[]> => {
    if (type === 'rent' && propertyType && page && page >= 0 && size && size >= 0) {
        return getRentOffers(filters as RentOfferRequestData, propertyType, page, size);
    } else {
        return getOtherOffers(type, city, filters as OfferRequestData);
    }
};

const getRentOfferDetails = async (propertyType: RentModuleType, id: number): Promise<AxiosResponse> => {
    return axiosApiInstance2
        .get(`/rent-offer/advertisement/detail?module=${propertyType}&advertisementId=${id}`)
        .then((resp) => {
            if (resp.data) {
                return {
                    ...resp.data,
                    price: resp.data.price ? convertPrice(resp.data.price) : ''
                };
            }
        })
        .catch(() => {
            activeNotification(
                'Nie udało się pobrać ogłoszenia',
                'Nie udało się pobrać danych szczegółowych ogłoszenia',
                'warning'
            );

            return [{ empty: true }];
        });
};

const getOfferDuplicates = async (propertyType: RentModuleType, id: string): Promise<AxiosResponse> => {
    return axiosApiInstance2
        .get(`/rent-offer/duplicate/find?module=${propertyType}&advertisementId=${id}`)
        .then((resp) => {
            if (resp.data) {
                return resp.data;
            }
        })
        .catch(() => {
            activeNotification(
                'Nie udało się pobrać duplikatów ogłoszenia',
                'Nie udało się pobrać danych szczegółowych duplikatów ogłoszenia',
                'warning'
            );

            return [];
        });
};

const getUsersFavoriteOffers = (type: string, page?: number, size?: number, filters?: FavoritePageFiltersType): Promise<RentOffersResponse[] | OfferData[] | SaleOffersResponse[] | BailiffOffersResponse[]> => {
    return axiosApiInstance2({
        method: 'get',
        url: `/favorite/find?module=${type}`,
        params: {
            ...filters,
            page,
            size
        },
        paramsSerializer: function (params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        }
    })
        .then((resp) => {
            if (resp.data.content && resp.data.content.length > 0) {
                const content = resp.data.content.map((x: RentOfferResponseContent) => {
                    if (type === 'BAILIFF_NOTICE') {
                        return {
                            ...x,
                            bailiffNoticeId: x.advertisementId
                        };
                    }

                    return x;
                });

                return {
                    ...resp.data,
                    pageable: {
                        ...resp.data.pageable,
                        pageNumber: resp.data.pageable.pageNumber + 1
                    },
                    content: content.sort(compareDateDescending)
                };
            }

            return [{ noItems: true }];
        })

        .catch(() => {
            activeNotification(
                'Nie znaleziono wyników',
                'Obecnie w naszej bazie nie posiadamy ogłoszeń, które spełniają twoje kryteria. Spróbuj zmienić kryteria i wyszukaj jeszcze raz.',
                'warning'
            );

            return [{ noItems: true }];
        })
        .catch(() => {
            activeNotification(
                'Nie znaleziono wyników',
                'Obecnie w naszej bazie nie posiadamy ogłoszeń, które spełniają twoje kryteria. Spróbuj zmienić kryteria i wyszukaj jeszcze raz.',
                'warning'
            );

            return [];
        });
};

const getSaleFavoriteOffers = (modules: string[], page?: number, size?: number, filters?: FavoritePageFiltersType): Promise<SaleOffersResponse> => {
    return axiosApiInstance2({
        method: 'get',
        url: '/favorite/find/sale',
        params: {
            module: modules,
            ...filters,
            page,
            size
        },
        paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        }
    })
        .then((resp) => {
            if (resp.data.content && resp.data.content.length > 0) {
                return {
                    ...resp.data,
                    pageable: {
                        ...resp.data.pageable,
                        pageNumber: resp.data.pageable.pageNumber + 1
                    },
                    content: resp.data.content.sort(compareDateDescending)
                };
            }

            return { empty: true, content: [], afterSearch: true };
        })
        .catch(() => {
            activeNotification(
                'Nie znaleziono wyników',
                'Obecnie w naszej bazie nie posiadamy ogłoszeń, które spełniają twoje kryteria. Spróbuj zmienić kryteria i wyszukaj jeszcze raz.',
                'warning'
            );

            return { empty: true, content: [], afterSearch: true };
        });
};

const getFavoriteOffers = (type: ModulesType, filters?: FavoritePageFiltersType, page?: number, size?: number): Promise<RentOffersResponse[] | OfferData[]> => {
    if (type === 'RENT_FLAT' || type === 'RENT_PLOT' || type === 'RENT_HOUSE' || type === 'RENT_OTHER') {
        return getUsersFavoriteOffers(type, page, size, filters) as Promise<RentOffersResponse[]>;
    } else {
        return axiosApiInstance({
            method: 'get',
            url: `/new-front/favorite/find?module=${type}`,
            params: {
                ...filters
            },
            paramsSerializer: function (params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            }
        })
            .then((resp) => {
                if (resp.data && resp.data.length > 0) {
                    return resp.data.map((x: OfferData) => {
                        const modifications = [
                            ...x.advertisementModifications ?? [],
                            ...x.modifications ?? []
                        ];

                        const duplications = [
                            ...x.advertisementDuplicates ?? [],
                            ...x.duplications ?? []
                        ];

                        return {
                            ...x,
                            reaction: {
                                ...x.reaction
                            },
                            // @ts-expect-error TODO: will be changed after old modules will be deleted
                            id: x.bailiffNoticeId || x.auctionId || x.cooperativeId || x.noticeId || x.rentId || x.advertisementId || x.id,
                            thumbnailPath: x.thumbnailPath ? x.thumbnailPath.replace(' ', '%20') : '/defaultImg.png',
                            depositPrice: x.depositPrice
                                ? x.depositPrice
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                    .replace('.', ',')
                                : undefined,
                            rentPrice: x.rentPrice
                                ? x.rentPrice
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                    .replace('.', ',')
                                : undefined,
                            price: x.price
                                ? x.price
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                    .replace('.', ',')
                                : undefined,
                            originalPrice: x.originalPrice
                                ? x.originalPrice
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                    .replace('.', ',')
                                : undefined,
                            pricePerSquare: x.pricePerSquareMeter
                                ? x.pricePerSquareMeter
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                    .replace('.', ',')
                                : undefined,
                            portal: {
                                link: (typeof x.portal === 'object' ? x.link ?? x.portal.link : x.link) ?? '',
                                name: (typeof x.portal === 'object' ? x.portal.portalName ?? x.portal : x.portal) ?? ''
                            },
                            isModified: modifications.length > 0,
                            modifications,
                            duplications
                        };
                    })
                        .sort(compareDateDescending);
                }

                return [{ noItems: true }];
            })
            .catch(() => {
                activeNotification(
                    'Nie znaleziono wyników',
                    'Obecnie w naszej bazie nie posiadamy ogłoszeń, które spełniają twoje kryteria. Spróbuj zmienić kryteria i wyszukaj jeszcze raz.',
                    'warning'
                );

                return [{ noItems: true }];
            })
            .catch(() => {
                activeNotification(
                    'Nie znaleziono wyników',
                    'Obecnie w naszej bazie nie posiadamy ogłoszeń, które spełniają twoje kryteria. Spróbuj zmienić kryteria i wyszukaj jeszcze raz.',
                    'warning'
                );

                return [];
            });
    }
};

const getNotes = (type: ModulesType, id: string, city: string): Promise<OfferData[]> => {
    return axiosApiInstance({
        method: 'get',
        url: `${getFilterTypeByType('notes')}/get-notes`,
        params: {
            ownerId: id,
            module: type === 'bailiff_notice' ? 'bailiff_notice' : getFilterTypeByType(type),
            city
        },
        paramsSerializer: function (params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        }
    })
        .then((resp) => {
            if (resp.data && resp.data.length > 0) {
                return resp.data;
            }

            return [];
        })
        .catch(() => {
            return [];
        });
};

// const v2GetNotes = (type: string, id: string) => {
//     return axiosApiInstance2({
//         method: 'get',
//         url: 'note/get-notes',
//         params: {
//             ownerId: id,
//             module: type,
//         },
//     })
//         .then((resp) => {
//             if (resp.data && resp.data.length > 0) {
//                 return resp.data;
//             }
//
//             return [];
//         })
//         .catch(() => {
//             return [];
//         });
// };
//

const deleteNote = (type: ModulesType, noteId: number): Promise<OfferData[]> => {
    return axiosApiInstance({
        method: 'delete',
        url: `${getFilterTypeByType('notes')}/delete/${noteId}`,
        params: {
            module: type === 'bailiff_notice' ? 'bailiff_notice' : getFilterTypeByType(type)
        },
        paramsSerializer: function (params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        }
    })
        .then(() => {
            activeNotification(
                'Notatka usunięta',
                'Pomyślnie usunięto notatkę',
                'success'
            );

            return [];
        })
        .catch(() => {
            activeNotification(
                'Coś poszło nie tak',
                'Niestety nie udało się usunąć notatki. Spróbuj ponownie.',
                'warning'
            );

            return [];
        });
};

// const v2UpdateNote = (type: string, note: string, id: string, noteId?: number): Promise<NoteDto> => {
//     return axiosApiInstance2({
//         method: 'put',
//         url: 'note/process',
//         params: {
//             module: type,
//         },
//         data: {
//             ownerId: id,
//             content: note,
//             noteId: noteId === 0 ? undefined : noteId,
//         },
//     })
//         .then((resp) => {
//             activeNotification(
//                 'Notatka zaaktualizowana',
//                 'Aktualizacja notatki przebiegła pomyślnie',
//                 'success'
//             );
//
//             return { ...resp.data, advertisementId: resp.data.advertisementId ? resp.data.advertisementId : id };
//         })
//         .catch(() => {
//             activeNotification(
//                 'Coś poszło nie tak',
//                 'Niestety nie udało się zaaktualizować notatki. Spróbuj ponownie.',
//                 'warning'
//             );
//         });
// };

const updateNote = (type: ModulesType, note: string, noteId: number, id: string, city: string): Promise<OfferData[]> => {
    return axiosApiInstance({
        method: 'put',
        url: `${getFilterTypeByType('notes')}/process`,
        params: {
            module: type === 'bailiff_notice' ? 'bailiff_notice' : getFilterTypeByType(type)
        },
        data: {
            ownerId: id,
            city,
            content: note,
            noteId
        }
    })
        .then((resp) => {
            activeNotification(
                'Notatka zaaktualizowana',
                'Aktualizacja notatki przebiegła pomyślnie',
                'success'
            );

            return { ...resp.data, advertisementId: resp.data.advertisementId ? resp.data.advertisementId : id };
        })
        .catch(() => {
            activeNotification(
                'Coś poszło nie tak',
                'Niestety nie udało się zaaktualizować notatki. Spróbuj ponownie.',
                'warning'
            );

            // return {...resp.data, advertisementId: resp.data.advertisementId ? resp.data.advertisementId : id};
        });
};

const setOfferReaction = async (id: string, city: string, type: ModulesType, reactions: UserReactions) => {
    return axiosApiInstance({
        method: 'PUT',
        url: `${getFilterTypeByType('reactions')}/process/`,
        params: {
            module: type === 'bailiff_notice' ? type : getFilterTypeByType(type)
        },
        data: {
            ownerId: id,
            ...reactions,
            city
        },
        paramsSerializer: function (params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        }
    })
        .then(() => {
            return true;
        })
        .catch(() => {
            activeNotification(
                'Coś poszło nie tak!',
                'Spróbuj ponownie',
                'danger'
            );

            return false;
        });
};

// const v2SetOfferReaction = async (id: number, city: string, reactions: UserReactions, propertyType: string) => {
//     return axiosApiInstance2({
//         method: 'PUT',
//         url: 'reaction/process',
//         params: {
//             module: propertyType,
//         },
//         data: {
//             ownerId: id,
//             ...reactions,
//             city,
//         },
//     })
//         .then((resp) => {
//             return resp.data;
//         })
//         .catch(() => {
//             activeNotification(
//                 'Coś poszło nie tak!',
//                 'Spróbuj ponownie',
//                 'danger'
//             );
//
//             return false;
//         });
// };

const setNewOfferNote = async (id: string, city: string, type: ModulesType, note: string): Promise<OfferNoteResponseType> => {
    const additionalParam = type === 'advertisement' ? `city=${city}` : '';

    return axiosApiInstance({
        method: 'PUT',
        url: `${getFilterTypeByType('notes')}/process?${additionalParam}`,
        params: {
            module: type === 'bailiff_notice' ? 'bailiff_notice' : getFilterTypeByType(type)
        },
        data: {
            ownerId: id,
            city,
            content: note
        }
    })
        .then((resp) => {
            activeNotification(
                'Zrobione!',
                'Notatka dodana pomyślnie',
                'success'
            );

            return { ...resp, validated: true };
        })
        .catch(({ response }) => {
            activeNotification(
                'Coś poszło nie tak!',
                response.data.note || 'Spróbuj ponownie',
                'danger'
            );

            return { validated: false };
        });
};

export const offersApiRequests = {
    getOffers,
    getNotes,
    setOfferReaction,
    setNewOfferNote,
    deleteNote,
    updateNote,
    getFavoriteOffers,
    getSaleFavoriteOffers,
    getRentOfferDetails,
    getOfferDuplicates,
    getOffersCSVFile,
    getUsersFavoriteOffers
};
