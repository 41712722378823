import React, { FC, useContext, useMemo, useState } from 'react';
import { OffersActions } from 'store/Offers/Actions';
import { useOffers } from 'store/Offers/Context';
import RentContext from 'utils/context/RentContext';
import { getNormalizedPropertyType } from 'utils/formatters/getNormalizedPropertyType';
import {
    areaOptions,
    buildYearOptions,
    flatsBuildingTypeOptions,
    housesBuildingTypeOptions, offerFromOptions, otherBuildingTypeOptions, plotAreaOptions, plotsBuildingTypeOptions,
    pricePerSquareMeterOptions,
    rentPriceOptions,
    rentPropertyTypeOptions,
    roomOptions,
    scheduleOptions,
    typeOfMarketOptions,
    userFloorOptions
} from 'utils/options';
import { getValueFromState } from 'utils/state-managment/rent/rentFilter';
import { InputType, LocationChangeType } from 'utils/types/InputTypes';
import { RentModuleType } from 'utils/types/Rent';

import Button from 'components/atom/Button';
import StickyBottom from 'components/common/FiltersRenderer/StickyBottom';
import V2FiltersRenderer, {
    ElementsToRender
} from 'components/common/FiltersRenderer/V2FiltersRenderer';
import { IconEnum } from 'components/common/Icon';
import InputContainer from 'components/common/InputContainer';
import { activeNotification } from 'components/functions/activeNotification';
import { getVoivodeshipCities } from 'components/functions/locations';

type FiltersRentProperties = object

const FiltersRent: FC<FiltersRentProperties> = () => {
    const {
        handleDeleteFilter,
        handleSetFiltersValues,
        handleChange,
        clearFilters,
        rentFiltersState,
        propertyType,
        handleChangeDateList,
        handleChangePropertyType,
        handleSaveOrUpdateFilter,
        handleChangeNotificationAlert
    } = useContext(RentContext);
    const { dispatch, offers } = useOffers();
    const [isFilterButtonVisible, setIsFilterButtonVisible] = useState(false);
    const buildingOptions = useMemo(() => {
        if (rentFiltersState.module === RentModuleType.RENT_FLAT) return flatsBuildingTypeOptions;
        if (rentFiltersState.module === RentModuleType.RENT_HOUSE) return housesBuildingTypeOptions;
        if (rentFiltersState.module === RentModuleType.RENT_OTHER) return otherBuildingTypeOptions;
        if (rentFiltersState.module === RentModuleType.RENT_PLOT) return plotsBuildingTypeOptions;

        return [];
    }, [rentFiltersState.module]);

    const resetFilters = () => {
        clearFilters(true);
    };

    const getCities = async (voivodeship: string) => {
        const newLocations = await getVoivodeshipCities(voivodeship, rentFiltersState.locations);

        handleChange('locations', newLocations || []);
    };

    const handleRemoveSavedLocation = (location: string) => {
        const savedLocations = rentFiltersState.savedLocations;

        delete savedLocations[location];

        handleChange('savedLocations', savedLocations);
        handleChange('city', Object.values(savedLocations as LocationChangeType));
    };

    const handleSubmit = () => {
        if (!rentFiltersState.city || !rentFiltersState.city.length) activeNotification('Popraw wpisane dane', 'Musisz wybrać poprawną miejscowość', 'warning');
        else {
            OffersActions.loadOffersAsync(dispatch, 'rent', rentFiltersState.city, { ...getValueFromState(rentFiltersState) }, getNormalizedPropertyType(rentFiltersState.module), 1, 25);

            document.body.scrollTo(0, 0);
        }
    };

    const rentFiltersStructure = useMemo<ElementsToRender[]>(() => [
        {
            accordionTitle: 'Podstawowe',
            hideTitle: true,
            filters: [
                {
                    title: 'Zapisane filtry',
                    type: InputType.FILTER_AUTOCOMPLETE,
                    icon: IconEnum.BOOKMARK,
                    placeholder: 'Wybierz filtr...',
                    options: {
                        [InputType.FILTER_AUTOCOMPLETE]: {
                            contextFunctions: {
                                handleDeleteFilter,
                                handleSetFiltersValues
                            },
                            modulesProps: {
                                filterId: rentFiltersState.id,
                                options: rentFiltersState.savedFilter
                            }
                        }
                    }
                },
                {
                    title: 'Lokalizacja',
                    type: InputType.LOCATION,
                    helperText: 'Wybierz z listy interesującą Ciebie lokalizację. Możesz zaznaczyć dowolną ilość.',
                    icon: IconEnum.MAP_PIN,
                    placeholder: 'Szukaj lokalizacji...',
                    options: {
                        [InputType.LOCATION]: {
                            modulesProps: {
                                locations: rentFiltersState.locations,
                                searchLocationResult: rentFiltersState.searchLocationResult,
                                savedLocations: rentFiltersState.savedLocations,
                                savedLocationsFieldName: 'savedLocations',
                                cityFieldName: 'city'
                            },
                            contextFunctions: {
                                getCities,
                                handleRemoveSavedLocation,
                                handleChange
                            }
                        }
                    }
                },
                {
                    title: 'Data',
                    type: InputType.FLEXIBLE_DATE,
                    helperText: 'Wybierz, z jakiego okresu chcesz wyświetlić oferty. Wybierz opcję "Przedział dat" aby precyzyjnie wskazać datę. Ten parametr nie wpływa na natychmiastowe wysyłanie alertów.',
                    icon: IconEnum.CALENDAR,
                    placeholder: 'Data dodania oferty...',
                    containerActionText: 'Przedział dat',
                    containerActionClick: () => handleChange('dateRangeFilter', !rentFiltersState.dateRangeFilter),
                    options: {
                        [InputType.FLEXIBLE_DATE]: {
                            modulesProps: {
                                date: rentFiltersState.offerAdded,
                                dateRangeFilter: rentFiltersState.dateRangeFilter,
                                daysAmount: rentFiltersState.daysAmount,
                                fieldName: 'offerAdded'
                            },
                            contextFunctions: {
                                handleChange,
                                handleChangeDateList
                            }
                        }
                    }
                }
            ]
        },
        {
            accordionTitle: 'Parametry',
            hideTitle: true,
            filters: [
                {
                    title: 'Typ nieruchomości',
                    type: InputType.AUTOCOMPLETE_PROPERTY,
                    icon: IconEnum.LEAVE,
                    placeholder: 'Wybierz typ...',
                    options: {
                        [InputType.AUTOCOMPLETE_PROPERTY]: {
                            contextFunctions: {
                                handleChangePropertyType,
                                handleChange
                            },
                            modulesProps: {
                                value: rentFiltersState.module,
                                options: rentPropertyTypeOptions
                            }
                        }
                    }
                },
                {
                    title: 'Cena wynajmu',
                    type: InputType.RANGE_PRICE_SELECT,
                    icon: IconEnum.PRICE,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_PRICE_SELECT]: {
                            modulesProps: {
                                value: rentFiltersState.price,
                                fieldName: 'price',
                                options: rentPriceOptions
                            },
                            contextFunctions: {
                                handleChange
                            }
                        }
                    }
                },
                {
                    title: 'Kaucja',
                    type: InputType.RANGE_PRICE_SELECT,
                    icon: IconEnum.PRICE,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_PRICE_SELECT]: {
                            modulesProps: {
                                value: rentFiltersState.depositPrice,
                                fieldName: 'depositPrice',
                                options: rentPriceOptions
                            },
                            contextFunctions: {
                                handleChange
                            }
                        }
                    }
                },
                {
                    title: 'Cena czynszu',
                    type: InputType.RANGE_PRICE_SELECT,
                    icon: IconEnum.PRICE,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_PRICE_SELECT]: {
                            modulesProps: {
                                value: rentFiltersState.depositPrice,
                                fieldName: 'depositPrice',
                                options: pricePerSquareMeterOptions
                            },
                            contextFunctions: {
                                handleChange
                            }
                        }
                    }
                },
                {
                    title: 'Cena za metr',
                    type: InputType.RANGE_PRICE_SELECT,
                    icon: IconEnum.PRICE_PART,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_PRICE_SELECT]: {
                            modulesProps: {
                                value: rentFiltersState.pricePerSquareMeter,
                                fieldName: 'pricePerSquareMeter',
                                options: pricePerSquareMeterOptions
                            },
                            contextFunctions: {
                                handleChange
                            }
                        }
                    }
                },
                {
                    title: 'Metraż',
                    type: InputType.RANGE_METERS_SELECT,
                    icon: IconEnum.SQUARE,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_METERS_SELECT]: {
                            modulesProps: {
                                value: rentFiltersState.area,
                                fieldName: 'area',
                                options: rentFiltersState.module === RentModuleType.RENT_PLOT ? plotAreaOptions : areaOptions
                            },
                            contextFunctions: {
                                handleChange
                            }
                        }
                    }
                },
                {
                    title: 'Typ ogłoszeniodawcy',
                    type: InputType.MULTIPLE_SELECT,
                    icon: IconEnum.PEOPLE,
                    placeholder: 'Wybierz typ...',
                    options: {
                        [InputType.MULTIPLE_SELECT]: {
                            modulesProps: {
                                value: rentFiltersState.offerFrom,
                                options: offerFromOptions,
                                optionsIcon: IconEnum.PEOPLE,
                                fieldName: 'offerFrom'
                            },
                            contextFunctions: {
                                handleChange
                            }
                        }
                    }
                },
                ...rentFiltersState.module === RentModuleType.RENT_FLAT || rentFiltersState.module === RentModuleType.RENT_HOUSE
                    ? [
                        {
                            title: 'Pokoje',
                            type: InputType.RANGE_NUMBER_SELECT,
                            icon: IconEnum.BED,
                            placeholder: '',
                            options: {
                                [InputType.RANGE_NUMBER_SELECT]: {
                                    modulesProps: {
                                        value: rentFiltersState.numberOfRooms,
                                        fieldName: 'numberOfRooms',
                                        options: roomOptions,
                                        maxInputLength: 3
                                    },
                                    contextFunctions: {
                                        handleChange
                                    }
                                }
                            }
                        }
                    ]
                    : [],
                ...rentFiltersState.module === RentModuleType.RENT_FLAT || rentFiltersState.module === RentModuleType.RENT_OTHER
                    ? [
                        {
                            title: 'Piętro',
                            type: InputType.RANGE_NUMBER_SELECT,
                            icon: IconEnum.FLOOR_LEVEL,
                            placeholder: '',
                            options: {
                                [InputType.RANGE_NUMBER_SELECT]: {
                                    modulesProps: {
                                        value: rentFiltersState.floor,
                                        fieldName: 'floor',
                                        options: userFloorOptions,
                                        maxInputLength: 3
                                    },
                                    contextFunctions: {
                                        handleChange
                                    }
                                }
                            }
                        }
                    ]
                    : [],
                ...rentFiltersState.module === RentModuleType.RENT_HOUSE
                    ? [
                        {
                            title: 'Ilość pięter',
                            type: InputType.RANGE_NUMBER_SELECT,
                            icon: IconEnum.TWO_TYPE,
                            placeholder: 'Wybierz piętra...',
                            options: {
                                [InputType.RANGE_NUMBER_SELECT]: {
                                    modulesProps: {
                                        value: rentFiltersState.floors,
                                        options: userFloorOptions,
                                        fieldName: 'floors',
                                        maxInputLength: 2
                                    },
                                    contextFunctions: {
                                        handleChange
                                    }
                                }
                            }
                        }
                    ]
                    : [],
                ...rentFiltersState.module === RentModuleType.RENT_FLAT || rentFiltersState.module === RentModuleType.RENT_HOUSE || rentFiltersState.module === RentModuleType.RENT_OTHER
                    ? [
                        {
                            title: 'Rok budowy',
                            type: InputType.RANGE_NUMBER_SELECT,
                            icon: IconEnum.CALENDAR,
                            placeholder: '',
                            options: {
                                [InputType.RANGE_NUMBER_SELECT]: {
                                    modulesProps: {
                                        value: rentFiltersState.builtYear,
                                        fieldName: 'builtYear',
                                        options: buildYearOptions,
                                        maxInputLength: 4
                                    },
                                    contextFunctions: {
                                        handleChange
                                    }
                                }
                            }
                        }
                    ]
                    : [],
                ...rentFiltersState.module === RentModuleType.RENT_FLAT || rentFiltersState.module === RentModuleType.RENT_HOUSE || rentFiltersState.module === RentModuleType.RENT_OTHER
                    ? [
                        {
                            title: 'Typ budynku',
                            type: InputType.MULTIPLE_SELECT,
                            icon: IconEnum.TWO_TYPE,
                            placeholder: 'Wybierz rodzaj...',
                            options: {
                                [InputType.MULTIPLE_SELECT]: {
                                    modulesProps: {
                                        value: rentFiltersState.buildingType,
                                        options: buildingOptions,
                                        optionsIcon: 'two_type' as IconEnum,
                                        fieldName: 'buildingType'
                                    },
                                    contextFunctions: {
                                        handleChange
                                    }
                                }
                            }
                        }
                    ]
                    : [],
                ...rentFiltersState.module === RentModuleType.RENT_FLAT || rentFiltersState.module === RentModuleType.RENT_HOUSE || rentFiltersState.module === RentModuleType.RENT_OTHER
                    ? [
                        {
                            title: 'Rodzaj rynku',
                            type: InputType.MULTIPLE_SELECT,
                            icon: IconEnum.WORK,
                            placeholder: 'Wybierz rodzaj rynku...',
                            options: {
                                [InputType.MULTIPLE_SELECT]: {
                                    modulesProps: {
                                        value: rentFiltersState.typeOfMarket,
                                        options: typeOfMarketOptions,
                                        optionsIcon: IconEnum.COG,
                                        fieldName: 'typeOfMarket'
                                    },
                                    contextFunctions: {
                                        handleChange
                                    }
                                }
                            }
                        }
                    ]
                    : []
            ]
        },
        {
            accordionTitle: 'Tagi',
            hideTitle: true,
            filters: [
                {
                    title: 'Słowa pożądane',
                    type: InputType.KEYWORDS_TEXT,
                    helperText: 'Podaj słowa kluczowe, które mają zawierać się w tytule lub opisie ogłoszenia, rozdzielając je przecinkiem. Więcej w zakładce Pomoc',
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Wpisz słowo...',
                    options: {
                        [InputType.KEYWORDS_TEXT]: {
                            modulesProps: {
                                value: rentFiltersState.wantedKeywords,
                                fieldName: 'wantedKeywords',
                                icon: IconEnum.SEARCH,
                                actionIcon: IconEnum.PLUS,
                                errorMessage: 'Słowo musi mieć minimum 3 znaki',
                                minLength: 3
                            },
                            contextFunctions: {
                                inputValidation: (val: string | number | null | undefined) => (val as string).length > 0 && (val as string).length < 3,
                                handleChange
                            }
                        }
                    }
                },
                {
                    title: 'Słowa niepożądane',
                    type: InputType.KEYWORDS_TEXT,
                    helperText: 'Podaj słowa kluczowe, które nie mają zawierać się w tytule lub opisie ogłoszenia, rozdzielając je przecinkiem. Więcej w zakładce Pomoc',
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Wpisz słowo...',
                    options: {
                        [InputType.KEYWORDS_TEXT]: {
                            modulesProps: {
                                value: rentFiltersState.unwantedKeywords,
                                fieldName: 'unwantedKeywords',
                                icon: IconEnum.SEARCH,
                                actionIcon: IconEnum.PLUS,
                                errorMessage: 'Słowo musi mieć minimum 3 znaki',
                                minLength: 3
                            },
                            contextFunctions: {
                                inputValidation: (val: string | number | null | undefined) => (val as string).length > 0 && (val as string).length < 3,
                                handleChange
                            }
                        }
                    }
                },
                {
                    title: 'Pożądane numery telefonów',
                    type: InputType.KEYWORDS_TEXT,
                    helperText: 'Podaj numery telefonów, które mają zawierać się w tytule lub opisie ogłoszenia, rozdzielając je przecinkiem. Więcej w zakładce Pomoc',
                    icon: IconEnum.PHONE,
                    placeholder: 'Wpisz numer...',
                    options: {
                        [InputType.KEYWORDS_TEXT]: {
                            modulesProps: {
                                value: rentFiltersState.wantedPhoneNumbers,
                                fieldName: 'wantedPhoneNumbers',
                                icon: IconEnum.SEARCH,
                                actionIcon: IconEnum.PLUS,
                                errorMessage: 'Numer telefonu musi mieć 9 znaków',
                                minLength: 9,
                                maxLength: 9
                            },
                            contextFunctions: {
                                inputValidation: (val: string | number | null | undefined) => (val as string).length > 0 && (val as string).length !== 9,
                                handleChange
                            }
                        }
                    }
                },
                {
                    title: 'Niepożądane numery telefonów',
                    type: InputType.KEYWORDS_TEXT,
                    helperText: 'Podaj numery telefonów, które nie mają zawierać się w tytule lub opisie ogłoszenia, rozdzielając je przecinkiem. Więcej w zakładce Pomoc',
                    icon: IconEnum.PHONE,
                    placeholder: 'Wpisz numer...',
                    options: {
                        [InputType.KEYWORDS_TEXT]: {
                            modulesProps: {
                                value: rentFiltersState.unwantedPhoneNumbers,
                                fieldName: 'unwantedPhoneNumbers',
                                icon: IconEnum.SEARCH,
                                actionIcon: IconEnum.PLUS,
                                errorMessage: 'Numer telefonu musi mieć 9 znaków',
                                minLength: 9,
                                maxLength: 9
                            },
                            contextFunctions: {
                                inputValidation: (val: string | number | null | undefined) => (val as string).length > 0 && (val as string).length !== 9,
                                handleChange
                            }
                        }
                    }
                }
            ]
        },
        {
            accordionTitle: 'Ustawienia',
            hideTitle: true,
            filters: [
                {
                    title: 'Grupowanie duplikatów',
                    type: InputType.RADIO,
                    helperText: 'Wybierz czy te same ogłoszenia mają zostać połączone.',
                    icon: IconEnum.OPTIONS,
                    placeholder: '',
                    options: {
                        [InputType.RADIO]: {
                            modulesProps: {
                                value: rentFiltersState.groupDuplicate,
                                fieldName: 'groupDuplicate'
                            },
                            contextFunctions: {
                                handleChange
                            }
                        }
                    }
                },
                {
                    title: 'Zerowe lub puste wartości',
                    type: InputType.RADIO,
                    helperText: 'Wybierz czy mają pojawiać się ogłoszenia, które nie spełniają parametrów filtra z powodu braku danych. Więcej w zakładce Pomoc',
                    icon: IconEnum.OPTIONS,
                    placeholder: '',
                    options: {
                        [InputType.RADIO]: {
                            modulesProps: {
                                value: rentFiltersState.includeZeroArea,
                                fieldName: 'includeZeroArea'
                            },
                            contextFunctions: {
                                handleChange
                            }
                        }
                    }
                }
            ]
        },
        {
            accordionTitle: 'Dodatkowe',
            hideTitle: true,
            filters: [
                {
                    title: 'Harmonogram',
                    type: InputType.AUTOCOMPLETE,
                    helperText: 'Co jaki okres chcesz otrzymywać powiadomienia? Jeżeli wybierzesz "Natychmiast" to każda oferta zostanie wysłana w osobnym mailu. w pozostałych przypadkach otrzymasz jednego maila np. co 5 min z listą ogłoszeń, które pojawiły się w tym czasie.',
                    icon: IconEnum.TIMER,
                    placeholder: 'Wybierz godziny...',
                    options: {
                        [InputType.AUTOCOMPLETE]: {
                            contextFunctions: {
                                handleChange
                            },
                            modulesProps: {
                                value: rentFiltersState.notificationsDelay,
                                fieldName: 'notificationsDelay',
                                options: scheduleOptions
                            }
                        }
                    }
                },
                {
                    title: 'Powiadomienia o modyfikacjach',
                    type: InputType.RADIO,
                    helperText: 'Wybierz czy mają pojawiać się ogłoszenia, które zostały zmodyfikowane.',
                    icon: IconEnum.AUDIO,
                    placeholder: '',
                    options: {
                        [InputType.RADIO]: {
                            modulesProps: {
                                value: rentFiltersState.modification,
                                fieldName: 'modification'
                            },
                            contextFunctions: {
                                handleChange
                            }
                        }
                    }
                },
                {
                    title: 'Powiadomienia o podbiciach',
                    type: InputType.RADIO,
                    helperText: 'Czy chcesz, abyśmy wysyłali Ci powiadomienia jeżeli ogłoszenie zostanie "podbite" przez sprzedającego (znajdzie się znów na pierwszej stronie portalu)?',
                    icon: IconEnum.AUDIO,
                    placeholder: '',
                    options: {
                        [InputType.RADIO]: {
                            modulesProps: {
                                value: rentFiltersState.tossedUp,
                                fieldName: 'tossedUp'
                            },
                            contextFunctions: {
                                handleChange
                            }
                        }
                    }
                }
            ]
        },
        {
            accordionTitle: 'Podsumowanie',
            hideTitle: true,
            isWideSpace: true,
            lastElement: true,
            filters: [
                {
                    title: 'Zapisz nowy filtr',
                    type: InputType.TEXT,
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Wpisz nazwę...',
                    containerActionText: rentFiltersState.id ? 'Aktualizuj' : undefined,
                    containerActionClick: handleSaveOrUpdateFilter,
                    options: {
                        [InputType.TEXT]: {
                            modulesProps: {
                                value: rentFiltersState.name || '',
                                fieldName: 'name',
                                inputIcon: IconEnum.BOOKMARK_WHITE,
                                actionIcon: IconEnum.ARROW_RIGHT,
                                important: true,
                                isSaveFilterButton: true
                            },
                            contextFunctions: {
                                handleChange,
                                inputActionHandler: handleSaveOrUpdateFilter
                            }
                        }
                    }
                },
                {
                    title: 'Aktywacja powiadomień',
                    type: InputType.NOTIFICATIONS,
                    helperText: 'Przed aktywacją powiadomienia zapisz filtr',
                    icon: IconEnum.ALERT,
                    placeholder: '',
                    options: {
                        [InputType.NOTIFICATIONS]: {
                            contextFunctions: {
                                handleChange: handleChangeNotificationAlert
                            },
                            modulesProps: {
                                alertEmail: rentFiltersState.alertEmail,
                                alertSms: rentFiltersState.alertSms,
                                pushAlert: rentFiltersState.pushAlert,
                                filterId: rentFiltersState.id
                            }
                        }
                    }
                },
                {
                    title: 'Zastosuj filtry',
                    type: InputType.SAVE_BUTTON,
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Przeglądaj oferty...',
                    options: {
                        [InputType.SAVE_BUTTON]: {
                            contextFunctions: {
                                onChangeVisibleButtonFilter: setIsFilterButtonVisible,
                                onActionClick: clearFilters,
                                onClick: handleSubmit
                            },
                            modulesProps: {
                                actionText: 'Resetuj'
                            }
                        }
                    }
                }
            ]
        }
    ], [rentFiltersState, propertyType]);

    return (
        <V2FiltersRenderer elementsToRender={rentFiltersStructure} type={'rent'} bottomActions={
            <StickyBottom
                isVisible={isFilterButtonVisible}
                active={offers.rentList.content?.length > 0 || offers.rentList.empty && offers.rentList.afterSearch}
                className={'sticky-filter-button'}>
                <InputContainer title={'Zastosuj filtry'} icon={IconEnum.DICTIONARY}
                    actionText={'Resetuj'}
                    onActionClick={resetFilters}>
                    <Button onClick={handleSubmit}>
                        {'Przeglądaj oferty...'}
                    </Button>
                </InputContainer>
            </StickyBottom>
        }/>
    );
};

export default FiltersRent;
