import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserActions } from 'store/User/Actions';
import { useUser } from 'store/User/Context';
import { UserMyOffersResponseContent } from 'store/User/State';
import styled from 'styled-components';
import { userApiRequests } from 'utils/api-requests/user';
import UserAddAdvertContext from 'utils/context/UserAddAdvertContext';
import { getEnumPropertyByTypeUser, getPropertyByTypeUser } from 'utils/formatters/getPropertyByType';
import { getMainValue } from 'utils/getMainValue';
import { getSubmainValues } from 'utils/getSubmainValues';
import { V2OfferType } from 'utils/types/UsersAdvert';

import Indicator from 'components/atom/Indicator';
import AccordionCard from 'components/common/AccordionCard';
import { NotInteractiveElement } from 'components/common/Card/common/V2InteractiveIconsWrapper/NotInteractiveIcons';
import FiltersContentWrapper from 'components/common/FiltersContentWrapper';
import Icon, { IconEnum } from 'components/common/Icon';
import Module from 'components/common/Module';
import { V2DetailsTabsProps } from 'components/common/Tabs/tabs/V2DetailsTab';
import TrashWithModal from 'components/common/TrashWithModal';
import { convertToThumbnailPath, createImagesForSlider } from 'components/functions/imagesFunctions';
import {
    userOfferListFunctions
} from 'components/functions/offerListFunctions/userOfferListFunctions/userOfferListFunctions';
import V2OfferList, { RenderList } from 'components/offerList/V2OfferList';
import { CustomNoOffersMessage } from 'components/users/myOffers/CustomNoOffersMessage';

const Wrapper = styled.div`
    .active .accordion-card-content {
        row-gap: 10px;
    }
`;

const FiltersContainer = styled.div`
    padding-left: 20px;

    @media (max-width: 1100px) {
        padding-right: 20px;
    }
`;

const ActionWrapper = styled.div`
    background-color: var(--color-alt-second);
    border-radius: 20px;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    gap: 8px;

    &:hover {
        transition: background-color ease-in .2s;
        background-color: var(--color-alt);
        cursor: pointer;

        p {
            color: white;
        }

        img {
            filter: ${(props) => props.darkMode ? 'none' : 'brightness(0) invert(1)'};
        }
    }
`;

const Options = styled.div`
    position: absolute;
    right: 0;
    top: -130px;
    background: var(--color-white);
    border-radius: var(--box-border-radius);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: auto;
`;

const MyOffersPage = () => {
    const [activeTypeFilter, setActiveTypeFilter] = useState<V2OfferType>(V2OfferType.DEAL);
    const { user, dispatch } = useUser();
    const [renderList, setRenderList] = useState<RenderList[]>([]);
    const navigate = useNavigate();
    const [myOfferActiveButtons, setMyOfferActiveButtons] = useState<string | null>(null);
    const { handleChange } = useContext(UserAddAdvertContext);

    useEffect(() => {
        UserActions.loadMyOffers(dispatch, activeTypeFilter, 0, 25);
    }, [activeTypeFilter]);

    const handleChangePage = useCallback((currentPage: number, rows = 25) => {
        UserActions.loadMyOffers(dispatch, activeTypeFilter, currentPage, rows);
    }, [dispatch, activeTypeFilter]);

    const generateNotInteractiveElements = (offer: UserMyOffersResponseContent): NotInteractiveElement[] => {
        return [
            {
                icon: IconEnum.EYE,
                text: offer.viewedStatus!.toString()
            },
            {
                icon: IconEnum.LIKE,
                text: offer.likeStatus!.toString()
            },
            {
                icon: IconEnum.DISLIKE,
                text: offer.unlikeStatus!.toString()
            },
            {
                icon: IconEnum.BLOCKED,
                text: offer.hideStatus!.toString()
            },
            {
                icon: IconEnum.PHONE,
                text: offer.phoneStatus!.toString()
            },
            {
                icon: IconEnum.PEOPLES_3,
                text: offer.arrangedStatus!.toString()
            }
        ];
    };

    const toggleContainerDetails = (detailsOpen: boolean, offer: UserMyOffersResponseContent) => {
        if (!detailsOpen) {
            UserActions.loadMyOfferDetailsAsync(dispatch, activeTypeFilter, offer.advertisementId);
        }
    };

    const handleDeleteOffer = async (id: string) => {
        await UserActions.deleteMyOffer(dispatch, id, activeTypeFilter);
    };

    const handleEditOffer = (id: string) => {
        handleChange('offerType', activeTypeFilter);
        UserActions.loadMyOfferToEdit(dispatch, activeTypeFilter, id).then(() => navigate('/edit-offer'));
    };

    const handleChangeVisibilityOffer = async (event: React.MouseEvent, module: string, id: string, status: boolean) => {
        event.stopPropagation();
        await UserActions.changeVisibilityOffer(dispatch, module, id, status);
        setMyOfferActiveButtons(null);
    };

    const generateCardData = (offer: UserMyOffersResponseContent) => {
        const isMoreButtonActive = myOfferActiveButtons === offer.advertisementId;

        return {
            preTitle: offer.location,
            date: offer.dateAdded!,
            title: offer.title!,
            infoElements: userOfferListFunctions.generateInfoElements(offer),
            notInteractiveElements: generateNotInteractiveElements(offer),
            interactiveElements: [
                {
                    icon: 'squares' as IconEnum,
                    tooltipText: 'Więcej',
                    onClick: () => isMoreButtonActive ? setMyOfferActiveButtons(null) : setMyOfferActiveButtons(offer.advertisementId),
                    additionalJSXSibling: isMoreButtonActive
                        ? <Options>
                            <ActionWrapper onClick={() => handleEditOffer(offer.advertisementId)}>
                                <Icon icon={IconEnum.EDIT}/>
                                <p>Edytuj ogłoszenie</p>
                            </ActionWrapper>
                            <ActionWrapper onClick={(event: React.MouseEvent) => handleChangeVisibilityOffer(event, offer.module, offer.advertisementId, !offer.activationStatus)}>
                                <Icon icon={offer.activationStatus ? IconEnum.EYE_HIDDEN : IconEnum.EYE } />
                                <p>{offer.activationStatus ? 'Ukryj ogłoszenie' : 'Aktywuj ogłoszenie'}</p>
                            </ActionWrapper>
                            <ActionWrapper darkMode={user.isDarkMode}>
                                <TrashWithModal iconText={'Zakończ ogłoszenie'}
                                    title={'Czy na pewno chcesz usunąć ogłoszenie?'}
                                    onChange={() => handleDeleteOffer(offer.advertisementId)}
                                    filter={offer.title!}/>
                            </ActionWrapper>
                        </Options>
                        : undefined
                }
            ],
            isNotInteractive: true,
            thumbnailPath: offer.photos && offer.photos.length > 0 ? convertToThumbnailPath(offer.photos[0], offer.module) : './defaultImg.png',
            images: offer.photos && offer.photos.length > 0 ? createImagesForSlider(offer.photos, getPropertyByTypeUser(activeTypeFilter)) : undefined,
            ratingScore: null,
            mainValue: `${getMainValue(offer)} zł`,
            subMainValues: getSubmainValues(offer)
        };
    };

    const handleDelete = (id: string, offerType: V2OfferType, noteId: string) => {
        UserActions.deleteNote(dispatch, id, offerType, noteId);
    };

    const handleSaveNote = async (value: string, id: string, offerType: V2OfferType, noteId?: string) => {
        await UserActions.updateNote(dispatch, id, offerType, value, noteId);
    };

    const handleNotes = async (offer: UserMyOffersResponseContent) => {
        UserActions.loadNotesAsync(dispatch, getEnumPropertyByTypeUser(offer.module), offer.advertisementId.toString());
    };

    useEffect(() => {
        const offersList = user.myOfferList?.content?.map((offer): RenderList => {
            const notInteractiveElements = generateNotInteractiveElements(offer);
            const detailsTabContent: V2DetailsTabsProps = offer.detailedContent
                ? {
                    title: offer.detailedContent.title,
                    address: offer.detailedContent.city?.fullName,
                    detailsElements: userOfferListFunctions.generateDetailsTabElements(offer),
                    description: offer.detailedContent.description,
                    documents: offer.detailedContent.documents,
                    handleDocumentClick: async (doc: string) => {
                        await userApiRequests.downloadFile(doc, getEnumPropertyByTypeUser(offer.module));
                    }
                }
                : {
                    detailsElements: []
                };

            return {
                id: offer.advertisementId,
                onSingleOfferClick: (_: React.MouseEvent, detailsOpen: boolean) => toggleContainerDetails(detailsOpen, offer),
                baseCardContent: {
                    bigImage: true,
                    cardData: generateCardData(offer),
                    openOfferTab: () => {
                    }
                },
                detailsCardData: {
                    notInteractiveElements,
                    isMyOffersPage: true,
                    thumbnailPath: offer.photos && offer.photos.length > 0 ? convertToThumbnailPath(offer.photos[0], offer.module) : './defaultImg.png',
                    date: offer.dateAdded!,
                    onToggleContainerDetails: () => {
                    },
                    tabsContent: {
                        toggleContainerDetails: () => {
                        },
                        notInteractiveElements,
                        detailsTabContent,
                        notesTabContent: {
                            notes: user.notes,
                            handleDelete: (noteId: string) => handleDelete(offer.advertisementId.toString(), getEnumPropertyByTypeUser(offer.module), noteId),
                            handleSaveNote: (value: string, id?: string) => handleSaveNote(value, offer.advertisementId.toString(), getEnumPropertyByTypeUser(offer.module), id)
                        },
                        handleLoadNotes: () => handleNotes(offer)
                    }
                }
            };
        });
        setRenderList(offersList);
    }, [
        user.myOfferList,
        activeTypeFilter,
        myOfferActiveButtons,
        user.notes
    ]);

    return (
        <Wrapper id={'offer-list'}>
            <FiltersContentWrapper className={'active'}>
                <FiltersContainer>
                    <AccordionCard type={'favorites'} title={'Wybierz moduł'}>
                        <Module isActive={activeTypeFilter === V2OfferType.DEAL} icon={IconEnum.BAG_WHITE}
                            title={'Odstąpię Deal'} onClick={() => setActiveTypeFilter(V2OfferType.DEAL)}/>
                        <Module isActive={activeTypeFilter === V2OfferType.INVESTOR} icon={IconEnum.CALENDAR_WHITE}
                            title={'Szukam Inwestora'} onClick={() => setActiveTypeFilter(V2OfferType.INVESTOR)}/>
                        <Module isActive={activeTypeFilter === V2OfferType.CAPITAL} icon={IconEnum.PEOPLES_2_WHITE}
                            title={'Mam Kapitał'}
                            onClick={() => setActiveTypeFilter(V2OfferType.CAPITAL)}/>
                    </AccordionCard>
                </FiltersContainer>
                <V2OfferList
                    showNoItemsText={user.myOfferList.empty}
                    showList={!user.myOfferList.empty && user.myOfferList.content && user.myOfferList.content?.length > 0}
                    renderList={renderList}
                    totalPages={user.myOfferList?.totalPages}
                    pageNumber={user.myOfferList?.pageable?.pageNumber || 1}
                    handleChangePage={handleChangePage}
                    lastPage={user.myOfferList?.last}
                    customNoOffersMessage={<CustomNoOffersMessage darkMode={user.isDarkMode}/>}
                />
            </FiltersContentWrapper>
            {user.isLoading && <Indicator/>}
        </Wrapper>
    );
};

export default MyOffersPage;
