import { useEffect, useState } from 'react';
import { userApiRequests } from 'utils/api-requests/user';

export const useProfileStatus = () => {
    const [isProfileActive, setIsProfileActive] = useState(false);

    useEffect(() => {
        userApiRequests.checkIfProfileExists().then((response) => {
            setIsProfileActive(response);
        });
    }, []);

    return { isProfileActive };
};
