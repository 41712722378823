import dayjs from 'dayjs';
import { stringToNumber } from 'utils/formatters/stringToNumber';
import { SortType } from 'utils/types/SortType';
import { DealBoxDtoBuildingTypeEnum, InvestorBoxDtoBuildingTypeEnum } from 'utils/types/UserModels';
import { RequireOnlyOne, UserFilterReturnData,
    UserOfferRequestData,
    UsersFiltersStateType,
    V2OfferType } from 'utils/types/UsersAdvert';

import { dateFormat, dateFromFunc } from 'components/FilterFunctions';

export const setStateValues = (state: UserFilterReturnData): UsersFiltersStateType => {
    const dateAddedFromWithDefault = state.dateAddedFrom || dayjs().subtract(3, 'day').toISOString();

    return {
        ...state,
        // @ts-expect-error TODO: INVEST-254 fix this
        city: state.city || [],
        wantedKeywords: state.wantedKeywords || [],
        unwantedKeywords: state.unwantedKeywords || [],
        wantedPhoneNumbers: state.wantedPhoneNumbers ?? [],
        unwantedPhoneNumbers: state.unwantedPhoneNumbers ?? [],
        floor: {
            from: state.floorFrom!,
            to: state.floorTo!
        },
        dateAdded: {
            from: dateAddedFromWithDefault && dateAddedFromWithDefault.includes('T') ? dateAddedFromWithDefault.split('T')[0] : dateAddedFromWithDefault,
            to: state.dateAddedTo && state.dateAddedTo.includes('T') ? state.dateAddedTo.split('T')[0] : state.dateAddedTo || ''
        },
        area: {
            from: state.areaFrom!,
            to: state.areaTo!
        },
        ...{ buildingType: state.buildingType ?? [] },
        price: {
            from: state.priceFrom ?? null,
            to: state.priceTo ?? null
        },
        estimatedSaleDateFrom: state.estimatedSaleDateFrom && state.estimatedSaleDateFrom.includes('T') ? state.estimatedSaleDateFrom.split('T')[0] : state.estimatedSaleDateFrom || '',
        estimatedSaleDateTo: state.estimatedSaleDateTo && state.estimatedSaleDateTo.includes('T') ? state.estimatedSaleDateTo.split('T')[0] : state.estimatedSaleDateTo || '',
        savedFilter: state.savedFilter || [],
        availableFrom: state.availableFrom ? dateFormat(dateFromFunc(state.availableFrom)) : '',
        ownContribution: {
            from: state.ownContributionFrom ?? null,
            to: state.ownContributionTo ?? null
        },
        investorContribution: {
            from: state.investorContributionFrom ?? null,
            to: state.investorContributionTo ?? null
        },
        totalInvestmentValue: {
            from: state.totalInvestmentValueFrom ?? null,
            to: state.totalInvestmentValueTo ?? null
        },
        minimumSalePrice: state.minimumSalePrice,
        maximumSalePrice: state.maximumSalePrice,
        investmentAmount: {
            from: state.investmentAmountFrom ?? null,
            to: state.investmentAmountTo ?? null
        },
        percentageOfInvestment: {
            from: state.percentageOfInvestmentFrom ?? null,
            to: state.percentageOfInvestmentTo ?? null
        },
        expectedProfit: {
            from: state.expectedProfitFrom ?? null,
            to: state.expectedProfitTo ?? null
        },
        renovationExpenses: {
            from: state.renovationExpensesFrom ?? null,
            to: state.renovationExpensesTo ?? null
        },
        investmentBudget: {
            from: state.investmentBudgetFrom ?? null,
            to: state.investmentBudgetTo ?? null
        },
        roi: {
            from: state.roiFrom ?? null,
            to: state.roiTo ?? null
        },
        leaseFee: {
            from: state.leaseFeeFrom ?? null,
            to: state.leaseFeeTo ?? null
        },
        floors: {
            from: state.floorsFrom ?? null,
            to: state.floorsTo ?? null
        },
        rentalIncome: {
            from: state.rentalIncomeFrom ?? null,
            to: state.rentalIncomeTo ?? null
        },
        investmentValue: {
            from: state.minimumInvestmentValue ?? null,
            to: state.maximumInvestmentValue ?? null
        },
        profitAmount: {
            from: state.minimumProfitAmountFrom ?? null,
            to: state.minimumProfitAmountTo ?? null
        },
        expectedReturnRate: {
            from: state.expectedReturnRateFrom ?? null,
            to: state.expectedReturnRateTo ?? null
        }
    };
};

export const getValueFromState = (state: UsersFiltersStateType): UserOfferRequestData => {
    const dataToReturn = {
        ...state,
        propertyCondition: state.propertyCondition ? state.propertyCondition : undefined,
        name: state.name ? state.name : undefined,
        city: state.city ? state.city : [],
        dateAddedTo: state.dateAdded?.to ? dateFromFunc(state.dateAdded.to) : undefined,
        dateAddedFrom: state.dateAdded?.from ? dateFromFunc(state.dateAdded.from) : undefined,
        availableFrom: state.availableFrom ? dateFromFunc(state.availableFrom) : undefined,
        areaFrom: stringToNumber(state.area?.from),
        areaTo: stringToNumber(state.area?.to),
        priceFrom: stringToNumber(state.price?.from),
        priceTo: stringToNumber(state.price?.to),
        floorTo: stringToNumber(state.floor?.to),
        floorFrom: stringToNumber(state.floor?.from),
        ownContributionFrom: stringToNumber(state.ownContribution?.from),
        ownContributionTo: stringToNumber(state.ownContribution?.to),
        investorContributionFrom: stringToNumber(state.investorContribution?.from),
        investorContributionTo: stringToNumber(state.investorContribution?.to),
        totalInvestmentValueFrom: stringToNumber(state.totalInvestmentValue?.from),
        totalInvestmentValueTo: stringToNumber(state.totalInvestmentValue?.to),
        minimumSalePrice: stringToNumber(state.minimumSalePrice),
        maximumSalePrice: stringToNumber(state.maximumSalePrice),
        investmentTimeFrom: stringToNumber(state.investmentTimeFrom),
        investmentTimeTo: stringToNumber(state.investmentTimeTo),
        estimatedSaleDateFrom: dateFromFunc(state.estimatedSaleDateFrom),
        estimatedSaleDateTo: dateFromFunc(state.estimatedSaleDateTo),
        investmentAmountFrom: stringToNumber(state.investmentAmountFrom?.from),
        investmentAmountTo: stringToNumber(state.investmentAmountTo?.to),
        percentageOfInvestmentFrom: stringToNumber(state.percentageOfInvestment?.from),
        percentageOfInvestmentTo: stringToNumber(state.percentageOfInvestment?.to),
        expectedProfitFrom: stringToNumber(state.expectedProfit?.from),
        expectedProfitTo: stringToNumber(state.expectedProfit?.to),
        renovationExpensesFrom: stringToNumber(state.renovationExpenses?.from),
        renovationExpensesTo: stringToNumber(state.renovationExpenses?.to),
        investmentBudgetFrom: stringToNumber(state.investmentBudget?.from),
        investmentBudgetTo: stringToNumber(state.investmentBudget?.to),
        roiFrom: stringToNumber(state.roi?.from),
        roiTo: stringToNumber(state.roi?.to),
        leaseFeeFrom: stringToNumber(state.leaseFee?.from),
        leaseFeeTo: stringToNumber(state.leaseFee?.to),
        floorsFrom: stringToNumber(state.floors?.from),
        floorsTo: stringToNumber(state.floors?.to),
        rentalIncomeFrom: stringToNumber(state.rentalIncome?.from),
        rentalIncomeTo: stringToNumber(state.rentalIncome?.to),
        expectedReturnRateFrom: stringToNumber(state.expectedReturnRate?.from),
        expectedReturnRateTo: stringToNumber(state.expectedReturnRate?.to),
        buildingType:
            state.buildingType
                ? state.buildingType
                : [] as unknown as RequireOnlyOne<DealBoxDtoBuildingTypeEnum | InvestorBoxDtoBuildingTypeEnum>,
        savedFilter: undefined,
        pushAlert: state.pushAlert ? state.pushAlert : false
    };

    // @ts-expect-error TODO: INVEST-237
    return dataToReturn;
};

const userFilterInitialState = setStateValues({
    // @ts-expect-error TODO: INVEST-237
    filterId: undefined,
    daysAmount: -1,
    offerType: V2OfferType.DEAL,
    floorFrom: undefined,
    floorTo: undefined,
    city: [],
    dateAddedFrom: '',
    dateAddedTo: '',
    locations: [],
    savedLocations: {},
    searchLocationResult: [],
    areaFrom: undefined,
    areaTo: undefined,
    buildingType: undefined,
    priceFrom: undefined,
    priceTo: undefined,
    alertSms: false,
    alertEmail: false,
    pushAlert: false,
    savedFilter: [],
    sortType: SortType.DATE_DESC,
    includeZeroArea: false
});

export const initialState = {
    filterInitialState: () => {
        return userFilterInitialState;
    }
};
