import { FC } from 'react';
import React from 'react';
import { ModulesType } from 'utils/types/ModulesType';
import { OfferData } from 'utils/types/OfferData';

import BaseCard from 'components/common/Card/BaseCard';
import MediumCard from 'components/common/Card/MediumCard';
import { BoxContainer } from 'components/sales/SingleAdvertisement';

interface DuplicatesTabProps {
  data: OfferData;
  type: ModulesType
}

const DuplicatesTab: FC<DuplicatesTabProps> = ({ data, type }) => {
    return (
        <>
            {type !== 'rent' && type !== 'RENT_FLAT' && type !== 'RENT_PLOT' && type !== 'RENT_HOUSE' && type !== 'RENT_OTHER'
                ? data.duplications.map((item, index) => <BoxContainer key={index}>
                    <MediumCard
                    // onClick={(e) => e.preventDefault()}
                    // @ts-expect-error TODO: need to specify what values come here
                        data={item}
                        type={'shortInfo'}
                    />
                </BoxContainer>)
                // @ts-expect-error TODO: will be fixed after refactoring
                : data.duplicates?.map((item, index) => <BoxContainer key={index}>
                    <BaseCard
                        onClick={(e) => e?.preventDefault()}
                        data={{ ...item }}
                        type={type}
                        openOfferTab={() => { }}
                    />
                </BoxContainer>)}
        </>
    );
};

export default DuplicatesTab;
