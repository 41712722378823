import React, { FC, forwardRef, LegacyRef, ReactNode, useState } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import { ModulesType } from 'utils/types/ModulesType';
import { useWindowWidth } from 'utils/useWindowWidth';

import Icon, { IconEnum } from './Icon';

const Container = styled.div`
    position: relative;
    display: block;
    margin-top: 10px;

    &:first-child {
        margin-top: 15px;

        @media (max-width: 1100px) {
            margin-top: 0;
        }
    }

    &.users-base-container {
        margin: 0;

        .accordion-card-content {
            row-gap: 20px !important;
        }
    }

    &.users-base-container:first-child {
        margin-top: 0;
        border-top: none;
    }

    &.line-above {
        border-top: 1px solid #030D451A;
    }

    &.flex-row .accordion-card-content {
        display: flex;
        flex-wrap: wrap;
        row-gap: 15px;
        column-gap: 28px;
    }
    &.last-element {
        margin-bottom: 60px;
    }
`;

const CardTitle = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;

    font-style: normal;
    font-weight: var(--font-weight);
    font-size: 20px;
    line-height: 30px;

    color: var(--color-primary);
    padding: 12px 15px;

    &.users-title {
        padding: 20px 30px;
    }
`;

const CardContent = styled.div<CardContentTypes>`
    background: var(--color-white);
    border-radius: var(--box-border-radius);
    padding: 21px 15px;
    display: grid;
    gap: ${(props) => props.inputsWithoutLabel ? '30px 45px' : '30px 45px'};
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    align-items: flex-start;
    
    &.align-to-end {
        align-items: end;
    }

    @media (min-width: 900px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 1400px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-areas: "one two three four";

        &.move-last > *:last-child {
            grid-area: four;
        }
    }

    &.users-base-card {
        padding: 0 15px 20px;

        @media (min-width: 990px) {
            grid-template-columns: 1fr 1fr 1fr !important;
        }
    }
    
    &.transparent {
        background: transparent;
        padding: 0;
    }

    &.one-item {
        @media (min-width: 900px) {
            grid-template-columns: 1fr 1fr;
        }
    }

    &.full-width {
        grid-template-columns: 1fr;
        grid-template-areas: "one";
    }
`;

const Required = styled.span`
    color: var(--color-alt-second-10);
    font-size: var(--font-size-body-1);
    font-weight: 500;
`;

const AdditionalWrapper = styled.span`
    font-size: var(--font-size-body-3);
    color: var(--color-primary-70);
    padding: 15px;
`;

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap:wrap;
`;

interface CardContentTypes {
    inputsWithoutLabel?: boolean,
}

interface AccordionCardProperties {
    disabledCollapse?: boolean,
    title?: string,
    moveLast?: boolean,
    oneItem?: boolean,
    fullWidth?: boolean,
    lineAbove?: boolean,
    children: ReactNode,
    ref?: LegacyRef<HTMLDivElement>
    type?: ModulesType,
    directionRow?: boolean,
    required?: boolean,
    inputsWithoutLabel?: boolean,
    usersMedia?: boolean,
    additionalText?: string,
    hideTitle?: boolean,
    alignToEnd?: boolean,
    lastElement?: boolean,
}

const AccordionCard: FC<AccordionCardProperties> = forwardRef(({
    moveLast,
    disabledCollapse = false,
    title,
    children,
    oneItem,
    fullWidth,
    lineAbove,
    type,
    directionRow,
    required,
    inputsWithoutLabel,
    usersMedia,
    additionalText,
    hideTitle,
    alignToEnd,
    lastElement
}, ref) => {
    const [contentIsVisible, setContentIsVisible] = useState(true);
    const width = useWindowWidth();

    const handleClickAccordionTitle = () => {
        setContentIsVisible((prev) => !prev);
    };

    const isRequired = required && <Required>Wymagane</Required>;

    return (
        <Container ref={ref} className={clsx({
            'line-above': lineAbove,
            'flex-row': directionRow,
            'users-base-container': usersMedia,
            'last-element': lastElement
        })}>
            {width <= 991 || type === 'favorites' || type === 'my_offer' || type === 'profile' || type === 'messages' || !hideTitle
                ? <TitleWrapper>
                    <CardTitle
                        className={clsx({ 'users-title': usersMedia })}>
                        {title}{isRequired}{disabledCollapse
                            ? null
                            : contentIsVisible
                                ? <Icon icon={IconEnum.ACCORDION_DOWN} onClick={handleClickAccordionTitle}/>
                                : <Icon icon={IconEnum.ACCORDION_UP} onClick={handleClickAccordionTitle}/>}
                    </CardTitle>
                    {additionalText && <AdditionalWrapper>{additionalText}</AdditionalWrapper>}
                </TitleWrapper>
                : null}
            {contentIsVisible &&
                <CardContent inputsWithoutLabel={inputsWithoutLabel} className={clsx('accordion-card-content', {
                    'move-last': moveLast,
                    'one-item': oneItem,
                    'full-width': fullWidth,
                    'users-base-card': usersMedia,
                    'align-to-end': alignToEnd,
                    transparent: type === 'messages'
                })}>
                    {children}
                </CardContent>
            }
        </Container>
    );
});

export default AccordionCard;
