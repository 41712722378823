import React, { FC, useMemo } from 'react';
import styled from 'styled-components';

import SingleOffer, { SingleOfferProps } from './SingleOffer';

const Container = styled.div`
    padding: 30px 20px 0;

    @media (max-width: 1100px) {
        padding: 20px 20px 0;
    }
`;

const LinkToProductPage = styled.a`
    text-decoration: none;
    z-index: 1;
    display: flex;
    
    & * {
        pointer-events: none;
    }
`;

const ContainerList = styled.div`
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;

    @media (min-width: 1000px) {
        gap: 2rem;
        grid-template-columns: repeat(2, 1fr);
    }
    
    @media (min-width: 1800px) {
        gap: 3rem;
        grid-template-columns: repeat(4, 1fr);
    }
`;

// TODO: Po zmianie API w rent tylko string
export interface RenderList extends SingleOfferProps {
    id: string | number;
}

export interface OfferListProperties {
    renderList: RenderList[],
}

const PROMO_CAMPAIGN_URL = 'https://investoro.pl/skaner_okazji/';

const OfferListMini: FC<OfferListProperties> = ({
    renderList
}) => {
    const renderedElements = useMemo(() => renderList?.map((el) => {
        return (
            <SingleOffer
                {...el}
                key={el.id}
                mobile
            />
        );
    }), [renderList]);

    return (
        <Container id="offer-list">
            <LinkToProductPage href={PROMO_CAMPAIGN_URL}>
                <ContainerList>
                    {renderedElements}
                </ContainerList>
            </LinkToProductPage>
        </Container>
    );
};

export default OfferListMini;
