import React, { ChangeEvent, KeyboardEvent } from 'react';

import { AlertType } from './AutocompleteInput';

const formatNumber = (numString: string) => {
    return numString.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
};

const handleChange = (
    val: string,
    e: ChangeEvent<HTMLInputElement>,
    onChange: (value: string) => void,
    inputType?: string
) => {
    if (!(inputType === 'money')) {
        onChange(val);

        return;
    }

    const valueWithoutSpaces = e.target.value.replace(/\s/g, '');
    const formattedValue = formatNumber(valueWithoutSpaces);

    onChange(formattedValue);
};

const handleKeyPress = (
    e: KeyboardEvent<HTMLInputElement>,
    filteredOptions: {label: string, value: string | number, alerts?: AlertType | undefined, trash?: boolean | undefined}[],
    preferredOptionIndex: number | null,
    onChange: (value?: string | number) => void,
    inputRef: React.RefObject<HTMLInputElement> | null,
    setPreferredOptionIndex: (index: number | null) => void,
    enableCustomValue?: boolean,
    maxInputLength?: number
) => {
    const allowedKeys = e.key !== 'Tab' && e.key !== 'Backspace' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight';

    if (e.key === 'Enter' || e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        e.preventDefault();
        e.stopPropagation();
    }

    if (maxInputLength && (e.target as HTMLInputElement).value.length >= maxInputLength && allowedKeys) {
        e.preventDefault();
    }

    switch (e.key) {
    case 'Enter':
        if (preferredOptionIndex !== null && filteredOptions[preferredOptionIndex]?.value) {
            onChange(filteredOptions[preferredOptionIndex].value);
            inputRef?.current?.blur();
        }
        break;
    case 'ArrowDown':
        if (preferredOptionIndex === null) {
            setPreferredOptionIndex(0);
        } else if (preferredOptionIndex < filteredOptions.length - 1) {
            setPreferredOptionIndex(preferredOptionIndex + 1);
        }
        break;
    case 'ArrowUp':
        if (preferredOptionIndex === null) {
            setPreferredOptionIndex(0);
        } else if (preferredOptionIndex > 0) {
            setPreferredOptionIndex(preferredOptionIndex - 1);
        }
        break;
    default:
        if (!enableCustomValue && e.key !== 'Tab') {
            e.preventDefault();
            e.stopPropagation();

            return;
        }

        setPreferredOptionIndex(null);
    }
};
export const autocompleteFunctions = {
    handleKeyPress,
    handleChange
};
