import React from 'react';

import { activeNotification } from 'components/functions/activeNotification';

export const generateOfferActionsElements = (offerLink: string, originalOfferLink: string, isDetailPage?: boolean) => {
    return [
        {
            children:
                <>
                    <p>Skopiuj link</p>
                </>,
            onClick: async (e: MouseEvent) => {
                e.stopPropagation();

                await navigator.clipboard.writeText(offerLink);
                activeNotification('Skopiowano', 'Link został pomyślnie skopiowany', 'success');
            }
        },
        ...!isDetailPage
            ? [
                {
                    children:
                <>
                    <p>Otwórz w nowej karcie</p>
                </>,
                    onClick: async (e: MouseEvent) => {
                        e.stopPropagation();

                        window.open(offerLink, '_blank');
                    }
                }
            ]
            : [],
        {
            children:
                <>
                    <p>Przejdź do oryginalnego ogłoszenia</p>
                </>,
            onClick: async (e: MouseEvent) => {
                e.stopPropagation();

                window.open(originalOfferLink, '_blank');
            }
        }
    ];
};
