import React, { createContext, useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { UserActions } from 'store/User/Actions';
import { useUser } from 'store/User/Context';
import { filtersApiRequests } from 'utils/api-requests/filters';
import { initialState } from 'utils/state-managment/user/userAddAdvert';
import { ObjectAndSingleValueChangeType } from 'utils/types/InputTypes';
import { UserAddAdvertStateType } from 'utils/types/UsersAdvert';
import { AdvertValidatorType, isFieldValid } from 'utils/validators/advertAddingValidator';

interface UserAddAdvertType {
    handleChange: (name: string, value: ObjectAndSingleValueChangeType) => void
    userAddAdvertFieldsState: UserAddAdvertStateType
    validateField: (name: AdvertValidatorType, value: ObjectAndSingleValueChangeType) => boolean
    clearFields: () => void
    validateAllFields: () => boolean
    handleFillAdvertFields: (data: UserAddAdvertStateType) => void
}

const values: UserAddAdvertType = {
    handleChange: () => {},
    userAddAdvertFieldsState: {} as UserAddAdvertStateType,
    validateField: () => false,
    clearFields: () => {},
    validateAllFields: () => false,
    handleFillAdvertFields: () => {}
};

const UserAddAdvertContext = createContext(values);

export const UserAddAdvertProvider = ({ children }: { children: React.ReactNode }) => {
    const [userAddAdvertFieldsState, setUserAddAdvertFieldsState] = useState(initialState.initialState());
    const { dispatch: dispatchUser } = useUser();
    const baseRequiredFields = [
        'cityName',
        'title',
        'description'
    ];

    const handleChange = useCallback((fieldName: string, value: ObjectAndSingleValueChangeType) => {
        setUserAddAdvertFieldsState((filters: UserAddAdvertStateType) => ({
            ...filters,
            [fieldName]: value
        }));
    }, []);

    const handleFillAdvertFields = useCallback((fieldsData: UserAddAdvertStateType) => {
        getVoivodeships();
        setUserAddAdvertFieldsState((prev) => ({ ...initialState.initialState(), ...fieldsData, locations: prev.locations, offerType: prev.offerType }));
    }, []);

    const validateAllFields = () => {
        return baseRequiredFields.every((item) => isFieldValid(AdvertValidatorType.NO_EMPTY_TEXT, userAddAdvertFieldsState[item as keyof UserAddAdvertStateType] as string));
    };

    const clearFields = () => {
        setUserAddAdvertFieldsState((prev) => ({ ...initialState.initialState(), offerType: prev.offerType, locations: prev.locations }));
    };

    const getVoivodeships = useCallback(async () => {
        const locations = await filtersApiRequests.getVoivodeships();
        setUserAddAdvertFieldsState((filters: UserAddAdvertStateType) => ({
            ...filters,
            locations
        }));
    }, []);

    useEffect(() => {
        clearFields();
    }, [userAddAdvertFieldsState.offerType]);

    useEffect(() => {
        UserActions.clearOffers(dispatchUser);
    }, [dispatchUser]);

    useEffect(() => {
        if (userAddAdvertFieldsState.locations) {
            getVoivodeships();
        }
    }, []);

    const handleChangeDateList = (fieldName: string, value: number) => {
        setUserAddAdvertFieldsState((filters: UserAddAdvertStateType) => ({
            ...filters,
            offerAdded: {
                from: value === -1
                    ? null
                    : dayjs().subtract(value, 'day').toISOString()
                        .split('T')[0],
                to: null
            },
            [fieldName]: value
        }));
    };

    const userFiltersContextValues = {
        userAddAdvertFieldsState,
        handleChange,
        clearFields,
        validateField: isFieldValid,
        validateAllFields,
        handleFillAdvertFields,
        handleChangeDateList
    };

    return (
        <UserAddAdvertContext.Provider value={userFiltersContextValues}>
            {children}
        </UserAddAdvertContext.Provider>
    );
};

export default UserAddAdvertContext;
