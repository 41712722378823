import React, { FC } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { ModulesType } from 'utils/types/ModulesType';
import { OfferData } from 'utils/types/OfferData';
import { useWindowWidth } from 'utils/useWindowWidth';

import Icon, { IconEnum } from 'components/common/Icon';

import InfoBadge from './InfoBadge';
import InteractiveIconsWrapper from './InteractiveIconsWrapper';

interface CardBodyMiniProperties {
    data: OfferData;
    type: ModulesType;
    openOfferTab: (tab: string) => void;
    showLastNote?: () => void;
    showNote?: boolean;
}

const Wrapper = styled.div`
    display: grid;
    grid-column: span 2;
    grid-template-areas:
          "top right" 
          "center center" 
          "center center"
          "bottom bottom";
    gap: 10px;

    @media (min-width: 991px) {
        gap: 15px;
    }

`;

const Center = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    grid-area: center;

    @media (min-width: 991px) {
        gap: 20px;
    }
`;

const Address = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-primary);
`;

const BottomActions = styled.div`
    grid-area: bottom;
    display: flex;
`;

const PriceContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 5px;
    grid-area: right;
`;

const Link = styled.a`
    display: flex;
    gap: 5px;
    text-decoration: none;
    color: var(--color-primary);
`;

const Modifications = styled.div`
    display: flex;
    gap: 5px;
    color: var(--color-primary);
    font-weight: 400;
    text-transform: capitalize;

    b {
        font-weight: 500;
    }
`;

const Title = styled.h1`
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
`;

const Top = styled.div`
    grid-area: top;
    display: grid;
    gap: 20px;
`;

const DateBadge = styled.p`
    white-space: nowrap;
`;

const InformationTitle = styled.h1`
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: var(--color-primary);
`;

const CardBodyMini: FC<CardBodyMiniProperties> = ({
    data,
    type,
    openOfferTab,
    showNote,
    showLastNote
}) => {
    const width = useWindowWidth();

    return (
        <Wrapper>

            <Top>
                {type === 'bailiff_notice' && width < 991 &&
                    <InfoBadge isGray>
                        <DateBadge>{data.dateAndSignature}</DateBadge>
                    </InfoBadge>}

                {(data.location ?? data.city) && <Address>{data.location ?? data.city}</Address>}
            </Top>

            <Center>
                {type === 'bailiff_notice' &&
                    <Title>{data.kwNumber}</Title>}

                {type === 'cooperative' &&
                    <InformationTitle>{data.title}</InformationTitle>}

                {type === 'bailiff_notice' && width < 991 &&
                    <Modifications><b>Kategoria:</b> {data.type?.replace(',', ' /') || '-'}</Modifications>}
            </Center>

            <PriceContainer>
                {type === 'bailiff_notice' && width >= 991 &&
                    <Modifications><b>Kategoria:</b> {data.type?.replace(',', ' /') || '-'}</Modifications>}

                {type === 'cooperative' && width >= 991 &&
                    <Link href={data.portal.link} target="_blank" rel="noopener noreferrer">
                        <Icon icon={IconEnum.LINK}/>Przejdź do ogłoszenia
                    </Link>
                }
            </PriceContainer>

            <BottomActions>
                {type === 'bailiff_notice' && width >= 991 &&
                    <InfoBadge isGray>
                        <DateBadge>{data.dateAndSignature}</DateBadge>
                    </InfoBadge>
                }
                {type === 'cooperative' && width >= 991 &&
                    <InfoBadge isGray>
                        <DateBadge>{dayjs(data.dateAdded).format('DD.MM.YYYY')}</DateBadge>
                    </InfoBadge>
                }
                <InteractiveIconsWrapper
                    type={type}
                    data={{ ...data, portal: { ...data.portal, name: 'Przejdź do ogłoszenia' } }}
                    onNoteAdd={() => {
                        if (!showNote && showLastNote) {
                            showLastNote();
                        }

                        if (!data.note.noteId) {
                            openOfferTab('note');
                        }
                    }}
                />
            </BottomActions>
        </Wrapper>
    );
};

export default CardBodyMini;
