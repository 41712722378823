import React, { FC } from 'react';

import TextInput from './TextInput';

interface DateInputProperties {
    date: string;
    handleChangeDate: (value: string) => void;
    placeholder: string;
}

const DateInput: FC<DateInputProperties> = ({
    date,
    handleChangeDate,
    placeholder
}) => {
    return (
        <>
            <TextInput
                value={date}
                onChange={({ target: { value: valueIn } }) => handleChangeDate(valueIn)}
                placeholder={placeholder}
                type={'date'}
            />
        </>
    );
};

export default DateInput;
