import React, { useCallback, useEffect, useState } from 'react';
import { FavoritePageFiltersType } from 'pages/FavoritesPage';
import { OffersActions } from 'store/Offers/Actions';
import { useOffers } from 'store/Offers/Context';
import { ReactionType, RentOfferResponseContent } from 'store/Offers/State';
import { formatFilterPrice } from 'utils/formatFilterPrice';
import { ModulesType } from 'utils/types/ModulesType';
import { RentModuleType } from 'utils/types/Rent';

import {
    InteractiveElements
} from 'components/common/Card/common/V2InteractiveIconsWrapper/V2InteractiveIconsWrapper';
import { IconEnum } from 'components/common/Icon';
import { SliderImages } from 'components/common/Slider/Slider';
import { V2DuplicatesTabProps } from 'components/common/Tabs/tabs/V2DuplicatesTab';
import { TabType } from 'components/common/Tabs/V2Tabs';
import { convertToThumbnailPath, createImagesForSlider } from 'components/functions/imagesFunctions';
import { saleAndRentOfferListFunctions } from 'components/functions/offerListFunctions/saleAndRentOfferListFunctions/saleAndRentOfferListFunctions';
import { RenderList } from 'components/offerList/V2OfferList';

export const useRentHook = (filters: FavoritePageFiltersType, activeFilter: ModulesType) => {
    const { offers, dispatch } = useOffers();
    const [renderList, setRenderList] = useState<RenderList[]>([]);

    const onNoteAdd = () => {
        // TODO: Oagrnac jak odpale projekt
        // if (!showNote && showLastNote) {
        //     showLastNote();
        // }
        //
        // if (!data.note?.noteId) {
        //     openOfferTab('note');
        // }
    };

    const onMessageClick = () => {
        onNoteAdd();
    };

    const handleChangeReaction = (name: keyof ReactionType, value: boolean, offer: RentOfferResponseContent) => {
        const userReaction = Object.assign({}, offer.reaction);
        (userReaction[name] as boolean) = value;
        const city = offer.location;

        if (name === 'likeStatus' && userReaction.unlikeStatus && value) {
            userReaction.unlikeStatus = false;
        } else if (name === 'unlikeStatus' && userReaction.likeStatus && value) {
            userReaction.likeStatus = false;
        }

        (userReaction[name] as boolean) = value;
        // @ts-expect-error after method refactor error will disappear
        OffersActions.reactionUpdate(dispatch, offer.advertisementId, city, 'rent', userReaction, offer.module as RentModuleType);
    };

    const generateInteractiveElements = (offer: RentOfferResponseContent): InteractiveElements[] => {
        return [
            {
                tooltipText: 'Wyświetlono',
                icon: IconEnum.EYE,
                active: offer.reaction.viewedStatus,
                disabled: offer.reaction.viewedStatus === undefined,
                onClick: () => handleChangeReaction('viewedStatus', !offer.reaction.viewedStatus, offer)
            },
            {
                tooltipText: 'Lubię to',
                icon: IconEnum.LIKE,
                active: offer.reaction.likeStatus,
                onClick: () => handleChangeReaction('likeStatus', !offer.reaction.likeStatus, offer)
            },
            {
                tooltipText: 'Nie lubię tego',
                icon: IconEnum.DISLIKE,
                active: offer.reaction.unlikeStatus,
                disabled: offer.reaction.unlikeStatus === undefined,
                onClick: () => handleChangeReaction('unlikeStatus', !offer.reaction.unlikeStatus, offer)
            },
            {
                tooltipText: 'Ogłoszenie nieaktualne',
                icon: IconEnum.BLOCKED,
                active: offer.reaction.hideStatus,
                disabled: offer.reaction.hideStatus === undefined,
                onClick: () => handleChangeReaction('hideStatus', !offer.reaction.hideStatus, offer)
            },
            {
                tooltipText: 'Przeprowadzona rozmowa',
                icon: IconEnum.PHONE,
                active: offer.reaction.phoneStatus,
                disabled: offer.reaction.phoneStatus === undefined,
                onClick: () => handleChangeReaction('phoneStatus', !offer.reaction.phoneStatus, offer)
            },
            {
                tooltipText: 'Umówione spotkanie',
                icon: IconEnum.PEOPLES_3,
                active: offer.reaction.arrangedStatus,
                disabled: offer.reaction.arrangedStatus === undefined,
                onClick: () => handleChangeReaction('arrangedStatus', !offer.reaction.arrangedStatus, offer)
            },
            {
                tooltipText: 'Notatki',
                icon: IconEnum.MESSAGE,
                active: offer.numberOfNotes ? offer.numberOfNotes > 0 : false,
                counter: offer.numberOfNotes ? offer.numberOfNotes || 1 : undefined,
                openTabOnClick: TabType.NOTES,
                onClick: onMessageClick
            },
            ...offer.phoneNumber
                ? [
                    {
                        icon: IconEnum.PHONE,
                        isLink: true,
                        active: false,
                        isPhoneBtn: false,
                        text: <a href={`tel:${offer.phoneNumber}`}>{offer.phoneNumber}</a>
                    }
                ]
                : [],
            {
                tooltipText: 'Przejdź do ogłoszenia',
                icon: IconEnum.LINK,
                isLink: true,
                noText: true,
                text: <a target="_blank" rel="noopener noreferrer" href={offer.link}/>
            },
            ...offer.photos && offer.photos.length > 0
                ? [
                    {
                        icon: IconEnum.PHOTOS,
                        isModalOpen: true,
                        isLink: true,
                        text: <p>Zobacz zdjęcia</p>,
                        isPhotoBtn: true,
                        visibleDuringModal: true,
                        sliderElements: {
                            photos: createImagesForSlider(offer.photos, offer.module) as SliderImages[],
                            title: offer.title
                        }
                    }
                ]
                : []
        ];
    };

    const generateCardData = (offer: RentOfferResponseContent) => {
        const subMainValues: React.ReactNode[] = [];

        if (offer.pricePerSquareMeter) {
            subMainValues.push(
                <><b>{offer.pricePerSquareMeter.toString()}</b> zł / m<sup>2</sup></>
            );
        }

        if (offer.depositPrice) {
            subMainValues.push(
                <><b>Kaucja: </b>{offer.depositPrice.toString()} zł</>
            );
        }

        return {
            preTitle: offer.location,
            lowestPriceLabel: offer.duplicateWithLowestPrice,
            privateOfferLabel: offer.duplicateWithPrivateOffer,
            oldPrice: offer.priceBeforeModification?.toString(),
            date: offer.dateAdded,
            title: offer.title,
            infoElements: saleAndRentOfferListFunctions.generateInfoElements(offer),
            interactiveElements: generateInteractiveElements(offer),
            modifications: {
                duplicationsNumber: offer.numberOfDuplicates ?? 0,
                modificationsNumber: offer.numberOfModifications ?? 0,
                boostsNumber: offer.numberOfRaises ?? 0
            },
            note: offer.note && offer.note.noteId ? offer.note : undefined,
            modificationDate: offer.lastUpdated,
            thumbnailPath: offer.photos && offer.photos.length > 0 ? convertToThumbnailPath(offer.photos[0], offer.module) : './defaultImg.png',
            ratingScore: offer.score ?? 0,
            additionalInfoBadge: offer.offerFrom,
            images: createImagesForSlider(offer.photos, offer.module),
            mainValue: offer.price ? `${formatFilterPrice(offer.price)} zł` : '- zł',
            link: {
                url: offer.link,
                name: offer.portal
            },
            subMainValues,
            ratingContainer: {
                opinionsNumber: offer.numberOfCommunityOpinions,
                opinionsRating: offer.communityScore,
                commentsCount: offer.numberOfCommunityComments,
                title: offer.title,
                userRatingValue: offer.myOpinion?.numberOfStars,
                userRatingComment: offer.myOpinion?.content || undefined,
                handleGetComments: () => handleNotes(offer),
                handleSendRating: (rating: number, description: string) => offer.myOpinion ? OffersActions.updateOpinion(dispatch, offer.module, description, rating, offer.advertisementId, offer.myOpinion?.id) : OffersActions.updateOpinion(dispatch, offer.module, description, rating, offer.advertisementId)
            }
        };
    };

    const toggleContainerDetails = (detailsOpen: boolean, offer: RentOfferResponseContent) => {
        if (!detailsOpen) {
            if (offer.reaction && !offer.reaction.viewedStatus) {
                OffersActions.reactionUpdate(dispatch, offer.advertisementId.toString(), offer.location, 'rent', {
                    ...offer.reaction,
                    openedStatus: true,
                    viewedStatus: true
                }, offer.module);
            }
            OffersActions.loadOfferDetailsAsync(dispatch, offer.module, offer.advertisementId);
        }
    };

    const handleDuplicates = async (offer: RentOfferResponseContent) => {
        // @ts-expect-error after method refactor error will disappear
        await OffersActions.loadDuplicates(dispatch, offer.module as RentModuleType, offer.advertisementId);
    };

    const handleNotes = async (offer: RentOfferResponseContent) => {
        // @ts-expect-error after method refactor error will disappear
        OffersActions.loadOldNotesAsync(dispatch, 'rent', offer.advertisementId, offer.location, offer.module);
    };

    const handleDelete = (noteId: number, id: string, type: RentModuleType) => {
        OffersActions.deleteNote(dispatch, id, 'rent', noteId, type);
    };

    const handleSaveNote = async (value: string, advertId: string, location: string, type: RentModuleType, id?: number) => {
        await OffersActions.updateNote(dispatch, advertId, location || '', 'rent', value, id || 0, type);
    };

    useEffect(() => {
        const offersList = offers.rentList?.content?.map((offer): RenderList => {
            const interactiveElements = generateInteractiveElements(offer);
            const detailsTabContent = saleAndRentOfferListFunctions.generateOfferDetailsTabContent(offer);
            const duplicatesTabContent: V2DuplicatesTabProps = {
                duplicates: offer.duplicates?.map((duplicate) => {
                    return {
                        id: duplicate.advertisementId,
                        bigImage: true,
                        cardData: generateCardData(duplicate),
                        onClick: () => {},
                        openOfferTab: () => {}
                    };
                })
            };

            return {
                id: offer.advertisementId,
                onSingleOfferClick: (_: React.MouseEvent, detailsOpen: boolean) => toggleContainerDetails(detailsOpen, offer),
                baseCardContent: {
                    bigImage: true,
                    cardData: generateCardData(offer),
                    openOfferTab: () => {} // openoffertab
                },
                detailsCardData: {
                    interactiveElements,
                    modificationDate: offer.lastUpdated,
                    additionalInfoBadge: offer.offerFrom,
                    thumbnailPath: offer.photos && offer.photos.length > 0 ? convertToThumbnailPath(offer.photos[0], offer.module) : './defaultImg.png',
                    date: offer.dateAdded,
                    onToggleContainerDetails: () => {},
                    tabsContent: {
                        toggleContainerDetails: () => {},
                        interactiveElements,
                        detailsTabContent,
                        duplicatesTabContent,
                        modificationTabContent: {
                            modifications: offer.detailedContent?.modifications || []
                        },
                        notesTabContent: {
                            notes: offers.notes,
                            // @ts-expect-error after method refactor error will disappear
                            handleDelete: (noteId: number) => handleDelete(noteId, offer.advertisementId, offer.module),
                            // @ts-expect-error after method refactor error will disappear
                            handleSaveNote: (value: string, id?: number) => handleSaveNote(value, offer.advertisementId, offer.location, offer.module, id)
                        },
                        opinionTabContent: {
                            handleSaveOpinion: (rating: number, content: string, id?: number) => OffersActions.updateOpinion(dispatch, offer.module, content, rating, offer.advertisementId, id || offer.myOpinion?.id),
                            comments: offers.scoreAndOpinions?.opinions?.map((opinion) => ({
                                id: opinion.opinionId,
                                date: opinion.dateAdded,
                                content: opinion.content || '',
                                rating: opinion.numberOfStars,
                                isUserComment: opinion.loggedUserOpinion
                            })),
                            applicationScore: offer.score,
                            applicationScorePrecision: offer.scorePrecision,
                            commentsCount: offer.numberOfCommunityComments,
                            opinionsNumber: offer.numberOfCommunityOpinions,
                            opinionsRating: offers.scoreAndOpinions?.communityScore || 0,
                            handleDeleteOpinion: (id: number) => OffersActions.deleteOpinion(dispatch, offer.advertisementId, offer.module, id)
                        },
                        numberOfModifications: offer.numberOfModifications || 0,
                        numberOfDuplicates: offer.numberOfDuplicates || 0,
                        numberOfNotes: offer.numberOfNotes || 0,
                        handleLoadDuplicates: () => handleDuplicates(offer),
                        handleLoadNotes: () => handleNotes(offer),
                        handleLoadComments: () => OffersActions.loadOpinionsAsync(dispatch, offer.advertisementId, offer.module)
                    }
                }
            };
        });

        setRenderList(offersList);
    }, [
        offers.rentList?.content,
        offers.notes,
        offers.scoreAndOpinions
    ]);

    const handleChangePage = useCallback((currentPage: number, rows = 25) => {
        OffersActions.loadFavoriteOffers(dispatch, activeFilter, filters, currentPage, rows);
    }, [filters, activeFilter]);

    const handleFavoriteSearch = (filter: ModulesType) => {
        OffersActions.clearOffers(dispatch);
        OffersActions.loadFavoriteOffers(dispatch, filter, filters, 1, 25);
    };

    const handleActiveFiltersChange = () => {
        OffersActions.loadFavoriteOffers(dispatch, activeFilter, filters, 1, 25);
    };

    return {
        offers,
        handleChangePage,
        handleFavoriteSearch,
        renderList,
        handleActiveFiltersChange
    };
};
