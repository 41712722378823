import { AxiosResponse } from 'axios';
import qs from 'qs';
import { axiosApiInstance2 } from 'Store';
import { getNormalizedSaleModuleName } from 'utils/formatters/getNormalizedSaleModuleName';
import { LocationDto, SaleModule, SavedSaleFiltersDto } from 'utils/types/AnalysisModels';
import { AnalysisSaleFilterReturnData } from 'utils/types/AnalysisSale';
import { SavedLocationsType } from 'utils/types/Locations';

import { activeNotification } from 'components/functions/activeNotification';

const getSavedFilters = async (): Promise< SavedSaleFiltersDto[]> => {
    return axiosApiInstance2
        .get<SavedSaleFiltersDto[]>('/analysis/sale/filter/list')
        .then((resp) => {
            if (resp.data) {
                return resp.data.map((el) => ({
                    ...el,
                    name: el.name
                }));
            }

            return [];
        })
        .catch((err) => {
            if (err?.response?.status === 401) {
                window.parent.location.reload();
            }

            return [];
        });
};

const getFilterDetailsAnalysis = async (filterId: number, module: SaleModule): Promise<AnalysisSaleFilterReturnData> => {
    return axiosApiInstance2
        .get(`/analysis/sale/filter/detail/${getNormalizedSaleModuleName(module)}?id=${filterId}`)
        .then((resp) => {
            const filters = resp.data;
            const savedLocations: SavedLocationsType[] = [];

            filters.city.forEach((city: LocationDto) => {
                if (city.fullName) {
                    // @ts-expect-error TODO: INVEST-254
                    savedLocations[city.fullName] = { ...city, label: city.fullName };
                }
            });

            return {
                ...filters,
                city: filters.city ? filters.city : [],
                boundaries: filters.boundaries,
                wantedKeywords: filters.wantedKeywords ? filters.wantedKeywords : [],
                unwantedKeywords: filters.unwantedKeywords ? filters.unwantedKeywords : [],
                id: filterId,
                name: filters.name ? filters.name : '',
                module: filters.module ? filters.module : '',
                reportType: filters.reportType ? filters.reportType : [],
                includeZeroArea: filters.includeZeroArea ? filters.includeZeroArea : false,
                areaFrom: filters.areaFrom ? filters.areaFrom : undefined,
                areaTo: filters.areaTo ? filters.areaTo : undefined,
                pricePerSquareMeterFrom: filters.pricePerSquareMeterFrom ? filters.pricePerSquareMeterFrom : undefined,
                pricePerSquareMeterTo: filters.pricePerSquareMeterTo ? filters.pricePerSquareMeterTo : undefined,
                groupDuplicate: filters.groupDublicate,
                unwantedPhoneNumbers: filters.unwantedPhoneNumbers ? filters.unwantedPhoneNumbers : [],
                wantedPhoneNumbers: filters.wantedPhoneNumbers ? filters.wantedPhoneNumbers : [],
                offerFrom: filters.offerFrom ? filters.offerFrom : undefined,
                priceFrom: filters.priceFrom ? filters.priceFrom : undefined,
                buildingType: filters.buildingType ? filters.buildingType : undefined,
                savedFilter: filters.savedFilter ? filters.savedFilter : false,
                locations: [],
                savedLocations: savedLocations,
                searchLocationResult: []
            };
        })
        .catch(() => {
            activeNotification('Problem z załadowaniem danych', 'Niestety nie udało się załadować danych. Spróbuj ponownie później', 'danger');
        });
};

const removeSavedFilter = (filterId: number, propertyType: SaleModule) => {
    return axiosApiInstance2
        .delete(`/analysis/sale/filter/delete?module=${propertyType}&id=${filterId}`)
        .then(() => {
            activeNotification('Udało się!', 'Filtr został usunięty', 'success');
        })
        .catch(() => {
            activeNotification('Problem z usunięciem', 'Niestety nie udało się usunąć danych. Spróbuj ponownie później', 'danger');
        });
};

const addFilter = (params: AnalysisSaleFilterReturnData, propertyType: SaleModule): Promise<AxiosResponse> => {
    const savedLocations = Object.values(params.savedLocations).map((x) => {
        // @ts-expect-error dont need districts and cities
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { districts, cities, ...rest } = x;

        return { ...rest };
    });

    return axiosApiInstance2({
        method: 'post',
        url: `/analysis/sale/filter/add/${getNormalizedSaleModuleName(propertyType)}`,
        data: {
            ...params,
            city: savedLocations
        },
        paramsSerializer: function (params) {
            return qs.stringify(params);
        }
    })
        .then((resp) => {
            activeNotification('Udało się!', 'Filtr został dodany', 'success');

            return resp;
        })
        .catch((err) => {
            if (err?.response?.status === 400) {
                activeNotification(
                    'Błąd',
                    err.response.data,
                    'danger'
                );

                return Promise.reject(err);
            } else {
                activeNotification(
                    'Coś poszło nie tak!',
                    'Spróbuj ponownie',
                    'danger'
                );

                return Promise.reject(err);
            }
        });
};

const updateFilter = async (params: AnalysisSaleFilterReturnData, propertyType: SaleModule) => {
    return axiosApiInstance2({
        method: 'put',
        url: `/analysis/sale/filter/edit/${getNormalizedSaleModuleName(propertyType)}`,
        params: { id: params.id },
        data: {
            ...params
        },
        paramsSerializer: function (params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        }
    })
        .then((resp) => {
            activeNotification('Udało się!', 'Filtr został zaktualizowany', 'success');

            return resp;
        })
        .catch((err) => {
            activeNotification('Problem z zapisem', 'Niestety nie udało się zapisać danych. Spróbuj ponownie później', 'danger');

            return Promise.reject(err);
        });
};

export const analysisSaleApiRequests = {
    getSavedFilters,
    getFilterDetailsAnalysis,
    removeSavedFilter,
    updateFilter,
    addFilter
};
