import React, { ChangeEvent, FocusEvent, ForwardedRef, forwardRef, KeyboardEvent, useEffect } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';

import Icon, { IconEnum } from 'components/common/Icon';

const Input = styled.input`
    font-weight: var(--font-weight);
    font-size: var(--font-size-body-2);
    line-height: 20px;
    width: 100%;
    flex: 1;
    background: transparent;
    border: none;
    color: var(--color-primary);
    outline: none;
    cursor: pointer;
    text-align: left;

    &:focus {
        cursor: auto;
    }

    &::placeholder {
        color: var(--color-primary);
        opacity: 0.7;
        font-weight: var(--font-weight);
        font-size: var(--font-size-body-2);
        line-height: 20px;
    }
`;

const TextArea = styled.textarea`
    font-weight: var(--font-weight);
    font-size: var(--font-size-body-2);
    line-height: 20px;
    width: 100%;
    flex: 1;
    background: transparent;
    border: none;
    color: var(--color-primary);
    outline: none;
    cursor: pointer;
    resize: none;
    height: 100%;

    &:focus {
        cursor: auto;
    }

    &::placeholder {
        color: var(--color-primary);
        opacity: 0.7;
        font-weight: var(--font-weight);
        font-size: var(--font-size-body-2);
        line-height: 20px;
    }
`;

const Action = styled.div`
    background: var(--color-white);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;

    &.orange-button {
        background: #fff;

        .icon {
            filter: invert(50%) sepia(87%) saturate(698%) hue-rotate(351deg) brightness(98%) contrast(90%);
        }
    }
`;

const Container = styled.span`
    position: relative;
    display: flex;
    gap: 10px;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    background: var(--color-alt-second);
    padding: 10px 10px 10px 20px;
    border-radius: var(--border-radius);
    height: 50px;

    &:hover {
        cursor: pointer;
    }

    &.description {
        height: 270px;
        padding: 15px 20px;
        border-radius: var(--box-border-radius);
    }
    
      &.align-right {
        width: 30%;
        justify-content: flex-end;
        padding: 10px 20px 10px 20px;
    }

    &.focus {
        border: 1px solid var(--color-primary);
    }
    
    &.error {
        border: 1px solid var(--color-error);

        p,
        input {
            color: var(--color-error);
        }
    }

    &.important {
        background: var(--color-warning);

        input {
            color: white !important;
        }

        input::placeholder {
            color: white !important;
            opacity: 0.7;
        }

        &:hover {
            --color-warning: #DA6900;
        }
    }
        &.darker {
            background:#DDDDDD;

            input {
                color: #030D45;
            }
        }
`;

const Suffix = styled.p`
    margin-right: 10px;
    &.darker {
        color: ${(props) => props.isDark ? 'var(--color-primary)' : '#030D45'};
    }
`;

const ErrorWrapper = styled.p`
    --font-size-body: var(--font-size-body-4);
    
    color: var(--color-error);
    padding: 0 10px;
    grid-column: 1 / -1;
`;

interface TextInputProperties {
    icon?: IconEnum,
    actionIcon?: IconEnum,
    value?: string | number | null,
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
    onFocus?: (e: FocusEvent<HTMLInputElement>) => void,
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void,
    onAction?: (e: KeyboardEvent<HTMLInputElement> | FocusEvent) => void,
    onClick?: (e: React.MouseEvent) => void,
    placeholder?: string,
    disabled?: boolean,
    important?: boolean,
    description?: boolean,
    type?: string,
    max?: string,
    min?: string,
    suff?: string,
    required?: boolean,
    isSaveFilterButton?: boolean,
    tagMode?: boolean,
    onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void
    isError?: boolean,
    inputValidation?: (value?: string | number | null) => boolean,
    errorMessage?: string,
    darker?: boolean,
    maxLength?: number
    triggerValidation?: boolean,
    pattern?: string
}

const TextInput = forwardRef(({
    onKeyDown,
    onFocus,
    onBlur,
    required,
    type,
    important,
    description,
    disabled,
    placeholder,
    onAction,
    actionIcon,
    icon,
    value,
    onChange,
    max,
    min,
    onClick,
    suff,
    isSaveFilterButton,
    tagMode,
    isError,
    inputValidation,
    errorMessage,
    darker,
    maxLength,
    triggerValidation,
    pattern
}: TextInputProperties, ref: ForwardedRef<HTMLInputElement>) => {
    const [isFocused, setIsFocused] = React.useState(false);
    const [isErrorInput, setIsErrorInput] = React.useState(false);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (maxLength && e.target.value.length > maxLength) return;
        onChange && onChange(e);
    };

    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
        setIsFocused(true);
        onFocus && onFocus(e);
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        setIsFocused(false);
        onBlur && onBlur(e);

        if (tagMode && onAction) {
            onAction(e);
        }

        if (inputValidation) {
            setIsErrorInput(inputValidation(value));
        } else {
            setIsErrorInput(false);
        }
    };

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if ((e.key === 'Enter' || tagMode && e.key === ',') && onAction) {
            onAction(e);

            if (inputValidation) {
                setIsErrorInput(inputValidation(value));
            } else {
                setIsErrorInput(false);
            }
        }

        onKeyDown && onKeyDown(e);
    };

    useEffect(() => {
        if (triggerValidation && inputValidation) {
            setIsErrorInput(inputValidation(value));
        }
    }, [triggerValidation]);

    return (
        <>
            <Container onClick={onClick} className={clsx({ important: important, focus: isFocused, error: isError || isErrorInput, darker: darker, description: description })}>
                {icon && <Icon classNames={clsx({ 'icon-in-button': important })} icon={icon}/>}
                {description
                    ? <TextArea ref={ref} onFocus={handleFocus} onClick={onClick} onBlur={handleBlur} required={required}
                        disabled={disabled} autocomplete="off" role="textbox"
                        placeholder={placeholder}
                        type={type ?? 'text'} onChange={handleChange} value={value ?? ''}
                        onKeyDown={handleKeyPress} max={max} min={min}/>
                    : <Input ref={ref} onFocus={handleFocus} onClick={onClick} onBlur={handleBlur} required={required} role="input" aria-label={placeholder}
                        disabled={disabled} autocomplete="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        placeholder={placeholder}
                        type={type ?? 'text'} onChange={handleChange} value={value ?? ''}
                        pattern={pattern}
                        onKeyDown={handleKeyPress} max={max} min={min}/>
                }
                {suff ? <Suffix classNames={clsx({ darker: darker })}>{suff}</Suffix> : null}
                {actionIcon &&
                    <Action className={clsx({ 'orange-button': isSaveFilterButton })} onClick={onAction}>
                        <Icon classNames={clsx({ 'icon-in-button': important })} icon={actionIcon}/>
                    </Action>
                }
            </Container>
            {isErrorInput ? <ErrorWrapper>{errorMessage}</ErrorWrapper> : null}
        </>
    );
});

export default TextInput;
