import { AxiosResponse } from 'axios';
import qs from 'qs';
import { axiosApiInstance2 } from 'Store';
import { convertPrice } from 'utils/convertPrice';
import { formatFilterPrice } from 'utils/formatFilterPrice';
import { getNormalizedSaleModuleName } from 'utils/formatters/getNormalizedSaleModuleName';
import { stringToNumber } from 'utils/formatters/stringToNumber';
import {
    SaleOfferDetailsResponse,
    SaleOfferDuplicatesResponse, SaleOfferHashResponse,
    SaleOfferResponseContent,
    SaleOffersResponse
} from 'utils/state-managment/sale/saleOffer';
import { SavedFilter } from 'utils/types/Filter';
import { LocationOptionsType, SavedLocationsType } from 'utils/types/Locations';
import { SaleFilterReturnData } from 'utils/types/Sale';
import { Module, OpinionUpdateInformation, SaleOpinionResult } from 'utils/types/SaleModels';

import { activeNotification } from 'components/functions/activeNotification';

const getSaleOffers = (params: SaleFilterReturnData, propertyType: Module, page: number, size: number): Promise<SaleOffersResponse> => {
    return axiosApiInstance2({
        method: 'post',
        url: `/sale-offer/advertisement/${getNormalizedSaleModuleName(propertyType)}`,
        params: {
            page,
            size
        },
        data: {
            ...params
        }
    })
        .then((resp) => {
            if (resp.data) {
                return {
                    ...resp.data,
                    content: resp.data.content.map((el: SaleOfferResponseContent) => ({
                        ...el,
                        city: el.location,
                        price: el.price ? convertPrice(el.price) : '',
                        pricePerSquareMeter: el.pricePerSquareMeter ? convertPrice(el.pricePerSquareMeter) : '',
                        reaction: {
                            likeStatus: el.reaction?.likeStatus,
                            unlikeStatus: el.reaction?.unlikeStatus,
                            viewedStatus: el.reaction?.viewedStatus,
                            outdatedStatus: el.reaction?.outdatedStatus,
                            arrangedStatus: el.reaction?.arrangedStatus,
                            phoneStatus: el.reaction?.phoneStatus,
                            hideStatus: el.reaction?.hideStatus,
                            openedStatus: el.reaction?.openedStatus
                        }
                    }))
                };
            }

            return { empty: true, content: [], afterSearch: true };
        })
        .catch(() => {
            activeNotification(
                'Nie znaleziono wyników',
                'Wyszukiwanie nie powiodło się, spróbuj jeszcze raz.',
                'warning'
            );

            return { empty: true, content: [], afterSearch: true };
        });
};

const getSaleOfferDetails = async (propertyType: Module, id: number): Promise<SaleOfferDetailsResponse> => {
    return axiosApiInstance2
        .get(`/sale-offer/advertisement/detail/${getNormalizedSaleModuleName(propertyType)}?advertisementId=${id}`)
        .then((resp) => {
            if (resp.data) {
                return {
                    ...resp.data,
                    price: resp.data.price ? convertPrice(resp.data.price) : ''
                };
            }
        })
        .catch(() => {
            activeNotification(
                'Nie udało się pobrać ogłoszenia',
                'Nie udało się pobrać danych szczegółowych ogłoszenia',
                'warning'
            );

            return [{ empty: true }];
        });
};

const getSaleOfferByHashId = async (hash: string): Promise<SaleOfferHashResponse> => {
    return axiosApiInstance2
        .get(`/sale-offer/single-advertisement/detail?hash=${hash}`)
        .then((resp) => {
            if (resp.data) {
                return {
                    ...resp.data,
                    price: resp.data.price ? convertPrice(resp.data.price) : '',
                    pricePerSquareMeter: resp.data.pricePerSquareMeter ? convertPrice(resp.data.pricePerSquareMeter) : ''
                };
            }
        })
        .catch(() => {
            activeNotification(
                'Nie udało się pobrać ogłoszenia',
                'Nie udało się pobrać danych szczegółowych ogłoszenia',
                'warning'
            );

            return null;
        });
};

const getSaleOffersCSVFile = (params: SaleFilterReturnData, propertyType: string) => {
    return axiosApiInstance2({
        method: 'post',
        responseType: 'blob',
        url: `/sale-offer/excel/${propertyType}`,
        data: {
            ...params,
            offerAdded: { from: params.offerAddedFrom, to: params.offerAddedTo },
            priceFrom: stringToNumber(params.priceFrom),
            priceTo: stringToNumber(params.priceTo),
            areaFrom: stringToNumber(params.areaFrom),
            areaTo: stringToNumber(params.areaTo),
            pricePerSquareMeterFrom: stringToNumber(params.pricePerSquareMeterFrom),
            pricePerSquareMeterTo: stringToNumber(params.pricePerSquareMeterTo)
            // daysAmount: params.dateRangeFilter ? null : params.daysAmount,
            // offerAddedFrom: params.dateRangeFilter ? params.offerAddedFrom : null,
            // offerAddedTo: params.dateRangeFilter ? params.offerAddedTo : null,
        }
    })
        .then((resp) => {
            const url = URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement('a');

            link.setAttribute('href', url);
            link.setAttribute('download', 'offers.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        });
};

const getSaleOfferDuplicates = async (propertyType: Module, id: number): Promise<SaleOfferDuplicatesResponse[]> => {
    return axiosApiInstance2
        .get(`/sale-offer/duplicate/find/${getNormalizedSaleModuleName(propertyType)}?advertisementId=${id}`)
        .then((resp) => {
            if (resp.data) {
                return resp.data;
            }
        })
        .catch(() => {
            activeNotification(
                'Nie udało się pobrać duplikatów ogłoszenia',
                'Nie udało się pobrać danych szczegółowych duplikatów ogłoszenia',
                'warning'
            );

            return [];
        });
};

const getSavedFilters = async (): Promise<SavedFilter<Module>[]> => {
    return axiosApiInstance2
        .get<SavedFilter<Module>[]>('/sale-offer/filter/list')
        .then((resp) => {
            if (resp.data) {
                return resp.data.map((el) => ({
                    ...el,
                    name: el.name
                }));
            }

            return [];
        })
        .catch((err) => {
            if (err?.response?.status === 401) {
                window.parent.location.reload();
            }

            return [];
        });
};

const getFilterDetailsSale = async (filterId: number, filterPropertyType: Module): Promise<SaleFilterReturnData> => {
    return axiosApiInstance2
        .get(`/sale-offer/filter/detail/${getNormalizedSaleModuleName(filterPropertyType)}?id=${filterId}`)
        .then((resp) => {
            const filters = resp.data;
            const savedLocations: SavedLocationsType[] = [];

            filters.city.forEach((city: LocationOptionsType) => {
                // @ts-expect-error TODO: INVEST-254
                savedLocations[city.fullName] = { ...city, label: city.fullName };
            });

            return {
                ...filters,
                city: filters.city ? filters.city : [],
                wantedKeywords: filters.wantedKeywords ? filters.wantedKeywords : [],
                unwantedKeywords: filters.unwantedKeywords ? filters.unwantedKeywords : [],
                wantedPhoneNumbers: filters.wantedPhoneNumbers ? filters.wantedPhoneNumbers : [],
                unwantedPhoneNumbers: filters.unwantedPhoneNumbers ? filters.unwantedPhoneNumbers : [],
                buildingType: filters.buildingType ? filters.buildingType : [],
                typeOfMarket: filters.typeOfMarket ? filters.typeOfMarket : [],
                propertyType: filters.propertyType ? filters.propertyType : [],
                id: filterId,
                name: filters.name ? filters.name : '',
                pushAlert: filters.pushAlert ? filters.pushAlert : false,
                alertEmail: filters.alertEmail ? filters.alertEmail : false,
                alertSms: filters.alertSms ? filters.alertSms : false,
                priceFrom: filters.priceFrom ? formatFilterPrice(filters.priceFrom) : '',
                priceTo: filters.priceTo ? formatFilterPrice(filters.priceTo) : '',
                areaFrom: filters.areaFrom ? filters.areaFrom : '',
                areaTo: filters.areaTo ? filters.areaTo : '',
                pricePerSquareMeterFrom: filters.pricePerSquareMeterFrom ? formatFilterPrice(filters.pricePerSquareMeterFrom) : '',
                pricePerSquareMeterTo: filters.pricePerSquareMeterTo ? formatFilterPrice(filters.pricePerSquareMeterTo) : '',
                numberOfRoomsFrom: filters.numberOfRoomsFrom ? filters.numberOfRoomsFrom : '',
                numberOfRoomsTo: filters.numberOfRoomsTo ? filters.numberOfRoomsTo : '',
                floorFrom: filters.floorFrom ? filters.floorFrom : '',
                floorTo: filters.floorTo ? filters.floorTo : '',
                builtYearFrom: filters.builtYearFrom ? filters.builtYearFrom : '',
                builtYearTo: filters.builtYearTo ? filters.builtYearTo : '',
                module: filters.module ? filters.module : '',
                tossedUp: filters.tossedUp ? filters.tossedUp : false,
                notificationsDelay: filters.notificationsDelay !== null && filters.notificationsDelay !== undefined
                    ? filters.notificationsDelay.toString()
                    : '0',
                groupDuplicate: filters.groupDuplicate ? filters.groupDuplicate : false,
                includeZeroArea: filters.includeZeroArea ? filters.includeZeroArea : false,
                modification: filters.modification ? true : filters.modification,
                offerAddedFrom: filters.offerAddedFrom ? filters.offerAddedFrom : '',
                offerAddedTo: filters.offerAddedTo ? filters.offerAddedTo : '',
                savedFilter: filters.savedFilter ? filters.savedFilter : false,
                locations: [],
                savedLocations: savedLocations,
                searchLocationResult: []
            };
        })
        .catch(() => {
            activeNotification('Problem z załadowaniem danych', 'Niestety nie udało się załadować danych. Spróbuj ponownie później', 'danger');
        });
};

const removeSavedFilter = (filterId: number, adType: Module) => {
    return axiosApiInstance2
        .delete(`/sale-offer/filter/delete?module=${adType}&id=${filterId}`)
        .then(() => {
            activeNotification('Udało się!', 'Filtr został usunięty', 'success');
        })
        .catch(() => {
            activeNotification('Problem z usunięciem', 'Niestety nie udało się usunąć danych. Spróbuj ponownie później', 'danger');
        });
};

const updateFilter = async (params: SaleFilterReturnData, propertyType: Module) => {
    return axiosApiInstance2({
        method: 'put',
        url: `/sale-offer/filter/edit/${getNormalizedSaleModuleName(propertyType)}`,
        params: { id: params.id },
        data: {
            ...params
        },
        paramsSerializer: function (params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        }
    })
        .then((resp) => {
            activeNotification('Udało się!', 'Filtr został zaktualizowany', 'success');

            return resp;
        })
        .catch((err) => {
            activeNotification('Problem z zapisem', 'Niestety nie udało się zapisać danych. Spróbuj ponownie później', 'danger');

            return Promise.reject(err);
        });
};

const addFilter = (params: SaleFilterReturnData, propertyType: Module): Promise<AxiosResponse<number>> => {
    const savedLocations = Object.values(params.savedLocations).map((x) => {
        // @ts-expect-error dont need districts and cities
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { districts, cities, ...rest } = x;

        return { ...rest };
    });

    return axiosApiInstance2({
        method: 'post',
        url: `/sale-offer/filter/add/${getNormalizedSaleModuleName(propertyType)}`,
        data: {
            ...params,
            city: savedLocations
        },
        paramsSerializer: function (params) {
            return qs.stringify(params);
        }
    })
        .then((resp) => {
            activeNotification('Udało się!', 'Filtr został dodany', 'success');

            return resp;
        })
        .catch((err) => {
            if (err?.response?.status === 400) {
                activeNotification(
                    'Błąd',
                    err.response.data,
                    'danger'
                );

                return Promise.reject(err);
            } else {
                activeNotification(
                    'Coś poszło nie tak!',
                    'Spróbuj ponownie',
                    'danger'
                );

                return Promise.reject(err);
            }
        });
};

const addOpinion = (advertisementId: number, content: string, numberOfStars: number, module: Module): Promise<OpinionUpdateInformation> => {
    return axiosApiInstance2({
        method: 'post',
        url: `/sale-offer/opinion/create?module=${module}`,
        data: {
            advertisementId,
            content,
            numberOfStars
        }
    })
        .then((response) => {
            activeNotification('Udało się!', 'Opinia została dodana', 'success');

            return response.data;
        })
        .catch(() => {
            activeNotification('Problem z dodaniem opinii', 'Niestety nie udało się dodać opinii', 'danger');
        });
};

const updateOpinion = (opinionId: number, content: string, numberOfStars: number, module: Module): Promise<OpinionUpdateInformation> => {
    return axiosApiInstance2({
        method: 'put',
        url: `/sale-offer/opinion/update?module=${module}`,
        data: {
            opinionId,
            content,
            numberOfStars
        }
    })
        .then((response) => {
            activeNotification('Udało się!', 'Opinia została za aktualizowana', 'success');

            return response.data;
        })
        .catch(() => {
            activeNotification('Problem z dodaniem opinii', 'Niestety nie udało się aktualizować opinii', 'danger');
        });
};

const deleteOpinion = (opinionId: number, module: Module): Promise<OpinionUpdateInformation> => {
    return axiosApiInstance2({
        method: 'delete',
        url: `/sale-offer/opinion/delete?module=${module}&opinionId=${opinionId}`
    })
        .then((response) => {
            activeNotification('Udało się!', 'Opinia została usunięta', 'success');

            return response.data;
        })
        .catch(() => {
            activeNotification('Problem z dodaniem opinii', 'Niestety nie udało się usunąć opinii', 'danger');
        });
};

const getOpinions = (advertisementId: number, module: Module): Promise<SaleOpinionResult> => {
    return axiosApiInstance2({
        method: 'get',
        url: `/sale-offer/opinion/find?module=${module}&advertisementId=${advertisementId}`
    })
        .then((resp) => {
            return resp.data;
        })
        .catch(() => {
            activeNotification('Problem z pobraniem opinii', 'Niestety nie udało się pobrać opinii', 'danger');
        });
};

export const saleOffersApiRequests = {
    getSaleOffers,
    getSaleOfferDetails,
    getSaleOffersCSVFile,
    getSaleOfferDuplicates,
    getFilterDetailsSale,
    getSavedFilters,
    removeSavedFilter,
    addFilter,
    updateFilter,
    addOpinion,
    updateOpinion,
    deleteOpinion,
    getOpinions,
    getSaleOfferByHashId
};
