import React, { FC } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import styled from 'styled-components';

import Icon, { IconEnum } from './Icon';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 85% 1fr;
  padding: 15px 0;
  align-items: center;

  @media (min-width: 1100px) {
    grid-column: span 2;
  }

  &.openable {
    background-color: var(--color-white);
    border-radius: var(--box-border-radius);
    padding: 15px;
    margin-top: 15px;
  }

  p {
    --font-weight: 400;
    line-height: 21px;
    text-align: justify;
  }
`;

const InputIcon = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--color-alt-second);
    margin-left: auto;

    &:hover {
        background: var(--color-alt);
        cursor: pointer;

        span {
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(105%) contrast(102%);
        }
    }
`;

export interface Note {
    content: string;
    date: string;
}

interface NoteBoxProperties {
    newestNote: Note;
    openOfferTab: (tab: string) => void;
    showNote?: () => void;
    openable?: boolean;
}

const NoteBox: FC<NoteBoxProperties> = ({ newestNote, openOfferTab, openable }) => {
    return (
        <Wrapper className={clsx({ openable: openable })}>
            <p><span>{dayjs(newestNote.date).format('DD.MM.YYYY, HH:mm')}</span> • {newestNote.content}</p>
            <InputIcon onClick={(event: Event) => {
                event.stopPropagation();
                openOfferTab('note');
            }}>
                <Icon icon={IconEnum.ARROW_RIGHT}/>
            </InputIcon>
        </Wrapper>
    );
};

export default NoteBox;
