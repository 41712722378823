import { Dispatch } from 'react';
import { FavoritePageFiltersType } from 'pages/FavoritesPage';
import { commonApiRequests } from 'utils/api-requests/common';
import { offersApiRequests } from 'utils/api-requests/offers';
import { rentOffersApiRequests } from 'utils/api-requests/rent';
import { NormalizedPropertyType } from 'utils/formatters/getNormalizedPropertyType';
import { CitiesType } from 'utils/types/Locations';
import { ModulesType } from 'utils/types/ModulesType';
import { UserReactions } from 'utils/types/OfferData';
import { RentModuleType, RentOfferRequestData } from 'utils/types/Rent';

import { OfferRequestData } from 'components/functions/advertisementsFuncs';

import { Action } from './Reducer';

const loadOffersAsync = async (dispatch: Dispatch<Action>, type: ModulesType, city: string | CitiesType[], filters: OfferRequestData | RentOfferRequestData, propertyType?: NormalizedPropertyType, page?: number, size?: number): Promise<void> => {
    dispatch({ type: 'SET_LOADING' });

    if (type === 'rent' || type === 'RENT_FLAT' || type === 'RENT_PLOT' || type === 'RENT_HOUSE' || type === 'RENT_OTHER') {
        dispatch({
            type: 'SET_RENT_OFFERS',
            payload: await offersApiRequests.getOffers(type, city, filters, propertyType, page, size)
        });
    } else {
        dispatch({
            type: 'SET_FILTERS',
            payload: await offersApiRequests.getOffers(type, city, filters)
        });
    }
    dispatch({ type: 'SET_SORT', payload: 'po dacie malejąco' });
};

const loadOfferDetailsAsync = async (dispatch: Dispatch<Action>, propertyType: RentModuleType, id: number): Promise<void> => {
    dispatch({
        type: 'SET_DETAILED_RENT_OFFER',
        payload: await offersApiRequests.getRentOfferDetails(propertyType, id)
    });
};

const loadFavoriteOffers = async (dispatch: Dispatch<Action>, type: ModulesType, filters?: FavoritePageFiltersType, page?: number, size?: number): Promise<void> => {
    dispatch({ type: 'SET_LOADING' });

    if (type === 'rent' || type === 'RENT_FLAT' || type === 'RENT_PLOT' || type === 'RENT_HOUSE' || type === 'RENT_OTHER') {
        dispatch({
            type: 'SET_RENT_OFFERS',
            payload: await offersApiRequests.getFavoriteOffers(type, filters, page, size)
        });
    } else {
        dispatch({
            type: 'SET_FILTERS',
            payload: await offersApiRequests.getFavoriteOffers(type, filters)
        });
    }
    dispatch({ type: 'SET_SORT', payload: 'po dacie malejąco' });
};

const loadOldNotesAsync = async (dispatch: Dispatch<Action>, type: ModulesType, id: string, city: string, v2Type?: RentModuleType): Promise<void> => {
    if (type === 'rent' || type === 'RENT_FLAT' || type === 'RENT_PLOT' || type === 'RENT_HOUSE' || type === 'RENT_OTHER') {
        dispatch({
            type: 'V2_SET_NOTES',
            payload: {
                id,
                data: await commonApiRequests.getNotes(v2Type!, id)
            }
        });
    } else {
        dispatch({ type: 'SET_LOADING' });
        dispatch({
            type: 'SET_OLD_NOTES',
            payload: {
                id,
                data: await offersApiRequests.getNotes(type, id, city)
            }
        });
    }
};

const loadOpinionsAsync = async (dispatch: Dispatch<Action>, id: number, module: RentModuleType) => {
    const opinions = await rentOffersApiRequests.getOpinions(id, module);

    dispatch({
        type: 'SET_OPINIONS',
        payload: {
            id,
            data: opinions
        }
    });
};

const loadDuplicates = async (dispatch: Dispatch<Action>, type: RentModuleType, id: string): Promise<void> => {
    dispatch({ type: 'SET_LOADING' });
    dispatch({
        type: 'SET_DUPLICATES',
        payload: {
            id,
            data: await offersApiRequests.getOfferDuplicates(type, id)
        }
    });
};

const sortOffers = async (dispatch: Dispatch<Action>, sort: string): Promise<void> => {
    dispatch({
        type: 'SET_SORT',
        payload: sort
    });
};

const clearOffers = async (dispatch: Dispatch<Action>): Promise<void> => {
    dispatch({
        type: 'CLEAR'
    });
};

const reactionUpdate = async (dispatch: Dispatch<Action>, id: string, city: string, type: ModulesType, reactions: UserReactions, v2Type?: RentModuleType): Promise<void> => {
    if (type === 'rent' || type === 'RENT_FLAT' || type === 'RENT_PLOT' || type === 'RENT_HOUSE' || type === 'RENT_OTHER') {
        // @ts-expect-error TODO: id should be a number, will be fixed after refactoring
        const response = await commonApiRequests.setOfferReaction(id, city, reactions, v2Type!);

        if (response) {
            dispatch({
                type: 'V2_REACTION_UPDATE',
                payload: {
                    id, reactions: response
                }
            });
        }
    } else {
        const validChangeStatus = await offersApiRequests.setOfferReaction(id, city, type, reactions);

        if (validChangeStatus) {
            dispatch({
                type: 'REACTION_UPDATE',
                payload: {
                    id, reactions
                }
            });
        }
    }
};

const setNewNote = async (dispatch: Dispatch<Action>, id: string, city: string, type: ModulesType, note: string): Promise<void> => {
    dispatch({ type: 'SET_LOADING' });
    const validChangeStatus = await offersApiRequests.setNewOfferNote(id, city, type, note);

    if (validChangeStatus.validated) {
        dispatch({
            type: 'SET_NEW_NOTE',
            payload: {
                id, note, ...validChangeStatus.data
            }
        });
    }

    dispatch({ type: 'SET_LOADING', payload: false });
};

const deleteNote = async (dispatch: Dispatch<Action>, id: string, type: ModulesType, noteId: number, v2Type?: RentModuleType): Promise<void> => {
    if (type === 'rent' || type === 'RENT_FLAT' || type === 'RENT_PLOT' || type === 'RENT_HOUSE' || type === 'RENT_OTHER') {
        dispatch({ type: 'SET_LOADING' });
        const validChangeStatus = await commonApiRequests.deleteNote(v2Type!, noteId);

        if (validChangeStatus) {
            dispatch({
                type: 'V2_DELETE_NOTE',
                payload: {
                    noteId, id
                }
            });
        }

        dispatch({ type: 'SET_LOADING', payload: false });
    } else {
        dispatch({ type: 'SET_LOADING' });
        const validChangeStatus = await offersApiRequests.deleteNote(type, noteId);

        if (validChangeStatus) {
            dispatch({
                type: 'DELETE_NOTE',
                payload: {
                    noteId, id
                }
            });
        }

        dispatch({ type: 'SET_LOADING', payload: false });
    }
};

const updateNote = async (dispatch: Dispatch<Action>, id: string | number, city: string, type: ModulesType, note: string, noteId: number, v2Type?: RentModuleType): Promise<void> => {
    if (type === 'rent' || type === 'RENT_FLAT' || type === 'RENT_PLOT' || type === 'RENT_HOUSE' || type === 'RENT_OTHER') {
        await updateRentNote(dispatch, id as number, v2Type!, note, noteId);
    } else {
        dispatch({ type: 'SET_LOADING' });
        const validChangeStatus = await offersApiRequests.updateNote(type, note, noteId, id as string, city);

        if (validChangeStatus) {
            dispatch({
                type: 'UPDATE_NOTE',
                payload: {
                    ...validChangeStatus
                }
            });
        }

        dispatch({ type: 'SET_LOADING', payload: false });
    }
};

const updateRentNote = async (dispatch: Dispatch<Action>, id: number, type: RentModuleType, note: string, noteId?: number): Promise<void> => {
    dispatch({ type: 'SET_LOADING' });
    const validChangeStatus = await commonApiRequests.updateNote(type, note, id, noteId);

    if (validChangeStatus) {
        if (noteId) {
            dispatch({
                type: 'V2_UPDATE_NOTE',
                payload: {
                    id,
                    ...validChangeStatus
                }
            });
        } else {
            dispatch({
                type: 'V2_NEW_NOTE',
                payload: {
                    id,
                    ...validChangeStatus
                }
            });
        }
    }

    dispatch({ type: 'SET_LOADING', payload: false });
};

const updateOpinion = async (dispatch: Dispatch<Action>, module: RentModuleType, commentContent: string, rating: number, advertisementId: number, opinionId?: number): Promise<void> => {
    dispatch({ type: 'SET_LOADING' });

    if (opinionId) {
        const response = await rentOffersApiRequests.updateOpinion(opinionId, commentContent, rating, module);

        if (response) {
            dispatch({
                type: 'UPDATE_OPINION',
                payload: {
                    response,
                    advertisementId
                }
            });
        }
    } else {
        const response = await rentOffersApiRequests.addOpinion(advertisementId, commentContent, rating, module);
        const newOpinions = await rentOffersApiRequests.getOpinions(advertisementId, module);

        if (response) {
            dispatch({
                type: 'NEW_OPINION',
                payload: {
                    response,
                    newOpinions,
                    advertisementId,
                    rating,
                    commentContent
                }
            });
        }
    }

    dispatch({ type: 'SET_LOADING', payload: false });
};

const deleteOpinion = async (dispatch: Dispatch<Action>, id: number, module: RentModuleType, opinionId: number): Promise<void> => {
    dispatch({ type: 'SET_LOADING' });

    const response = await rentOffersApiRequests.deleteOpinion(opinionId, module);

    if (response) {
        dispatch({
            type: 'DELETE_OPINION',
            payload: {
                response,
                id,
                opinionId
            }
        });
    }

    dispatch({ type: 'SET_LOADING', payload: false });
};

export const OffersActions = {
    loadOffersAsync,
    sortOffers,
    reactionUpdate,
    clearOffers,
    setNewNote,
    loadOldNotesAsync,
    deleteNote,
    updateNote,
    loadFavoriteOffers,
    loadOfferDetailsAsync,
    loadDuplicates,
    loadOpinionsAsync,
    updateOpinion,
    deleteOpinion
};
