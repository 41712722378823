import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';

import Icon, { IconEnum } from './Icon';

const DetailsModal = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
    opacity: 1;

  top: 0;
  left: 0;
  
  z-index: 1;

  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const ModalBackground = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${(props) => props.darkMode ? `rgba(7, 7, 7, ${props.fullOpacity ? '1' : '0.9'})` : `rgba(242, 245, 249, ${props.fullOpacity ? '1' : '0.9'})`};
`;

const ModalContentContainer = styled.div<ModalContentProperties>`
  position: relative;
  width: 100%;
  height: ${(props) => props.centeredContent ? '100%' : 'auto'};
  max-width: 1000px;
  max-height: 95vh;
  border-radius: var(--box-border-radius);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-top: 20px;
  z-index: 1;
  ${(props) => props.centeredContent ? 'justify-content: center' : null}
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  height: ${(props) => props.centeredContent ? '100%' : 'auto'};
  ${(props) => props.centeredContent ? null : 'overflow-y: scroll'};
  ${(props) => props.centeredContent ? 'display: flex' : null};
  ${(props) => props.centeredContent ? 'flex-direction: column' : null};
  ${(props) => props.centeredContent ? 'justify-content: center' : null};
  background-color: var(--color-alt-second);
`;

const ModalCloseButton = styled.div`
  position: absolute;

  right: 15px;
  top: 15px;
  background: var(--color-white);
  border-radius: 50%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 10px;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    transition: transform 0.2s ease-in-out;
    transform: rotateZ(90deg) scale(1.1);
  }

  z-index: 2;
`;

const ModalWrapper = styled.div`
    ${(props) => props.showContent ? null : 'display: none'};
    z-index: 100;
    position: relative;
`;

interface ModalContentProperties {
    centeredContent?: boolean;
}

interface OfferDetailsModalProperties {
    isOpen?: boolean;
    onClose: (e: KeyboardEvent) => void;
    children: ReactNode;
    centeredContent?: boolean;
    fullOpacity?: boolean;
    type?: string;
}

const OfferDetailsModal: FC<OfferDetailsModalProperties> = ({ children, isOpen, onClose, centeredContent, fullOpacity, type }) => {
    const { user } = useUser();
    const ref = useRef(null);
    const [showContent, setShowContent] = useState(isOpen);

    useEffect(() => {
        setShowContent(isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (!ref.current || type !== 'rent') return;
        const mainNode = document.querySelector('#offer-list');
        mainNode?.contains(ref.current) ? mainNode?.parentNode?.insertBefore(ref.current, mainNode) : null;
    }, []);

    useEffect(() => {
        const keyDownHandler = (event: KeyboardEvent) => {
            if (event.key === 'Escape' && showContent) {
                event.preventDefault();
                onClose(event);
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onClose]);

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <ModalWrapper showContent={showContent} onClick={handleClick} ref={ref}>
            {showContent
                ? <DetailsModal>
                    <ModalBackground fullOpacity={fullOpacity} darkMode={user.isDarkMode} onClick={!fullOpacity ? onClose : undefined} />
                    <ModalContentContainer centeredContent={centeredContent}>
                        <ModalCloseButton onClick={onClose}>
                            <Icon icon={IconEnum.CROSS}/>
                        </ModalCloseButton>
                        <Content centeredContent={centeredContent}>
                            {children}
                        </Content>
                    </ModalContentContainer>
                </DetailsModal>
                : null}
        </ModalWrapper>
    );
};

export default OfferDetailsModal;
