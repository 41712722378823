import { UserSavedFilter } from './Filter';
import { MultipleFilterType } from './InputTypes';
import { CitiesType, LocationOptionsType, SavedLocationsType, SearchLocationResultType } from './Locations';
import { SortType } from './SortType';
import {
    BooleanEnum, BuildingTypeEnum,
    CapitalBaseDtoFormOfInvestmentEnum,
    CapitalBaseDtoInvestmentSecurityEnum,
    CapitalEditDto,
    DealBaseDtoFeaturesEnum,
    DealBaseDtoLegalStatusEnum,
    DealBoxDtoBuildingTypeEnum,
    DealEditDto, FilterCapitalDtoFormOfInvestmentEnum, FilterCapitalDtoFormOfProfitEnum,
    FilterCapitalDtoInvestmentSecurityEnum,
    FilterCapitalPageDto,
    FilterDealDto, FilterDealDtoFeaturesEnum,
    FilterDealDtoLegalStatusEnum,
    FilterEditCapitalDto,
    FilterEditDealDto,
    FilterEditInvestorDto,
    FilterEditInvestorDtoLegalStatusEnum,
    FilterInvestorDto,
    FilterInvestorDtoFormOfInvestmentEnum,
    FilterInvestorDtoInvestmentSecurityEnum,
    InvestorBaseDtoFormOfInvestmentEnum,
    InvestorBaseDtoInvestmentSecurityEnum,
    InvestorBaseDtoLegalStatusEnum,
    InvestorBoxDtoPropertyConditionEnum,
    InvestorEditDto,
    LocationDto
} from './UserModels';

export enum V2OfferType {
    DEAL = 'deal',
    INVESTOR = 'investor',
    CAPITAL = 'capital',
}

export type RequireOnlyOne<T, Keys extends keyof T = keyof T> =
    Pick<T, Exclude<keyof T, Keys>>
    & {
    [K in Keys]-?:
    Required<Pick<T, K>>
    & Partial<Record<Exclude<Keys, K>, undefined>>
}[Keys]

export interface UsersFiltersStateType {
    city: CitiesType[];
    filterId: number | null,
    dateRangeFilter?: boolean;
    name: string | null,
    offerType: V2OfferType;
    savedFilter: [] | UserSavedFilter[],
    locations: LocationOptionsType[],
    searchLocationResult: SearchLocationResultType[],
    savedLocations: SavedLocationsType,
    includeZeroArea?: boolean;
    wantedKeywords?: string[];
    unwantedKeywords?: string[];
    wantedPhoneNumbers?: string[];
    unwantedPhoneNumbers?: string[];
    dateAdded?: MultipleFilterType;
    daysAmount?: number;
    alertSms?: boolean;
    alertEmail?: boolean;
    pushAlert?: boolean;
    buildingType?: RequireOnlyOne<DealBoxDtoBuildingTypeEnum>;
    legalStatus?: RequireOnlyOne<FilterEditInvestorDtoLegalStatusEnum | FilterDealDtoLegalStatusEnum>;
    propertyCondition?: [InvestorBoxDtoPropertyConditionEnum];
    floor?: MultipleFilterType;
    area?: MultipleFilterType;
    preContract?: BooleanEnum[];
    constructionCrew?: BooleanEnum[];
    ownContribution?: MultipleFilterType;
    investorContribution?: MultipleFilterType;
    totalInvestmentValue?: MultipleFilterType;
    minimumSalePrice?: number;
    maximumSalePrice?: number;
    estimatedSaleDateFrom?: string;
    estimatedSaleDateTo?: string;
    investmentAmountFrom?: MultipleFilterType;
    investmentAmountTo?: MultipleFilterType;
    percentageOfInvestment?: MultipleFilterType;
    investmentTimeFrom?: number;
    investmentTimeTo?: number;
    investmentSecurity?: FilterInvestorDtoInvestmentSecurityEnum | FilterCapitalDtoInvestmentSecurityEnum;
    formOfInvestment?: [FilterInvestorDtoFormOfInvestmentEnum | FilterCapitalDtoFormOfInvestmentEnum];
    expectedProfit?: MultipleFilterType;
    price?: MultipleFilterType;
    renovationExpenses?: MultipleFilterType;
    investmentBudget?: MultipleFilterType;
    roi?: MultipleFilterType;
    leaseFee?: MultipleFilterType;
    floors?: MultipleFilterType;
    features?: RequireOnlyOne<FilterDealDtoFeaturesEnum>;
    availableFrom?: string;
    rentalIncome?: MultipleFilterType;
    investmentValue?: MultipleFilterType;
    formOfProfit?: FilterCapitalDtoFormOfProfitEnum[];
    profitAmount?: MultipleFilterType;
    expectedReturnRate?: MultipleFilterType;
    landRegistryNumber?: string;
    sortType: SortType;
}

interface CostEstimate {
    name: string;
    price: number;
}

export interface UserAddAdvertStateType {
    costEstimationTitle?: string;
    costEstimationPrice?: string;
    locations: LocationOptionsType[],
    searchLocationResult: SearchLocationResultType[],
    savedLocations: SavedLocationsType,
    city?: LocationDto[];
    cityName?: string;
    offerType: V2OfferType;
    expectedProfit?: number;
    price?: number;
    title?: string;
    description?: string;
    renovationExpenses?: number;
    investmentBudget?: number;
    roi?: number;
    leaseFee?: number;
    buildingType?: BuildingTypeEnum;
    floor?: number;
    floors?: number;
    area?: number;
    features?: DealBaseDtoFeaturesEnum;
    preContract?: boolean;
    availableFrom?: string;
    investmentGoal?: string;
    rentalIncome?: number;
    propertyCondition?: InvestorBoxDtoPropertyConditionEnum;
    legalStatus?: DealBaseDtoLegalStatusEnum | InvestorBaseDtoLegalStatusEnum;
    landRegistryNumber?: string;
    photos?: string[];
    documents?: string[];
    constructionCrew?: boolean;
    ownContribution?: number;
    investorContribution?: number;
    totalInvestmentValue?: number;
    minimumSalePrice?: number;
    maximumSalePrice?: number;
    estimatedSaleDate?: string;
    costEstimate?: CostEstimate[];
    investmentAmountFrom?: number;
    investmentAmountTo?: number;
    percentageOfInvestment?: number;
    investmentTime?: string;
    funding?: string;
    investmentSecurity?: InvestorBaseDtoInvestmentSecurityEnum | CapitalBaseDtoInvestmentSecurityEnum;
    formOfInvestment?: InvestorBaseDtoFormOfInvestmentEnum | CapitalBaseDtoFormOfInvestmentEnum;
    details?: string;
    investmentValue?: MultipleFilterType;
    formOfProfit?: FilterCapitalDtoFormOfProfitEnum[];
    minimumProfitAmount?: number;
    expectedReturnRate?: number;
    investmentTimeFrom?: string;
    investmentTimeTo?: string;
    minimumInvestmentValue?: number;
    maximumInvestmentValue?: number;
}

type FilterEditDto = FilterEditInvestorDto & FilterEditDealDto & FilterEditCapitalDto;

type OfferGetDto = FilterInvestorDto | FilterDealDto | FilterCapitalPageDto;

type OfferEditDto = InvestorEditDto & DealEditDto & CapitalEditDto;

export interface UserFilterReturnData extends FilterEditDto {
    city: LocationDto[];
    offerType: V2OfferType;
    savedFilter?: [] | UserSavedFilter[],
    locations?: LocationOptionsType[], // potrzebne?
    searchLocationResult?: SearchLocationResultType[], // potrzebne?
    savedLocations: SavedLocationsType,
}

// @ts-expect-error TODO: INVEST-254 fix later
export interface UserAdvertEditReturnData extends OfferEditDto {
    city: LocationDto[];
    cityName: string;
    offerType: V2OfferType;
    savedFilter?: [] | UserSavedFilter[],
    locations?: LocationOptionsType[], // potrzebne?
    searchLocationResult?: SearchLocationResultType[], // potrzebne?
    savedLocations: SavedLocationsType,
}

export type UserOfferRequestData = OfferGetDto & {
    offerType: V2OfferType;
    savedFilter?: [] | UserSavedFilter[],
    locations?: LocationOptionsType[], // potrzebne?
    searchLocationResult?: SearchLocationResultType[], // potrzebne?
    savedLocations: SavedLocationsType,
}
