import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { isTouchDevice } from 'utils/checkIsTouchDevice';
import { ImageSize } from 'utils/enums/imageSize';

import Icon, { IconEnum } from 'components/common/Icon';
import Modal from 'components/common/Modal/Modal';

import Dot from './Dot';

const ImgStyled = styled.img<ImageProperties>`
    object-fit: cover;
    width: 100%;
    height: 100%;
    ${(props) => props.isBiggerSlider ? 'max-width: 850px' : null};
    ${(props) => props.isBiggerSlider ? 'max-height: 500px' : null};
    ${(props) => props.isBiggerSlider ? 'justify-self: center' : null};
    ${(props) => props.isBiggerSlider ? 'border-radius: 10px' : null};
`;

const ImgThumbnail = styled.img`
    width: 100%;
    height: 100%;
    max-width: 160px;
    max-height: 80px;
    border-radius: 6px;
    ${(props) => !props.active ? 'opacity: .5' : null};
`;

const Action = styled.div`
    background: white;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 14px;

    img {
        width: 15px;
        height: 15px;
    }
`;

const ActionFullscreen = styled.div`
    position: absolute;
    top: 22px;
    width: 100%;
    display: flex;
    justify-content: end;
`;

const Wrapper = styled.div`
    z-index: 100;
    height: 100%;
    display: grid;
`;

const ActionArrows = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-self: center;
`;

const Dots = styled.div`
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: center;
    align-self: end;
    margin-bottom: ${(props) => props.isBiggerSlider ? '30px' : '5px'};
`;

const ModalWrapper = styled.div`
    h1 {
        margin-bottom: 40px;
        text-align: center;
    }
`;

const SliderModalContent = styled.div`

`;

const ThumbnailImagesWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 40px;
    justify-items: center;
    margin-top: 40px;

    div {
        height: 100%;
        width: 100%;
        max-width: 160px;
        max-height: 80px;
    }
`;

interface ImageProperties {
    isBiggerSlider?: boolean;
}

export interface SliderImages {
    url: string;
}

interface SliderProperties {
    images: SliderImages[];
    title?: string;
    isHovered?: boolean;
    isBiggerSlider?: boolean;
    modalOpen?: boolean;
    toggleIsModalSlider?: () => void;
    modalMode?: boolean;
}

const Slider: FC<SliderProperties> = ({
    images,
    title,
    isHovered,
    isBiggerSlider,
    toggleIsModalSlider,
    modalOpen = false,
    modalMode = false
}) => {
    const [isModalOpen, setIsModalOpen] = useState(modalOpen);
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToPrevious = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const goToNext = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        const isLastSlide = currentIndex === images.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    const goToSlide = (e: React.MouseEvent, slideIndex: number) => {
        e.stopPropagation();
        e.preventDefault();

        setCurrentIndex(slideIndex);
    };

    const handleModalOpen = (e: React.MouseEvent | KeyboardEvent, isOpen: boolean) => {
        e.stopPropagation();
        e.preventDefault();

        toggleIsModalSlider ? toggleIsModalSlider() : setIsModalOpen(isOpen);
    };

    return (
        <>
            <Wrapper>
                {isTouchDevice() || isHovered || isBiggerSlider
                    ? <>
                        {!isBiggerSlider
                            ? <ActionFullscreen>
                                <Action onClick={(e: React.MouseEvent) => handleModalOpen(e, true)}>
                                    <Icon classNames={'icon-in-button'} icon={IconEnum.FULLSCREEN}/>
                                </Action>
                            </ActionFullscreen>
                            : null}
                        <ActionArrows>
                            <Action onClick={goToPrevious}>
                                <Icon classNames={'icon-in-button'} icon={IconEnum.ARROW_LEFT}/>
                            </Action>
                            <Action onClick={goToNext}>
                                <Icon classNames={'icon-in-button'} icon={IconEnum.ARROW_RIGHT}/>
                            </Action>
                        </ActionArrows>
                    </>
                    : null}
                {!modalMode ? <ImgStyled isBiggerSlider src={images[currentIndex].url.replace('PHOTO_SIZE', isBiggerSlider ? ImageSize.NORMAL_PHOTO : ImageSize.FULL_SCREEN_PHOTO)}/> : null}
                {!modalMode
                    ? <Dots isBiggerSlider={isBiggerSlider}>
                        {images.map((_, slideIndex) => <div
                            key={slideIndex}
                            onClick={(e) => goToSlide(e, slideIndex)}
                        >
                            <Dot slideIndex={slideIndex} currentIndex={currentIndex}/>
                        </div>)}
                    </Dots>
                    : null}
                <Modal isOpen={isModalOpen} onClose={(e: React.MouseEvent | KeyboardEvent) => handleModalOpen(e, false)}
                    centeredContent>
                    <ModalWrapper>
                        <h1>{title}</h1>
                        <SliderModalContent>
                            <Slider images={images} isBiggerSlider/>
                        </SliderModalContent>
                    </ModalWrapper>
                </Modal>
            </Wrapper>
            {isBiggerSlider
                ? <ThumbnailImagesWrapper>
                    {images.map((_, slideIndex) => <div
                        key={slideIndex}
                        onClick={(e) => goToSlide(e, slideIndex)}
                    >
                        <ImgThumbnail active={currentIndex === slideIndex} src={images[slideIndex].url.replace('PHOTO_SIZE', ImageSize.FULL_SCREEN_PHOTO)}/>
                    </div>)}
                </ThumbnailImagesWrapper>
                : null}
        </>
    );
};

export default Slider;
