import React, { FC, useCallback, useState } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { getPropertyByTypeRent, getPropertyByTypeUser } from 'utils/formatters/getPropertyByType';
import { stringToBoolean } from 'utils/formatters/stringToBoolean';
import { ModulesType } from 'utils/types/ModulesType';
import { OfferData } from 'utils/types/OfferData';
import { RentModuleType } from 'utils/types/Rent';
import { V2OfferType } from 'utils/types/UsersAdvert';
import { useWindowWidth } from 'utils/useWindowWidth';

import NoteBox from 'components/common/NoteBox';
import Slider from 'components/common/Slider/Slider';

import CardBodyDefault from './common/CardBodyDefault';
import InfoBadge from './common/InfoBadge';

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    border-radius: var(--box-border-radius);
    background-color: var(--color-white);
    padding: 15px;
    gap: 22px;
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out;
    position: relative;

    &:hover {
        transition: box-shadow 0.3s ease-in-out;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.07);
    }

    & > div:nth-child(1) {
        width: 100%;
        height: 150px;
    }

    & > div:nth-child(2) {
        width: 100%;
        height: auto;
    }
    
    &.grey {
        opacity: 0.5;
    }

    @media (min-width: 991px) {
        grid-template-columns: 240px 1fr;

        &.bigImage {
            grid-template-columns: 340px 1fr;
        }

        & > div:nth-child(1) {
            width: 240px;
        }

        & > div:nth-child(2) {
            width: 100%;
            height: auto;
        }
    }
`;

export const InfoBoxContainer = styled.div`
    position: absolute;
    top: 0;
    width: fit-content;
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
`;

export const CardImageContainer = styled.div`
    background: var(--color-alt-second);
    border-radius: var(--box-border-radius);
    overflow: hidden;
    position: relative;
    min-height: 240px;

    &.big {
        min-height: 250px;
        min-width: 300px;

        @media (max-width: 991px) {
            min-height: 240px;
            min-width: 240px;
        }
    }
`;

export const ImgStyled = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease-in-out;

    &:hover {
        transition: transform 0.3s ease-in-out;
        transform: scale(1.2);
    }
`;

interface BaseCardProperties {
    data: OfferData;
    onClick: (event?: Event) => void;
    type: ModulesType;
    openOfferTab: (tab: string) => void;
    showLastNote?: () => void;
    showNote?: boolean;
}

const BaseCard: FC<BaseCardProperties> = ({
    data,
    onClick,
    openOfferTab,
    type,
    showNote,
    showLastNote
}) => {
    const [hoveredImgArea, setHoveredImgArea] = useState(false);
    const {
        thumbnailPath,
        dateAdded,
        offerFrom,
        auctionDate,
        tooltipDate,
        note,
        dateAddedToDataBase,
        propertyType,
        // @ts-expect-error will be changed when refactoring
        lastUpdated,
        reaction
    } = data;

    const [isSuccess, setIsSuccess] = useState(type !== 'rent' ? !tooltipDate : !lastUpdated);
    const width = useWindowWidth();
    const moduleType = type === 'rent' ? getPropertyByTypeRent(data.module as RentModuleType) : type === 'RENT_FLAT' || type === 'RENT_PLOT' || type === 'RENT_HOUSE' || type === 'RENT_OTHER' ? type : getPropertyByTypeUser(V2OfferType.CAPITAL);
    const images = data.photos && data.photos.length > 0 ? data.photos.map((photo) => ({ url: photo.includes('http') ? photo : `${process.env.REACT_APP_API_URL_2}image/find?module=${moduleType}&fileName=${photo}&photoSize=NORMAL_PHOTO` })) : undefined;
    const modifyDate = tooltipDate;
    const [offerDate, setOfferDate] = useState(dateAdded);
    const [rentOfferDate, setRentOfferDate] = useState(lastUpdated || dateAdded);

    const handleOpenOfferTab = () => {
        if (showLastNote) showLastNote();

        openOfferTab('note');
    };

    const handleBadgeChange = (e: Event) => {
        if (modifyDate && width <= 1200 && type !== 'rent') {
            e.stopPropagation();
            offerDate === dateAdded ? setOfferDate(modifyDate) : setOfferDate(dateAdded);
            setIsSuccess((prev) => !prev);
        }

        if (lastUpdated && width <= 1200 && type === 'rent') {
            e.stopPropagation();
            rentOfferDate === dateAdded ? setRentOfferDate(lastUpdated) : setRentOfferDate(dateAdded);
            setIsSuccess((prev) => !prev);
        }
    };

    const onMouseLeave = useCallback(() => {
        if (modifyDate && width > 1100 && type !== 'rent') {
            setTimeout(() => {
                setOfferDate(dateAdded);
                setIsSuccess(false);
            }, 150);
        }

        if (lastUpdated && width > 1100 && type === 'rent') {
            setTimeout(() => {
                setRentOfferDate(lastUpdated);
                setIsSuccess(false);
            }, 150);
        }
    }, [
        modifyDate,
        tooltipDate,
        width,
        lastUpdated
    ]);

    const onMouseEnter = useCallback(() => {
        if (modifyDate && width > 1100 && type !== 'rent') {
            setTimeout(() => {
                setOfferDate(modifyDate);
                setIsSuccess(true);
            }, 150);
        }

        if (lastUpdated && width > 1100 && type === 'rent') {
            setTimeout(() => {
                setRentOfferDate(dateAdded);
                setIsSuccess(true);
            }, 150);
        }
    }, [
        dateAdded,
        width,
        lastUpdated
    ]);

    return (
        <Container onClick={onClick}
            className={clsx({ bigImage: type === 'users' || type === 'my_offer' || type === 'profile' || type === 'rent' || type === 'RENT_FLAT' || type === 'RENT_PLOT' || type === 'RENT_HOUSE' || type === 'RENT_OTHER', grey: reaction.openedStatus && type !== 'RENT_FLAT' && type !== 'RENT_PLOT' && type !== 'RENT_HOUSE' && type !== 'RENT_OTHER' })}>
            <CardImageContainer
                className={clsx({ big: type === 'users' || type === 'my_offer' || type === 'profile' || type === 'rent' || type === 'RENT_FLAT' || type === 'RENT_PLOT' || type === 'RENT_HOUSE' || type === 'RENT_OTHER' })}
                onMouseEnter={() => setHoveredImgArea(true)} onMouseLeave={() => setHoveredImgArea(false)}
            >
                {images
                    ? <Slider images={images} title={data.title} isHovered={hoveredImgArea}/>
                    : <ImgStyled src={thumbnailPath || '/defaultImg.png'}/>}
                <InfoBoxContainer>
                    <InfoBadge onClick={handleBadgeChange}
                        isWhite={type === 'my_offer' || type === 'users' || type === 'profile' || type === 'rent' }
                        onMouseLeave={onMouseLeave}
                        onMouseEnter={onMouseEnter}
                        isSuccess={isSuccess} isWarning={!isSuccess} showInfoIcon={stringToBoolean(modifyDate)}>
                        {type !== 'rent' ? dayjs(offerDate || dateAddedToDataBase || dateAdded).format('DD.MM.YYYY, HH:mm') : dayjs(rentOfferDate || dateAdded).format('DD.MM.YYYY, HH:mm')}
                    </InfoBadge>
                    {type === 'my_offer' || type === 'users' || type === 'profile'
                        ? <InfoBadge isWhite>
                            {propertyType}
                        </InfoBadge>
                        : offerFrom && <InfoBadge isWhite>
                            {offerFrom}
                        </InfoBadge>}
                    {auctionDate && <InfoBadge isWhite>
                        Data licytacji: {dayjs(auctionDate).format('DD.MM.YYYY')}
                    </InfoBadge>}
                </InfoBoxContainer>
            </CardImageContainer>
            <CardBodyDefault
                data={data}
                type={type}
                onNoteAdd={() => {
                    if (!showNote && showLastNote) {
                        showLastNote();
                    }

                    if (!data.note?.noteId) {
                        openOfferTab('note');
                    }
                }}
            />
            {showNote
                ? <NoteBox newestNote={note} openOfferTab={handleOpenOfferTab}/>
                : null}
        </Container>
    );
};

export default BaseCard;
