import { FC, useState } from 'react';
import React from 'react';
import styled from 'styled-components';

import { IconEnum } from 'components/common/Icon';
import IconButton from 'components/common/IconButton';
import InputContainer from 'components/common/InputContainer';

import TextInput from './TextInput';

export const ButtonWrapper = styled.span`
    display: flex;
    gap: 5px;

    p {
        --font-size-body: var(--font-size-body-3);

        cursor: default;
    }
`;

export const Keywords = styled.div`
    display: flex;
    gap: 10px 6px;
    padding: 0 10px;
    flex-wrap: wrap;
`;

interface KeywordsProps {
    handleChange: (name: string, value: string[]) => void,
    value: string[],
    errorMessage: string,
    minLength: number,
    maxLength?: number,
    fieldName: string,
    inputValidation: (value?: string | number | null) => boolean,
    title: string,
    helperText: string,
    icon: IconEnum,
    inputIcon: IconEnum,
    actionIcon: IconEnum,
    placeholder: string,
}

const KeywordsInput: FC<KeywordsProps> = ({
    handleChange,
    value,
    errorMessage,
    minLength,
    maxLength,
    fieldName,
    title,
    helperText,
    icon,
    inputIcon,
    actionIcon,
    placeholder,
    inputValidation
}) => {
    const [keywords, setKeywords] = useState('');

    const handleKeywordAdd = (e: KeyboardEvent) => {
        e.preventDefault();

        if (keywords.length >= minLength) {
            if (maxLength && keywords.length > maxLength) return;
            handleChange(fieldName, [...value, keywords]);
            setKeywords('');
        }
    };

    const handleChangeKeyword = ({ target: { value } }: { target: { value: string } }) => {
        setKeywords(value.split(/\s{2,}/).join(' '));
    };

    const handleRemoveKeyword = (keyword: string) => {
        handleChange(fieldName, value.filter((x: string) => x !== keyword));
    };

    return (
        <InputContainer title={title}
            helperText={helperText}
            icon={icon}>
            <TextInput
                placeholder={placeholder}
                onChange={handleChangeKeyword}
                value={keywords}
                icon={inputIcon}
                actionIcon={actionIcon}
                onAction={(e) => handleKeywordAdd(e as unknown as KeyboardEvent)}
                tagMode
                inputValidation={inputValidation}
                errorMessage={errorMessage}
            />
            <Keywords>
                {value?.map((keyword: string, index: number) => <ButtonWrapper key={index}>
                    <p>{keyword}</p>
                    <IconButton
                        onClick={() => handleRemoveKeyword(keyword)}
                        icon={IconEnum.CIRCLE_CROSS}/>
                </ButtonWrapper>)}
            </Keywords>
        </InputContainer>
    );
};

export default KeywordsInput;
