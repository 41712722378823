import React, { FC, useState } from 'react';
import { usePopper } from 'react-popper';
import clsx from 'clsx';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';

const Wrapper = styled.div`
    z-index: 1;
`;

export const Cloud = styled.div`
    position: relative;
    color: var(--color-primary);
    max-width: 600px;
    width: 100%;
    text-align: center;
    height: auto;
    border-radius: 13px;
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.08));
    background: ${(props) => props.darkMode ? 'var(--color-alt-hover)' : 'var(--color-alt-second)'};
    padding: 5px 12px;
    font-size: 13px;
    line-height: 20px;
    border: 1px solid rgba(3, 13, 69, 0.05);
    ${(props) => props.bottomTooltip ? 'margin-top: 12px' : 'margin-bottom: 12px'};

`;

export const Triangle = styled.div`
    :after {
        content: '';
        position: absolute;
        ${(props) => props.bottomTooltip ? 'bottom: 27px;' : 'top: -18px;'}
        left: -5px;
        width: 10px;
        height: 10px;
        background: ${(props) => props.darkMode ? 'var(--color-alt-hover)' : 'var(--color-alt-second)'};
        transform: ${(props) => props.bottomTooltip ? 'rotate(225deg)' : 'rotate(45deg)'};
        border-bottom: 1px solid rgba(3, 13, 69, 0.05);
        border-right: 1px solid rgba(3, 13, 69, 0.05);
        border-bottom-right-radius: 2px;
        z-index: 1;
    }
`;

interface TooltipProps {
    text?: string | React.ReactChildren;
    alertsTooltip?: boolean;
    bottomTooltip?: boolean;
    showTooltip?: boolean;
    referenceElement?: Element | null;
}

const Tooltip: FC<TooltipProps> = ({
    text,
    alertsTooltip,
    bottomTooltip,
    showTooltip,
    referenceElement,
    ...rest
}) => {
    const { user } = useUser();
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);

    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'top',
        modifiers: [{ name: 'arrow', options: { element: arrowElement } }]
    });

    return showTooltip && text
        ? <Wrapper role="tooltip" ref={setPopperElement} style={styles.popper} {...attributes.popper}
            className={clsx({ 'alerts-tooltip': alertsTooltip, 'bottom-tooltip': bottomTooltip })} {...rest}>
            <Cloud darkMode={user.isDarkMode} bottomTooltip={bottomTooltip}>{text}</Cloud>
            <Triangle darkMode={user.isDarkMode} bottomTooltip={bottomTooltip} ref={setArrowElement} {...attributes.arrow} style={styles.arrow} />
        </Wrapper>
        : null;
};

export default Tooltip;
