
import React, { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { UserActions } from 'store/User/Actions';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';

import { Title } from './Title';

const ActivePackagesContainer = styled.div`
    padding: 30px 10px 30px 15px;
    border-radius: var(--box-border-radius);
    background: var(--color-white);
    display: flex;
    flex-direction: column;
    width: 33%;
    gap: 10px;
    margin-bottom: 60px;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const Header = styled.h2`
    margin-bottom: 10px;
`;

const Wrapper = styled.span`
    position: relative;
    display: flex;
    gap: 10px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background: var(--color-alt-second);
    color: var(--color-primary);
    padding: 15px 19px;
    border-radius: var(--border-radius);
    height: 50px;
`;

const Label = styled.span`
    font-weight: var(--font-weight);
    font-size: var(--font-size-body-2);
    line-height: 20px;
    background: transparent;
    border: none;
    color: var(--color-primary);
`;

const ValueBox = styled.div`
    line-height: 30px;
`;

interface ActivePackagesProps {
    title: string;
}

const ActivePackagesSettings = forwardRef<HTMLDivElement, ActivePackagesProps>(({ title }, ref: ForwardedRef<HTMLDivElement>) => {
    const { user, dispatch } = useUser();
    const [userActivePackagesInfo, setUserActivePackagesInfo] = useState(user.activePackageInfo);

    useEffect(() => {
        UserActions.getActivePackageInfo(dispatch);
    }, [dispatch]);

    useEffect(() => {
        setUserActivePackagesInfo(user.activePackageInfo);
    }, [user.activePackageInfo]);

    const activePackagesInfoBoxesStructure = [
        {
            label: 'Pakiet aktywny od:',
            value: userActivePackagesInfo.startDate ? dayjs(userActivePackagesInfo.startDate).format('DD.MM.YYYY, HH:mm') : ''
        },
        {
            label: 'Pakiet aktywny do:',
            value: userActivePackagesInfo.endDate ? dayjs(userActivePackagesInfo.endDate).format('DD.MM.YYYY, HH:mm') : ''
        },
        {
            label: 'Pozostało:',
            value: userActivePackagesInfo.daysToEnd ? `${userActivePackagesInfo.daysToEnd} dni` : ''
        }
    ];

    return (
        <div ref={ref}>
            <Title>{title}</Title>
            <ActivePackagesContainer>
                <Header>{userActivePackagesInfo.packageName}</Header>
                {activePackagesInfoBoxesStructure.map((el) => <Wrapper key={el.label}>
                    <Label>{el.label}</Label>
                    <ValueBox>{el.value}</ValueBox>
                </Wrapper>)}
            </ActivePackagesContainer>
        </div>
    );
});

export default ActivePackagesSettings;
