import styled from 'styled-components';

export const Title = styled.div`
    font-style: normal;
    font-weight: var(--font-weight);
    font-size: 20px;
    line-height: 30px;

    color: var(--color-primary);
    padding: 12px 15px;
`;
