import React, { FC } from 'react';
import { useUser } from 'store/User/Context';
import { UserFiltersProvider } from 'utils/context/UserFiltersContext';

import Indicator from 'components/atom/Indicator';
import UsersOfferListMini from 'components/users/UsersOfferListMini';

const UserOffersMiniPage: FC = () => {
    const { user } = useUser();

    return (
        <UserFiltersProvider>
            <UsersOfferListMini
                limitItems={4}
                showList={!user.usersOfferList.empty && user.usersOfferList.content && user.usersOfferList.content?.length > 0}
                type={'users'}
                showNoItemsText={user.usersOfferList && user.usersOfferList.empty}
            />
            {user.isLoading && <Indicator/>}
        </UserFiltersProvider>
    );
};

export default UserOffersMiniPage;
